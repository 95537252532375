import React from 'react';
import { DefaultIcon } from '../../../components/Icon';
import { capitalize, getReadableDate } from '../../../lib/utils';
import { Listing } from '../../../types';
import { RentalType } from '../../../types/RentalType';
import { ListingType } from '../../../types/ListingType';
import {
  Highlight,
  HighlightDescription,
  HighlightLabel,
  HighlightLabelDescription,
  HighlightsSection,
} from '../components';
import IfLoading from '../../../components/IfLoading';

const iconByRentalType = {
  [RentalType.apartment]: 'apartmentRentalType',
  [RentalType.house]: 'houseRentalType',
  [RentalType.condo]: 'condoRentalType',
  [RentalType.coop]: 'coopRentalType',
  [RentalType.townhouse]: 'townhouseRentalType',
};

const highlightByListingType = {
  [ListingType.FULL]: {
    label: 'Entire Place',
    description: 'A place all to yourself',
    icon: 'entirePlace',
  },
  [ListingType.PRIVATE]: {
    label: 'Private room',
    description: 'Your own room, plus some shared common spaces',
    icon: 'privateRoom',
  },
  [ListingType.SHARED]: {
    label: 'Shared room',
    description:
      'A sleeping space and common areas that may be shared with others',
    icon: 'sharedRoom',
  },
};

const leaseDuration = (leaseDurationDays?: number): string => {
  if (leaseDurationDays !== undefined && leaseDurationDays <= 7 * 6) {
    return 'Short term';
  } else {
    return 'Long term';
  }
};

export type HighlightType = {
  icon: string;
  label: string;
  description: string;
};

const Highlights: React.FC<{
  isLoading: boolean;
  listingDetails?: Listing;
}> = ({ isLoading, listingDetails }) => {
  const now = new Date();
  const listingTypeHighlight =
    highlightByListingType[listingDetails?.listingType?.toUpperCase() || ''] ||
    highlightByListingType[ListingType.FULL];
  const rentalTypeHighlight = listingDetails
    ? `${capitalize(listingDetails.rentalType)} in ${capitalize(
        listingDetails.address.city
      )}`
    : '';
  const rentalTypeHighlightDescription = listingDetails
    ? `${listingDetails.address.state}, ${listingDetails.address.countryCode}`
    : '';
  const durationHighlightDescription = leaseDuration(
    listingDetails?.leaseDurationDays
  );
  const availableAt =
    listingDetails && new Date(listingDetails.availableAt) <= now
      ? `Available ${getReadableDate(listingDetails.availableAt)}`
      : `Available on ${getReadableDate(
          listingDetails?.availableAt || now.toISOString()
        )}`;

  const highlights: HighlightType[] = [
    {
      icon:
        iconByRentalType[listingDetails?.rentalType || ''] ||
        'apartmentRentalType',
      label: rentalTypeHighlight,
      description: rentalTypeHighlightDescription,
    },
    {
      icon: listingTypeHighlight.icon,
      label: listingTypeHighlight.label,
      description: listingTypeHighlight.description,
    },
    {
      icon: 'calendar',
      label: availableAt,
      description: durationHighlightDescription,
    },
  ];

  return (
    <HighlightsSection data-testid="listing-highlights">
      {highlights.map((highlight) => (
        <Highlight key={highlight.label}>
          <IfLoading
            isLoading={isLoading}
            loadMultiple={false}
            numLoaders={1}
            height={36}
            width={36}
          >
            <DefaultIcon name={highlight.icon} />
          </IfLoading>
          {isLoading ? <div style={{ marginRight: '16px' }} /> : ''}
          <IfLoading
            isLoading={isLoading}
            loadMultiple={false}
            numLoaders={1}
            height={36}
            width={300}
          >
            <HighlightDescription>
              <HighlightLabel>{highlight.label}</HighlightLabel>
              <HighlightLabelDescription>
                {highlight.description}
              </HighlightLabelDescription>
            </HighlightDescription>
          </IfLoading>
        </Highlight>
      ))}
    </HighlightsSection>
  );
};

export default Highlights;
