import React from 'react';
import styled from '@emotion/styled';

import { fontWeightMap, WeightTypes } from '../utils';

export enum LabelTypes {
  l1 = 'l1',
  l2 = 'l2',
  l3 = 'l3',
  l4 = 'l4',
  l5 = 'l5',
  l6 = 'l6',
}

const fontSizeMap = {
  l1: 1.375, // 22px
  l2: 1.25, // 20px
  l3: 1.125, // 18px
  l4: 1, // 16px
  l5: 0.875, // 14px
  l6: 0.625, // 10px
};

export type LabelPropTypes = {
  type: LabelTypes;
  weight?: WeightTypes;
  className?: string;
  color?: string;
  styles?: React.CSSProperties;
};

const Label: React.FC<LabelPropTypes> = ({ styles, children, ...rest }) => {
  return (
    <StyledLabel style={styles} {...rest}>
      {children}
    </StyledLabel>
  );
};

export default Label;

const StyledLabel = styled.div<LabelPropTypes>`
  font-family: 'ZichtbaarRegular';
  font-weight: ${({ weight = WeightTypes.normal }) => fontWeightMap[weight]};
  font-size: ${({ type }) => `${fontSizeMap[type]}rem`};
  color: ${({ color }) => color};
`;
