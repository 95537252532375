import { lastResortFallbackLocation } from '../../lib/constants';
import { DiscoverFeed } from '../../lib/services/discover';
import { VideoListingDetails } from '../../types';

export const discoverFeeds: DiscoverFeed[] = ['popular', 'local'];

export const extractMapLocation = (
  videoListingDetails?: VideoListingDetails,
  fallback = lastResortFallbackLocation
) => {
  return videoListingDetails?.approximateLocation || fallback;
};
