function resetViewportUnits() {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

let debounceTimeout;
window.addEventListener('resize', () => {
  window.clearTimeout(debounceTimeout);
  window.setTimeout(resetViewportUnits, 200);
});

resetViewportUnits();

export {};
