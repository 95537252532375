import type { apiClient } from '../../hooks';
import { MessageProps } from '../../pages/Home/Messaging';

export type MessageService = {
  fetchMessagesByListingId: (listingId: string) => Promise<Array<MessageProps>>;
  updateMessageRead: (messageId: string) => Promise<MessageProps>;
  updateMessageResponded: (messageId: string) => Promise<MessageProps>;
};

export const messageServiceFactory = (
  apiClient: apiClient
): MessageService => ({
  fetchMessagesByListingId: (listingId) =>
    apiClient(`/messaging/by-listing-id?listing_id=${listingId}`),
  updateMessageRead: (messageId) =>
    apiClient(`/messaging/set-read`, {
      method: 'PUT',
      body: {
        message_id: messageId,
      },
    }),
  updateMessageResponded: (messageId) =>
    apiClient(`/messaging/set-responded`, {
      method: 'PUT',
      body: {
        message_id: messageId,
      },
    }),
});
