/* @jsxImportSource @emotion/react */

import React from 'react';
import styled from '@emotion/styled';
import Icon from '../Icon';
import { MediaFormat } from '../../../types/Media';
import { useFullscreen } from '../../../hooks';
import { ReactComponent as EnterFullscreenSvg } from '../images/EnterFullscreen.svg';
import { ReactComponent as ExitFullscreenSvg } from '../images/ExitFullscreen.svg';
import useScrollLock from '../../../hooks/useScrollLock';
import VideoPlayButton from '../../../components/VideoPlayButton';

type Video = {
  mediaFormat: MediaFormat;
  url: string;
};

type VideoProps = {
  videos: Video[];
  isPlaying: boolean;
  handlePlay: () => void;
  handleError: () => void;
};

const MobileVideo: React.FC<VideoProps> = ({
  videos,
  isPlaying,
  handlePlay,
  handleError,
}) => {
  const { scrollStart, scrollMove, scrollEnd } = useScrollLock();

  return (
    <StyledVideo onClick={handlePlay} key={videos[0]?.url}>
      <video
        id="carousel-video"
        onTouchStart={scrollStart}
        onTouchMove={scrollMove}
        onTouchCancel={scrollEnd}
        onTouchEnd={scrollEnd}
        onError={handleError}
        playsInline={true}
        autoPlay={true}
        muted={true}
        preload="auto"
      >
        {videos.map(({ url, mediaFormat }) => {
          return (
            <source src={url} type={`video/${mediaFormat.toLowerCase()}`} />
          );
        })}
      </video>

      <VideoPlayButton showButton={!isPlaying} />
    </StyledVideo>
  );
};

const DesktopVideo: React.FC<VideoProps> = ({
  videos,
  isPlaying,
  handlePlay,
  handleError,
}) => {
  const {
    fullscreenRef,
    isFullscreen,
    enterFullscreen,
    exitFullscreen,
  } = useFullscreen();

  return (
    <StyledVideo onClick={handlePlay} ref={fullscreenRef} key={videos[0]?.url}>
      <video
        data-testid="listing-desktop-video"
        id="carousel-video"
        onError={handleError}
        autoPlay={true}
        muted={true}
        preload="auto"
      >
        {videos.map(({ url, mediaFormat }) => {
          return (
            <source src={url} type={`video/${mediaFormat.toLowerCase()}`} />
          );
        })}
      </video>

      <VideoPlayButton showButton={!isPlaying} />

      <FullscreenButton
        onClick={() => (isFullscreen ? exitFullscreen() : enterFullscreen())}
      >
        <Icon
          css={{
            backgroundColor: 'transparent',
            stroke: `white !important`,
          }}
          icon={isFullscreen ? ExitFullscreenSvg : EnterFullscreenSvg}
        />
      </FullscreenButton>
    </StyledVideo>
  );
};

export { MobileVideo, DesktopVideo };

export const StyledVideo = styled.div`
  position: relative;
  background: linear-gradient(90deg, #eee, #f5f5f5, #eee);
  flex: 1;
  height: 100%;

  video {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  button {
    visibility: hidden;
  }

  &:hover {
    button {
      visibility: visible;
    }
  }

  @media ${({ theme }) => theme.mediaQuery.medium} {
    height: auto;

    video {
      object-fit: cover;
      border-top-left-radius: 16px;
      border-bottom-left-radius: 16px;

      transition: hover 0.25s ease;

      &:hover {
        -webkit-filter: brightness(80%);
      }
    }
  }x
`;

export const FullscreenButton = styled.button`
  right: 0;
  bottom: 0;
  margin: 0px 1rem 1rem 0px;
  border: none;
  background-color: transparent;
  position: absolute;
  z-index: ${({ theme }) => theme.zIndices.middle};
  cursor: pointer;
  transition: hover 0.25s ease-in-out;

  &:hover {
    opacity: 1;
    transform: scale(0.9);
  }
`;
