import React from 'react';
import styled from '@emotion/styled';

import { fontWeightMap, WeightTypes } from '../utils';

export enum HeadingElementTypes {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
  h6 = 'h6',
}

const fontSizeMap = {
  h1: 2.75, // 44px
  h2: 2.5, // 40px
  h3: 2.25, // 36px
  h4: 2, // 32px
  h5: 1.75, // 28px
  h6: 1.5, // 24px
};

export type HeadingPropTypes = {
  type: HeadingElementTypes;
  weight?: WeightTypes;
  className?: string;
  color?: string;
  styles?: React.CSSProperties;
};

const Heading: React.FC<HeadingPropTypes> = ({
  type,
  styles,
  children,
  ...rest
}) => {
  const Component = (type as unknown) as keyof JSX.IntrinsicElements;

  return (
    <StyledHeading as={Component} type={type} style={styles} {...rest}>
      {children}
    </StyledHeading>
  );
};

export default Heading;

const StyledHeading = styled.div<HeadingPropTypes>`
  font-family: 'ZichtbaarRegular';
  font-weight: ${({ weight = WeightTypes.normal }) => fontWeightMap[weight]};
  font-size: ${({ type }) => `${fontSizeMap[type]}rem`};
  color: ${({ color }) => color};
`;
