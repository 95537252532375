import styled from '@emotion/styled';
import { useState } from 'react';
import { VideoListingDetails } from '../../../types';
import { bedsDisplay } from '../../../lib/utils';
import { DefaultIcon } from '../../../components/Icon';
import {
  DEFAULT_VIDEO_HEIGHT,
  DEFAULT_VIDEO_WIDTH,
  MIN_VIDEO_HEIGHT,
  MIN_VIDEO_WIDTH,
} from './constants';

type VideoPreviewProps = {
  video: VideoListingDetails;
  className?: string;
  redirectOnClick?: (listingId: string) => void;
};

const playVideo = (id) => {
  const videoEl = document.getElementById(id) as HTMLVideoElement;
  if (videoEl) {
    videoEl.play();
  }
};

const pauseVideo = (id) => {
  const videoEl = document.getElementById(id) as HTMLVideoElement;
  if (videoEl) {
    videoEl.pause();
    videoEl.currentTime = 0;
  }
};

const VideoPreview: React.FC<VideoPreviewProps> = ({
  video,
  className = 'marketplace-video-container',
  redirectOnClick,
}) => {
  const videoId = `marketplace-video-${video.videoUrl}`;
  const [showVideo, setShowVideo] = useState<boolean>();
  const onClick = () => {
    if (redirectOnClick) {
      pauseVideo(videoId);
      redirectOnClick(video.listingId);
    }
  };

  return (
    <VideoContainer className={className}>
      <Poster
        onClick={onClick}
        onMouseOver={() => {
          setShowVideo(true);
          playVideo(videoId);
        }}
        onMouseLeave={() => {
          setShowVideo(false);
          pauseVideo(videoId);
        }}
        src={video.posterUrl}
      />
      <Video
        className="marketplace-video"
        visibility={showVideo ? 'visible' : 'hidden'}
        onClick={onClick}
        id={`${videoId}`}
        onLoadedMetadata={() => setShowVideo(true)}
        onMouseOver={() => playVideo(videoId)}
        onMouseLeave={() => pauseVideo(videoId)}
        poster={video.posterUrl}
        src={video.videoUrl}
        muted={true}
        playsInline={true}
        autoPlay={false}
      />
      <ListingDetails>
        {bedsDisplay(video.numBedrooms)} in {video.city}
      </ListingDetails>
      <StyledIconWrapper>
        <DefaultIcon css={{ width: '100%' }} name="globeGradient" />
      </StyledIconWrapper>
    </VideoContainer>
  );
};

export default VideoPreview;

const VideoContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  min-height: ${MIN_VIDEO_HEIGHT}px;
  min-width: ${MIN_VIDEO_WIDTH}px;
  max-width: calc(100% - 30px);
  aspect-ratio: ${DEFAULT_VIDEO_WIDTH} / ${DEFAULT_VIDEO_HEIGHT};
`;

const Video = styled.video<{ visibility: string }>`
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 16px;
  visibility: ${(props) => props.visibility};
  cursor: pointer;
`;

const Poster = styled.img`
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 16px;
  cursor: pointer;
`;

const StyledIconWrapper = styled.div`
  position: absolute;
  width: 44px;
  height: 44px;
  bottom: 25px;
  margin: auto;
  left: 0;
  right: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colorMap.neutral.black};
`;

const ListingDetails = styled.div`
  color: ${({ theme }) => theme.colorMap.neutral.white};
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  padding: 1rem;
  font-size: 1.25rem;
`;
