import styled from '@emotion/styled';
import React, { useContext } from 'react';
import ReactModal, { Styles } from 'react-modal';
import { ModalContext } from '../../hooks/useModal';
import { zIndices } from '../../lib/theme';

const ModalOverlayStyle: React.CSSProperties = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: 'rgba(75, 75, 75, 0.5)',
  backdropFilter: 'blur(1.25px)',
  zIndex: zIndices.topmost,
};

const MODAL_TRANSITION_MS = 400;

const Modal: React.FC = () => {
  const { isOpen, handleCloseModal, contentStyles, modalContent } = useContext(
    ModalContext
  );

  const style: Styles = {
    overlay: ModalOverlayStyle,
    content: contentStyles,
  };

  return (
    <StyledReactModal
      isOpen={isOpen}
      onRequestClose={handleCloseModal}
      contentLabel="Modal"
      style={style}
      preventScroll={true}
      onAfterOpen={() => (document.body.style.overflow = 'hidden')}
      onAfterClose={() => (document.body.style.overflow = 'unset')}
      appElement={document.getElementsByTagName('body')[0]}
      height={style.content?.height || '95%'}
    >
      {modalContent}
    </StyledReactModal>
  );
};

const StyledReactModal = styled(ReactModal)<{ height: number | string }>`
  @keyframes slide-up {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  transform: translateY(100%);
  animation: slide-up ${MODAL_TRANSITION_MS}ms cubic-bezier(0.4, 0, 0.2, 1)
    100ms forwards;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    opacity: 0;
    transform: translateY(0);
    animation: none;
    animation: fade-in ${MODAL_TRANSITION_MS}ms cubic-bezier(0.4, 0, 0.2, 1)
      100ms forwards;
  }
`;

export default Modal;
