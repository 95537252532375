/* @jsxImportSource @emotion/react */

import React from 'react';
import styled from '@emotion/styled';

/**
 * primary: button with a filled in background
 * secondary: button with a white background and solid border
 * tertiary: button with just the text
 */
export type ButtonVariant = 'primary' | 'secondary' | 'tertiary' | 'underline';

export type ButtonSize = 'small' | 'medium' | 'large' | 'unset';

export type ButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  variant?: ButtonVariant;
  size?: ButtonSize;
  style?: React.CSSProperties;
};

const StyledButton = styled.button`
  appearance: none;

  display: block;
  width: 100%;
  max-width: 171px;
  min-height: 40px;

  text-align: center;
  font-size: 0.875rem;
  font-weight: 600;

  border: 1px solid transparent;
  border-radius: 16px;

  &:focus {
    outline: none;
  }

  &:active {
    position: relative;
    top: 1px;
  }

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.color.disabled};
    border-color: ${({ theme }) => theme.color.disabled};
    color: white;
  }
`;

const getStylesForVariant = (variant: ButtonVariant, color, theme) => {
  const themeColor = theme.color[color];

  switch (variant) {
    case 'primary':
      return {
        backgroundColor: themeColor,
        color: 'white',
      };
    case 'tertiary':
      return {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        color: themeColor,
      };
    case 'secondary':
      return {
        backgroundColor: 'white',
        border: `1px solid ${themeColor}`,
        color: themeColor,
      };
    case 'underline':
      return {
        backgroundColor: 'white',
        borderColor: themeColor,
        color: themeColor,
        border: 'none',
        borderRadius: 'unset',
        borderBottom: `1px solid ${themeColor}`,
      };
  }
};

const getStylesForSize = (size: ButtonSize) => {
  switch (size) {
    case 'small':
      return {
        maxWidth: '80px',
        minHeight: '30px',
        borderRadius: '10px',
      };
    case 'large':
      return {
        fontSize: '1rem',
        maxWidth: '327px',
        minHeight: '48px',
      };
    case 'unset':
      return {
        fontSize: '20px',
        minHeight: 'unset',
        maxHeight: 'unset',
        minWidth: 'unset',
        maxWidth: 'unset',
      };
  }
  return {};
};

const getVariantStyles = (variant: ButtonVariant, size: ButtonSize, color) => (
  theme
) => ({
  ...getStylesForVariant(variant, color, theme),
  ...getStylesForSize(size),
});

const Button = ({
  variant = 'primary',
  size = 'medium',
  color,
  ...props
}: ButtonProps = {}) => {
  const styles = React.useMemo(() => getVariantStyles(variant, size, color), [
    variant,
    size,
    color,
  ]);
  return <StyledButton css={styles} {...props} />;
};

export default Button;
