import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { DEFAULT_QUERY_CACHE_STALE_TIME } from '../lib/constants';
import {
  getSessionStorage,
  setSessionStorage,
  SS_ONBOARDING_PAGE_PATH,
  SS_SHOW_ONBOARDING_IF_REQUIRED,
} from '../lib/session-storage';
import { Address } from '../types';
import { useApiClient } from './index';

export enum UserAction {
  COMPLETE_REVIEW_FORM = 'COMPLETE_REVIEW_FORM',
  UPLOAD_IMAGES = 'UPLOAD_IMAGES',
  UPLOAD_VIDEOS = 'UPLOAD_VIDEOS',
}

export interface UserInfo {
  id: string;
  mobileNumber: string;
  email: string;
  firstName: string;
  lastName: string;
}

export interface UserActions {
  required?: UserAction;
  optional: UserAction[];
}

export interface UserSearchLocation {
  location?: {
    latitude: number;
    longitude: number;
  };
  city?: string;
  state?: string;
}

export interface UserSearchDefaults {
  expectedLocation?: UserSearchLocation;
  expectedMoveDate?: Date;
}

export interface UserListing {
  listingId: string;
  rentalId: string;
  address: Address;
}

type AppConfig = {
  user?: UserInfo;
  actions: UserActions;
  searchDefaults: UserSearchDefaults;
  userListing?: UserListing;
};

export const AppConfigCacheKey = 'appConfig';

const randomizeOnboardingRouteToShow = () => {
  return Math.round(Math.random()) === 0 ? '/listings/add' : '/welcome';
};

export const ConfigContext = React.createContext<AppConfig>({} as AppConfig);

function ConfigProvider(props) {
  const apiClient = useApiClient();
  const history = useHistory();
  const { isLoading, data } = useQuery(
    AppConfigCacheKey,
    () => apiClient('/config'),
    { staleTime: DEFAULT_QUERY_CACHE_STALE_TIME }
  );

  useEffect(() => {
    if (isLoading) return;
    // Only show to users on login/signup right now if this flag is set:
    if (getSessionStorage(SS_SHOW_ONBOARDING_IF_REQUIRED) !== true) return;

    const {
      userListing,
      actions: { required, optional },
    } = data as AppConfig;

    if (required === UserAction.COMPLETE_REVIEW_FORM) {
      // (A/B Test) unicorn.atlassian.net/browse/UN-647
      const cachedReviewPage = getSessionStorage(SS_ONBOARDING_PAGE_PATH);
      if (!!cachedReviewPage) {
        history.push(cachedReviewPage);
      } else {
        const path = randomizeOnboardingRouteToShow();
        setSessionStorage(SS_ONBOARDING_PAGE_PATH, path);
        history.push(path);
      }
    } else if (
      userListing?.listingId &&
      optional.includes(UserAction.UPLOAD_VIDEOS)
    ) {
      history.push(`/listings/add/media/${userListing.listingId}`);
    }
  }, [isLoading, data, history]);

  return isLoading ? null : <ConfigContext.Provider value={data} {...props} />;
}

const useConfig = () => React.useContext(ConfigContext);

export { ConfigProvider, useConfig };
