import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { FullscreenLoader } from '../../components/Animations/Loader';
import Discover from '../Discover';
import ListingDetailPage from '../ListingDetailPage';
import Marketplace from '../Marketplace';
import QrCodeRedirect from '../QrCodeRedirect';
import RedirectRoute from '../Redirect';
import Home from './Home';

const Login = React.lazy(() => import('./Login'));

const Auth = () => (
  <React.Suspense fallback={<FullscreenLoader />}>
    <Switch>
      <Route path="/listings/:listingId" exact component={ListingDetailPage} />
      <Route path="/discover" component={Discover} />
      <Route path="/marketplace" exact component={Marketplace} />
      <Route
        path="/create"
        exact
        render={(props) => <Login flow="create" {...props} />}
      />
      <Route path="/login" exact component={Login} />
      <Route path="/qr/:code" exact component={QrCodeRedirect} />
      <Route path="/" exact component={Home} />
      <Route
        component={(props) => <RedirectRoute redirectTo="/" {...props} />}
      />
    </Switch>
  </React.Suspense>
);

export default Auth;
