import React from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { useMediaQuery } from '../../hooks';
import { Logo } from '../UI/Brand';
import Display, { DisplayElementTypes } from '../Typography/Display';
import { WeightTypes } from '../Typography/utils';
import { DefaultIcon } from '../Icon';
import Heading, { HeadingElementTypes } from '../Typography/Heading';
import Button from '../UI/Button';

export type DecisionIconProps = {
  iconName: string;
  iconWidth?: number;
  iconHeight?: number;
};

export type DecisionButtonOptionProps = {
  elementId: string;
  text?: string;
  icon?: DecisionIconProps;
  handleOnClick: (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

export type DecisionBottomButtonProps = {
  elementId: string;
  text?: string;
  handleOnClick: (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export type DecisionPageProps = {
  title: string;
  subTitle?: string;
  buttonOptions: DecisionButtonOptionProps[];
  bottomButton?: DecisionBottomButtonProps;
};

export type DecisionTitleProps = {
  text: string;
  minWidthMedium: boolean;
  color: string;
};

const DecisionTitle: React.FC<DecisionTitleProps> = ({
  text,
  minWidthMedium,
  color,
}) => {
  return minWidthMedium ? (
    <Display
      data-testid="decision-page-title"
      css={{ marginBottom: '0.5rem' }}
      type={DisplayElementTypes.d5}
      color={color}
      weight={WeightTypes.bold}
    >
      {text}
    </Display>
  ) : (
    <Heading
      data-testid="decision-page-title"
      type={HeadingElementTypes.h3}
      color={color}
      weight={WeightTypes.bold}
    >
      {text}
    </Heading>
  );
};

const DecisionSubTitle: React.FC<DecisionTitleProps> = ({
  text,
  minWidthMedium,
  color,
}) => {
  return minWidthMedium ? (
    <Heading
      data-testid="decision-page-sub-title"
      styles={{ width: '500px' }}
      type={HeadingElementTypes.h5}
      color={color}
      weight={WeightTypes.bold}
    >
      {text}
    </Heading>
  ) : (
    <SubTitle
      data-testid="decision-page-sub-title"
      style={{ color: `${color}` }}
    >
      {text}
    </SubTitle>
  );
};

const DecisionBottomButton: React.FC<DecisionBottomButtonProps> = ({
  elementId,
  text,
  handleOnClick,
}) => {
  return (
    <BottomButtonContainer>
      <Button
        data-testid={elementId}
        id={elementId}
        variant="primary"
        color="black"
        style={{ maxWidth: '85%' }}
        onClick={handleOnClick}
      >
        {text}
      </Button>
    </BottomButtonContainer>
  );
};

const DecisionPage: React.FC<DecisionPageProps> = ({
  title,
  subTitle,
  buttonOptions,
  bottomButton,
}) => {
  const { colorMap, mediaQuery } = useTheme();
  const minWidthMedium = useMediaQuery(mediaQuery.medium);

  return (
    <Container>
      <TitleSection>
        <LogoContainer>
          <Logo
            css={{
              color: 'white !important',
              width: '31px',
            }}
          />
        </LogoContainer>
        <Title>
          {/* Title */}
          <DecisionTitle
            text={title}
            minWidthMedium={minWidthMedium}
            color={colorMap.neutral.white}
          />
          {/* Sub title */}
          {subTitle && (
            <DecisionSubTitle
              text={subTitle}
              minWidthMedium={minWidthMedium}
              color={colorMap.neutral.white}
            />
          )}
        </Title>
      </TitleSection>
      <ContentSection>
        {/* Buttons */}
        <Buttons>
          {buttonOptions.map((button) => (
            <DecisionButton
              data-testid={button.elementId}
              key={button.elementId}
              id={button.elementId}
              onClick={button.handleOnClick}
            >
              {button.icon && (
                <DefaultIcon
                  width={button.icon.iconWidth}
                  height={button.icon.iconHeight}
                  name={button.icon.iconName}
                />
              )}
              {button.text && (
                <DecisionButtonText>{button.text}</DecisionButtonText>
              )}
            </DecisionButton>
          ))}
        </Buttons>
        {/* Bottom button */}
        {bottomButton && (
          <DecisionBottomButton
            elementId={bottomButton.elementId}
            text={bottomButton.text}
            handleOnClick={bottomButton.handleOnClick}
          />
        )}
      </ContentSection>
    </Container>
  );
};

export default DecisionPage;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  overflow-x: hidden;
  overflow-y: scroll;
  flex-direction: column;
  display: flex;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    flex-direction: row;
  }
`;

const Section = styled.div`
  flex-basis: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    height: 100%;
    align-items: flex-start;
    justify-content: center;
  }
`;

const TitleSection = styled(Section)`
  flex-basis: 40%;
  background: linear-gradient(180deg, #ff70a3 0%, #fc4c9b 49.88%, #ff3d82 100%);
  padding: 2rem;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    flex-basis: 50%;
    padding: 2rem 5rem;
  }
`;

const ContentSection = styled(Section)`
  flex: 1;
  padding: 1.5rem;
`;

const LogoContainer = styled.div`
  align-self: flex-start;
  justify-self: flex-start;
`;

const Title = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  min-height: 250px;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    justify-content: center;
  }
`;

const SubTitle = styled.span`
  font-size: 1.15rem;
  font-weight: 600;
  line-height: 1.25rem;
  max-width: 350px;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 98%;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    justify-content: center;
    width: 100%;
  }
`;

const DecisionButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 17px;
  margin-bottom: 1.5rem;
  width: 100%;
  min-width: 170px;
  height: 140px;
  border: 2px solid ${({ theme }) => `${theme.colorMap.gray.gray4}`};
  border-radius: 16px;
  transition: hover 0.25s ease 0s;

  &:active {
    box-shadow: 0px 2px 6px ${({ theme }) => `${theme.colorMap.gray.gray4}`};
    transform: scale(0.98);
  }

  &:hover {
    border: 2px solid ${({ theme }) => `${theme.colorMap.gray.gray2}`};
    cursor: pointer;
  }

  @media ${({ theme }) => theme.mediaQuery.medium} {
    width: 464px;
    height: 200px;
    margin-bottom: 2rem;
  }
`;

const DecisionButtonText = styled.span`
  font-size: 1.25rem;
  margin-top: 1rem;
`;

const BottomButtonContainer = styled.div`
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => `${theme.colorMap.neutral.white}`};
  z-index: ${({ theme }) => `${theme.zIndices.top}`};
  box-shadow: -0.141053px -4.53484px 16.3981px rgba(0, 0, 0, 0.0209549),
    -0.0838259px -2.69499px 8.91852px rgba(0, 0, 0, 0.0158889),
    -0.043525px -1.39932px 4.55px rgba(0, 0, 0, 0.0130312),
    -0.0177324px -0.570095px 2.28148px rgba(0, 0, 0, 0.0106111);
`;
