export const MAX_PAGINATION_PAGES = 2;
export const CLICK_SLIDE_TRANSITION_MS = 400;
export const SWIPE_SLIDE_TRANSITION_MS = 450;
export const SLIDE_TRANSITION_DELAY_MS = 450;
export const DESKTOP_MAX_PAGE_HEIGHT = 1500;
export const DISCOVER_PADDING_REM = 1.5;
export const DISCOVER_MIN_HEIGHT_MEDIA_QUERY = 500;

export const cacheKeyByFeed = {
  popular: 'discover-by-popular',
  local: 'discover-by-local',
};
