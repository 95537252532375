import styled from '@emotion/styled';
import { maxWidth } from '../../lib/constants';

export const PageContentWrapper = styled.div<{ padding?: string }>`
  width: 100%;
  max-width: ${maxWidth}px;
  margin: 0 auto;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    padding: ${(props) => props.padding || '0px 5rem'};
  }
`;
export default PageContentWrapper;
