import styled from '@emotion/styled';

export const CloseIconWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 36px;
  z-index: ${({ theme }) => theme.zIndices.topmost};
  margin-left: 1rem;
  margin-top: 1rem;
  transition: hover 0.25s ease 0s;
  opacity: 1;
  cursor: pointer;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    margin-left: -1rem;
    margin-top: -1rem;
  }
`;

export const ModalContentWrapper = styled.div`
  display: block;
  position: relative;
  height: 100%;
  width: 100%;
`;
