/* @jsxImportSource @emotion/react */
import React from 'react';
import { useHistory } from 'react-router';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { useMediaQuery } from '../../../hooks';
import Button from '../../../components/UI/Button';
import Heading, {
  HeadingElementTypes,
} from '../../../components/Typography/Heading';
import { WeightTypes } from '../../../components/Typography/utils';

type Neighborhood = {
  name: string;
  search: string;
  imageUrl: {
    png: string;
    webp: string;
  };
};

type SearchNeighborhoodProps = {
  city: string;
  neighborhoods: Neighborhood[];
};

export const SearchNeighborhood: React.FC<SearchNeighborhoodProps> = ({
  city,
  neighborhoods,
}) => {
  const history = useHistory();
  const searchCity = (city: string, availableAt: string) =>
    history.push('/search', {
      city: city,
      available_at: availableAt,
    });
  const { mediaQuery } = useTheme();
  const minWidthMedium = useMediaQuery(mediaQuery.medium);

  return (
    <SearchNeighborhoodWrapper>
      <Heading
        weight={WeightTypes.semiBold}
        type={minWidthMedium ? HeadingElementTypes.h3 : HeadingElementTypes.h5}
      >
        Popular neighborhoods in {city}
      </Heading>
      <NeighborhoodWrapper>
        {neighborhoods.map((neighborhood, idx) => (
          <NeighborhoodContent key={idx}>
            <Picture>
              <source srcSet={neighborhood.imageUrl.webp} />
              <source srcSet={neighborhood.imageUrl.png} />
              <Image src={neighborhood.imageUrl.png} alt={neighborhood.name} />
            </Picture>
            <Heading
              css={{ padding: '0rem 0.75rem' }}
              weight={WeightTypes.semiBold}
              type={HeadingElementTypes.h5}
            >
              {neighborhood.name}
            </Heading>
            <Button
              onClick={() =>
                searchCity(neighborhood.search, new Date().toISOString())
              }
              style={{
                marginTop: '1.0rem',
                margin: '0rem 0.75rem',
                border: '1px solid black',
                maxWidth: '100px',
              }}
              color="black"
              variant="tertiary"
              size="small"
            >
              View all
            </Button>
          </NeighborhoodContent>
        ))}
      </NeighborhoodWrapper>
    </SearchNeighborhoodWrapper>
  );
};

export default SearchNeighborhood;
export { SearchNeighborhoodDefaults } from './constants';

const SearchNeighborhoodWrapper = styled.div`
  width: 100%;
`;

const NeighborhoodWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const NeighborhoodContent = styled.div`
  min-width: 275px;
  min-height: 366px;
  aspect-ratio: 275/366;
  background: ${({ theme }) => theme.colorMap.gray.gray5};
  border-radius: 16px;
  margin: 0 auto;
  margin-bottom: 1rem;
  flex-basis: 23.75%;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    margin: 0;
    margin-bottom: 1rem;
  }
`;

const Picture = styled.picture`
  width: 100%;
`;

const Image = styled.img`
  width: 100%;
  object-fit: cover;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
`;
