import React from 'react';
import useSlider from './useSlider';
import {
  DescriptionsWrapper,
  SliderWrapper,
  SliderBackground,
  EmptyStop,
  SliderStop,
  SliderSelection,
  SliderThumb,
  LabelsWrapper,
  SliderLabel,
} from './components';

import type { SliderInputProps } from './types';
import { useTheme } from '@emotion/react';

const SliderInput = React.forwardRef<HTMLInputElement, SliderInputProps>(
  (
    {
      from,
      to,
      initialValue,
      step,
      labels: labelOverrides = [],
      descriptions,
      className,
      onChange,
      onBlur,
      ...inputProps
    },
    ref
  ) => {
    const {
      stops,
      value,
      offset,
      getWrapperProps,
      getThumbProps,
      handleClick,
    } = useSlider({
      from,
      to,
      step,
      initialValue,
      onChange,
      onBlur,
    });

    const { colorMap, color } = useTheme();

    const sliderLabelColorByState = (labelValue: number) => {
      if (labelValue <= value) {
        return { color: color.secondaryGreen };
      } else {
        return { color: colorMap.gray.gray4 };
      }
    };

    return (
      <div className={className}>
        <DescriptionsWrapper>
          {descriptions &&
            descriptions.map((label, i) => <span key={i}>{label}</span>)}
        </DescriptionsWrapper>
        <SliderWrapper {...getWrapperProps()}>
          <input type="hidden" value={value} ref={ref} {...inputProps} />
          <SliderSelection />
          <SliderBackground
            style={{
              backgroundImage: `linear-gradient(to right, ${
                color.secondaryGreen
              }, ${color.secondaryGreen} ${offset * 100}%, ${
                colorMap.gray.gray6
              } ${offset * 100}%)`,
            }}
          >
            {stops.map((s, i) =>
              i / (stops.length - 1) <= offset ? (
                <EmptyStop onClick={handleClick} key={s} />
              ) : (
                <SliderStop onClick={handleClick} key={s} />
              )
            )}
          </SliderBackground>
          <SliderThumb {...getThumbProps()} />
        </SliderWrapper>
        <LabelsWrapper>
          {stops.map((s, i) => (
            <SliderLabel
              style={sliderLabelColorByState(s)}
              onClick={handleClick}
              key={s}
              children={labelOverrides[i] || String(s)}
            />
          ))}
        </LabelsWrapper>
      </div>
    );
  }
);

export default SliderInput;
