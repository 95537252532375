/* @jsxImportSource @emotion/react */

import React, { useState, useContext } from 'react';
import { useTheme } from '@emotion/react';
import { useLocation } from 'react-router-dom';

import useMediaQuery from '../../hooks/useMediaQuery';
import Icon, { IconWrapper } from '../Icon';
import { useAuth, useConfig, useSearch } from '../../hooks';

import { ReactComponent as Logo } from './images/logo.svg';

import {
  NavBarWrapper,
  AccountSubMenu,
  SubMenuItem,
  LogoWrapper,
  MenuItem,
  MenuList,
  StyledLink,
  StyledNavBar,
  Overlay,
  SubMenuText,
  SemiCircle,
  SearchContainer,
} from './components';
import { SearchDesktopView } from '../../pages/Marketplace/Search';
import AddRoommateModal from '../../modals/AddRoommate';
import { ModalContext } from '../../hooks/useModal';
import { useParams } from 'react-router';
import { PATHS_WITH_SEARCH_ON_NAVBAR } from '../../hooks/useSearch';
import { useConditionalHistory } from '../../hooks/useConditionalHistory';
import Paragraph, { ParagraphVariant } from '../Typography/Paragraph';
import { WeightTypes } from '../Typography/utils';
import { IconSizeKeys } from '../Icon/utils';

const NavBar: React.FC = () => {
  const { mediaQuery, colorMap, color } = useTheme();
  const { pathname } = useLocation();
  const { listingId } = useParams<{ listingId?: string }>();
  const { userId, logout } = useAuth();
  const history = useConditionalHistory();

  const { userListing } = useConfig();

  const minWidthMedium = useMediaQuery(mediaQuery.medium);

  const [showProfileMenu, setShowProfileMenu] = useState(false);

  const { handleOpenModal, setModalContent } = useContext(ModalContext);

  const {
    handlers: { handlePageClick },
    showSearchDesktopView,
    searchRef: ref,
    shouldShowSearch,
  } = useSearch();

  const getActiveMenuItem = (targetPath: string): boolean => {
    return pathname === targetPath;
  };

  const iconWrapperProps = {
    variant: minWidthMedium ? IconSizeKeys.X_LARGE : IconSizeKeys.MEDIUM,
    fillColor: minWidthMedium ? colorMap.neutral.white : 'none',
    type: minWidthMedium ? 'round' : undefined,
  };

  const handleAddRoommateClick = () => {
    if (userId) {
      handleOpenModal();
      setModalContent(() => (
        <AddRoommateModal listingId={userListing?.listingId || ''} />
      ));
    } else {
      history.push('/');
    }
  };

  const showSearch = shouldShowSearch(
    pathname,
    [{ param: listingId, dynamicKey: '/:listingId' }],
    PATHS_WITH_SEARCH_ON_NAVBAR
  );

  return (
    <NavBarWrapper>
      <StyledNavBar>
        <LogoWrapper to="/listings">
          <Logo />
        </LogoWrapper>

        {minWidthMedium && showSearch && (
          <SearchContainer data-testid="desktop-search-container">
            <SearchDesktopView
              setFixed={true}
              ref={ref}
              onClick={handlePageClick}
              showSearchView={showSearchDesktopView}
            />
          </SearchContainer>
        )}

        <MenuList>
          <MenuItem isActive={getActiveMenuItem('/discover')}>
            <StyledLink to="/discover">
              <IconWrapper
                {...iconWrapperProps}
                fillColor={colorMap.neutral.white}
              >
                <Icon
                  size={iconWrapperProps.variant}
                  name="globe"
                  height={36}
                  width={36}
                  style={{
                    transform: minWidthMedium ? 'scale(1.3)' : 'scale(1.1)',
                  }}
                />
              </IconWrapper>

              {!minWidthMedium && (
                <Paragraph
                  variant={ParagraphVariant.small}
                  color={
                    getActiveMenuItem('/discover')
                      ? color.primaryPink
                      : colorMap.gray.gray1
                  }
                  weight={WeightTypes.normal}
                  styles={{ margin: 0, marginTop: '0.5rem' }}
                >
                  Discover
                </Paragraph>
              )}
            </StyledLink>
          </MenuItem>

          {!minWidthMedium && (
            <MenuItem isActive={getActiveMenuItem('/listings')}>
              <StyledLink to="/listings">
                <IconWrapper {...iconWrapperProps}>
                  <Icon
                    size={iconWrapperProps.variant}
                    name="search"
                    height={36}
                    width={36}
                    style={{
                      transform: minWidthMedium ? 'scale(1.3)' : 'scale(1.1)',
                    }}
                    color={colorMap.gray.gray1}
                  />
                </IconWrapper>

                <Paragraph
                  variant={ParagraphVariant.small}
                  color={colorMap.gray.gray1}
                  weight={WeightTypes.normal}
                  styles={{ margin: 0, marginTop: '0.5rem' }}
                >
                  Search
                </Paragraph>
              </StyledLink>
            </MenuItem>
          )}

          {!minWidthMedium && (
            <MenuItem isActive={getActiveMenuItem('/home')}>
              <StyledLink to="/home">
                <IconWrapper {...iconWrapperProps}>
                  <Icon
                    size={iconWrapperProps.variant}
                    height={36}
                    width={36}
                    name="unicornLogo"
                    style={{
                      transform: minWidthMedium ? 'scale(1.3)' : 'scale(1.1)',
                    }}
                    color={
                      getActiveMenuItem('/home')
                        ? colorMap.gray.gray4
                        : colorMap.pink.pink1
                    }
                  />
                </IconWrapper>

                <Paragraph
                  variant={ParagraphVariant.small}
                  color={colorMap.gray.gray1}
                  weight={WeightTypes.normal}
                  styles={{ margin: 0, marginTop: '0.5rem' }}
                >
                  My Home
                </Paragraph>
              </StyledLink>
            </MenuItem>
          )}

          <MenuItem isActive={getActiveMenuItem('/favorites')}>
            <StyledLink to="/favorites">
              <IconWrapper {...iconWrapperProps}>
                <Icon
                  size={iconWrapperProps.variant}
                  name="heart"
                  height={36}
                  width={36}
                  style={{
                    transform: minWidthMedium ? 'scale(1.3)' : 'scale(1.1)',
                  }}
                />
              </IconWrapper>

              {!minWidthMedium && (
                <Paragraph
                  variant={ParagraphVariant.small}
                  color={
                    getActiveMenuItem('/favorites')
                      ? color.primaryPink
                      : colorMap.gray.gray1
                  }
                  weight={WeightTypes.normal}
                  styles={{ margin: 0, marginTop: '0.5rem' }}
                >
                  Favorites
                </Paragraph>
              )}
            </StyledLink>
          </MenuItem>

          <MenuItem isActive={getActiveMenuItem('/profile')}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: minWidthMedium ? 0 : '0.5rem',
              }}
            >
              <div onClick={() => setShowProfileMenu(!showProfileMenu)}>
                <IconWrapper
                  type={getActiveMenuItem('/profile') ? 'round' : 'none'}
                  fillColor={
                    getActiveMenuItem('/profile')
                      ? minWidthMedium
                        ? colorMap.neutral.white
                        : colorMap.gray.gray6
                      : 'none'
                  }
                >
                  <Icon
                    size={iconWrapperProps.variant}
                    name="profile"
                    color={colorMap.gray.gray1}
                    width={36}
                    height={36}
                    style={{
                      transform: minWidthMedium ? 'scale(1.3)' : 'scale(1.1)',
                    }}
                  />
                </IconWrapper>
              </div>
              {!minWidthMedium && (
                <Paragraph
                  variant={ParagraphVariant.small}
                  color={colorMap.gray.gray1}
                  weight={WeightTypes.normal}
                  styles={{ margin: 0, marginTop: '0.5rem' }}
                >
                  Profile
                </Paragraph>
              )}
            </div>
          </MenuItem>
        </MenuList>

        {showProfileMenu && (
          <AccountSubMenu>
            <SubMenuItem
              isActive={getActiveMenuItem('/profile')}
              onClick={() => history.push('/profile')}
            >
              <IconWrapper
                type="round"
                fillColor={colorMap.gray.gray6}
                variant={IconSizeKeys.MEDIUM}
              >
                <Icon
                  name="gear"
                  size={IconSizeKeys.LARGE}
                  style={{
                    transform: 'scale(0.8)',
                  }}
                />
              </IconWrapper>
              <SubMenuText>Account</SubMenuText>
            </SubMenuItem>

            {minWidthMedium && (
              <>
                <hr
                  css={{
                    border: `1px dashed ${colorMap.gray.gray4}`,
                    margin: '0.125rem 1.5rem',
                  }}
                />
                <SubMenuItem
                  isActive={getActiveMenuItem('/home')}
                  onClick={() => history.push('/home')}
                >
                  <IconWrapper
                    type="round"
                    fillColor={colorMap.gray.gray6}
                    variant={IconSizeKeys.MEDIUM}
                  >
                    <Icon
                      name="unicornLogo"
                      size={IconSizeKeys.LARGE}
                      style={{
                        transform: 'scale(0.8)',
                      }}
                    />
                  </IconWrapper>
                  <SubMenuText>My Home</SubMenuText>
                </SubMenuItem>
              </>
            )}

            <hr
              css={{
                border: `1px dashed ${colorMap.gray.gray4}`,
                margin: '0.125rem 1.5rem',
              }}
            />

            <SubMenuItem onClick={handleAddRoommateClick}>
              <IconWrapper
                type="round"
                fillColor={colorMap.gray.gray6}
                variant={IconSizeKeys.MEDIUM}
              >
                <Icon
                  name="addUser"
                  color={colorMap.gray.gray6}
                  size={IconSizeKeys.LARGE}
                  style={{
                    transform: 'scale(0.7)',
                  }}
                />
              </IconWrapper>
              <SubMenuText>Add Roommate</SubMenuText>
            </SubMenuItem>

            <hr
              css={{
                border: `1px dashed ${colorMap.gray.gray4}`,
                margin: '0.125rem 1.5rem',
              }}
            />
            <SubMenuItem onClick={() => logout()}>
              <IconWrapper
                type="round"
                fillColor={colorMap.gray.gray6}
                variant={IconSizeKeys.MEDIUM}
              >
                <Icon
                  name="exit"
                  size={IconSizeKeys.LARGE}
                  style={{
                    transform: 'scale(0.8)',
                  }}
                />
              </IconWrapper>
              <SubMenuText>Log out</SubMenuText>
            </SubMenuItem>
          </AccountSubMenu>
        )}

        <SemiCircle>
          <button onClick={() => setShowProfileMenu(!showProfileMenu)}>
            <Icon name="caret" width={14} height={8} color="none" />
          </button>
        </SemiCircle>
        {showProfileMenu && (
          <Overlay onClick={() => setShowProfileMenu(false)} />
        )}
      </StyledNavBar>
    </NavBarWrapper>
  );
};

export default NavBar;
