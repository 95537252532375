import React from 'react';
import { colorMap } from '../../lib/theme';
import { getIcon } from './iconSelector';
import { iconSizes } from './utils';

export type IconType = {
  size?: string;
  color?: string;
  name: any;
  height?: number;
  width?: number;
  viewBox?: string;
  stroke?: string;
  style?: React.CSSProperties;
  preserveAspectRatio?: string;
};

const Icon: React.FC<IconType> = ({
  size = 'small',
  color = colorMap.gray.gray1,
  name,
  height,
  width,
  viewBox,
  stroke,
  preserveAspectRatio = 'xMidYMid slice',
  ...props
}) => {
  const IconComponent = getIcon(name);
  const iconSize = iconSizes[size];
  const viewBoxValuesBySize = () =>
    height && width ? `${width} ${height}` : `${iconSize} ${iconSize}`;
  const iconViewBox = viewBox || `0 0 ${viewBoxValuesBySize()}`;

  return (
    <IconComponent
      height={height || iconSize}
      width={width || iconSize}
      viewBox={iconViewBox}
      fill={color}
      stroke={stroke}
      preserveAspectRatio={preserveAspectRatio}
      {...props}
    />
  );
};

export default Icon;
