import React from 'react';
import styled from '@emotion/styled';
import { navBarHeight } from '../../../components/NavBar/components';
import {
  DESKTOP_MAX_PAGE_HEIGHT,
  DISCOVER_MIN_HEIGHT_MEDIA_QUERY,
  SLIDE_TRANSITION_DELAY_MS,
  SWIPE_SLIDE_TRANSITION_MS,
} from '../constants';
import { useDiscover, useVideoSwiper } from '../../../hooks';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import 'swiper/swiper.scss';
import { Volumelevel } from '../../../hooks/useVideoSwiper';
import { Video } from '../Video';
import { VideoListingDetails } from '../../../types';
import { lastResortFallbackLocation } from '../../../lib/constants';
import SlideMessage from '../SlideMessage';

type VideoSwiperProps = {
  videos?: VideoListingDetails[];
  videoPageParams: number[];
  allowSwipe: boolean;
  prev: () => void;
  next: () => void;
  reset: () => void;
};

const VideoSwiper: React.FC<VideoSwiperProps> = ({
  videos,
  videoPageParams,
  allowSwipe,
  prev,
  next,
  reset,
}) => {
  const {
    index,
    offset,
    swiperRef,
    hasLocationResults,
    hasReachedEnd,
    handlers,
  } = useDiscover();

  const {
    requiresUserInteraction,
    handlers: { play, adjustVolume },
  } = useVideoSwiper();

  const transitionStart = (currentIndex: number) => {
    const currentVideo = (videos || [])[currentIndex];
    if (videos && currentVideo && !requiresUserInteraction && !hasReachedEnd) {
      adjustVolume(currentVideo.id, Volumelevel.MUTED);
    }
  };

  const transitionEnd = (currentIndex: number) => {
    // transition finishes before slide change after
    const currentVideo = (videos || [])[currentIndex];
    setTimeout(() => {
      if (
        videos &&
        currentVideo &&
        !requiresUserInteraction &&
        !hasReachedEnd
      ) {
        adjustVolume(currentVideo.id, Volumelevel.LOUD);
      }
    }, SLIDE_TRANSITION_DELAY_MS + 250);
  };

  const afterChange = (next) => {
    // Make sure we don't cause an index out of bounds exception since
    // we add a slide to the end of the swiper when user has reached the end of the feed.
    if (videos && next < videos.length && !requiresUserInteraction) {
      play(videos[next].id);
    }
  };

  const onFirstSlide = (numSlides: number) => {
    const hasSlides = videos && numSlides > 0;
    const hasPrev = offset !== 0 && !!videoPageParams[0];

    if (hasSlides && hasPrev) {
      prev();
    }
  };

  const onLastSlide = (numSlides: number) => {
    const hasSlides = videos && numSlides > 0;

    if (hasSlides && !hasReachedEnd) {
      next();
    }
  };

  return (
    <VideoSwiperContainer>
      <Swiper
        key={'discover-swiper'}
        onInit={(swiper: any) => {
          swiperRef.current = swiper;
          swiper.init();
        }}
        speed={SWIPE_SLIDE_TRANSITION_MS}
        slidesPerView={1}
        direction={'vertical'}
        spaceBetween={0}
        allowTouchMove={allowSwipe}
        simulateTouch={false}
        updateOnWindowResize={true}
        centeredSlides={true}
        centeredSlidesBounds={true}
        autoHeight={true}
        loop={false}
        initialSlide={index}
        normalizeSlideIndex={true}
        runCallbacksOnInit={false}
        onRealIndexChange={({ activeIndex }) => {
          handlers.updateIndex(activeIndex);
        }}
        onSliderMove={({ activeIndex }) => transitionStart(activeIndex)}
        onTouchEnd={({ realIndex }) => transitionEnd(realIndex)}
        onSlideChangeTransitionEnd={({ activeIndex }) => {
          afterChange(activeIndex);
        }}
        onSlideChange={({ slides, previousIndex, activeIndex }) => {
          if (!videos) return;
          // Check to make sure we aren't in a middle of index change
          // after we get next batch of videos where diff > 1
          const diff = activeIndex - previousIndex;
          if (diff === 1 && activeIndex === videos.length - 1) {
            onLastSlide(slides.length);
          } else if (diff === -1 && activeIndex === 0) {
            onFirstSlide(slides.length);
          }
        }}
        preventInteractionOnTransition={false}
      >
        {!hasLocationResults && (
          <SwiperSlide>
            <SlideMessage
              buttonText={`Search Los Angeles`}
              description={`We’re on our way to more cities soon. Stay tuned as Unicorn launches
                new markets!`}
              handleClick={() =>
                handlers.changeLocation(lastResortFallbackLocation)
              }
            />
          </SwiperSlide>
        )}
        {videos &&
          videos.map((video, idx) => {
            return (
              <SwiperSlide key={`discover-swiper-video-${video.id}`}>
                {({ isActive }) => (
                  <Video
                    isActiveVideo={isActive}
                    idx={idx}
                    videoDetails={video}
                  />
                )}
              </SwiperSlide>
            );
          })}
        {hasReachedEnd && (
          <SwiperSlide>
            <SlideMessage
              buttonText={`Restart feed`}
              description={`You're all caught up on the latest videos.`}
              handleClick={reset}
            />
          </SwiperSlide>
        )}
      </Swiper>
    </VideoSwiperContainer>
  );
};

export default VideoSwiper;

export const VideoSwiperContainer = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 0px;

  .swiper-wrapper {
    height: calc(var(--vh, 1vh) * 100) !important;

    .swiper-slide {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @media ${({ theme }) => theme.mediaQuery.medium} {
    margin-top: ${navBarHeight}px;

    .swiper-wrapper {
      height: calc(var(--vh, 1vh) * 100 - ${navBarHeight}px) !important;
      max-height: ${DESKTOP_MAX_PAGE_HEIGHT}px;

      .swiper-slide {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    // Mobile landscape mode w/min height (when device rotates)
    @media (max-height: ${DISCOVER_MIN_HEIGHT_MEDIA_QUERY}px) {
      margin-top: 0px;
      .swiper-wrapper {
        height: calc(var(--vh, 1vh) * 100) !important;
      }
    }
  }

  @media (min-height: ${DESKTOP_MAX_PAGE_HEIGHT}px) {
    margin-top: 0px;
  }
`;
