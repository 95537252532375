import React from 'react';
import { useHistory } from 'react-router';
import {
  removeSessionStorage,
  SS_SHOW_ONBOARDING_IF_REQUIRED,
} from '../../../lib/session-storage';
import {
  DecisionPage,
  DecisionButtonProps,
} from '../../../components/DecisionPage';

const DISPLAY_TITLE = 'Welcome to Unicorn!';
const DISPLAY_PARAGRAPH =
  'A video-listing platform, built for the next generation of renters.';

const Welcome = () => {
  const history = useHistory();

  const buttons: DecisionButtonProps[] = [
    {
      elementId: 'welcome-start-searching-button',
      text: 'Start searching',
      icon: {
        iconName: 'welcomeSearchIcon',
        iconWidth: 40,
        iconHeight: 40,
      },
      handleOnClick: (_) => {
        removeSessionStorage(SS_SHOW_ONBOARDING_IF_REQUIRED);
        history.push('/listings');
      },
    },
    {
      elementId: 'welcome-review-your-home-button',
      text: 'Review your home',
      icon: {
        iconName: 'review',
        iconWidth: 40,
        iconHeight: 40,
      },
      handleOnClick: (_) => {
        history.push('/listings/add');
      },
    },
  ];

  return (
    <DecisionPage
      title={DISPLAY_TITLE}
      subTitle={DISPLAY_PARAGRAPH}
      buttonOptions={buttons}
    />
  );
};

export default Welcome;
