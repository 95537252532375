// Constants
export const searchFixedPositionTop = {
  mobile: 80,
  desktop: 128,
};

export const searchHeight = {
  small: 46,
  medium: 66,
};
