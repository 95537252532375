import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const formWrapperStyles = css`
  margin-bottom: 144px;
`;

export const dekStyles = css`
  font-size: 1rem;
  line-height: 22px;
  letter-spacing: 0.18px;
`;

export const textStyles = css`
  font-size: 0.875rem;
  line-height: 1.5;
  color: #4f4f4f;
`;

export const PageContainer = styled.div`
  padding: 0 1.5rem;
  max-width: 1328px;
  margin: 0 auto;
`;

export const GridContainer = styled(PageContainer)`
  @media ${({ theme }) => theme.mediaQuery.medium} {
    display: grid;
    grid-template-columns: repeat(2, minmax(10px, 1fr));
    grid-template-areas:
      'leftTop rightTop'
      'leftBottom rightBottom';
    grid-gap: 1em;
  }
`;

export const Column = styled.div`
  @media ${({ theme }) => theme.mediaQuery.medium} {
    max-width: 413px;
  }
`;

export const SubmitContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  box-shadow: -0.217625px -6.99662px 28px rgba(0, 0, 0, 0.03),
    -0.141053px -4.53484px 16.3981px rgba(0, 0, 0, 0.0209549),
    -0.0838259px -2.69499px 8.91852px rgba(0, 0, 0, 0.0158889),
    -0.043525px -1.39932px 4.55px rgba(0, 0, 0, 0.0130312),
    -0.0177324px -0.570095px 2.28148px rgba(0, 0, 0, 0.0106111),
    -0.00403009px -0.129567px 1.10185px rgba(0, 0, 0, 0.00685764);

  button {
    width: 80%;
    max-width: 80%;
    margin: 0.5rem 0rem;
  }

  @media ${({ theme }) => theme.mediaQuery.medium} {
    flex-direction: row;

    button {
      max-width: 171px;
      margin: 0 1rem;
    }
  }
`;
