import React from 'react';
import styled from '@emotion/styled';
import { fontWeightMap, WeightTypes } from '../utils';

export enum ParagraphVariant {
  xSmall = 'xSmall',
  small = 'small',
  medium = 'medium',
  large = 'large',
  xLarge = 'xLarge',
  xxLarge = 'xxLarge',
}

const fontSizeMap = {
  xSmall: 0.625, // 10px
  small: 0.75, // 12px
  medium: 0.875, // 14px
  large: 1, // 16px
  xLarge: 1.125, // 18px
  xxLarge: 1.25, // 20px
};

export type ParagraphPropTypes = {
  variant: ParagraphVariant;
  weight?: WeightTypes;
  color?: string;
  className?: string;
  styles?: React.CSSProperties;
};

const Paragraph: React.FC<ParagraphPropTypes> = ({
  children,
  styles,
  ...paragraphProps
}) => {
  return (
    <StyledParagraph style={styles} {...paragraphProps}>
      {children}
    </StyledParagraph>
  );
};

const StyledParagraph = styled.p<ParagraphPropTypes>`
  font-family: 'ZichtbaarRegular';
  font-size: ${({ variant }) => `${fontSizeMap[variant]}rem`};
  font-weight: ${({ weight = WeightTypes.normal }) => fontWeightMap[weight]};
  color: ${({ color }) => color};
`;

export default Paragraph;
