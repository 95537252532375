import styled from '@emotion/styled';

export const ClickableLabel = styled.label`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  &:before {
    content: ' ';
    display: block;
    width: 22px;
    height: 22px;
    flex-shrink: 0;
    border-radius: 11px;
    border: 2px solid ${({ theme }) => theme.color.primaryPink};
    margin-right: 8px;
  }

  input:checked + &:before {
    background-color: ${({ theme }) => theme.color.primaryPink};
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.42522 4.65831L4.03887 7.29674C4.27964 7.53979 4.67 7.53979 4.91076 7.29674L10.574 1.57979' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-position: center center;
    background-repeat: no-repeat;
  }
`;
