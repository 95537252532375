import { useHistory } from 'react-router';
import useUnlockAccessModal from './useUnlockAccessModal';

export type UseConditionalHistory = {
  push: (pathname: string) => void;
};
// Conditional history push. User sees a modal to signup/login if they are not authenticated
export const useConditionalHistory = (): UseConditionalHistory => {
  const history = useHistory();
  const { showModal, showModalForUserAndRoute } = useUnlockAccessModal();

  const push = (pathname: string) => {
    if (showModalForUserAndRoute(pathname)) {
      showModal();
    } else {
      history.push(pathname);
    }
  };

  return { push };
};
