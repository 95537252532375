import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import React, { useEffect } from 'react';
import { ThemeProvider } from '@emotion/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import TagManager from 'react-gtm-module';
import GlobalStyles from './GlobalStyles';
import {
  AuthProvider,
  LoggedIn,
  NotLoggedIn,
  ConfigProvider,
  ServicesProvider,
  FullscreenProvider,
} from './hooks';
import Auth from './pages/Auth';
import Router from './Router';
import getTheme from './lib/theme';
import PaginationProvider from './hooks/usePagination';
import ModalProvider from './hooks/useModal';
import { Modal } from './components/Modal';
import { SearchProvider } from './hooks/useSearch';
import loadHotJar from './lib/hot-jar';
import { checkForLocalStorageExpiredKeys } from './lib/local-storage';

const theme = getTheme();
const queryClient = new QueryClient();

const App = () => {
  useEffect(() => {
    TagManager.initialize({
      gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
      auth: process.env.REACT_APP_GOOGLE_TAG_MANAGER_AUTH,
      preview: process.env.REACT_APP_GOOGLE_TAG_MANAGER_PREVIEW,
    });
    loadHotJar();
    checkForLocalStorageExpiredKeys();
  }, []);
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <GlobalStyles theme={theme} />
        <FullscreenProvider>
          <AuthProvider>
            <ModalProvider>
              <SearchProvider>
                <ServicesProvider>
                  <NotLoggedIn>
                    <Auth />
                  </NotLoggedIn>

                  <LoggedIn>
                    <ConfigProvider>
                      <PaginationProvider>
                        <Router />
                      </PaginationProvider>
                    </ConfigProvider>
                  </LoggedIn>
                  <Modal />
                </ServicesProvider>
              </SearchProvider>
            </ModalProvider>
          </AuthProvider>
        </FullscreenProvider>
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default App;
