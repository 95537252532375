import useUnlockAccessModal from './useUnlockAccessModal';

export type UseConditionalWindow = {
  open: (linkTo: string, target: string) => void;
};
// Conditional window open. User sees a modal to signup/login if they are not authenticated
export const useConditionalWindow = (): UseConditionalWindow => {
  const { showModal, showModalForUserAndRoute } = useUnlockAccessModal();

  const open = (linkTo: string, target: string) => {
    if (showModalForUserAndRoute(linkTo)) {
      showModal();
    } else {
      window.open(linkTo, target);
    }
  };

  return { open };
};
