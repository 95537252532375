import { useQuery, UseQueryOptions } from 'react-query';
import { MessageProps } from '../pages/Home/Messaging';
import { useService } from './useServices';

const useMessagesByListingId = (
  queryKey: string,
  listingId: string | undefined = '',
  options: UseQueryOptions<Array<MessageProps>, {}>
) => {
  const messageService = useService<'message'>('message');

  return useQuery<Array<MessageProps>, {}>(
    [queryKey],
    () => messageService.fetchMessagesByListingId(listingId),
    {
      ...options,
      refetchOnWindowFocus: false,
    }
  );
};

export default useMessagesByListingId;
