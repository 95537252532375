import React from 'react';
import styled from '@emotion/styled';
import { IconSizeKeys, iconSizes } from './utils';

type IconWrapperType = {
  type?: string;
  fillColor: string;
  variant?: IconSizeKeys;
};

const IconWrapper: React.FC<IconWrapperType> = (props) => {
  return (
    <StyledIconWrapper {...props} size={iconSizes[props.variant || 'small']}>
      {props.children}
    </StyledIconWrapper>
  );
};

const StyledIconWrapper = styled.div<{
  type?: string;
  fillColor?: string;
  size: number;
}>`
  // padding: 0.5rem;
  border-radius: ${({ type }) => (type === 'round' ? '40px' : '0')};
  background-color: ${({ fillColor, theme }) =>
    fillColor || theme.colorMap.neutral.white};
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: ${({ size }) => `calc(2px * ${size / 2})`};
  height: ${({ size }) => `calc(2px * ${size / 2})`};
`;

export default IconWrapper;
