import type { apiClient } from '../../hooks';
import { Listing, RoommateRequests } from '../../types';

export type ListingResponse = {
  listingId: string;
};

export type ListingService = {
  createListing: (
    data: Record<string, string | number>
  ) => Promise<ListingResponse>;
  approveRoommate: (
    data: Record<string, string | number | boolean>
  ) => Promise<any>;
  rejectRoommate: (
    data: Record<string, string | number | boolean>
  ) => Promise<any>;
  fetchRoommateRequests: (listingId: string) => Promise<RoommateRequests>;
  updateListing: (body: Record<string, string | number>) => any;
  fetchListing: (listingId: string) => any;
  searchListingsByFilters: (
    searchParams: any,
    paginationCacheKey: string
  ) => any;
};

export const listingServiceFactory = (
  apiClient: apiClient
): ListingService => ({
  createListing: (body): Promise<ListingResponse> =>
    apiClient<ListingResponse>('/onboarding/submit-listing', {
      method: 'POST',
      body,
    }),
  approveRoommate: (body): Promise<any> =>
    apiClient('/listing-users/approve-roommate', {
      method: 'PUT',
      body,
    }),
  rejectRoommate: (body): Promise<any> =>
    apiClient('/listing-users/reject-roommate', {
      method: 'PUT',
      body,
    }),
  fetchRoommateRequests: (listingId: string): Promise<RoommateRequests> =>
    apiClient<RoommateRequests>(
      `/listing-users/pending-roommates?listing_id=${listingId}`,
      { method: 'GET' }
    ),
  updateListing: (body): Promise<ListingResponse> =>
    apiClient('/onboarding/update-listing', {
      method: 'PUT',
      body,
    }),
  fetchListing: (listingId): Promise<Listing> =>
    apiClient(`/search/by-id?id=${listingId}`),
  searchListingsByFilters: (
    searchParams,
    paginationCacheKey
  ): Promise<ListingResponse[]> =>
    apiClient(`/search/by-filter`, {
      method: 'GET',
      body: searchParams,
      paginationCacheKey: paginationCacheKey,
    }),
});
