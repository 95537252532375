/* @jsxImportSource @emotion/react */
import React from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
} from 'react-places-autocomplete';
import styled from '@emotion/styled';
import { TextInput } from './UI';
import { DefaultIcon } from '../../../components/Icon';

const AddressAutocompleteSuggestions = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: ${({ theme }) => theme.zIndices.middle};

  padding: 1.875rem 0;
  background: white;
  box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.05),
    0px 5.39259px 7.64444px rgba(0, 0, 0, 0.0303704),
    0px 1.14074px 1.95556px rgba(0, 0, 0, 0.0196296);
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;

  &:empty:before {
    display: block;
    content: 'Loading...';
  }
`;

const AddressAutocompleteSuggestion = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 0.625rem;

  svg {
    flex-basis: 20%;
  }

  &.address-autocomplete-suggestion--active {
    background-color: rgba(255, 112, 163, 0.1);
  }
`;

const AddressAutocompleteTextContainer = styled.div`
  flex: 1;
`;

type AddressAutocompleteProps = {
  onChange: (object) => void;
  error?: {
    message: string;
  };
  defaultValue?: any;
};

const AddressAutocomplete = ({
  onChange,
  error,
  defaultValue,
}: AddressAutocompleteProps) => {
  const [address, setAddress] = React.useState(defaultValue);

  const handleChange = (address) => {
    setAddress(address);

    if (!address) {
      onChange(address);
    }
  };

  const handleSelect = (address) => {
    setAddress(address);
    geocodeByAddress(address).then(onChange);
  };

  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
      searchOptions={{
        componentRestrictions: {
          country: ['us'],
        },
      }}
    >
      {({ getInputProps, getSuggestionItemProps, loading, suggestions }) => (
        <div css={{ position: 'relative' }}>
          <TextInput
            {...getInputProps({ placeholder: 'Search' })}
            defaultValue={defaultValue}
            error={error}
          />
          {(loading || suggestions.length > 0) && (
            <AddressAutocompleteSuggestions>
              {suggestions.map((suggestion) => (
                <AddressAutocompleteSuggestion
                  className={
                    suggestion.active &&
                    'address-autocomplete-suggestion--active'
                  }
                  key={suggestion.placeId}
                  {...getSuggestionItemProps(suggestion)}
                >
                  <DefaultIcon name="pin" />
                  <AddressAutocompleteTextContainer>
                    <strong>{suggestion.formattedSuggestion?.mainText}</strong>
                    <br />
                    {suggestion.formattedSuggestion?.secondaryText}
                  </AddressAutocompleteTextContainer>
                </AddressAutocompleteSuggestion>
              ))}
            </AddressAutocompleteSuggestions>
          )}
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default AddressAutocomplete;
