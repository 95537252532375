/* @jsxImportSource @emotion/react */

import styled from '@emotion/styled';
import React, { useContext, useMemo } from 'react';

import Paragraph, { ParagraphVariant } from '../Typography/Paragraph';
import { LeftArrow, RightArrow } from '../UI/Button';
import { PaginationContext } from '../../hooks/usePagination';

export const RESULTS_PER_PAGE = 20;

export const generatePageNumbers = (
  currentPage: number,
  total: number,
  limit: number
) => {
  const totalPages = Math.ceil(total / limit);

  if (totalPages <= 5) {
    const pages: number[] = [];

    for (let page = 1; page <= totalPages; page++) {
      pages.push(page);
    }
    return pages;
  } else if (currentPage > totalPages - 4) {
    return [
      1,
      '...',
      totalPages - 4,
      totalPages - 3,
      totalPages - 2,
      totalPages - 1,
      totalPages,
    ];
  } else if (currentPage <= 4) {
    return [1, 2, 3, 4, 5, '...', totalPages];
  } else {
    return [
      1,
      '...',
      currentPage - 1,
      currentPage,
      currentPage + 1,
      '...',
      totalPages,
    ];
  }
};

const PageNumber: React.FC<{ pageNum: number | string; isActive: boolean }> = ({
  pageNum,
  isActive,
}) => {
  const { setCurrentPage } = useContext(PaginationContext);

  return (
    <StyledPageNumber
      css={{
        backgroundColor: `${isActive ? '#FF70A3' : 'white'}`,
        color: `${isActive ? 'white' : 'black'}`,
        margin: '0 0.625rem',
      }}
      onClick={() => {
        if (typeof pageNum === 'number') setCurrentPage(pageNum);
      }}
    >
      <Paragraph variant={ParagraphVariant.large}>{pageNum}</Paragraph>
    </StyledPageNumber>
  );
};

const Pages: React.FC<{ pageNumbers: (number | string)[] }> = ({
  pageNumbers,
}) => {
  const { currentPage } = useContext(PaginationContext);

  return (
    <ul css={{ paddingLeft: 0, display: 'flex' }}>
      {pageNumbers.map((number, idx) => (
        <PageNumber
          key={idx}
          pageNum={number}
          isActive={currentPage === number}
        />
      ))}
    </ul>
  );
};

const Pagination: React.FC = () => {
  const {
    currentPage,
    total,
    setCurrentPage,
    currentPageResults,
    offset,
    limit,
  } = useContext(PaginationContext);

  const offsetPlusTotal = useMemo(() => {
    return offset + limit;
  }, [offset, limit]);

  if (!total && !currentPageResults) {
    return null;
  }

  const pageNumbers = generatePageNumbers(currentPage, total, limit);
  const totalPages = Math.ceil(total / limit);

  return total ? (
    <PaginationContainer>
      <CenteredContainer>
        {totalPages > 1 && (
          <StyledContentWrapper
            css={{
              visibility: `${currentPage > 1 ? 'visible' : 'hidden'}` as
                | 'hidden'
                | 'visible',
              backgroundColor: '#F9F9F9',
              marginRight: '10px',
            }}
          >
            <LeftArrow onClick={() => setCurrentPage(currentPage - 1)} />
          </StyledContentWrapper>
        )}

        <Pages pageNumbers={pageNumbers} />

        {totalPages > 1 && (
          <StyledContentWrapper
            css={{
              visibility: `${
                currentPage * limit < total ? 'visible' : 'hidden'
              }` as 'hidden' | 'visible',
              backgroundColor: '#F9F9F9',
              marginLeft: '10px',
            }}
          >
            <RightArrow onClick={() => setCurrentPage(currentPage + 1)} />
          </StyledContentWrapper>
        )}
      </CenteredContainer>
      <Paragraph
        variant={ParagraphVariant.medium}
        color="#828282"
        css={{ marginTop: 0, textAlign: 'center' }}
      >
        {offset + 1} - {offsetPlusTotal > total ? total : offsetPlusTotal} of{' '}
        {total} places
      </Paragraph>
    </PaginationContainer>
  ) : (
    <PaginationContainer></PaginationContainer>
  );
};

export default Pagination;

const PaginationContainer = styled.div`
  padding-bottom: 40px;
`;

const CenteredContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledContentWrapper = styled(CenteredContainer)`
  height: 30px;
  width: 30px;
  border-radius: 30px;
`;

const StyledPageNumber = styled(StyledContentWrapper)<{ onClick: () => void }>`
  &:hover {
    cursor: pointer;s
  }
`;
