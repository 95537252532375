import React, { useState } from 'react';

type ModalContextValues = {
  isOpen: boolean;
  handleOpenModal: () => void;
  handleCloseModal: () => void;
  modalContent: React.ReactNode;
  setModalContent: React.Dispatch<React.SetStateAction<React.ReactNode>>;
  contentStyles: React.CSSProperties;
  setContentStyles: React.Dispatch<React.SetStateAction<React.CSSProperties>>;
};

export const ModalContext = React.createContext<ModalContextValues>({
  isOpen: false,
  handleOpenModal: () => {
    /** do something */
  },
  handleCloseModal: () => {
    /** do something */
  },
  modalContent: null,
  setModalContent: () => {
    /** do something */
  },
  contentStyles: {},
  setContentStyles: () => {
    /** do something */
  },
});

const ModalProvider: React.FC<{ state?: Partial<ModalContextValues> }> = ({
  state = {},
  children,
}) => {
  const {
    isOpen,
    handleOpenModal,
    handleCloseModal,
    modalContent,
    setModalContent,
    contentStyles,
    setContentStyles,
  } = useModal();

  return (
    <ModalContext.Provider
      value={{
        isOpen,
        handleOpenModal,
        handleCloseModal,
        modalContent,
        setModalContent,
        contentStyles,
        setContentStyles,
        ...state,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export default ModalProvider;

export const useModal = () => {
  const [isOpen, setModalVisibility] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<React.ReactNode>(null);
  const [contentStyles, setContentStyles] = useState<React.CSSProperties>({});

  const handleOpenModal = () => {
    setModalVisibility(true);
  };

  const handleCloseModal = () => {
    setModalVisibility(false);
  };

  return {
    isOpen,
    handleOpenModal,
    handleCloseModal,
    modalContent,
    setModalContent,
    contentStyles,
    setContentStyles,
  };
};
