import styled from '@emotion/styled';

import Button from '../../components/UI/Button';
import RentalImage from './images/bottom-image.png';
import { carouselImageSizes, maxWidth } from '../../lib/constants';
import { getStaticAssetS3Url } from '../../lib/static-assets';

export const HeaderWrapper = styled.div`
  background-color: #03292f;
  width: 100%;
  height: 100vh;
  max-height: 467px;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    height: 850px;
    max-height: 850px;
  }
`;

export const Header = styled.header`
  background: url(${getStaticAssetS3Url(
    '/marketplace/marketplace-hero-entrance.webp'
  )});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 47%;
  max-width: ${maxWidth}px;
  margin: 0 auto;
  height: 100%;

  // For specific marketplace search view
  @media ${({ theme }) => theme.mediaQuery.medium} {
    .search-view__desktop {
      position: absolute;
      top: 128px;
      left: 50%;
      transform: translateX(-50%);
    }

    .search-view__desktop.fixed {
      position: fixed;
      z-index: ${({ theme }) => theme.zIndices.top};
      top: 10px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  // Background image media breakpoint
  @media ${({ theme }) => theme.mediaQuery.xlarge} {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    height: 100%;
  }
`;

export const HeaderTitleContainer = styled.div`
  width: 280px;
  margin-left: 24px;
  padding-top: 130px;

  .places-open-button {
    font-size: 0.875rem;
    max-width: 191px;
    height: 40px;
  }

  @media ${({ theme }) => theme.mediaQuery.medium} {
    padding-top: 270px;
    margin: 0 15%;

    .places-open-button {
      font-size: 1rem;
      max-width: 100%;
    }
  }
`;

export const HeaderTitle = styled.h2`
  font-size: 2.5rem;
  line-height: 2.5rem;
  width: unset;
  color: ${({ theme }) => theme.colorMap.neutral.white};
  padding: 0;
  margin: 0;
  margin-bottom: 1.25rem;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    font-size: 4rem;
    font-weight: 700;
    width: 450px;
    line-height: 5.125rem;
    margin-bottom: 1.5rem;
  }
`;

export const PlacesAroundYouSlickSliderSizes = {
  mediumSize: {
    width: '304px',
  },
  defaultSize: {
    width: '230px',
  },
};

export const PlacesAroundYouSection = styled.section`
  background: ${({ theme }) => theme.colorMap.neutral.white};
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  margin-bottom: 56px;
`;

export const PlacesAroundYouCarousel = styled.div<{ width: number }>`
  width: ${({ width }) => `${width}px`};

  .parent-carousel {
    padding-left: 30px;

    > .slick-list {
      margin-left: -10px;
      margin-right: -10px;
    }

    > .slick-list > .slick-track > .slick-active + .slick-slide {
      margin-left: ${({ width }) => `-${width - 333}px`};
    }
  }

  img {
    border-radius: 20px;
    width: 100%;
    object-fit: cover;
    margin: 0 auto;
    max-width: ${carouselImageSizes.mobile.maxWidth}px;
    max-height: ${carouselImageSizes.mobile.maxHeight}px;
  }

  @media ${({ theme }) => theme.mediaQuery.small} {
    .parent-carousel {
      padding-left: 24px;
      padding-right: 24px;

      > .slick-list > .slick-track > .slick-active + .slick-slide {
        margin-left: 0;
      }
    }
  }

  @media ${({ theme }) => theme.mediaQuery.medium} {
    margin-left: -80px;

    .parent-carousel {
      padding-left: 80px;
      padding-right: 80px;
    }

    img {
      max-width: ${carouselImageSizes.desktop.maxWidth}px;
      max-height: ${carouselImageSizes.desktop.maxHeight}px;
    }
  }
`;

export const Left = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

export const Right = styled.div`
  display: flex;

  > :first-of-type {
    margin-right: 10px;
  }

  > :last-of-type {
    margin-left: 10px;
  }
`;

export const PlacesAroundYouChildSection = styled.div`
  @media ${({ theme }) => theme.mediaQuery.medium} {
  }
`;

export const PlacesAroundYouChildImage = styled.img``;

export const StyledViewAllBtn = styled(Button)`
  max-width: 80px;
  padding: 0.25rem 0.75rem;
  margin-left: 1rem;
`;

export const PlacesAroundYouHeader = styled.div`
  padding: 2.25rem 1.5rem 0 1.5rem;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    padding-bottom: 16px;
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 0;
    height: auto;
  }
`;

export const PlacesAroundYouHeaderFirstRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0;
  justify-content: space-between;

  svg g {
    filter: none;
  }

  h3 {
    font-size: 1.625rem;
    margin-bottom: 0;
    margin-top: 0;
    margin-right: 12px;
  }

  button {
    font-size: 0.875rem;
  }

  @media ${({ theme }) => theme.mediaQuery.medium} {
    display: flex;
    align-items: center;
    margin-top: 0;

    h3 {
      font-size: 2rem;
      margin-bottom: 0;
      margin-top: 0;
      margin-right: 12px;
    }
  }
`;

export const PlacesAroundYouHeaderSecondRow = styled.p`
  margin-top: 0;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colorMap.gray.gray1};

  @media ${({ theme }) => theme.mediaQuery.medium} {
    font-size: 1rem;
    margin-top: 0;
  }
`;

export const Section = styled.section`
  margin: 0 auto;
  margin-bottom: 80px;
  width: 85%;

  @media ${({ theme }) => theme.mediaQuery.large} {
    width: 100%;
    min-width: 1220px;
  }
`;

export const CommuterFriendlyRentalsSection = styled.section`
  // TODO: Remove display: none in future work
  display: none !important;
  flex-direction: column;
  padding: 1.25rem 0 8rem 1.5rem;

  .slick-track {
    display: flex;
  }

  @media ${({ theme }) => theme.mediaQuery.medium} {
    display: flex;
    flex-direction: row;
    background-color: $color-white;
    align-items: flex-start;
    padding-bottom: 185px;
    padding-right: 80px;
    padding-left: 80px;
  }
`;

export const HeaderContainer = styled.div`
  width: 100%;
  background: url(${RentalImage}) no-repeat center;
  background-size: cover;
  height: 332px;
  border-radius: 20px;
  position: relative;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    width: 50%;
  }
`;

export const HeaderContent = styled.div`
  width: 200px;
  padding-top: 12px;
  margin-left: 12px;

  h3 {
    font-size: 1.375rem;
    margin-bottom: 8px;
    color: $color-secondary;
  }

  p {
    font-size: 0.75rem;
    font-weight: 500;
  }

  @media ${({ theme }) => theme.mediaQuery.medium} {
    width: 275px;

    h3 {
      font-size: 2.125rem;
      font-weight: 700;
      color: $color-secondary;
    }

    p {
      font-size: 1rem;
      font-weight: 500;
      color: $color-grey;
    }
  }
`;

export const CommuterFriendlyRentalList = styled.ul`
  width: 100%;
  margin-top: 16px;
  list-style: none;
  padding: 0;

  .slick-list {
    padding: 0 20% 0 0;
  }

  li {
    width: 160px;
    padding-right: 8px;

    img {
      width: 100%;
      height: 190px;
      border-radius: 16px 16px 0 0;
    }
  }

  @media ${({ theme }) => theme.mediaQuery.medium} {
    display: flex;
    justify-content: space-evenly;
    width: 50%;

    li {
      width: 30%;

      img {
        width: 100%;
        height: auto;
      }
    }
  }
`;

export const PopularListingsSection = styled(Section)`
  background: ${({ theme }) => theme.colorMap.neutral.white};
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  width: 100%;
  margin-bottom: 56px;
`;

export const PopularListingsSectionHeader = styled.div`
  padding: 1.5rem 0px;
  border-radius: 40px;
  margin-top: -40px;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    padding: 0px;
    margin-top: 0px;
  }
`;
