import { ReactComponent as SoundSvg } from './images/Sound.svg';
import { ReactComponent as KeySvg } from './images/Key.svg';
import { ReactComponent as SunSvg } from './images/Sun.svg';
import { ReactComponent as PathSvg } from './images/Path.svg';
import { ReactComponent as AirConditioningSvg } from './images/AirConditioning.svg';
import { ReactComponent as DishwasherSvg } from './images/Dishwasher.svg';
import { ReactComponent as DogFriendly } from './images/DogFriendly.svg';
import { ReactComponent as BarbellSvg } from './images/Barbell.svg';

export const ratingData = [
  {
    icon: SoundSvg,
    title: 'Noise Level',
    description: 'Compared to rush hour traffic.',
    rating: 3.5,
  },
  {
    icon: KeySvg,
    title: 'Landlord',
    description: 'Fixes issues within days of calling.',
    rating: 4.0,
  },
  {
    icon: SunSvg,
    title: 'Natural Light',
    description: 'Amazing light all day long.',
    rating: 4.5,
  },
  {
    icon: PathSvg,
    title: 'Walkable',
    description: 'Need to drive to get to essentials.',
    rating: 2.0,
  },
];

export const amenitiesData = [
  {
    icon: AirConditioningSvg,
    label: 'AC Unit',
  },
  {
    icon: DishwasherSvg,
    label: 'Dishwasher',
  },
  {
    icon: DogFriendly,
    label: 'Pets Allowed',
  },
  {
    icon: BarbellSvg,
    label: 'Gym',
  },
];

export const mockQuestions = [
  {
    label: 'What’s the neighborhood like?',
    value: 'what_is_neighborhood_like',
  },
  { label: 'How’s the parking situation?', value: 'how_is_parking_situation' },
  { label: 'Is your landlord receptive?', value: 'is_your_landlord_receptive' },
  {
    label: 'What will you miss about this place?',
    value: 'what_will_you_miss_about_this_place',
  },
];

// Location map stylings
export const sharedMapStyles = [
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#767676',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#767676',
      },
    ],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#FFFFFF',
      },
    ],
  },
  {
    featureType: 'administrative',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#c9b2a6',
      },
    ],
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#dcd2be',
      },
    ],
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#ae9e90',
      },
    ],
  },
  {
    featureType: 'landscape.natural',
    elementType: 'geometry',
    stylers: [
      {
        color: '#EEE7EF',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry.fill',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'transit.line',
    elementType: 'labels.text.fill',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'transit.line',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'transit.station',
    elementType: 'geometry',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#b9d3c2',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#92998d',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [
      {
        color: '#FFBBC1',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#FFBBC1',
      },
    ],
  },
  {
    featureType: 'road.highway.controlled_access',
    elementType: 'geometry',
    stylers: [
      {
        color: '#FFBBC1',
      },
    ],
  },
  {
    featureType: 'road.highway.controlled_access',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#FFBBC1',
      },
    ],
  },
];

export const desktopMapStyles = [
  {
    elementType: 'geometry',
    stylers: [
      {
        color: 'EEE7EF',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [
      {
        color: '#F8F8F8',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry',
    stylers: [
      {
        color: '#F8F8F8',
      },
    ],
  },
  ...sharedMapStyles,
];

export const mobileMapStyles = [
  {
    elementType: 'geometry',
    stylers: [
      {
        color: '#FBF8FB',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [
      {
        color: '#FFFFFF',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry',
    stylers: [
      {
        color: '#FFFFFF',
      },
    ],
  },
  ...sharedMapStyles,
];

export const stripAddressNumber = (str: string): string => {
  const indexOfSpace = str.indexOf(' ');

  if (indexOfSpace === -1) {
    return '';
  }

  return str.substring(indexOfSpace + 1);
};

export const generateSimilarListingQuery = (
  lat: number | undefined,
  lng: number | undefined,
  limit: number,
  offset: number
) => {
  return `/search/by-proximity?lat=${lat}&lon=${lng}&available_at=${new Date(
    new Date().setHours(0, 0, 0, 0)
  ).toISOString()}&limit=${limit}&offset=${offset}`;
};
