/* @jsxImportSource @emotion/react */
import React from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import Paragraph, {
  ParagraphVariant,
} from '../../../components/Typography/Paragraph';
import { WeightTypes } from '../../../components/Typography/utils';
import Button from '../../../components/UI/Button';
import { getStaticAssetS3Url } from '../../../lib/static-assets';

const ScoutsImagePng = getStaticAssetS3Url('/marketplace/scouts.png');
const ScoutsImageWebp = getStaticAssetS3Url('/marketplace/scouts.webp');

const linkToTypeForm = () =>
  window
    ?.open('https://rentunicorn.typeform.com/to/W17RIkva', '_blank')
    ?.focus();

export const UnicornScouts: React.FC = () => {
  const { colorMap } = useTheme();

  return (
    <UnicornScoutsWrapper>
      <Content>
        <Paragraph
          css={{ marginBottom: '0.75rem' }}
          variant={ParagraphVariant.large}
          weight={WeightTypes.normal}
        >
          Introducing
        </Paragraph>
        <Header>Unicorn</Header>
        <Header>Scouts</Header>
        <Button
          onClick={linkToTypeForm}
          style={{
            marginTop: '1.0rem',
            background: colorMap.neutral.black,
          }}
          color="white"
          variant="tertiary"
          size="medium"
        >
          Become a Scout
        </Button>
      </Content>
      <ImageWrapper>
        <Picture>
          <source srcSet={ScoutsImageWebp} />
          <source srcSet={ScoutsImagePng} />
          <Image src={ScoutsImagePng} alt="Unicorn Scouts Program" />
        </Picture>
      </ImageWrapper>
    </UnicornScoutsWrapper>
  );
};

export default UnicornScouts;

const UnicornScoutsWrapper = styled.div`
  width: 100%;
  height: 540px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #f1f1f1 78.61%, #f5f5f5 85.38%);
  flex-direction: column;
  border-radius: 16px;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    flex-direction: row;
    width: 100%;
  }
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 40%;
`;
// Bigger than h1
const Header = styled.div`
  font-weight: 600;
  font-size: 2.875rem;
  line-height: 2.875rem;

  @media ${({ theme }) => theme.mediaQuery.small} {
    font-size: 2.875rem;
    line-height: 2.875rem;
  }

  @media ${({ theme }) => theme.mediaQuery.medium} {
    font-size: 3.875rem;
    line-height: 3.875rem;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 55%;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    flex-wrap: wrap;
    min-width: 61%;
    height: 100%;
    margin-left: auto;
  }

  @media ${({ theme }) => theme.mediaQuery.large} {
    width: 61%;
    min-width: 744px;
    height: 100%;
    margin-left: auto;
  }
`;

const Picture = styled.picture`
  width: 100%;
  height: 100%;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    object-fit: cover;
  }

  @media ${({ theme }) => theme.mediaQuery.large} {
    object-fit: contain;
  }
`;
