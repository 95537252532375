/* @jsxImportSource @emotion/react */

import React from 'react';
import styled from '@emotion/styled';
import { DefaultIcon } from '../../../components/Icon';
import ShareListing, {
  ShareListingButton,
} from '../../../components/ShareListing';
import { Listing } from '../../../types';
import { useApiClient, useToggleSentimentMutation } from '../../../hooks';
import SentimentButton from '../../../components/SentimentButton';
import { useQuery } from 'react-query';
import { useConditionalHistory } from '../../../hooks/useConditionalHistory';
import { IconSizeKeys } from '../../../components/Icon/utils';
import { DISCOVER_PADDING_REM } from '../constants';

type VideoButtonsProps = {
  listingId: string;
  isMuted: boolean;
  handleMuted: () => void;
};

const AuthenticatedVideoButtons: React.FC<VideoButtonsProps> = ({
  listingId,
  isMuted,
  handleMuted,
}) => {
  const client = useApiClient();
  const { data: listingDetails } = useQuery<{}, unknown, Listing>(
    [`listing-details-${listingId}`, { listingId }],
    () => client(`search/by-id?id=${listingId}`),
    { refetchOnWindowFocus: false, enabled: !!listingId }
  );

  const sentimentMutation = useToggleSentimentMutation(
    `listing-details-${listingId}`,
    {}
  );

  return (
    <VideoButtonWrapper>
      <ShareListing
        listingId={listingId}
        button={
          <ShareListingButton
            iconWrapperProps={{
              variant: IconSizeKeys.LARGE,
              height: 36,
              width: 36,
              fillColor: 'white',
              type: 'round',
            }}
          />
        }
      />
      <IconWrapper onClick={handleMuted}>
        <DefaultIcon name={isMuted ? 'soundOff' : 'soundOn'} />
      </IconWrapper>
      <IconWrapper>
        <SentimentButton
          className={'discover-video-sentiment-button'}
          listing={listingDetails}
          iconStyle={{
            padding: '0 !important',
          }}
          toggleSentiment={sentimentMutation.mutate}
        />
      </IconWrapper>
    </VideoButtonWrapper>
  );
};

const PublicVideoButtons: React.FC<VideoButtonsProps> = ({
  handleMuted,
  isMuted,
}) => {
  const history = useConditionalHistory();
  const redirectToLogin = () => {
    history.push('/');
  };
  return (
    <VideoButtonWrapper>
      <IconWrapper onClick={redirectToLogin}>
        <DefaultIcon width={20} height={20} name="send" />
      </IconWrapper>
      <IconWrapper onClick={handleMuted}>
        <DefaultIcon name={isMuted ? 'soundOff' : 'soundOn'} />
      </IconWrapper>
      <IconWrapper onClick={redirectToLogin}>
        <DefaultIcon name="heart" />
      </IconWrapper>
    </VideoButtonWrapper>
  );
};

export { PublicVideoButtons, AuthenticatedVideoButtons };

const VideoButtonWrapper = styled.div<{ videoWidth?: string }>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: ${DISCOVER_PADDING_REM}rem;

  > div {
    margin: 0.75rem 0rem;

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  @media ${({ theme }) => theme.mediaQuery.medium} {
    > div {
      margin: 0.5rem 0rem;
    }
  }
`;

const IconWrapper = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
  background: white;
  border-radius: 50%;
  cursor: pointer;

  .discover-video-sentiment-button {
    padding: 0;
  }

  @media ${({ theme }) => theme.mediaQuery.medium} {
    margin: 0.5rem 0;
  }
`;
