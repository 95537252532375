import { useQuery } from 'react-query';
import { Listing } from '../types';
import { useService } from './useServices';

const useSearchById = ({ userListing }) => {
  const listingService = useService<'listing'>('listing');

  return useQuery<{}, unknown, Listing>(
    [
      `listing-details-${userListing.listingId}`,
      { listingId: userListing.listingId },
    ],
    () => listingService.fetchListing(userListing.listingId),
    {
      refetchOnWindowFocus: false,
      enabled: !!userListing,
    }
  );
};

export default useSearchById;
