import type { apiClient } from '../../hooks';
import { User } from '../../types';
import { parseQueryParams } from '../utils';

export type UserService = {
  byUserId: (userId: string) => Promise<User>;
  byUserIds: (userIds: Array<string>) => Promise<Array<User>>;
};

export const userServiceFactory = (apiClient: apiClient): UserService => ({
  byUserId: (userId: string): Promise<User> =>
    apiClient<User>(`/user/by-id?user_id=${userId}`, { method: 'GET' }),
  byUserIds: (userIds: Array<string>): Promise<Array<User>> =>
    apiClient<Array<User>>(
      `/user/by-ids?${parseQueryParams({ user_ids: userIds })}`
    ),
});
