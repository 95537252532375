import styled from '@emotion/styled';

export const MAIN_IMAGE_WIDTH = 350;

export const UnlockAccessWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0rem 1rem;
`;

export const MainImage = styled.img`
  object-fit: contain;
  width: ${MAIN_IMAGE_WIDTH}px;
`;

export const ValueProps = styled.div`
  color: ${({ theme }) => theme.color.primaryPink};
  display: flex;
  flex-direction: column;
`;

export const ValueProp = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  span {
    color: ${({ theme }) => theme.colorMap.neutral.black};
  }
`;
