export enum RentalType {
  coop = 'coop',
  multi_family = 'multi_family',
  single_family = 'single_family',
  apartment = 'apartment',
  duplex_triplex = 'duplex_triplex',
  townhome = 'townhome',
  other = 'other',
  condo = 'condo',
  townhouse = 'townhouse',
  house = 'house',
}

export default RentalType;
