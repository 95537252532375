import styled from '@emotion/styled';
import Button from '../UI/Button';

export const ShareListingButtonWrapper = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ShareListingContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 1rem;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    justify-content: space-between;
  }
`;

export const ContentTitle = styled.span`
  width: 100%;
  font-weight: 700;
  font-size: 1.625rem;
  line-height: 34px;
  text-align: center;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    text-align: left;
  }
`;

export const Section = styled.div`
  position: relative;
  width: 100%;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    margin-top: auto;
  }
`;

export const SectionTitle = styled.span`
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 13px;
  display: block;
`;

export const MessagingSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    text-align: left;
  }
`;

export const MessagingIconSection = styled.div`
  display: flex;
`;

export const MessagingIconWrapper = styled.div`
  background: ${({ theme }) => theme.colorMap.gray.gray5};
  padding: 0.25rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const MessagingIconLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 1rem;
  margin-right: 1rem;
`;

export const Link = styled.div`
  display: flex;
  align-items: center;
  height: 54px;
  width: 100%;
  border-radius: 16px;
  border: 2.0355px solid #f4f4f4;
`;

export const LinkUrl = styled.input`
  flex: 1;
  text-overflow: clip;
  padding: 0.5rem;
  outline: none;
  border: none;
  font-size: 1rem;
`;

export const CopyButton = styled(Button)`
  width: 80px;
  height: 30px;
  margin-right: 0.5rem;
  border-color: ${({ theme }) => theme.color.primaryGreen};
  background: ${({ theme }) => theme.color.primaryGreen};
`;

export const CopyText = styled.span`
  font-size: 0.8rem;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  color: ${({ theme }) => theme.color.primaryGreen};
`;
