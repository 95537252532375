import { ReactComponent as LeftArrowSVG } from './leftArrow.svg';
import { ReactComponent as RightArrowSVG } from './rightArrow.svg';

export const LeftArrow = (props) => (
  <div {...props}>
    <LeftArrowSVG style={{ width: '100%' }} />
  </div>
);

export const RightArrow = (props) => (
  <div {...props}>
    <RightArrowSVG style={{ width: '100%' }} />
  </div>
);
