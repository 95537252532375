import { useEffect } from 'react';
import { Redirect, useLocation, useHistory } from 'react-router-dom';

import { useAuth } from '../../hooks';
import {
  getLocalStorage,
  LS_REDIRECT_URL_KEY,
  removeLocalStorage,
} from '../../lib/local-storage';
import {
  setSessionStorage,
  SS_ONBOARDING_PAGE_PATH,
} from '../../lib/session-storage';

const onboardingReviewPagePaths = new Set(['/listings/add', '/list-your-home']);

const RedirectRoute: React.FC<{ redirectTo: string }> = ({ redirectTo }) => {
  const { pathname } = useLocation();
  const history = useHistory();

  const { handleSetRedirectUrl, redirectUrl } = useAuth();

  useEffect(() => {
    // If we are redirecting from the Login/Signup page,
    // instead of sending the user to the Marketplace,
    // we will send user to the page they last visited
    // before showing the Auth
    if (pathname === '/login' || pathname === '/create') {
      const url = redirectUrl || getLocalStorage<string>(LS_REDIRECT_URL_KEY);
      removeLocalStorage(LS_REDIRECT_URL_KEY);
      // Only redirect here if url actually exists in auth provider or local storage.
      // Otherwise do an early return
      if (!url) return;

      // Set onboarding page if one is specified
      if (onboardingReviewPagePaths.has(url)) {
        setSessionStorage(SS_ONBOARDING_PAGE_PATH, url);
      }

      history.push(url);
    } else {
      handleSetRedirectUrl(pathname);
    }
  });

  return <Redirect to={redirectTo} />;
};

export default RedirectRoute;
