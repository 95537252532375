/* @jsxImportSource @emotion/react */
import React from 'react';

import { ReactComponent as LogoSVG } from './logo.svg';
import { ReactComponent as WordmarkSVG } from './wordmark.svg';
import { ReactComponent as LockupSVG } from './lockup.svg';
import { ReactComponent as SpinnerSVG } from './spinner.svg';
import { ReactComponent as UnicornSmallLogoSvg } from './UnicornSmallLogo.svg';
import { ReactComponent as UnicornLargeLogoSvg } from './UnicornLargeLogo.svg';

export const Logo = (props) => <LogoSVG {...props} />;

export const Wordmark = (props) => (
  <div css={{ color: 'black', width: '99px' }} {...props}>
    <WordmarkSVG style={{ width: '100%' }} />
  </div>
);

/**
 * TODO: Remove Lockup once Landing Page is updated to new branding
 */
export const Lockup = (props) => (
  <div css={{ color: 'black', width: '99px' }} {...props}>
    <LockupSVG style={{ width: '100%' }} />
  </div>
);

export const Spinner = (props) => (
  <div {...props}>
    <SpinnerSVG style={{ width: '100%' }} />
  </div>
);

export const UnicornSmallLogo = (props) => (
  <div {...props}>
    <UnicornSmallLogoSvg />
  </div>
);

export const UnicornLargeLogo = (props) => (
  <div {...props}>
    <UnicornLargeLogoSvg />
  </div>
);
