export enum MediaFormat {
  MP4 = 'MP4',
  MOV = 'MOV',
  PNG = 'PNG',
  JPG = 'JPG',
  JPEG = 'JPEG',
}

export enum MediaType {
  IMAGE = 'image',
  VIDEO = 'video',
}

export enum UploadState {
  READY = 'ready',
  UPLOADING = 'uploading',
  SUCCESS = 'success',
  ERROR = 'error',
}

export interface ListingMedia {
  id: string;
  mediaType: string;
  mediaFormat: string;
  objectKey: string;
  groupId: string;
  bucket: string;
  url: string;
  posterUrl?: string;
}

export interface MediaUploadState {
  uploaded: boolean;
}
