import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import useUnlockAccessModal from '../../hooks/useUnlockAccessModal';

export const ConditionalLink: React.FC<Link> = ({
  className,
  children,
  to,
}) => {
  const { showModal, showModalForUserAndRoute } = useUnlockAccessModal();

  const shouldShow = showModalForUserAndRoute(to);

  return shouldShow ? (
    <ShowModal className={className} onClick={() => showModal()}>
      {children}
    </ShowModal>
  ) : (
    <Link className={className} to={to}>
      {children}
    </Link>
  );
};

export default ConditionalLink;

const ShowModal = styled.span`
  cursor: pointer;
`;
