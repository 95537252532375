/* @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import Button from '../../../components/UI/Button';
import {
  DESKTOP_MAX_PAGE_HEIGHT,
  DISCOVER_MIN_HEIGHT_MEDIA_QUERY,
  DISCOVER_PADDING_REM,
} from '../constants';

export const VideoPlayerContainer = styled.div`
  position: absolute;
  margin: auto;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: ${({ theme }) => theme.zIndices.bottom};

  @media ${({ theme }) => theme.mediaQuery.medium} {
    width: 100%;
    height: 100%;
    padding-left: 80px;
  }
`;

export const MuteButtonContainer = styled.div`
  position: absolute;
  z-index: ${({ theme }) => theme.zIndices.topmost};
  top: 100px;
  left: ${DISCOVER_PADDING_REM}rem;
  // Need this to prevent flicker: https://stackoverflow.com/questions/63474734/background-video-glitch-on-mobile-safari-and-chrome
  -webkit-transform: translate3d(0, 0, 0);

  // Mobile landscape mode w/min height (when device rotates)
  @media ${({ theme }) => theme.mediaQuery.medium} {
    @media (max-height: ${DISCOVER_MIN_HEIGHT_MEDIA_QUERY}px) {
      padding-left: ${DISCOVER_PADDING_REM}rem;
    }
  }
`;

export const StyledMuteButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 35px;
  height: 35px;
  border-color: transparent;
`;

export const StyledVideo = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.2) 0%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.2) 100%
  );

  @media ${({ theme }) => theme.mediaQuery.medium} {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  @media (min-height: ${DESKTOP_MAX_PAGE_HEIGHT}px) {
    margin: auto;
  }
`;

export const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  margin: auto;
`;

export const VideoPlayer = styled.video`
  position: relative;
  object-fit: cover;
  margin: auto;
  background: transparent;

  &.landscape {
    height: auto;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
  }

  &.portrait {
    height: 100%;
    width: 100%;
  }

  // Mobile landscape mode w/min height (when device rotates)
  @media ${({ theme }) => theme.mediaQuery.medium} {
    @media (max-height: ${DISCOVER_MIN_HEIGHT_MEDIA_QUERY}px) {
      &.landscape {
        height: 100%;
        width: 100%;
      }
    }
  }

  @media (min-height: ${DESKTOP_MAX_PAGE_HEIGHT}px) {
    min-width: 500px;
  }

  &.hidden {
    visibility: hidden;
  }
`;

export const Poster = styled.img`
  position: absolute;
  object-fit: cover;
  margin: auto;
  z-index: ${({ theme }) => theme.zIndices.middle};

  &.landscape {
    height: auto;
    width: 100%;
    top: 0;
    bottom: 0;
  }

  &.portrait {
    height: 100%;
    width: 100%;
  }

  &.hidden {
    display: none;
  }

  // Mobile landscape mode w/min height (when device rotates)
  @media ${({ theme }) => theme.mediaQuery.medium} {
    @media (max-height: ${DISCOVER_MIN_HEIGHT_MEDIA_QUERY}px) {
      &.landscape {
        height: 100%;
        width: 100%;
        top: 0;
        bottom: 0;
      }
    }
  }

  @media (min-height: ${DESKTOP_MAX_PAGE_HEIGHT}px) {
    min-width: 500px;
  }
`;

export const ErrorScreen = styled.div`
  position: absolute;
  display: flex;
  max-height: 100%;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(50px);
  background-color: rgba(191, 191, 191, 0.1);
  z-index: ${({ theme }) => theme.zIndices.top};
  color: ${({ theme }) => theme.colorMap.neutral.white};
`;

export const ErrorTitle = styled.div`
  display: flex;
  flex: auto;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-height: 100px;

  svg {
    margin-right: 0.25rem;
    width: 36px;
    height: 36px;
    color: ${({ theme }) => theme.colorMap.neutral.white};
  }

  span {
    font-size: 1.5rem;
    font-weight: 700;
  }
`;

export const VideoPlayerBottom = styled.div`
  position: relative;
  width: 100%;
`;

export const VideoDetailsAndControls = styled.div`
  position: absolute;
  display: flex;
  align-items: flex-end;
  width: 100%;
  margin: auto;
  bottom: 0;
  right: 0;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0,
    rgba(0, 0, 0, 0.6) 92.19%
  );
  z-index: ${({ theme }) => theme.zIndices.top};
  min-height: 200px;
  padding-bottom: 1rem;
  // Need this to prevent flicker: https://stackoverflow.com/questions/63474734/background-video-glitch-on-mobile-safari-and-chrome
  -webkit-transform: translate3d(0, 0, 0);

  @media ${({ theme }) => theme.mediaQuery.medium} {
    padding-bottom: 2rem;
  }
`;

export const Details = styled.div`
  flex: 1;

  // Mobile landscape mode w/min height (when device rotates)
  @media ${({ theme }) => theme.mediaQuery.medium} {
    @media (max-height: ${DISCOVER_MIN_HEIGHT_MEDIA_QUERY}px) {
      padding-left: ${DISCOVER_PADDING_REM}rem;
    }
  }
`;

export const Controls = styled.div`
  flex-basis: 20%;

  // Mobile landscape mode w/min height (when device rotates)
  @media ${({ theme }) => theme.mediaQuery.medium} {
    @media (max-height: ${DISCOVER_MIN_HEIGHT_MEDIA_QUERY}px) {
      padding-right: ${DISCOVER_PADDING_REM}rem;
    }
  }
`;
