import { useState } from 'react';
import { Orientation } from '../types';
import { MediaType } from '../types/Media';

export type UseMediaDimensions = {
  width?: number;
  height?: number;
  orientation?: Orientation;
};

const videoDimensions = async (
  url
): Promise<{ width: number; height: number }> => {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');

    video.addEventListener(
      'loadedmetadata',
      () => {
        const width = video.videoWidth;
        const height = video.videoHeight;
        resolve({ width, height });
      },
      false
    );

    video.addEventListener('error', () => resolve({ width: 0, height: 0 }));

    video.src = url;
  });
};

const imageDimensions = async (
  url
): Promise<{ width: number; height: number }> => {
  return new Promise((resolve, reject) => {
    const image = document.createElement('img');

    image.addEventListener(
      'load',
      () => {
        const width = image.width;
        const height = image.height;
        resolve({ width, height });
      },
      false
    );

    image.addEventListener('error', () => resolve({ width: 0, height: 0 }));

    image.src = url;
  });
};

const useMediaDimensions = (
  elementType: MediaType,
  url: string
): UseMediaDimensions => {
  const [width, setWidth] = useState<number>();
  const [height, setHeight] = useState<number>();
  const [orientation, setOrientation] = useState<Orientation>();

  const resolveDimensions =
    elementType === MediaType.VIDEO ? videoDimensions : imageDimensions;

  resolveDimensions(url).then(({ width, height }) => {
    setWidth(width);
    setHeight(width);

    height < width ? setOrientation('landscape') : setOrientation('portrait');
  });

  return {
    width,
    height,
    orientation,
  };
};

export default useMediaDimensions;
