import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import { carouselImageSizes } from '../../lib/constants';

export const modalImageSizes = {
  mediumSize: {
    width: '400px',
    height: '332px',
  },
  defaultSize: {
    width: '320x',
    height: '266px',
  },
};

export const ListingTileContainer = styled.div`
  .slick-slider {
    .slick-dots {
      bottom: 24px;
      max-width: ${carouselImageSizes.mobile.maxWidth}px;
    }
    .slick-dots li,
    li button,
    li button:before {
      width: 6px;
      height: 6px;
      padding: 0px;
      margin: 0 0.25rem;
    }

    li button:before {
      color: ${({ theme }) => theme.color.white};
      opacity: 1;
    }

    li.slick-active button:before {
      color: ${({ theme }) => theme.color.primaryPink};
    }
  }

  position: relative;

  .sentiment-btn {
    position: absolute;
    top: 8px;
    left: 264px;
  }

  @media ${({ theme }) => theme.mediaQuery.small} {
    .slick-dots {
      bottom: 24px;
      left: 50%;
      transform: translateX(-50%);
    }

    .slick-slide > div {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 10px;
      padding-right: 10px;
    }

    .sentiment-btn {
      left: unset;
      right: 16px;
    }
  }

  @media ${({ theme }) => theme.mediaQuery.medium} {
    .slick-dots {
      max-width: ${carouselImageSizes.desktop.maxWidth}px;
    }
  }
`;

export const ListingTileChildImage = styled.img`
  object-fit: cover;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  width: 100%;
  margin-left: -24px;
  bottom: 0;
  padding: 1rem 1.5rem;

  box-shadow: -0.217625px -6.99662px 28px rgba(0, 0, 0, 0.03),
    -0.141053px -4.53484px 16.3981px rgba(0, 0, 0, 0.0209549),
    -0.0838259px -2.69499px 8.91852px rgba(0, 0, 0, 0.0158889),
    -0.043525px -1.39932px 4.55px rgba(0, 0, 0, 0.0130312),
    -0.0177324px -0.570095px 2.28148px rgba(0, 0, 0, 0.0106111),
    -0.00403009px -0.129567px 1.10185px rgba(0, 0, 0, 0.00685764);

  button {
    margin: 0 auto;
    max-width: none;
  }

  @media ${({ theme }) => theme.mediaQuery.medium} {
    margin-left: -108px;

    button {
      max-width: 327px;
    }
  }
`;

export const CloseIconWrapper = styled.div`
  display: inline-block;
  height: 36px;
  width: 36px;
  position: absolute;
  top: 20px;
  left: 24px;
`;

export const ModalContent = styled.div`
  .listing-tile {
    margin: 2rem auto 0;
    width: ${modalImageSizes.defaultSize.width} !important;
    height: ${modalImageSizes.defaultSize.height} !important;

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      border-radius: 16px;
    }

    @media ${({ theme }) => theme.mediaQuery.medium} {
      margin: 0 auto;
      width: ${modalImageSizes.mediumSize.width} !important;
      height: ${modalImageSizes.mediumSize.height} !important;
    }

    h5 {
      margin-top: 8px;
    }
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.colorMap.gray.gray1};
  display: inherit;
  align-items: inherit;
  outline: none;

  &:visited {
    color: inherit;
  }

  &:hover {
    text-decoration: none;
  }
`;
