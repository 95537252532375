import React, { Suspense, lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { FullscreenLoader } from './components/Animations/Loader';
import AddListingDetailsWithSublet from './pages/AddListing/Details/AddListingDetailsWithSublet';
import Welcome from './pages/AddListing/Welcome';
import EditListing from './pages/EditListing';
import Home from './pages/Home';
import QrCodeRedirect from './pages/QrCodeRedirect';
import RedirectRoute from './pages/Redirect';

const Profile = lazy(() => import('./pages/UserSettings'));
const AddListingDetails = lazy(() => import('./pages/AddListing/Details'));
const AddListingMedia = lazy(() => import('./pages/AddListing/Media'));
const RoommateOnboarding = lazy(
  () => import('./pages/AddListing/RoommateOnboarding')
);
const ListingDetailPage = lazy(() => import('./pages/ListingDetailPage'));
const Marketplace = lazy(() => import('./pages/Marketplace'));
const Favorites = lazy(() => import('./pages/Favorites'));
const Search = lazy(() => import('./pages/Search'));
const ListingsNearYou = lazy(() => import('./pages/ListingsNearYou'));
const Discover = lazy(() => import('./pages/Discover'));

const Router = () => (
  <Suspense fallback={<FullscreenLoader />}>
    <Switch>
      <Route path="/profile" exact component={Profile} />
      <Route
        path="/list-your-home/:listingId/media"
        exact
        component={AddListingMedia}
      />
      <Route path="/list-your-home" component={AddListingDetailsWithSublet} />
      <Route path="/listings/add" exact component={AddListingDetails} />
      <Route
        path="/listings/add/media/:listingId"
        exact
        component={AddListingMedia}
      />
      <Route path="/listings/:listingId" exact component={ListingDetailPage} />
      <Route path="/listings" exact component={Marketplace} />
      <Route path="/listings-near-you" exact component={ListingsNearYou} />
      <Route path="/search" exact component={Search} />
      <Route path="/refer/:referralCode" exact component={RoommateOnboarding} />
      <Route path="/favorites" exact component={Favorites} />
      <Route path="/discover" exact component={Discover} />
      <Route path="/qr/:code" exact component={QrCodeRedirect} />
      <Route path="/welcome" exact component={Welcome} />
      <Route path="/" exact component={Marketplace} />
      <Route path="/home" exact component={Home} />
      <Route path="/listing/:listingId/edit" exact component={EditListing} />
      <Route
        component={(props) => (
          <RedirectRoute redirectTo="/listings" {...props} />
        )}
      />
    </Switch>
  </Suspense>
);

export default Router;
