import styled from '@emotion/styled/macro';
import GoogleMapReact from 'google-map-react';
import { DESKTOP_MAX_PAGE_HEIGHT } from '../constants';
import MapMarker from '../../../components/MapMarker';

type MapViewProps = {
  lat: number;
  lng: number;
};

const MapView: React.FC<MapViewProps> = ({ lat, lng }) => {
  return (
    <MapContainer>
      <GoogleMapReact
        resetBoundsOnResize
        center={{
          lat: lat,
          lng: lng,
        }}
        zoom={15}
        options={{
          draggable: true,
          disableDefaultUI: true,
          keyboardShortcuts: false,
        }}
      >
        <MapMarker lat={lat} lng={lng} />
      </GoogleMapReact>
    </MapContainer>
  );
};

export default MapView;

const MapContainer = styled.div`
  height: 100%;
  width: 100%;

  @media (min-height: ${DESKTOP_MAX_PAGE_HEIGHT}px) {
    div div {
      border-radius: 32px;
    }

    div div div {
      border-radius: 32px;
    }
  }
`;
