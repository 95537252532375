import { RentalRatingType } from '../../../types/RentalRatingType';
import { RentalAmenityKey } from '../../../types/RentalAmenityKey';

type AddressComponent = {
  long_name: string;
  short_name: string;
  types: Array<string>;
};

type AmenityCreateBody = {
  amenity_key: string;
  amenity_type?: string;
};

type RatingCreateBody = {
  rating_type: RentalRatingType;
  rating: number;
};

const extractAddressData = (components: Array<AddressComponent>) => (
  type: string,
  version: 'long_name' | 'short_name' = 'long_name'
): string =>
  components.find(({ types }) => types.includes(type))?.[version] || '';

export const extractFieldValues = (geocodeResult) => {
  const extract = extractAddressData(geocodeResult[0].address_components);

  return {
    address1: `${extract('street_number')} ${extract('route')}`,
    city: extract('locality'),
    state: extract('administrative_area_level_1'),
    postal_code: extract('postal_code'),
    postal_code2: extract('postal_code_suffix'),
    country_code: extract('country', 'short_name'),
  };
};

export const processFormValues = (
  values: Record<string, string>
): Record<string, any> => {
  const ratings: RatingCreateBody[] = [
    {
      rating_type: RentalRatingType.LIGHT_LEVEL,
      rating: parseInt(values['natural_light_rating']),
    },
    {
      rating_type: RentalRatingType.LANDLORD,
      rating: parseInt(values['landlord_rating']),
    },
    {
      rating_type: RentalRatingType.NOISE_LEVEL,
      rating: parseInt(values['noise_level_rating']),
    },
  ];

  const amenities: AmenityCreateBody[] = [
    {
      amenity_key: values['has_washer_dryer']
        ? RentalAmenityKey.WASHER_DRYER
        : '',
    },
    {
      amenity_key: values['has_ac_unit']
        ? RentalAmenityKey.AIR_CONDITIONING
        : '',
    },
    {
      amenity_key: values['has_dishwasher'] ? RentalAmenityKey.DISHWASHER : '',
    },
    { amenity_key: values['has_heater'] ? RentalAmenityKey.HEATING : '' },
    { amenity_key: values['allows_dogs'] ? RentalAmenityKey.DOG_FRIENDLY : '' },
    { amenity_key: values['allows_cats'] ? RentalAmenityKey.CAT_FRIENDLY : '' },
    { amenity_key: values['has_backyard'] ? RentalAmenityKey.BACKYARD : '' },
    { amenity_key: values['has_gym'] ? RentalAmenityKey.GYM : '' },
    {
      amenity_key: values['has_balcony'] ? RentalAmenityKey.BALCONY_PATIO : '',
    },
    { amenity_key: values['has_pool'] ? RentalAmenityKey.POOL : '' },
    { amenity_key: values['furnished'] ? RentalAmenityKey.FURNISHED : '' },
  ].filter((amenity) => amenity.amenity_key !== '');

  const leaseDurationDays = values['short_term']
    ? daysBetween(
        new Date(values['lease_start_date']),
        new Date(values['lease_end_date'])
      )
    : null;

  const availableAt = values['short_term']
    ? values['lease_start_date']
    : values['available_at'];

  return {
    created_by: values['created_by'],
    user_id: values['user_id'],
    first_name: values['first_name'],
    last_name: values['last_name'],
    address1: values['address1'],
    address2: values['address2'],
    city: values['city'],
    state: values['state'],
    postal_code: values['postal_code'],
    postal_code2: values['postal_code2'],
    country_code: values['country_code'],
    monthly_rent: values['monthly_rent'],
    rental_type: values['rental_type'],
    num_bathrooms: values['num_bathrooms'],
    num_bedrooms: values['num_bedrooms'],
    num_parking_spots: values['num_parking_spots'],
    available_at: availableAt,
    listing_type: values['listing_type'],
    description: values['description'] || null,
    lease_duration_days: leaseDurationDays,
    ratings: ratings,
    amenities: amenities,
  };
};

export const DAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];
export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const nth = (d) => {
  const dString = String(d);
  const last = +dString.slice(-2);
  if (last > 3 && last < 21) return 'th';
  switch (last % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

export const dateFieldFormat = (dateString: string): string => {
  const date = new Date(dateString);
  const year = date.getFullYear().toString();
  const month = (date.getMonth() + 1).toString();
  const dt = date.getDate().toString();

  const formattedDt = dt.length <= 1 ? `0${dt}` : dt;
  const formattedMonth = month.length <= 1 ? `0${month}` : month;
  return `${formattedMonth}/${formattedDt}/${year.slice(2, 4)}`;
};

export const datePickerAriaLabelFormat = (date: Date): string => {
  const year = date.getFullYear().toString();
  const day = DAYS[date.getDay()];
  const dt = date.getDate().toString();
  const month = MONTHS[date.getMonth()];

  return `${day}, ${month} ${dt}${nth(dt)}, ${year}`;
};

export const daysBetween = (date1: Date, date2: Date) => {
  const dayMilli = 1000 * 60 * 60 * 24;
  const timeDiff = date2.getTime() - date1.getTime();
  return Math.round(timeDiff / dayMilli);
};
