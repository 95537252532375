import React, { useMemo } from 'react';

import { ClickableLabel } from './styles';

export type RadioInputProps = React.HTMLProps<HTMLInputElement> & {
  label: string;
};

const uniqId = ((n) => () => `unicorn-form-input-radio-${++n}`)(0);

const RadioInput = React.forwardRef<HTMLInputElement, RadioInputProps>(
  ({ label, className, ...props }, ref) => {
    const id = useMemo(uniqId, []);

    return (
      <div className={className}>
        <input
          id={id}
          className="visually-hidden"
          type="radio"
          ref={ref}
          {...props}
        />
        <ClickableLabel htmlFor={id}>{label}</ClickableLabel>
      </div>
    );
  }
);

export default RadioInput;
