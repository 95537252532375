import SliderInput from './SliderInput';
import { useController } from 'react-hook-form';

import type { Control, FieldValues } from 'react-hook-form';
import type { SliderInputProps } from './types';

const ControlledSliderInput = ({
  control,
  ...props
}: SliderInputProps & { control: Control<FieldValues> }) => {
  const { field } = useController({ name: props.name as string, control });

  return <SliderInput {...props} {...field} />;
};

export default SliderInput;
export { SliderInput, ControlledSliderInput };
