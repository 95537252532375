import { CSSProperties } from 'react';

export const priceTextInputStyles: CSSProperties = {
  border: '1px solid #E0E0E0',
  background: 'none',
  paddingLeft: '20px',
  paddingBottom: '8px',
  paddingTop: '24px',
  height: '56px',
  fontSize: '18px',
  fontWeight: 400,
  letterSpacing: '2px',
  fontFamily: 'ZichtbaarRegular',
  borderRadius: '8px',
};

export const placeTypeSelectionStyles: CSSProperties = {
  width: 'auto',
  padding: '16px',
  maxWidth: 'unset',
  textAlign: 'left',
};

export const durationSelectionStyles: CSSProperties = {
  width: 'auto',
  padding: '16px',
  flex: 1,
  maxWidth: 'unset',
  textAlign: 'left',
};

export const bedroomSelectionStyles: CSSProperties = {
  width: 'auto',
  paddingLeft: '8px',
  paddingRight: '8px',
  borderRadius: '8px',
};

export const desktopButtonStyles: CSSProperties = {
  borderRadius: '8px',
  padding: '8px 20px',
  minWidth: 'unset',
  maxWidth: 'unset',
  width: 'fit-content',
  display: 'flex',
};

export enum Duration {
  SHORT_TERM = 'short_term',
  LONG_TERM = 'long_term',
}

export enum PlaceType {
  FULL = 'full',
  PRIVATE = 'private',
}

export const durationOptions = [
  {
    label: 'Short-term',
    value: Duration.SHORT_TERM,
    subLabel: '6 months or less',
    icon: 'leaf',
  },
  {
    label: 'Long-term',
    value: Duration.LONG_TERM,
    subLabel: '6 months or more',
    icon: 'tree',
  },
];

export const placeTypeOptions = [
  {
    label: 'Entire place',
    value: PlaceType.FULL,
    subLabel: 'A place all to yourself',
  },
  {
    label: 'Private room',
    value: PlaceType.PRIVATE,
    subLabel: 'Your own room in a home, plus some shared common space',
  },
];

export const bedroomOptionMax = 'max';
export const bedroomOptionMaxArrIndex = 3; // index when in list of below options

export const bedroomOptions = [
  {
    label: 'Studio',
    value: '0',
  },
  {
    label: '1',
    value: '1',
  },
  {
    label: '2',
    value: '2',
  },
  {
    label: '3',
    value: '3',
  },
  {
    label: '4+',
    value: bedroomOptionMax,
  },
];

export enum FilterDropdown {
  Duration = 'duration',
  Beds = 'beds',
  Price = 'price',
}
