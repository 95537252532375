import React, { MouseEventHandler, useMemo } from 'react';
import styled from '@emotion/styled';

import NavBar from '../../components/NavBar';
import { useSearch } from '../../hooks';
import { useLocation, useParams } from 'react-router';
import { PATHS_WITH_SEARCH } from '../../hooks/useSearch';

const AppPageComponent: React.FC<{ className?: string }> = ({
  children,
  className,
}) => {
  const { pathname } = useLocation();
  const { listingId } = useParams<{ listingId?: string }>();

  const {
    handlers: { handlePageClick },
    shouldShowSearch,
    showSearchDesktopView,
  } = useSearch();

  const props = useMemo(() => {
    const p = {};

    const showSearch = shouldShowSearch(
      pathname,
      [{ param: listingId, dynamicKey: '/:listingId' }],
      PATHS_WITH_SEARCH
    );

    if (showSearch && showSearchDesktopView) {
      p['onClick'] = ((event) => {
        handlePageClick((event as unknown) as Event);
      }) as MouseEventHandler<HTMLDivElement>;
    }

    return p;
  }, [
    pathname,
    showSearchDesktopView,
    handlePageClick,
    listingId,
    shouldShowSearch,
  ]);

  return (
    <AppPage className={className} {...props}>
      <NavBar />
      {children}
    </AppPage>
  );
};

const AppPage = styled.div<{ onClick?: MouseEventHandler<HTMLDivElement> }>`
  margin-bottom: 40px;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    margin-bottom: 0;
    margin-top: 72px;

    &.fullbleed {
      margin-top: 0;
    }
  }
`;

export default AppPageComponent;
