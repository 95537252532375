import { useState } from 'react';
import { lastResortFallbackLocation } from '../lib/constants';
import {
  getLocalStorage,
  LS_SEARCH_LOCATION_KEY,
  setLocalStorage,
} from '../lib/local-storage';
import { Location } from '../types/Location';
import { useConfig } from './useConfig';

export const useUserLocation = () => {
  /**
   * Sequence of fallbacks if we don't have a location
   * 1. Check if there is a location is state
   * 2. Check local storage cache
   * 3. Ask for the browser's location
   * 4. If brower location check fails, check the user's expected location based off listing rental's location
   * 5. Last resort, fall back to LA location
   */
  const cachedLocation = getLocalStorage<Location>(LS_SEARCH_LOCATION_KEY);
  const [userLocation, setUserLocation] = useState<Location | null>(
    cachedLocation
  );
  const { searchDefaults } = useConfig();

  const setLocationCache = (location) => {
    const daysExpiresAtMilliseconds = 1000 * 3600 * 24 * 5; // 5 days
    setLocalStorage(
      LS_SEARCH_LOCATION_KEY,
      location,
      daysExpiresAtMilliseconds
    );
  };

  const success = (pos) => {
    const location: Location = {
      latitude: pos.coords.latitude.toFixed(4),
      longitude: pos.coords.longitude.toFixed(4),
    };
    setUserLocation(location);
    setLocationCache(location);
  };

  const error = (err) => {
    console.warn(`ERROR(${err.code}): ${err.message}`);

    const fallbackLocation =
      searchDefaults?.expectedLocation?.location || lastResortFallbackLocation;

    setUserLocation(fallbackLocation);
    setLocationCache(fallbackLocation);
  };

  const navigatorLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error);
    }
  };

  if (!userLocation) {
    navigatorLocation();
  }

  return {
    userLocation,
  };
};
