import { ListingType } from '../types/ListingType';

export const monthlyRent = (cents: number): string => {
  const rent = parseInt((cents / 100).toFixed(1));
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumSignificantDigits: 6,
  }).format(rent);
};

export const abbrMonthlyRent = (cents: number): string => {
  const rent = (cents / 100000).toFixed(1);
  if (rent[0] === '0') {
  }
  return `$${rent}K`;
};

export const getReadableDate = (date: string): string => {
  const d = new Date(date);
  const today = new Date();

  if (d.setHours(0, 0, 0, 0) <= today.setHours(0, 0, 0, 0)) {
    return 'Now';
  } else {
    return d.toLocaleString('default', {
      month: 'short',
      day: 'numeric',
    });
  }
};

export const getReadableMonth = (date: string): string => {
  const d = new Date(date);
  const today = new Date();

  if (d.setHours(0, 0, 0, 0) <= today.setHours(0, 0, 0, 0)) {
    return 'Now';
  } else {
    return d.toLocaleString('default', {
      month: 'long',
    });
  }
};

export const rangeTo = (start: number, end: number): number[] => {
  const keys = new Array(end + 1 - start).keys();
  return [...Array.from(keys)];
};

export const sortKeys = (
  obj: any
): Record<string, string | number | boolean | undefined | any> => {
  return Object.keys(obj)
    .sort()
    .reduce(
      (acc, key) => ({
        ...acc,
        [key]: obj[key],
      }),
      {}
    );
};

export const encodeBody = (
  body: Record<string, string | number | boolean | undefined | any>
) => {
  return Object.entries(sortKeys(body))
    .flatMap((pair) => {
      const [k, v] = pair;

      if (v === undefined || v === null) {
        return [];
      }

      if (typeof v === 'object') {
        return (v as any[]).map((val) =>
          [k, encodeURIComponent(val)].join('=')
        );
      }

      return [k, encodeURIComponent(v)].join('=');
    })
    .join('&');
};

export const bedsDisplay = (numBeds: number) => {
  if (numBeds === 0) {
    return 'Studio';
  } else if (numBeds === 1) {
    return '1 Bed';
  } else {
    return `${numBeds} Beds`;
  }
};

export const bathsDisplay = (numBaths: number) => {
  if (numBaths === 1) {
    return '1 Bath';
  } else {
    return `${numBaths} Baths`;
  }
};

export const availDisplay = (date: string) => {
  return `Avail. ${getReadableMonth(date)}`;
};

export const toMapWithInitialValue = <K, V>(arr: K[], initialValue: V) => {
  return arr.reduce((prev, curr) => {
    const newObject = {};
    newObject[`${curr}`] = initialValue;
    return { ...prev, ...newObject };
  }, {});
};

export const associate = <T, K extends keyof T, V extends keyof T>(
  arr: T[],
  k: K,
  v: V
) => {
  return arr.reduce((prev, curr) => {
    const key = curr[k];
    const value = curr[v];
    const newObject = {
      [`${key}`]: value,
    };
    return { ...prev, ...newObject };
  }, {});
};

export const loadImageOffscreen = async (url): Promise<{ loaded: boolean }> => {
  return new Promise((resolve, reject) => {
    const img = document.createElement('img');

    img.addEventListener(
      'load',
      () => {
        resolve({ loaded: true });
      },
      false
    );

    img.addEventListener('error', () => resolve({ loaded: false }));

    img.src = url;
  });
};

export const loadVideoOffscreen = async (url): Promise<{ loaded: boolean }> => {
  return new Promise((resolve) => {
    const video = document.createElement('video');

    video.addEventListener(
      'loadedmetadata',
      () => {
        resolve({ loaded: true });
      },
      false
    );

    video.addEventListener('error', () => resolve({ loaded: false }));

    video.src = url;
  });
};

export const stringToListingType = (str: string): ListingType => {
  switch (str) {
    case ListingType.FULL.toString().toLowerCase():
      return ListingType.FULL;
    case ListingType.PRIVATE.toString().toLowerCase():
      return ListingType.PRIVATE;
    case ListingType.SHARED.toString().toLowerCase():
      return ListingType.SHARED;
    default:
      return ListingType.UNKNOWN;
  }
};

export const capitalize = (str: string): string =>
  str.charAt(0).toUpperCase() + str.slice(1);

const fullToAbbreviatedStateMap: Record<string, string> = {
  Alabama: 'AL',
  Alaska: 'AK',
  'American Samoa': 'AS',
  Arizona: 'AZ',
  Arkansas: 'AR',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  Delaware: 'DE',
  'District Of Columbia': 'DC',
  'Federated States Of Micronesia': 'FM',
  Florida: 'FL',
  Georgia: 'GA',
  Guam: 'GU',
  Hawaii: 'HI',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  'Marshall Islands': 'MH',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  'Northern Mariana Islands': 'MP',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Palau: 'PW',
  Pennsylvania: 'PA',
  'Puerto Rico': 'PR',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Vermont: 'VT',
  'Virgin Islands': 'VI',
  Virginia: 'VA',
  Washington: 'WA',
  'West Virginia': 'WV',
  Wisconsin: 'WI',
  Wyoming: 'WY',
};

export const abbreviateState = (fullStateName?: string): string => {
  if (!fullStateName) return '';

  return fullToAbbreviatedStateMap[fullStateName] || fullStateName;
};

export const parseQueryParams = (params = {}) => {
  return Object.keys(params)
    .filter((key) => {
      return (
        params[key] !== undefined ||
        params[key] !== null ||
        (typeof params[key] === 'object' && params[key]?.length > 0)
      );
    })
    .flatMap((key) => {
      return typeof params[key] === 'object'
        ? params[key].map((k) => key + '=' + k)
        : key + '=' + params[key];
    })
    .sort((a, b) => a - b)
    .join('&');
};

// hack: google's autocomplete gives cities in the form
// "Los Angeles, CA, USA" -- but the API expects just the
// name of the city
export const toShortCityName = (result: string) => result?.replace(/,.*$/, '');
