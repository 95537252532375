/* @jsxImportSource @emotion/react */

import React from 'react';
import styled from '@emotion/styled';
import Button from '../../../components/UI/Button';
import { DiscoverFeed } from '../../../lib/services/discover';
import { navBarHeight } from '../../../components/NavBar/components';
import Icon from '../../../components/Icon';
import { useConditionalHistory } from '../../../hooks/useConditionalHistory';
import { useTheme } from '@emotion/react';
import { useMediaQuery } from '../../../hooks';
import { DISCOVER_PADDING_REM } from '../constants';
import { DISCOVER_MIN_HEIGHT_MEDIA_QUERY } from '../constants';

type DiscoverButtonsProps = {
  feeds: DiscoverFeed[];
  activeFeed: DiscoverFeed;
  handleFeedClick: (feed: DiscoverFeed) => void;
};

const DiscoverButtons: React.FC<DiscoverButtonsProps> = ({
  feeds,
  activeFeed,
  handleFeedClick,
}) => {
  const { mediaQuery } = useTheme();
  const minWidthMedium = useMediaQuery(mediaQuery.medium);
  const minHeight = useMediaQuery(
    `(min-height: ${DISCOVER_MIN_HEIGHT_MEDIA_QUERY}px)`
  );
  const { push } = useConditionalHistory();

  return (
    <DiscoverButtonContainer>
      {(!minWidthMedium || !minHeight) && (
        <SearchIconWrapper onClick={() => push('/listings')}>
          <Icon
            size={'medium'}
            name="search"
            height={36}
            width={36}
            color={'white'}
          />
        </SearchIconWrapper>
      )}
      {feeds.map((feed) => {
        return activeFeed === feed ? (
          <ActiveButton
            key={feed}
            size="small"
            variant="primary"
            onClick={() => handleFeedClick(feed)}
            color="primaryPink"
          >
            {feed}
          </ActiveButton>
        ) : (
          <InactiveButton
            key={feed}
            size="small"
            variant="tertiary"
            onClick={() => handleFeedClick(feed)}
            color="primaryGreen"
          >
            {feed}
          </InactiveButton>
        );
      })}
    </DiscoverButtonContainer>
  );
};

export default DiscoverButtons;

const DiscoverButtonContainer = styled.div`
  position: absolute;
  top: 0;
  padding: ${DISCOVER_PADDING_REM}rem;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: ${({ theme }) => theme.zIndices.top};
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    top: ${navBarHeight}px;
    left: 0;
    right: 0;
    padding: 0;
    padding-top: 2rem;
    padding-left: 80px;
    width: 100%;

    // Mobile landscape mode w/min height (when device rotates)
    @media (max-height: ${DISCOVER_MIN_HEIGHT_MEDIA_QUERY}px) {
      top: 0px;
      padding-left: 0px;
    }
  }
`;

const SearchIconWrapper = styled.div`
  position: absolute;
  left: ${DISCOVER_PADDING_REM}rem;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 50%;
  cursor: pointer;
  max-width: 40px;
  justify-self: flex-start;
`;

const FeedButton = styled(Button)`
  color: ${({ theme }) => theme.colorMap.neutral.white};
  text-transform: capitalize;
  margin: 0.25rem;
`;

const InactiveButton = styled(FeedButton)`
  border-color: white;
  color: ${({ theme }) => theme.colorMap.neutral.white};
`;

const ActiveButton = styled(FeedButton)`
  border-color: ${({ theme }) => theme.color.primaryPink};
  color: ${({ theme }) => theme.colorMap.neutral.white};
`;
