import { VideoListingDetails } from '../../../types';

export type InfiniteQueryPaginationUpdate = {
  pages: VideoListingDetails[][];
  pageParams: any[];
  index: number;
  currentVideoId: string;
};

export const getPreviousPageParam = (offset: number, limit: number) => {
  const prevOffset = Math.max(0, offset - limit);
  if (prevOffset < 0) {
    return undefined;
  } else {
    return prevOffset;
  }
};

export const getNextPageParam = (
  offset: number,
  limit: number,
  hasReachedEnd: boolean
) => {
  if (hasReachedEnd) {
    return undefined;
  }
  return offset + limit;
};

// Recursive function to determine which page the current video is in
export const currentPageByIndex = (
  pages: VideoListingDetails[][],
  index: number,
  pageIndex: number = 0
): number => {
  if (pageIndex === pages.length) {
    return pages.length;
  }

  const total = pages.slice(0, pageIndex + 1).flat().length;

  if ((index + 1) / total <= 1) {
    return pageIndex + 1;
  } else {
    return currentPageByIndex(pages, index, pageIndex + 1);
  }
};

export const handleNext = (
  index: number,
  paginatedResults: { pages: VideoListingDetails[][]; pageParams: any[] }
): InfiniteQueryPaginationUpdate => {
  const { pages, pageParams } = paginatedResults;

  const videos = pages.flat();
  const currentPage = currentPageByIndex(pages, index, 0);
  // If current page is 1, we should check to get current video at the index of page 1.
  // Otherwise, calling prev will always jump to the first element of the prev batch of videos.
  const currentVideo =
    currentPage === 1
      ? pages[1][index]
      : videos[Math.min(videos.length - 1, index)];

  const startingIndex = Math.max(0, currentPage - 1);
  const endingIndex = Math.min(pages.length, currentPage + 1);

  const slicedPages = pages.slice(startingIndex, endingIndex);
  const slicedParams = pageParams.slice(startingIndex, endingIndex);

  const slicedVideos = slicedPages.flat();
  const adjustedIndex =
    slicedVideos.findIndex((vid) => vid.id === currentVideo.id) || 0;

  return {
    pages: slicedPages,
    pageParams: slicedParams,
    index: adjustedIndex,
    currentVideoId: currentVideo.id,
  };
};
