import { useState } from 'react';

export type UseVideo = {
  isPlaying: boolean;
  videoError: boolean;
  handlers: {
    handleError;
    handlePlay;
  };
};

const useVideo = (): UseVideo => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [videoError, setVideoError] = useState<boolean>(false);
  const [userPlayedVideo, setUserPlayedVideo] = useState<boolean>(false);

  const handleError = (e) => {
    console.log('Error ', e);
    setVideoError(true);
  };

  const onFirstVideoPlay = (video: HTMLMediaElement) => {
    if (!userPlayedVideo) {
      video.currentTime = 0;
      setUserPlayedVideo(true);
    }
  };

  const handlePlay = () => {
    const videoId = `carousel-video`;
    const video = document.getElementById(videoId) as HTMLMediaElement;

    if (!video) return;

    if (!isPlaying) {
      setIsPlaying(true);
      onFirstVideoPlay(video);

      video!!.play();
      video!!.muted = false;
    } else {
      setIsPlaying(false);
      video?.pause();
    }
  };

  return {
    isPlaying,
    videoError,
    handlers: {
      handleError,
      handlePlay,
    },
  };
};

export default useVideo;
