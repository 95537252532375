import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

const todayAtMidnight = () => {
  const d = new Date();
  d.setHours(0, 0, 0, 0);
  return d.toISOString();
};

export function useListingSearchParams() {
  const { state } = useLocation<{ city: string; available_at: string }>();
  const [city, setCity] = useState<string>(state?.city);
  const [available_at, setAvailableAt] = useState<string>(
    state?.available_at || todayAtMidnight()
  );

  useEffect(() => {
    setCity(state?.city);
    setAvailableAt(state?.available_at);
  }, [state?.city, state?.available_at]);

  return { city, available_at };
}
