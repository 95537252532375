import React from 'react';
import ReactCarousel from 'react-slick';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import { useMediaQuery, useToggleSentimentMutation } from '../../hooks';
import { carouselImageSizes } from '../../lib/constants';
import { rangeTo } from '../../lib/utils';

import ListingTile from '../ListingTile';
import Skeleton from '../UI/Skeleton/Skeleton';
import { Listing } from '../../types';

type SliderSectionProps = {
  ref?: React.Ref<ReactCarousel>;
  total: number;
  data: Listing[] | undefined;
  loading: boolean;
  queryPath: string;
};

const SliderSection: React.FC<SliderSectionProps> = ({
  ref,
  total,
  data,
  loading,
  queryPath,
}) => {
  const { mediaQuery } = useTheme();
  const minWidthSmall = useMediaQuery(mediaQuery.small);
  const minWidthMedium = useMediaQuery(mediaQuery.medium);
  const minWidthLarge = useMediaQuery(mediaQuery.large);
  const minWidthXlarge = useMediaQuery(mediaQuery.xlarge);
  const minWidthMax = useMediaQuery(mediaQuery.max);

  const mutateSentiment = useToggleSentimentMutation(queryPath);

  const getTilesInRow = () => {
    if (minWidthXlarge || minWidthMax) {
      return 4;
    }

    if (minWidthLarge || minWidthMedium) {
      return 3;
    }

    if (minWidthSmall) {
      return 2;
    }

    return 1;
  };

  return (
    <StyledReactCarousel
      ref={ref}
      slidesToShow={Math.min(4, total)}
      slidesToScroll={Math.min(4, total)}
      arrows={minWidthMedium}
      infinite={minWidthMedium}
      className="parent-carousel"
      responsive={[
        {
          breakpoint: 1023,
          settings: {
            slidesToShow: Math.min(3, total),
            slidesToScroll: Math.min(3, total),
            infinite: true,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: Math.min(2, total),
            slidesToScroll: Math.min(2, total),
            infinite: true,
          },
        },
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
          },
        },
      ]}
    >
      {loading &&
        rangeTo(1, Math.min(4, total)).map((_, idx) => {
          return (
            <Skeleton
              key={`skeleton-${idx}`}
              height={carouselImageSizes.height}
              width={carouselImageSizes.width}
            />
          );
        })}

      {!loading &&
        data?.map((listing, idx) => {
          const { listingId } = listing;
          return (
            <ListingTile
              tilesInRow={Math.min(getTilesInRow(), total)}
              key={`${listingId}-${idx}`}
              className="places-around-you-tile"
              listingId={listingId}
              data={listing}
              toggleSentiment={mutateSentiment.mutate}
            />
          );
        })}
    </StyledReactCarousel>
  );
};

export default SliderSection;

const StyledReactCarousel = styled(ReactCarousel)``;
