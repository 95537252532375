import fscreen from 'fscreen';
import React from 'react';
import {
  useMemo,
  useRef,
  useState,
  useEffect,
  useCallback,
  useContext,
  MutableRefObject,
} from 'react';

type Fullscreen = {
  fullscreenRef: MutableRefObject<any>;
  isFullscreenEnabled: boolean;
  isFullscreen: boolean;
  enterFullscreen: () => void;
  exitFullscreen: () => void;
};

const FullscreenContext = React.createContext<Fullscreen>({} as Fullscreen);

const FullscreenProvider = ({ children }) => {
  const fullscreenRef = useRef();
  const [isFullscreen, setIsFullscreen] = useState(false);

  const onChange = () =>
    setIsFullscreen(fscreen.fullscreenElement === fullscreenRef.current);

  const enterFullscreen = useCallback(async () => {
    if (fscreen.fullscreenElement) {
      await fscreen.exitFullscreen();
    }
    return fscreen.requestFullscreen(fullscreenRef.current);
  }, []);

  const exitFullscreen = useCallback(async () => {
    if (fscreen.fullscreenElement === fullscreenRef.current) {
      return fscreen.exitFullscreen();
    }
  }, []);

  useEffect(() => {
    fscreen.addEventListener('fullscreenchange', onChange);
    return () => fscreen.removeEventListener('fullscreenchange', onChange);
  }, []);

  const context = useMemo(() => {
    return {
      fullscreenRef,
      isFullscreenEnabled: fscreen.fullscreenEnabled,
      isFullscreen,
      enterFullscreen,
      exitFullscreen,
    };
  }, [isFullscreen, enterFullscreen, exitFullscreen]);

  return (
    <FullscreenContext.Provider value={context}>
      {children}
    </FullscreenContext.Provider>
  );
};

const useFullscreen = () => useContext(FullscreenContext);

export { useFullscreen, FullscreenProvider };
