import { useMutation, useQueryClient } from 'react-query';

import { MessageProps } from '../pages/Home/Messaging';
import { useService } from './useServices';

const useSetMessageRead = (queryKey: string) => {
  const messageService = useService<'message'>('message');
  const queryClient = useQueryClient();

  return useMutation(
    (message: MessageProps): Promise<MessageProps> =>
      messageService.updateMessageRead(message.id),
    {
      // optimistically update on read
      async onMutate(message: MessageProps) {
        await queryClient.cancelQueries([queryKey]);

        const optimisticMessage = {
          id: message.id,
          listingId: message.listingId,
          message: message.message,
          respondedAt: message.respondedAt,
          submittedAt: message.submittedAt,
          userId: message.userId,
          readAt: new Date().toISOString(),
        };

        queryClient.setQueryData([queryKey], (previous) => {
          return !Array.isArray(previous)
            ? optimisticMessage
            : previous.map((m) =>
                m.id !== message.id ? m : optimisticMessage
              );
        });

        return { optimisticMessage };
      },
      // set to actual value on success
      onSuccess(result: MessageProps) {
        console.log(' in onSuccess ');
        queryClient.setQueryData([queryKey], (previous) => {
          return !Array.isArray(previous)
            ? previous
            : previous.map((m) => {
                console.log({ m, result });
                return m.id !== result.id
                  ? m
                  : { ...(m as MessageProps), ...result };
              });
        });
      },
      // reset to previous value when the mutation errors
      onError(_err, message, previousValue) {
        queryClient.setQueryData([queryKey, message.listingId], previousValue);
      },
    }
  );
};

export default useSetMessageRead;
