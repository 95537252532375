import {
  ListingMedia,
  MediaFormat,
  MediaType,
  MediaUploadState,
} from '../../types/Media';

export const mimeTypeToMediaFormat = (mimeType) => {
  switch (mimeType) {
    case 'video/mp4':
      return MediaFormat.MP4;
    case 'video/quicktime':
      return MediaFormat.MOV;
    case 'image/jpg':
      return MediaFormat.JPG;
    case 'image/jpeg':
      return MediaFormat.JPEG;
    case 'image/png':
      return MediaFormat.PNG;
  }
};

interface EnhancedFile extends File {}
export type ListingMediaService = {
  createMultiple: (
    files: Array<EnhancedFile>,
    listingId: string
  ) => Promise<ListingMedia[]>;
  create: (file: EnhancedFile, listingId: string) => Promise<ListingMedia>;
  deleteByGroupId: (
    mediaType: MediaType,
    groupId: string
  ) => Promise<ListingMedia[]>;
  uploadState: (
    mediaType: MediaType,
    bucket: string,
    objectKey: string
  ) => Promise<MediaUploadState>;
  byListingId: (
    mediaType: MediaType,
    listingId: string
  ) => Promise<ListingMedia[]>;
};

const getUploader = (apiClient: any, listing_id: string) => {
  return async (file: EnhancedFile): Promise<ListingMedia> => {
    const media_type = file.type.split('/')[0];
    const media_format = mimeTypeToMediaFormat(file.type);

    const { bucket, objectKey: object_key, method, url } = await apiClient(
      `/listing/${media_type}/signed-url`,
      {
        method: 'POST',
        body: {
          media_type,
          media_format,
          file_name: file.name,
          listing_id,
        },
      }
    );

    await fetch(url, {
      method,
      headers: {
        'Content-type': file.type,
      },
      body: file,
    });

    const media = apiClient(`/listing/${media_type}/create`, {
      method: 'POST',
      body: {
        media_type,
        media_format,
        bucket,
        object_key,
        listing_id,
      },
    });
    return media;
  };
};

export const listingMediaServiceFactory = (
  apiClient: any
): ListingMediaService => ({
  create: async (file, listingId): Promise<ListingMedia> => {
    return await getUploader(apiClient, listingId)(file);
  },
  createMultiple: async (files, listingId): Promise<ListingMedia[]> => {
    const results: Promise<ListingMedia>[] = files.map(
      await getUploader(apiClient, listingId)
    );
    return Promise.all(results);
  },
  deleteByGroupId: async (
    mediaType: MediaType,
    groupId: string
  ): Promise<ListingMedia[]> => {
    return await apiClient(`/listing/${mediaType}/delete-by-group-id`, {
      method: 'POST',
      body: {
        group_id: groupId,
      },
    });
  },
  uploadState: async (
    mediaType: MediaType,
    bucket: string,
    objectKey: string
  ): Promise<MediaUploadState> => {
    return await apiClient(
      `/listing/${mediaType}/upload-state?object_key=${objectKey}&bucket=${bucket}`,
      {
        method: 'GET',
      }
    );
  },
  byListingId: (
    mediaType: MediaType,
    listingId: string
  ): Promise<ListingMedia[]> => {
    return apiClient(`listing/${mediaType}/by-listing-id`, {
      method: 'GET',
      body: {
        listing_id: listingId,
      },
    });
  },
});
