import React from 'react';
import styled from '@emotion/styled';
import moment from 'moment';

import Label, { LabelTypes } from '../../../../components/Typography/Label';
import { WeightTypes } from '../../../../components/Typography/utils';
import Button from '../../../../components/UI/Button';
import Paragraph, {
  ParagraphVariant,
} from '../../../../components/Typography/Paragraph';
import { MessageProps } from '..';
import { useMutation } from 'react-query';
import { useMediaQuery, useService } from '../../../../hooks';
import Icon from '../../../../components/Icon';
import { IconSizeKeys } from '../../../../components/Icon/utils';
import { useTheme } from '@emotion/react';

const MessageAvatar = ({ name }) => {
  return (
    <Avatar>
      <Label type={LabelTypes.l1} weight={WeightTypes.bold} color="#BEFEF2">
        {name.charAt(0).toUpperCase()}
      </Label>
    </Avatar>
  );
};

const MessageDetail: React.FC<MessageProps & { onBack: () => void }> = ({
  submittedAt,
  firstName,
  lastName,
  message,
  mobileNumber,
  onBack,
  id,
  respondedAt,
}) => {
  const messageDateData = new Date(submittedAt);
  const hasFullName = firstName && lastName;
  const sender = hasFullName ? `${firstName} ${lastName}` : mobileNumber;

  const messageService = useService<'message'>('message');

  const { mediaQuery, colorMap } = useTheme();
  const minWidthMedium = useMediaQuery(mediaQuery.medium);

  const { mutate: markResponded } = useMutation((messageId: string) =>
    messageService.updateMessageResponded(messageId)
  );

  const handleSendText = () => {
    window.open(`sms://${mobileNumber}`, '_blank');
  };

  return (
    <MessageDetailContainer data-testid="message-detail-container">
      <MessageDetailContent>
        {!minWidthMedium && (
          <MessageHeader>
            <IconWrapper onClick={onBack}>
              <Icon
                name="leftChevron"
                size={IconSizeKeys.MEDIUM}
                height={36}
                width={36}
                style={{
                  transform: 'scale(1.3)',
                }}
                color={colorMap.gray.gray1}
              />
            </IconWrapper>
            <Label type={LabelTypes.l2} weight={WeightTypes.semiBold}>
              {sender}
            </Label>
          </MessageHeader>
        )}

        <MessageBody>
          <Label type={LabelTypes.l5} styles={{ textAlign: 'center' }}>
            {moment(messageDateData).format('MMM D, YYYY')}
          </Label>

          <MessageContent>
            <MessageAvatar name={hasFullName ? sender : 'Unknown'} />

            <TextContent>
              <FirstRow>
                <Paragraph
                  variant={ParagraphVariant.large}
                  weight={WeightTypes.semiBold}
                  styles={{ margin: 0 }}
                >
                  {sender}
                </Paragraph>

                <Paragraph
                  variant={ParagraphVariant.small}
                  styles={{ margin: 0 }}
                >
                  {moment(messageDateData).format('h:mm A')}
                </Paragraph>
              </FirstRow>

              <SecondRow>
                <Paragraph
                  variant={ParagraphVariant.large}
                  styles={{ margin: 0 }}
                >
                  {message}
                </Paragraph>
              </SecondRow>
            </TextContent>
          </MessageContent>
        </MessageBody>
      </MessageDetailContent>
      <ActionFooter>
        <Button variant="primary" color="black" onClick={handleSendText}>
          Send a text
        </Button>

        <Button
          variant="secondary"
          color="black"
          onClick={() => {
            markResponded(id);
          }}
        >
          {respondedAt ? 'Respond again' : 'Mark responded'}
        </Button>
      </ActionFooter>
    </MessageDetailContainer>
  );
};

export default MessageDetail;

const MessageDetailContent = styled.div`
  height: calc(var(--vh, 1vh * 100 - 140px);

  @media ${({ theme }) => theme.mediaQuery.medium} {
    height: calc(var(--vh, 1vh * 100 - 88px));
    overflow-y: scroll;
  }
`;
const MessageDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const MessageHeader = styled.div`
  border-bottom: ${({ theme }) => `1px solid ${theme.colorMap.gray.gray4}`};
  padding: 1.5rem;
  padding-left: 0.75rem;

  display: flex;
  align-items: center;
  column-gap: 0.75rem;
`;

const IconWrapper = styled.div`
  cursor: pointer;
`;

const MessageBody = styled.div`
  margin-top: 1.5rem;
`;

const MessageContent = styled.div`
  display: flex;
  column-gap: 0.75rem;

  align-items: center;
  padding: 1.5rem;
`;

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
`;

const FirstRow = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
`;

const SecondRow = styled.div``;

const ActionFooter = styled.div`
  position: sticky;
  bottom: 0;
  width: 100%;
  padding: 1.5rem;

  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;

  > button {
    max-width: none;
    margin: 0;
  }

  @media ${({ theme }) => theme.mediaQuery.medium} {
    flex-direction: row;
    column-gap: 0.75rem;
  }
`;

const Avatar = styled.div`
  border-radius: 100%;
  height: 30px;
  width: 30px;
  background-color: #202020;
  text-align: center;
`;
