import React from 'react';

import type { UseSliderProps } from './types';

function range(from: number, to: number, step: number): Array<number> {
  const result: Array<number> = [];
  for (let i = from; i <= to; i += step) {
    result.push(i);
  }
  return result;
}

function getValueOffsetPercentage(stops: Array<number>, value: number) {
  return stops.indexOf(value) / (stops.length - 1);
}

function clamp(min: number, max: number, n: number): number {
  return Math.max(min, Math.min(n, max));
}

function getSliderOffsetPercentage(el: HTMLDivElement, x: number): number {
  const { left, right, width } = el.getBoundingClientRect();
  return (clamp(left, right, x) - left) / width;
}

function useSliderMouseInteraction({ setOffset, setValue, stops }) {
  const sliderRef = React.useRef<HTMLDivElement>(null);

  const handleClick = React.useCallback(
    (e) => {
      if (!sliderRef.current) return;
      const offsetPercentage = getSliderOffsetPercentage(
        sliderRef.current,
        e.clientX
      );
      const index = Math.round(offsetPercentage * (stops.length - 1));
      const newValue = stops[index];
      setValue(newValue);
    },
    [sliderRef, stops, setValue]
  );

  const handleMouseMove = React.useCallback(
    (e) => {
      if (!sliderRef.current) return;
      setOffset(getSliderOffsetPercentage(sliderRef.current, e.x));
    },
    [setOffset, sliderRef]
  );

  const handleMouseUp = React.useCallback(
    (e) => {
      if (!sliderRef.current) return;
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
      const offsetPercentage = getSliderOffsetPercentage(
        sliderRef.current,
        e.x
      );
      const index = Math.round(offsetPercentage * (stops.length - 1));
      const newValue = stops[index];
      setOffset(index / (stops.length - 1));
      setValue(newValue);
    },
    [sliderRef, handleMouseMove, stops, setValue, setOffset]
  );

  const handleMouseDown = React.useCallback(
    (e) => {
      e.preventDefault();
      if (!sliderRef.current) return;
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    },
    [sliderRef, handleMouseMove, handleMouseUp]
  );

  const handleTouchMove = React.useCallback(
    (e) => {
      if (!sliderRef.current) return;
      setOffset(
        getSliderOffsetPercentage(sliderRef.current, e.touches[0].clientX)
      );
    },
    [setOffset, sliderRef]
  );

  const handleTouchEnd = React.useCallback(
    (e) => {
      if (!sliderRef.current) return;
      window.removeEventListener('touchmove', handleTouchMove);
      window.removeEventListener('touchend', handleTouchEnd);
      const offsetPercentage = getSliderOffsetPercentage(
        sliderRef.current,
        e.changedTouches[0].clientX
      );
      const index = Math.round(offsetPercentage * (stops.length - 1));
      const newValue = stops[index];
      setOffset(index / (stops.length - 1));
      setValue(newValue);
    },
    [sliderRef, handleTouchMove, stops, setValue, setOffset]
  );

  const handleTouchStart = React.useCallback(
    (e) => {
      if (!sliderRef.current) return;
      window.addEventListener('touchmove', handleTouchMove);
      window.addEventListener('touchend', handleTouchEnd);
    },
    [sliderRef, handleTouchMove, handleTouchEnd]
  );

  return { sliderRef, handleMouseDown, handleTouchStart, handleClick };
}

function useSliderKeyboardInteraction({ stops, value, setValue }) {
  const [focused, setFocused] = React.useState(false);

  const handleKeyDown = React.useCallback(
    (e: KeyboardEvent) => {
      if (!focused) return;
      let currentIndex = stops.indexOf(Math.max(value, stops[0]));

      switch (e.key) {
        case 'ArrowRight':
        case 'ArrowUp':
          currentIndex += 1;
          break;

        case 'ArrowLeft':
        case 'ArrowDown':
          currentIndex -= 1;
          break;

        case 'PageUp':
          e.preventDefault();
          currentIndex += 2;
          break;

        case 'PageDown':
          e.preventDefault();
          currentIndex -= 2;
          break;

        case 'End':
          e.preventDefault();
          currentIndex = stops.length - 1;
          break;

        case 'Home':
          e.preventDefault();
          currentIndex = 0;
          break;
      }
      if (value !== stops[currentIndex]) {
        setValue(stops[clamp(0, stops.length - 1, currentIndex)]);
      }
    },
    [focused, value, stops, setValue]
  );

  React.useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  return React.useMemo(
    () => ({
      onFocus: () => setFocused(true),
      onBlur: () => setFocused(false),
    }),
    []
  );
}

function useSliderInputElement(sliderRef): HTMLInputElement | null {
  const [el, setEl] = React.useState<HTMLInputElement | null>(null);
  const idRef = React.useRef();

  React.useEffect(() => {
    if (sliderRef.current && sliderRef.current !== idRef.current) {
      idRef.current = sliderRef.current;
      setEl(sliderRef.current.querySelector('input[type="hidden"'));
    }
  }, [sliderRef]);

  return el;
}

export default function useSlider({
  from,
  to,
  step = 1,
  initialValue,
  onChange,
  onBlur,
}: UseSliderProps) {
  const stops = React.useMemo(() => range(from, to, step), [from, to, step]);
  const [value, setValue] = React.useState<number>(initialValue || from);
  const [offset, setOffset] = React.useState<number>(() =>
    getValueOffsetPercentage(stops, value)
  );

  const {
    sliderRef,
    handleMouseDown,
    handleTouchStart,
    handleClick,
  } = useSliderMouseInteraction({
    setOffset,
    setValue,
    stops,
  });

  const inputEl = useSliderInputElement(sliderRef);

  const {
    onFocus: handleFocus,
    onBlur: handleBlur,
  } = useSliderKeyboardInteraction({
    stops,
    value,
    setValue,
  });

  /**
   * When the value changes, update the offset
   * and optionally trigger the change handler
   *
   * Note: We are using Math.max below as a "hack" right now for setting the initial value to -1.
   * I did this so we could set validation on the form and require a user to interact with the slider and make a selection.
   * This is not the ideal approach here but works for now.
   */
  React.useEffect(() => {
    setOffset(getValueOffsetPercentage(stops, Math.max(value, from))); // Make sure slider offset does not set to negative num
    if (!onChange || !inputEl) return;
    onChange({ target: inputEl });
  }, [inputEl, onChange, stops, value, from]);

  const getWrapperProps = React.useCallback(
    () => ({
      ref: sliderRef,
    }),
    [sliderRef]
  );

  const getThumbProps = React.useCallback(
    () => ({
      style: {
        left: `${offset * 100}%`,
        transform: `translateX(-${offset * 100}%)`,
      },
      role: 'slider',
      tabIndex: 0,
      'aria-valuemin': from,
      'aria-valuemax': to,
      'aria-valuenow': Math.max(value, from),
      onMouseDown: handleMouseDown,

      // proxy the mouse up event to the input's "blur" event, so that validations
      // and other functionaltiy can be triggered when the user has changed the input
      onMouseUp: () => {
        if (!onBlur) return;
        const e = new FocusEvent('blur');
        Object.defineProperty(e, 'target', { value: inputEl });
        onBlur(e);
      },
      onFocus: handleFocus,
      onTouchStart: handleTouchStart,
      // proxy the blur event from the thumb to the underlying input, so that validations
      // and other functionaltiy can be triggered when the user has changed the input
      onBlur: (e) => {
        handleBlur();
        if (!onBlur) return;
        e.target = inputEl;
        return onBlur(e);
      },
    }),
    [
      from,
      handleMouseDown,
      handleTouchStart,
      offset,
      to,
      value,
      handleFocus,
      handleBlur,
      onBlur,
      inputEl,
    ]
  );

  return {
    stops,
    value,
    offset,
    getWrapperProps,
    getThumbProps,
    handleClick,
  };
}
