import React, { useContext, useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';

import Icon from '../../components/Icon';

import {
  ShareListingContent,
  ContentTitle,
  Section,
  SectionTitle,
  MessagingSection,
  MessagingIconSection,
  MessagingIconWrapper,
  MessagingIconLabel,
  Link,
  LinkUrl,
  CopyButton,
  CopyText,
} from './components';

import { CloseIconWrapper } from '../Modal/components';
import { ModalContext } from '../../hooks/useModal';
import { useMediaQuery } from '../../hooks';
import { getModalStyles } from '../Modal/utils';

const listingUrl = (listingId: string): string => {
  const urlParts = window.location.href.split('//');
  return `${urlParts[0]}//${urlParts[1].split('/')[0]}/listings/${listingId}`;
};

const messagingBody = (listingId) =>
  encodeURI(
    `Check out this home on Unicorn 🦄 LA’s most exclusive apartment rental platform. `
  ) + encodeURIComponent(listingUrl(listingId));
const messageSubject = () => encodeURI('Check out this home on Unicorn 🦄');

const sms = (listingId) => {
  const body = messagingBody(listingId);
  const url = `sms:?&body=${body}`;
  window?.open(url, '_blank')?.focus();
};

const email = (listingId) => {
  const body = messagingBody(listingId);
  const subject = messageSubject();
  const url = `mailto:?&body=${body}&subject=${subject}`;
  window?.open(url, '_blank')?.focus();
};

type ShareListingModalProps = {
  listingId?: string;
  title?: string;
};

const ShareListingModal: React.FC<ShareListingModalProps> = ({
  listingId,
  title = 'Share this home with your friends and roomates',
}) => {
  const { color, colorMap, mediaQuery } = useTheme();
  const { handleCloseModal, setContentStyles } = useContext(ModalContext);
  const minWidthMedium = useMediaQuery(mediaQuery.medium);

  const [showCopyText, setShowCopyText] = useState<boolean>(false);

  const copy = (url: string) => {
    navigator.clipboard.writeText(url);
    setShowCopyText(true);
  };

  useEffect(() => {
    const timer = setTimeout(() => setShowCopyText(false), 1000);
    return () => clearTimeout(timer);
  }, [showCopyText]);

  useEffect(() => {
    setContentStyles(
      getModalStyles(minWidthMedium, colorMap, {
        mediumScreen: { width: '408px', height: '399px' },
      })
    );
  }, [minWidthMedium, colorMap, setContentStyles]);

  return (
    <>
      <CloseIconWrapper
        aria-label={'close-icon-wrapper'}
        onClick={handleCloseModal}
      >
        <Icon
          color={colorMap.gray.gray6}
          width={36}
          height={36}
          name="close"
        ></Icon>
      </CloseIconWrapper>
      <ShareListingContent aria-label="ShareListingContent">
        <ContentTitle>{title}</ContentTitle>
        <Section>
          <SectionTitle>Messaging {'&'} Email</SectionTitle>
          <MessagingSection>
            <MessagingIconSection>
              <MessagingIconWrapper
                aria-label="ShareListingSms"
                onClick={() => sms(listingId)}
              >
                <Icon
                  height={36}
                  width={36}
                  viewBox={`0 0 36 36`}
                  name="messaging"
                  color={color.primaryPink}
                />
              </MessagingIconWrapper>
              <MessagingIconLabel onClick={() => sms(listingId)}>
                SMS
              </MessagingIconLabel>
            </MessagingIconSection>
            <MessagingIconSection>
              <MessagingIconWrapper
                aria-label="ShareListingEmail"
                onClick={() => email(listingId)}
              >
                <Icon
                  height={36}
                  width={36}
                  name="email"
                  color={'none'}
                  stroke={color.primaryPink}
                />
              </MessagingIconWrapper>
              <MessagingIconLabel onClick={() => email(listingId)}>
                Email
              </MessagingIconLabel>
            </MessagingIconSection>
          </MessagingSection>
        </Section>
        <Section>
          <SectionTitle>Copy Link</SectionTitle>
          {showCopyText ? <CopyText>Link Copied</CopyText> : ''}
          <Link>
            <LinkUrl
              readOnly={true}
              defaultValue={listingId && listingUrl(listingId)}
            />
            <CopyButton
              aria-label="ShareListingCopy"
              onClick={() => listingId && copy(listingUrl(listingId))}
            >
              Copy
            </CopyButton>
          </Link>
        </Section>
      </ShareListingContent>
    </>
  );
};

export default ShareListingModal;
