import React from 'react';
import styled from '@emotion/styled';
import FormError from './Error';

export type TextAreaProps = Omit<React.HTMLProps<HTMLTextAreaElement>, 'as'> & {
  error?: { message: string } | string;
  styleType?: 'default' | 'round';
  rows?: number;
};

const StyledTextArea = styled.textarea`
  background: #f4f4f4;
  appearance: none;
  border: none;
  width: 100%;
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 1rem 1.25rem;
  min-height: 300px;
  resize: none;
  font-family: 'ZichtbaarRegular';
  font-size: 1rem;

  &:focus {
    outline: 0;
  }

  &::placeholder {
    color: ${({ theme }) => theme.colorMap.gray.gray2};
  }

  &.error,
  &.error:focus {
    color: ${({ theme }) => theme.color.error};
  }
`;

const StyledRoundTextArea = styled(StyledTextArea)`
  border-radius: 16px;
`;

const textAreaTypes = {
  default: StyledTextArea,
  round: StyledRoundTextArea,
};

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ styleType = 'default', rows = 18, error, className, ...props }, ref) => {
    const message =
      error && (typeof error === 'string' ? error : error.message);

    const classNames = [message && 'error', className]
      .filter(Boolean)
      .join(' ');

    const StyledTextAreaType = textAreaTypes[styleType];

    return (
      <>
        <StyledTextAreaType
          rows={rows}
          draggable={false}
          wrap="soft"
          className={classNames}
          ref={ref}
          {...props}
        />
        {message && <FormError message={message} />}
      </>
    );
  }
);

export default TextArea;
