import { MutableRefObject, useRef, useState } from 'react';
import { useService, useUserLocation } from './index';
import { Location, VideoListingDetails } from '../types';
import {
  DEFAULT_PROXIMITY_METERS,
  DiscoverFeed,
} from '../lib/services/discover';
import { lastResortFallbackLocation } from '../lib/constants';
import React from 'react';

export const PAGINATION_LIMIT = 8;

type UseDiscover = {
  feed: DiscoverFeed;
  index: number;
  offset: number;
  limit: number;
  location: Location;
  hasLocationResults: boolean;
  hasReachedEnd: boolean;
  isLoading: boolean;
  swiperRef: MutableRefObject<any | null>;
  handlers: {
    videos: (
      feed: DiscoverFeed,
      offset: number,
      limit: number
    ) => Promise<VideoListingDetails[]>;
    updateIndex: (idx: number) => void;
    setLoading: (isLoading: boolean) => void;
    changeFeed: (feed: DiscoverFeed) => void;
    changeLocation: (location: Location) => void;
    restartFeed: () => void;
  };
};

const DiscoverContext = React.createContext<UseDiscover>({} as UseDiscover);

const DiscoverProvider = ({ children }) => {
  const [feed, setFeed] = useState<DiscoverFeed>('popular');
  const [offset, setOffset] = useState<number>(0);
  const [index, setIndex] = useState<number>(0);
  const [hasLocationResults, setHasLocationResults] = useState<boolean>(true);
  const [hasReachedEnd, setHasReachedEnd] = useState<boolean>(false);
  const [selectedLocation, setSelectedLocation] = useState<Location>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const { userLocation } = useUserLocation();
  const locationToSearch =
    selectedLocation || userLocation || lastResortFallbackLocation;
  const discoverService = useService<'discover'>('discover');
  const swiperRef = useRef<any>(null);

  const setDiscoverState = (videoCount: number, offset: number) => {
    // Returning 1 result creates issues.
    // Bug on backend right now where we return less than limit.
    setHasReachedEnd(videoCount <= 1 && offset !== 0);
    setHasLocationResults(videoCount !== 0 || offset !== 0);
    setOffset(offset);
  };

  const videos = async (
    feed: DiscoverFeed,
    offset: number = 0,
    limit: number = 10
  ) => {
    const handleResponse = (
      videos: VideoListingDetails[]
    ): VideoListingDetails[] => {
      setDiscoverState(videos.length, offset);
      return videos;
    };

    switch (feed) {
      case 'local':
        return discoverService
          .byProximity(
            locationToSearch.latitude,
            locationToSearch.longitude,
            offset,
            limit,
            DEFAULT_PROXIMITY_METERS
          )
          .then(handleResponse);
      default:
        return discoverService.byPopular(offset, limit).then(handleResponse);
    }
  };

  const changeLocation = (newLocation: Location) => {
    setSelectedLocation(newLocation);
    setHasLocationResults(true);
  };

  const restartFeed = () => {
    setIndex(0);
    setOffset(0);
    setHasReachedEnd(false);
  };

  const changeFeed = (newFeed: DiscoverFeed) => {
    if (newFeed !== feed) {
      setFeed(newFeed);
    }
  };

  const value = {
    feed,
    index,
    offset,
    limit: PAGINATION_LIMIT,
    location: locationToSearch,
    hasLocationResults,
    hasReachedEnd,
    isLoading,
    swiperRef,
    handlers: {
      videos,
      updateIndex: setIndex,
      changeFeed,
      changeLocation,
      restartFeed,
      setLoading,
    },
  };

  return (
    <DiscoverContext.Provider value={value}>
      {children}
    </DiscoverContext.Provider>
  );
};

const useDiscover = () => React.useContext(DiscoverContext);

export default DiscoverProvider;

export { useDiscover, DiscoverProvider };
