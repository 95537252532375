import styled from '@emotion/styled';
import React, { useState } from 'react';
import Button from '../Button';
import { ButtonSize, ButtonVariant } from '../Button/Button';

export type DecisionOption = {
  label: string;
  value: any;
};

export type DecisionSelectedButtonProps = {
  variant?: ButtonVariant;
  color?: string;
};

export type DecisionProps = {
  options: DecisionOption[];
  onChange: (value: any) => void;
  defaultValue?: any;
  title?: string;
  buttonSize?: ButtonSize;
  selectedButtonProps?: DecisionSelectedButtonProps;
};

const Decision = React.forwardRef<HTMLInputElement, DecisionProps>(
  (
    {
      title,
      onChange,
      options,
      defaultValue,
      buttonSize,
      selectedButtonProps,
      ...inputProps
    },
    ref
  ) => {
    const [selectedOptionValue, setSelectedOptionValue] = useState<any>(
      defaultValue
    );

    return (
      <React.Fragment>
        {title && <Label>{title}</Label>}
        <ButtonSelectionContainer>
          {options.map((option) => {
            return selectedOptionValue === option.value ? (
              <StyledButton
                key={option.value}
                onClick={(evt) => {
                  evt.preventDefault();
                  setSelectedOptionValue(option.value);
                  onChange(option.value);
                }}
                variant={selectedButtonProps?.variant || 'primary'}
                color={selectedButtonProps?.color || 'primaryPink'}
                size={buttonSize || 'medium'}
              >
                {option.label}
              </StyledButton>
            ) : (
              <NotSelectedStyledButton
                key={option.value}
                onClick={(evt) => {
                  evt.preventDefault();
                  setSelectedOptionValue(option.value);
                  onChange(option.value);
                }}
                variant="secondary"
                color="black"
                size={buttonSize || 'medium'}
              >
                {option.label}
              </NotSelectedStyledButton>
            );
          })}
        </ButtonSelectionContainer>
      </React.Fragment>
    );
  }
);

export default Decision;

export const ButtonSelectionContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.25rem 0rem;
`;

export const Label = styled.label`
  color: 'black';
  display: block;
  font-size: 1.125rem;
  line-height: 1.4;
  font-weight: 600;
  margin-top: 24px;
  margin-bottom: 12px;
  flex-basis: 100%;
`;

export const StyledButton = styled(Button)`
  &:nth-of-type(2n) {
    margin-left: 0.5rem;
  }
`;

export const NotSelectedStyledButton = styled(StyledButton)`
  color: ${({ theme }) => `${theme.colorMap.gray.gray1}`} !important;
  border-color: ${({ theme }) => `${theme.colorMap.gray.gray3}`} !important;
`;
