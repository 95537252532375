/* @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { ClassNames, useTheme } from '@emotion/react';
import { useForm } from 'react-hook-form';
import { useLocation, useHistory } from 'react-router';
import { ErrorMessage as ErrorMessageContainer } from '@hookform/error-message';
import { DevTool } from '@hookform/devtools';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import moment from 'moment';
import DatePicker from 'react-datepicker';

import { useService, useConfig, useApiClient } from '../../../hooks';
import { getStaticAssetS3Url } from '../../../lib/static-assets';
import {
  dateFieldFormat,
  datePickerAriaLabelFormat,
  daysBetween,
  extractFieldValues,
  processFormValues,
} from './helpers';
import { Button } from '../../../components/UI/Button';
import { ErrorMessage } from '../../../components/UI/Form';
import SliderInput from '../../../components/UI/SliderInput';
import AddressAutocomplete from './AddressAutocomplete';
import {
  TextInput,
  RadioInput,
  CheckboxInput,
  HeaderContainer,
  ImageContainer,
  FieldGrid,
  Label,
  SecondaryLabel,
  FieldSingle,
  FieldDivider,
  datePickerStyles,
  StyledDatePickerButton,
  LeaseStartEndDateButtons,
  StyledErrorContainer,
  LeaseEndButton,
} from './UI';
import {
  SubmitContainer,
  GridContainer,
  Column,
  formWrapperStyles,
} from '../UI';
import { AppConfigCacheKey } from '../../../hooks/useConfig';
import PageContentWrapper from '../../../components/PageContentWrapper';
import SkipButton from '../SkipButton';
import Decision from '../../../components/UI/Form/Decision';
import TextArea from '../../../components/UI/Form/TextArea';
import { ListingType } from '../../../types/ListingType';
import { Listing } from '../../../types';
import { RentalType } from '../../../types/RentalType';
import { RentalAmenityKey } from '../../../types/RentalAmenityKey';

const addressAttr = ['address1', 'city', 'state', 'countryCode'];

const AddListingDetailsWithSublet = () => {
  const [availableAt, setAvailableAt] = React.useState<Date>();
  const [leaseStart, setLeaseStart] = React.useState<Date>();
  const [leaseEnd, setLeaseEnd] = React.useState<Date>();
  const [isShortTerm, setIsShortTerm] = useState<boolean | null>(null);
  const [
    listingAmenitySet,
    setListingAmenitySet,
  ] = useState<Set<string> | null>(null);

  const {
    handleSubmit,
    register,
    setValue,
    setError,
    clearErrors,
    control,
    trigger,
    formState: { errors, isValid },
  } = useForm({ mode: 'onChange' });
  const { user, userListing } = useConfig();
  const theme = useTheme();
  const listingService = useService<'listing'>('listing');
  const queryClient = useQueryClient();
  const client = useApiClient();

  const location = useLocation();

  const pathKeys = location.pathname.split('/');
  const isEditMode = pathKeys.includes('edit');

  useEffect(() => {
    if (availableAt) {
      setValue('available_at', availableAt?.toISOString(), {
        shouldValidate: true,
      });
      clearErrors('available_at_date_picker');
      if (availableAt?.getTime() < Date.now()) {
        setError('available_at_date_picker', {
          type: 'manual',
          message: 'Please select a date after today.',
        });
      }
    }
    if (leaseStart) {
      setValue('lease_start_date', leaseStart?.toISOString(), {
        shouldValidate: true,
      });
      clearErrors('lease_start_date_picker');
      if (leaseStart?.getTime() < Date.now()) {
        setError('lease_start_date_picker', {
          type: 'manual',
          message: 'Please select a date after today.',
        });
      }
    }
    if (leaseEnd) {
      setValue('lease_end_date', leaseEnd?.toISOString(), {
        shouldValidate: true,
      });
      clearErrors('lease_end_date_picker');
      if (leaseEnd?.getTime() < Date.now()) {
        setError('lease_end_date_picker', {
          type: 'manual',
          message: 'Please select a date after today.',
        });
      }
      if (leaseStart && daysBetween(leaseStart, leaseEnd) <= 0) {
        setError('lease_end_date_picker', {
          type: 'manual',
          message: 'End date must be after the start date.',
        });
      }
    }
  }, [
    setValue,
    clearErrors,
    availableAt,
    leaseStart,
    leaseEnd,
    setError,
    register,
  ]);

  const firstNamePlaceholder = React.useMemo(
    () => (Math.random() < 0.5 ? 'Jane' : 'John'),
    []
  );

  const setAddressValues = (input) => {
    const updatedValue = input
      ? extractFieldValues(input)
      : {
          address1: '',
          city: '',
          state: '',
          postal_code: '',
          postal_code2: '',
          country_code: '',
        };

    clearErrors('address1');
    Object.entries(updatedValue).forEach(([field, value]) =>
      setValue(field, value)
    );

    (async () => {
      await trigger('address1');
    })();
  };

  const { data: listingDetails, isLoading: listingDetailsLoading } = useQuery<
    {},
    unknown,
    Listing
  >(
    [
      `listing-details-${!!user}-${userListing?.listingId}`,
      { listingId: userListing?.listingId },
    ],
    () => client(`search/by-id?id=${userListing?.listingId}`),
    {
      refetchOnWindowFocus: false,
      enabled: !!userListing && isEditMode,
      onSuccess: (data: Listing) => {
        if (data.availableAt) {
          setAvailableAt(new Date(data.availableAt));
        }

        const amenitySet: Set<string> = new Set();

        data.amenities.forEach(({ amenityKey }) => {
          amenitySet.add(amenityKey);
        });

        setListingAmenitySet(amenitySet);

        setIsShortTerm((data?.leaseDurationDays || 0) <= 180);
      },
    }
  );

  useEffect(() => {
    if (!listingDetailsLoading && listingDetails) {
      trigger();
    }
  }, [trigger, listingDetailsLoading, listingDetails]);

  const history = useHistory();

  const { isLoading, mutate: submit } = useMutation(
    listingService.createListing,
    {
      onSuccess: (newListing) => {
        // Invalid once listing is created to get updated configuration
        queryClient.invalidateQueries(AppConfigCacheKey);
        history.push(`/list-your-home/${newListing.listingId}/media`);
      },
    }
  );

  const { mutate: updateListing } = useMutation(listingService.updateListing, {
    onSuccess: () => {
      queryClient.invalidateQueries(AppConfigCacheKey);
      history.push('/home');
    },
  });

  const prefilledAddressExists = addressAttr.every(
    (attr) => !!listingDetails?.address[attr]
  );
  const prefilledAddressStr = `${listingDetails?.address.address1}, ${listingDetails?.address.city}, ${listingDetails?.address.state}, ${listingDetails?.address.countryCode}`;
  const prefilledAddress = prefilledAddressExists ? prefilledAddressStr : '';

  const {
    ratings = { naturalLight: -1, noiseLevel: -1, landlordRating: -1 },
    numBedrooms = -1,
    numBathrooms = -1,
    numParkingSpots = -1,
  } = listingDetails || {};

  const conditionalIsValid =
    (isShortTerm && !!leaseStart && !!leaseEnd) ||
    (!isShortTerm && !!availableAt);

  return (
    <form
      onSubmit={handleSubmit((values) => {
        if (isEditMode && listingDetails?.listingId) {
          updateListing({
            ...processFormValues(values),
            listing_id: listingDetails.listingId,
          });
          return;
        }

        submit(processFormValues(values));
      })}
    >
      <PageContentWrapper>
        {!listingDetailsLoading && (
          <GridContainer css={[formWrapperStyles, { paddingBottom: '1.5em' }]}>
            <div css={{ gridArea: 'leftTop' }}>
              <input
                type="hidden"
                value={user?.id}
                {...register('created_by')}
              />
              <input type="hidden" value={user?.id} {...register('user_id')} />
              <Column>
                <HeaderContainer>
                  <h1>List your home on Unicorn</h1>
                  <p>
                    Unicorn is on a mission to build the next-generation rental
                    marketplace. We have created the world’s first video-listing
                    platform that connects millions of renters looking for their
                    next place with thousands of available homes.
                  </p>
                </HeaderContainer>
                {isShortTerm !== null && (
                  <FieldSingle>
                    <Decision
                      css={{ marginBottom: '1.0rem' }}
                      title={'What is the duration of your rental listing?'}
                      defaultValue={isShortTerm}
                      options={[
                        {
                          label: 'Short-term',
                          value: true,
                        },
                        {
                          label: 'Long-term',
                          value: false,
                        },
                      ]}
                      onChange={(value) => {
                        setValue('short_term', value);
                        setIsShortTerm(value);
                      }}
                    />
                    <input
                      data-testid="short-term"
                      type="hidden"
                      {...register('short_term', { required: false })}
                      defaultValue={`${isShortTerm}`}
                    />
                  </FieldSingle>
                )}

                <FieldSingle>
                  <Decision
                    css={{ marginBottomew: '1.0rem' }}
                    title={'What are you listing?'}
                    defaultValue={listingDetails?.listingType.toUpperCase()}
                    options={[
                      {
                        label: 'An entire place',
                        value: ListingType.FULL,
                      },
                      {
                        label: 'A private room',
                        value: ListingType.PRIVATE,
                      },
                    ]}
                    onChange={(value) => setValue('listing_type', value)}
                  />
                  <input
                    data-testid="listing-type"
                    type="hidden"
                    {...register('listing_type', { required: false })}
                    defaultValue={listingDetails?.listingType.toUpperCase()}
                  />
                </FieldSingle>

                <FieldDivider />

                <FieldGrid>
                  <div>
                    <Label htmlFor="first_name">First Name</Label>
                    <TextInput
                      data-testid="first-name"
                      placeholder={firstNamePlaceholder}
                      id="first_name"
                      {...register('first_name', { required: true })}
                      defaultValue={user?.firstName}
                      error={errors.first_name}
                    />
                  </div>
                  <div>
                    <Label htmlFor="last_name">Last Name</Label>
                    <TextInput
                      placeholder="Tenant"
                      id="last_name"
                      {...register('last_name', { required: true })}
                      defaultValue={user?.lastName}
                      error={errors.last_name}
                    />
                  </div>
                </FieldGrid>
                <FieldSingle>
                  <div>
                    <Label htmlFor="email">Email</Label>
                    <TextInput
                      placeholder="Email"
                      id="email"
                      type="email"
                      {...register('email', { required: true })}
                      defaultValue={user?.email}
                      error={errors.email}
                    />
                  </div>
                </FieldSingle>

                <FieldDivider />

                <div>
                  <Label htmlFor="address-search">Current Home Address</Label>
                  <input
                    data-testid="address1"
                    type="hidden"
                    {...register('address1', { required: true })}
                    defaultValue={listingDetails?.address.address1}
                  />
                  <input
                    data-testid="city"
                    type="hidden"
                    {...register('city')}
                    defaultValue={listingDetails?.address.city}
                  />
                  <input
                    data-testid="state"
                    type="hidden"
                    {...register('state')}
                    defaultValue={listingDetails?.address.state}
                  />
                  <input
                    data-testid="postal-code"
                    type="hidden"
                    {...register('postal_code')}
                    defaultValue={listingDetails?.address.postalCode}
                  />
                  <input
                    data-testid="country-code"
                    type="hidden"
                    {...register('country_code')}
                    defaultValue={listingDetails?.address.countryCode}
                  />
                  <AddressAutocomplete
                    defaultValue={prefilledAddress}
                    onChange={setAddressValues}
                    error={errors.address1}
                  />
                </div>
                <FieldGrid>
                  <div>
                    <Label htmlFor="address2">Apt. / Suite</Label>
                    <TextInput
                      id="address2"
                      placeholder="1002"
                      {...register('address2')}
                      defaultValue={listingDetails?.address.address2}
                    />
                  </div>
                  <div>
                    <Label htmlFor="monthly_rent">Monthly Rent</Label>
                    <TextInput
                      id="monthly_rent"
                      placeholder="1250"
                      {...register('monthly_rent', {
                        required: true,
                        pattern: {
                          value: /^\d+$/,
                          message: 'Please enter rent in whole dollars',
                        },
                      })}
                      defaultValue={(listingDetails?.monthlyRent || 0) / 100}
                      error={errors.monthly_rent}
                    />
                  </div>
                </FieldGrid>
              </Column>
            </div>
            <div css={{ gridArea: 'leftBottom' }}>
              <Column>
                <div>
                  <Label htmlFor="num_bedrooms">Bedrooms (entire place)</Label>
                  <SliderInput
                    data-testid="num-bedrooms"
                    id="num_bedrooms"
                    initialValue={numBedrooms >= 0 ? numBedrooms : -1}
                    from={0}
                    to={4}
                    labels={[null, null, null, null, '4+']}
                    {...register('num_bedrooms', { required: true, min: 0 })}
                  />
                </div>
                <div>
                  <Label htmlFor="num_bathrooms">
                    Bathrooms (entire place)
                  </Label>
                  <SliderInput
                    data-testid="num-bathrooms"
                    id="num_bathrooms"
                    initialValue={numBathrooms >= 0 ? numBathrooms : -1}
                    from={0}
                    to={5}
                    step={0.5}
                    labels={[
                      null,
                      '½',
                      null,
                      '½',
                      null,
                      '½',
                      null,
                      '½',
                      null,
                      '½',
                    ]}
                    {...register('num_bathrooms', { required: true, min: 0 })}
                  />
                </div>
                <div>
                  <Label htmlFor="num_parking_spots">
                    Parking Spots (entire place)
                  </Label>
                  <SliderInput
                    data-testid="num-parking-spots"
                    id="num_parking_spots"
                    initialValue={numParkingSpots >= 0 ? numParkingSpots : -1}
                    from={0}
                    to={4}
                    labels={[null, null, null, null, '4+']}
                    {...register('num_parking_spots', {
                      required: true,
                      min: 0,
                    })}
                  />
                </div>
                {listingAmenitySet && (
                  <div>
                    <Label>Amenities</Label>
                    <SecondaryLabel>
                      Select all that are included.
                    </SecondaryLabel>
                    <FieldGrid>
                      <CheckboxInput
                        label="AC Unit"
                        {...register('has_ac_unit')}
                        defaultChecked={listingAmenitySet.has(
                          RentalAmenityKey.AIR_CONDITIONING.toLowerCase()
                        )}
                      />
                      <CheckboxInput
                        label="Furnished"
                        {...register('furnished')}
                        defaultChecked={listingAmenitySet.has(
                          RentalAmenityKey.FURNISHED.toLowerCase()
                        )}
                      />
                      <CheckboxInput
                        label="Washer & Dryer"
                        {...register('has_washer_dryer')}
                        defaultChecked={listingAmenitySet.has(
                          RentalAmenityKey.WASHER_DRYER.toLowerCase()
                        )}
                      />
                      <CheckboxInput
                        label="Heater"
                        {...register('has_heater')}
                        defaultChecked={listingAmenitySet.has(
                          RentalAmenityKey.HEATING.toLowerCase()
                        )}
                      />
                      <CheckboxInput
                        label="Dishwasher"
                        {...register('has_dishwasher')}
                        defaultChecked={listingAmenitySet.has(
                          RentalAmenityKey.DISHWASHER.toLowerCase()
                        )}
                      />
                      <CheckboxInput
                        label="Dogs Allowed"
                        {...register('allows_dogs')}
                        defaultChecked={listingAmenitySet.has(
                          RentalAmenityKey.DOG_FRIENDLY.toLowerCase()
                        )}
                      />
                      <CheckboxInput
                        label="Back Yard"
                        {...register('has_backyard')}
                        defaultChecked={listingAmenitySet.has(
                          RentalAmenityKey.BACKYARD.toLowerCase()
                        )}
                      />
                      <CheckboxInput
                        label="Cats Allowed"
                        {...register('allows_cats')}
                        defaultChecked={listingAmenitySet.has(
                          RentalAmenityKey.CAT_FRIENDLY.toLowerCase()
                        )}
                      />
                      <CheckboxInput
                        label="Gym"
                        {...register('has_gym')}
                        defaultChecked={listingAmenitySet.has(
                          RentalAmenityKey.GYM.toLowerCase()
                        )}
                      />
                      <CheckboxInput
                        label="Balcony"
                        {...register('has_balcony')}
                        defaultChecked={listingAmenitySet.has(
                          RentalAmenityKey.BALCONY_PATIO.toLowerCase()
                        )}
                      />
                      <CheckboxInput
                        label="Swimming Pool"
                        {...register('has_pool')}
                        defaultChecked={listingAmenitySet.has(
                          RentalAmenityKey.POOL.toLowerCase()
                        )}
                      />
                    </FieldGrid>
                  </div>
                )}
                <div>
                  <Label>Rental Type</Label>
                  <SecondaryLabel>
                    Select one that best describes your home.
                  </SecondaryLabel>
                  <ErrorMessageContainer
                    name="rental_type"
                    errors={errors}
                    render={({ message }) => <ErrorMessage message={message} />}
                  />
                  <FieldGrid>
                    <RadioInput
                      label="House"
                      value="house"
                      {...register('rental_type', { required: true })}
                      defaultChecked={
                        listingDetails?.rentalType === RentalType.house
                      }
                    />
                    <RadioInput
                      label="Apartment"
                      value="apartment"
                      {...register('rental_type', { required: true })}
                      defaultChecked={
                        listingDetails?.rentalType === RentalType.apartment
                      }
                    />
                    <RadioInput
                      label="Townhouse"
                      value="townhouse"
                      {...register('rental_type', { required: true })}
                      defaultChecked={
                        listingDetails?.rentalType === RentalType.townhouse
                      }
                    />
                    <RadioInput
                      label="Condo"
                      value="condo"
                      {...register('rental_type', { required: true })}
                      defaultChecked={
                        listingDetails?.rentalType === RentalType.condo
                      }
                    />
                    <RadioInput
                      label="CO-OP"
                      value="coop"
                      {...register('rental_type', { required: true })}
                      defaultChecked={
                        listingDetails?.rentalType === RentalType.coop
                      }
                    />
                  </FieldGrid>
                </div>
              </Column>
            </div>
            <div css={{ gridArea: 'rightTop' }}>
              <ImageContainer>
                <img
                  css={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    objectPosition: 'bottom',
                  }}
                  src={getStaticAssetS3Url('onboarding/default-onboarding.png')}
                  alt=""
                />
              </ImageContainer>
              <Column>
                <div>
                  <Label htmlFor="natural_light_rating">Natural Light</Label>
                  <SliderInput
                    data-testid="natural-light-rating"
                    id="natural_light_rating"
                    initialValue={
                      ratings?.naturalLight >= 0 ? ratings?.naturalLight : -1
                    }
                    from={1}
                    to={5}
                    descriptions={['Dark', 'Light']}
                    {...register('natural_light_rating', {
                      required: true,
                      min: 1,
                    })}
                  />
                </div>
                <div>
                  <Label htmlFor="noise_level_rating">Noise Level</Label>
                  <SliderInput
                    data-testid="noise-level-rating"
                    id="noise_level_rating"
                    initialValue={
                      ratings?.noiseLevel >= 0 ? ratings?.noiseLevel : -1
                    }
                    from={1}
                    to={5}
                    descriptions={['Loud', 'Quiet']}
                    {...register('noise_level_rating', {
                      required: true,
                      min: 1,
                    })}
                  />
                </div>
                <div>
                  <Label htmlFor="landlord_rating">Rate Your Landlord</Label>
                  <SliderInput
                    data-testid="landlord-rating"
                    id="landlord_rating"
                    initialValue={
                      ratings?.landlordRating >= 0
                        ? ratings?.landlordRating
                        : -1
                    }
                    from={1}
                    to={5}
                    descriptions={['Bad', 'Great']}
                    {...register('landlord_rating', { required: true, min: 1 })}
                  />
                </div>
              </Column>
            </div>
            <div css={{ gridArea: 'rightBottom' }}>
              <Column>
                {isShortTerm && (
                  <LeaseStartEndDateButtons>
                    <div>
                      <Label>Start Date</Label>
                      <StyledErrorContainer>
                        <ErrorMessageContainer
                          name="lease_start_date_picker"
                          errors={errors}
                          render={({ message }) => (
                            <ErrorMessage message={message} />
                          )}
                        />
                      </StyledErrorContainer>
                      <ClassNames>
                        {({ css }) => (
                          <DatePicker
                            placeholderText={'mm/dd/yyyy'}
                            calendarClassName={css(
                              datePickerStyles(
                                theme,
                                leaseStart !== undefined,
                                datePickerAriaLabelFormat(
                                  leaseStart || new Date()
                                )
                              )
                            )}
                            customInput={
                              <StyledDatePickerButton
                                type="button"
                                onClick={(evt) => {
                                  evt.preventDefault();
                                }}
                              >
                                {leaseStart
                                  ? dateFieldFormat(leaseStart.toISOString())
                                  : 'mm/dd/yyyy'}
                              </StyledDatePickerButton>
                            }
                            onChange={(d) => setLeaseStart(d)}
                          />
                        )}
                      </ClassNames>
                    </div>
                    <LeaseEndButton>
                      <Label>End Date</Label>
                      <StyledErrorContainer>
                        <ErrorMessageContainer
                          name="lease_end_date_picker"
                          errors={errors}
                          render={({ message }) => (
                            <ErrorMessage message={message} />
                          )}
                        />
                      </StyledErrorContainer>
                      <ClassNames>
                        {({ css }) => (
                          <DatePicker
                            placeholderText={'mm/dd/yyyy'}
                            calendarClassName={css(
                              datePickerStyles(
                                theme,
                                leaseEnd !== undefined,
                                datePickerAriaLabelFormat(
                                  leaseEnd || new Date()
                                )
                              )
                            )}
                            customInput={
                              <StyledDatePickerButton
                                type="button"
                                onClick={(evt) => {
                                  evt.preventDefault();
                                }}
                              >
                                {leaseEnd
                                  ? dateFieldFormat(leaseEnd.toISOString())
                                  : 'mm/dd/yyyy'}
                              </StyledDatePickerButton>
                            }
                            onChange={(d) => setLeaseEnd(d)}
                          />
                        )}
                      </ClassNames>
                    </LeaseEndButton>
                  </LeaseStartEndDateButtons>
                )}
                {!isShortTerm && (
                  <div>
                    <Label>Move Out Date</Label>
                    <StyledErrorContainer>
                      <ErrorMessageContainer
                        name="available_at_date_picker"
                        errors={errors}
                        render={({ message }) => (
                          <ErrorMessage message={message} />
                        )}
                      />
                    </StyledErrorContainer>
                    <ClassNames>
                      {({ css }) => (
                        <DatePicker
                          selected={moment(
                            listingDetails?.availableAt
                          ).toDate()}
                          popperPlacement={'bottomLeft'}
                          placeholderText={'mm/dd/yyyy'}
                          calendarClassName={css(
                            datePickerStyles(
                              theme,
                              availableAt !== undefined,
                              datePickerAriaLabelFormat(
                                availableAt || new Date()
                              )
                            )
                          )}
                          customInput={
                            <StyledDatePickerButton
                              type="button"
                              onClick={(evt) => {
                                evt.preventDefault();
                              }}
                            >
                              {availableAt
                                ? dateFieldFormat(availableAt.toISOString())
                                : 'mm/dd/yyyy'}
                            </StyledDatePickerButton>
                          }
                          onChange={(d) => setAvailableAt(d)}
                        />
                      )}
                    </ClassNames>
                  </div>
                )}
                <React.Fragment>
                  <input
                    type="hidden"
                    {...register('lease_start_date', {
                      required: false,
                    })}
                  />
                  <input
                    type="hidden"
                    {...register('lease_end_date', {
                      required: false,
                    })}
                  />
                  <input
                    data-testid="available-at"
                    type="hidden"
                    {...register('available_at', {
                      required: false,
                    })}
                    defaultValue={listingDetails?.availableAt}
                  />
                </React.Fragment>
                <FieldSingle>
                  <Label htmlFor={'description'}>
                    Let’s describe your place:
                  </Label>
                  <TextArea
                    styleType={'round'}
                    placeholder={
                      'This is an awesome home that is walking distance from several local coffee shops.'
                    }
                    id="description"
                    {...register('description', { required: false })}
                    defaultValue={listingDetails?.description}
                    error={errors.description}
                  />
                </FieldSingle>
              </Column>
            </div>
            <DevTool control={control} placement="bottom-right" />
          </GridContainer>
        )}
        <SubmitContainer>
          <SkipButton
            elementId={'list-your-home-details-skip-button'}
            disabled={isLoading}
            redirectRoute={'/listings'}
          />
          <Button
            type="submit"
            variant="primary"
            color="primaryPink"
            disabled={isLoading || !isValid || !conditionalIsValid}
          >
            {isEditMode ? 'Save changes' : 'Save & Submit'}
          </Button>
        </SubmitContainer>
      </PageContentWrapper>
    </form>
  );
};

export default AddListingDetailsWithSublet;
