import React from 'react';
import DefaultIcon from '../../../components/Icon/DefaultIcon';

import { ReactComponent as LeftArrowIcon } from '../images/leftArrow.svg';

import {
  GoBackButton,
  RemoveTextButton,
  SearchActionsContainer,
  SearchInput,
} from './components';

const SearchActionBar: React.FC<{
  inputProps: object;
  focus: boolean;
  onBack: () => void;
  onRemove: () => void;
}> = ({ inputProps, focus, onBack, onRemove }) => {
  const elementRef = (e: HTMLInputElement | null) =>
    focus ? e?.focus() : e?.blur();

  return (
    <SearchActionsContainer>
      <GoBackButton onClick={onBack}>
        <LeftArrowIcon />
      </GoBackButton>
      <SearchInput
        ref={elementRef}
        data-testid="search-input"
        {...inputProps}
      />
      <RemoveTextButton onClick={onRemove}>
        <DefaultIcon name="close" />
      </RemoveTextButton>
    </SearchActionsContainer>
  );
};

export default SearchActionBar;
