/* @jsxImportSource @emotion/react */

import React from 'react';

const AmenityTile: React.FC<{ icon: React.FC; label: string }> = ({
  icon: Icon,
  label,
}) => {
  return (
    <div
      css={{ display: 'flex', alignItems: 'center' }}
      key={label}
      data-testid="amenity-tile"
    >
      <Icon />
      <p css={{ fontSize: '1rem', fontWeight: 400, marginLeft: '12px' }}>
        {label}
      </p>
    </div>
  );
};

export default AmenityTile;
