export enum WeightTypes {
  light = 'light',
  normal = 'normal',
  semiBold = 'semiBold',
  bold = 'bold',
}

export const fontWeightMap = {
  light: 300,
  normal: 500,
  semiBold: 600,
  bold: 700,
};
