import React from 'react';
import styled from '@emotion/styled';

import { fontWeightMap, WeightTypes } from '../utils';

export enum DisplayElementTypes {
  d1 = 'd1',
  d2 = 'd2',
  d3 = 'd3',
  d4 = 'd4',
  d5 = 'd5',
  d6 = 'd6',
  d7 = 'd7',
  d8 = 'd8',
}

const fontSizeMap = {
  d1: 6.5, // 104px
  d2: 5, // 80px
  d3: 4.5, // 72px
  d4: 3.75, // 60px
  d5: 3.5, // 56px
  d6: 3, // 48px
  d7: 2.75, // 44px
  d8: 2.5, // 40px
};

const lineHeightMap = {
  d1: 116,
  d2: 102,
  d3: 82,
  d4: 64,
  d5: 68,
  d6: 52,
  d7: 52,
  d8: 44,
};

export type DisplayPropTypes = {
  type: DisplayElementTypes;
  weight?: WeightTypes;
  className?: string;
  color?: string;
  styles?: React.CSSProperties;
};

const Display: React.FC<DisplayPropTypes> = ({
  type,
  styles,
  children,
  ...rest
}) => {
  return (
    <StyledDisplay type={type} style={styles} {...rest}>
      {children}
    </StyledDisplay>
  );
};

export default Display;

const StyledDisplay = styled.div<DisplayPropTypes>`
  font-family: 'ZichtbaarRegular';
  font-weight: ${({ weight = WeightTypes.normal }) => fontWeightMap[weight]};
  font-size: ${({ type }) => `${fontSizeMap[type]}rem`};
  line-height: ${({ type }) => `${lineHeightMap[type]}px`};
  color: ${({ color }) => color};
`;
