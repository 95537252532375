import {
  default as LoadingSkeleton,
  SkeletonTheme,
} from 'react-loading-skeleton';

/**
 * The props are all optional because defaults are set in LoadingSkeleton
 * Defaults are as follow:
 * delay: 0
 * duration: 1.2
 * color: #202020
 * highlightColor: #444
 * count: 1
 * height: auto
 * width: auto
 */
export type SkeletonProps = {
  delay?: number;
  duration?: number;
  color?: string;
  highlightColor?: string;
  count?: number;
  height?: number | string;
  width?: number | string;
};

const Skeleton: React.FC<SkeletonProps> = (props) => {
  const { color, highlightColor, ...skeletonProps } = props;

  return (
    <SkeletonTheme color={color} highlightColor={highlightColor}>
      <LoadingSkeleton {...skeletonProps} />
    </SkeletonTheme>
  );
};

export default Skeleton;
