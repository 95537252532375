/* @jsxImportSource @emotion/react */

import React from 'react';
import styled from '@emotion/styled';
import { colorMap } from '../../../lib/theme';

type IconTypes = {
  icon: React.FC;
  variant?: string;
  style?: any;
  className?: string;
};

const colorScheme = {
  dark: colorMap.gray.gray6,
  light: colorMap.neutral.white,
};

const sizeMap = {
  small: 36,
  large: 45,
};

const getStyles = (variant) => {
  const [color, size] = variant.split('-');
  const iconSize = `${sizeMap[size]}px`;

  const styles = {
    backgroundColor: colorScheme[color],
    height: iconSize,
    width: iconSize,
  };

  return styles;
};

const Icon: React.FC<IconTypes> = (props) => {
  const { icon: Icon, variant = 'light-small' } = props;

  return (
    <StyledIcon css={{ ...getStyles(variant) }} {...props}>
      <Icon />
    </StyledIcon>
  );
};

export default Icon;

const StyledIcon = styled.div`
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
