import CommuterRentalPlaceholder from '../images/commuter-rental-placeholder.png';

const commuterFriendlyMockData = [
  {
    image: {
      src: CommuterRentalPlaceholder,
      alt: 'placeholder',
    },
    listingDetail: {},
  },
  {
    image: {
      src: CommuterRentalPlaceholder,
      alt: 'placeholder',
    },
    listingDetail: {},
  },
  {
    image: {
      src: CommuterRentalPlaceholder,
      alt: 'placeholder',
    },
    listingDetail: {},
  },
];

const staticTexts = [
  {
    title: 'Reduce Stress',
    description: 'Lock in your next place months ahead not days.',
  },
  {
    title: 'Full Transparency',
    description: 'Reviews from the renters that live there today',
  },
  {
    title: 'Save Up To $100',
    description: 'Apply with one Unicorn Application for all listings.',
  },
  {
    title: 'Save Time & Money',
    description: 'One universal application for all listings',
  },
  {
    title: 'Powered by Renters',
    description: 'Community driven platform from listing to lease.',
  },
];

const carouselSharedSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
};

export { commuterFriendlyMockData, staticTexts, carouselSharedSettings };
