import { getStaticAssetS3Url } from '../../../lib/static-assets';

const SantaMonicaImagePng = getStaticAssetS3Url(
  '/marketplace/santa-monica.png'
);
const SantaMonicaImageWebp = getStaticAssetS3Url(
  '/marketplace/santa-monica.webp'
);
const VeniceBeachPng = getStaticAssetS3Url('/marketplace/venice-beach.png');
const VeniceBeachWebp = getStaticAssetS3Url('/marketplace/venice-beach.webp');
const BeverlyHillsPng = getStaticAssetS3Url('/marketplace/beverly-hills.png');
const BeverlyHillsWebp = getStaticAssetS3Url('/marketplace/beverly-hills.webp');
const WestHollywoodPng = getStaticAssetS3Url('/marketplace/west-hollywood.png');
const WestHollywoodWebp = getStaticAssetS3Url(
  '/marketplace/west-hollywood.webp'
);

export const SearchNeighborhoodDefaults = {
  city: 'Los Angeles',
  neighborhoods: [
    {
      name: 'Santa Monica',
      search: 'Santa Monica',
      imageUrl: {
        png: SantaMonicaImagePng,
        webp: SantaMonicaImageWebp,
      },
    },
    {
      name: 'Venice Beach',
      search: 'Venice',
      imageUrl: {
        png: VeniceBeachPng,
        webp: VeniceBeachWebp,
      },
    },
    {
      name: 'Beverly Hills',
      search: 'Beverly Hills',
      imageUrl: {
        png: BeverlyHillsPng,
        webp: BeverlyHillsWebp,
      },
    },
    {
      name: 'West Hollywood',
      search: 'West Hollywood',
      imageUrl: {
        png: WestHollywoodPng,
        webp: WestHollywoodWebp,
      },
    },
  ],
};
