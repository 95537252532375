/* @jsxImportSource @emotion/react */
import React from 'react';
import { useHistory } from 'react-router';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { useMediaQuery } from '../../../hooks';
import Heading, {
  HeadingElementTypes,
} from '../../../components/Typography/Heading';
import Button from '../../../components/UI/Button';
import { WeightTypes } from '../../../components/Typography/utils';
import { getStaticAssetS3Url } from '../../../lib/static-assets';

const Image1Png = getStaticAssetS3Url('/marketplace/home-you-can-love-1.png');
const Image1Webp = getStaticAssetS3Url('/marketplace/home-you-can-love-1.webp');
const Image2Png = getStaticAssetS3Url('/marketplace/home-you-can-love-2.png');
const Image2Webp = getStaticAssetS3Url('/marketplace/home-you-can-love-2.webp');
const DiscoverVideoPng = getStaticAssetS3Url('/marketplace/discover-video.png');
const DiscoverVideoWebp = getStaticAssetS3Url(
  '/marketplace/discover-video.webp'
);

export const SearchHighlight: React.FC = () => {
  const history = useHistory();
  const search = () => history.push('/listings-near-you');
  const discover = () => history.push('/discover');
  const { colorMap, mediaQuery } = useTheme();
  const minWidthMedium = useMediaQuery(mediaQuery.medium);

  return (
    <SearchHighlightWrapper>
      <Section>
        <Picture>
          <source srcSet={Image1Webp} />
          <source srcSet={Image1Png} />
          <SectionImage
            src={Image1Png}
            loading="lazy"
            alt="Find your dream home"
          />
        </Picture>
        <SectionContent>
          <Heading
            css={{
              color: colorMap.neutral.white,
            }}
            weight={WeightTypes.semiBold}
            type={
              minWidthMedium ? HeadingElementTypes.h3 : HeadingElementTypes.h5
            }
          >
            Find your dream home
          </Heading>
          <Button
            onClick={search}
            style={{
              marginTop: '0.25rem',
              borderColor: colorMap.neutral.white,
            }}
            color="black"
            variant="secondary"
            size="medium"
          >
            Start searching
          </Button>
        </SectionContent>
      </Section>
      <Section
        css={{
          background: `linear-gradient(131.06deg, #FF0082 0%, rgba(255, 0, 130, 0) 36.94%), linear-gradient(90deg, #FF70A3 0%, #FC4C9B 49.88%, #FF3D82 100%)`,
        }}
      >
        <Picture css={{ visibility: 'hidden' }}>
          <source srcSet={Image2Webp} />
          <source srcSet={Image2Png} />
          <SectionImage
            src={Image2Png}
            loading="lazy"
            alt=" Discover a new way to search"
          />
        </Picture>
        <SectionContent>
          <Heading
            css={{
              color: colorMap.neutral.white,
            }}
            weight={WeightTypes.semiBold}
            type={
              minWidthMedium ? HeadingElementTypes.h3 : HeadingElementTypes.h5
            }
          >
            Discover a new way to search
          </Heading>
          <Button
            onClick={discover}
            style={{
              marginTop: '0.25rem',
              borderColor: colorMap.neutral.white,
            }}
            color="black"
            variant="secondary"
            size="medium"
          >
            Explore video
          </Button>
        </SectionContent>

        <Picture>
          <source srcSet={DiscoverVideoWebp} />
          <source srcSet={DiscoverVideoPng} />
          <DiscoverVideoImage
            src={DiscoverVideoWebp}
            loading="lazy"
            alt=" Discover a new way to search"
          />
        </Picture>
      </Section>
    </SearchHighlightWrapper>
  );
};

export default SearchHighlight;

const SearchHighlightWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 100%;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    justify-content: space-between;
  }
`;

const Section = styled.div`
  min-width: 327px;
  min-height: 436px;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  margin-bottom: 1rem;
  flex-basis: 100%;
  border-radius: 16px;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    flex-basis: 49.25%;
  }
`;

const Picture = styled.picture`
  width: 100%;
  height: 100%;
`;

const SectionImage = styled.img`
  width: 100%;
  height: 100%;
  min-height: 436px;
  object-fit: cover;
  border-radius: 16px;
`;

const SectionContent = styled.div`
  max-width: 220px;
  position: absolute;
  left: 0;
  top: 0;
  margin-left: 1.5rem;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const DiscoverVideoImage = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  width: 100%;
  border-bottom-right-radius: 16px;

  @media ${({ theme }) => theme.mediaQuery.xsmall} {
    right: 0;
    width: 100%;
  }

  @media (min-width: 400px) {
    right: 0;
    left: auto;
    width: 72%;
  }

  @media ${({ theme }) => theme.mediaQuery.max} {
    left: auto;
    right: 0;
    width: 80%;
  }
`;
