export enum AppEnv {
  PROD = 'PROD',
  STAGE = 'STAGE',
  LOCAL = 'LOCAL',
}

const getAppEnv = (): AppEnv => {
  const env = process.env.REACT_APP_ENV?.toLowerCase();

  if (env === AppEnv.PROD.toLowerCase()) {
    return AppEnv.PROD;
  } else if (env === AppEnv.STAGE.toLowerCase()) {
    return AppEnv.PROD;
  } else if (env === AppEnv.LOCAL.toLowerCase()) {
    return AppEnv.LOCAL;
  } else {
    throw Error(`Invalid app env ${env}`);
  }
};

export const APP_ENV: AppEnv = getAppEnv();
