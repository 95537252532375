import styled from '@emotion/styled';
import React from 'react';
import { ListingType } from '../../types/ListingType';
import { fontWeightMap, WeightTypes } from '../Typography/utils';

export type ListingLabelProps = {
  listingType?: ListingType;
  leaseDurationDays?: number;
  styleOverrides?: React.CSSProperties;
};

const ListingLabel: React.FC<ListingLabelProps> = ({
  listingType,
  leaseDurationDays,
  styleOverrides,
}) => {
  const labelText = (): string | null => {
    if (leaseDurationDays !== undefined && leaseDurationDays <= 7 * 6) {
      return 'Short term';
    } else if (
      listingType?.toLowerCase() &&
      listingType === ListingType.PRIVATE.toLowerCase()
    ) {
      return 'Private room';
    } else {
      return null;
    }
  };

  const label = labelText();

  return (
    <React.Fragment>
      {label && (
        <ListingLabelDiv style={styleOverrides}>{label}</ListingLabelDiv>
      )}
    </React.Fragment>
  );
};

export default ListingLabel;

const ListingLabelDiv = styled.div`
  font-weight: 700;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colorMap.neutral.black};
  z-index: ${({ theme }) => theme.zIndices.middle};
  position: absolute;
  left: 200px;
  width: 91px;
  height: 24px;
  left: 12px;
  top: 12px;
  padding: 4px;
  background: ${({ theme }) => theme.colorMap.neutral.white};
  border-radius: 4px;
  text-align: center;
  font-weight: ${fontWeightMap[WeightTypes.bold]};
  line-height: 1rem;
`;
