/*eslint eqeqeq: "off"*/
import React, {
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
  RefObject,
} from 'react';

import PlacesAutocomplete from 'react-places-autocomplete';
import DefaultIcon from '../../../components/Icon/DefaultIcon';

import { useSearch, useScroll } from '../../../hooks';
import { DEFAULT_SEARCH_OPTIONS } from '../../../lib/constants';

import SearchActionBar from '../SearchActionBar';
import { RemoveTextButton } from '../SearchActionBar/components';
import SearchInput from '../SearchInput';
import SearchView from '../SearchView';

import {
  SearchMobileInputContainer,
  SearchMobileSidePanel,
  SearchDesktopDropdownView,
  SearchInputContainer,
} from './components';

import { searchFixedPositionTop, searchHeight } from './constants';

export const SearchMobileView: React.FC = () => {
  const {
    city,
    searchView,
    showDatePicker,
    handlers: {
      handleChange,
      handleSelect,
      handleGoBack,
      handleRemove,
      handleInputClick,
    },
  } = useSearch();

  const { scrollY } = useScroll();
  const minScrollYForFixedSearch =
    searchFixedPositionTop.mobile - searchHeight.small;

  const handleInputClickCustom = (eventTarget: EventTarget) => {
    const inputTarget = eventTarget as HTMLInputElement;
    inputTarget.blur();
    handleInputClick();
  };

  return (
    <SearchMobileInputContainer
      showBackground={minScrollYForFixedSearch <= scrollY}
    >
      <SearchInput
        size="small"
        onClick={(evt) => {
          if (evt.target) {
            handleInputClickCustom(evt.target);
          }
        }}
        value={city}
        onChange={() => {}}
        className={'search-view__mobile fixed'}
      />

      <PlacesAutocomplete
        value={city}
        onChange={handleChange}
        onSelect={handleSelect}
        debounce={500}
        shouldFetchSuggestions={true}
        searchOptions={DEFAULT_SEARCH_OPTIONS}
        className="place__autocomplete"
      >
        {({ getInputProps, suggestions, getSuggestionItemProps }) => {
          let searchViewClassName = '';

          if (searchView) searchViewClassName = 'show';
          if (searchView == false) searchViewClassName = 'hide';

          return (
            <SearchMobileSidePanel className={searchViewClassName}>
              <SearchActionBar
                inputProps={{
                  ...getInputProps({
                    placeholder: 'Where do you want to live?',
                    className: 'location-search-input',
                    disabled: showDatePicker,
                  }),
                }}
                focus={!!searchView}
                onBack={handleGoBack}
                onRemove={handleRemove}
              />

              <SearchView
                city={city}
                suggestions={suggestions}
                getSuggestionItemProps={getSuggestionItemProps}
                showDatePicker={showDatePicker}
                onSelect={handleSelect}
                className={searchViewClassName}
              />
            </SearchMobileSidePanel>
          );
        }}
      </PlacesAutocomplete>
    </SearchMobileInputContainer>
  );
};

export const SearchDesktopView: ForwardRefExoticComponent<
  {
    showSearchView?: boolean;
    onClick?: (event: Event) => void;
    setFixed?: boolean;
  } & RefAttributes<unknown>
> = forwardRef((props, ref) => {
  const {
    city,
    searchView,
    showDatePicker,
    handlers: { handleChange, handleSelect, handleInputClick, handleRemove },
  } = useSearch();

  const { scrollY } = useScroll();
  const minScrollYForFixedSearch =
    searchFixedPositionTop.desktop - searchHeight.medium;
  const isFixed = props.setFixed || minScrollYForFixedSearch <= scrollY;

  const handleInputClickCustom = (event: Event) => {
    if (props.onClick) props.onClick(event);
    handleInputClick();
  };

  return (
    <PlacesAutocomplete
      value={city}
      onChange={handleChange}
      onSelect={handleSelect}
      debounce={500}
      searchOptions={DEFAULT_SEARCH_OPTIONS}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps }) => {
        let searchViewClassName = '';

        if (searchView && props.showSearchView) searchViewClassName = 'show';
        if (searchView == false || !props.showSearchView)
          searchViewClassName = 'hide';

        const searchSize: 'small' | 'medium' =
          isFixed && searchViewClassName === 'hide' ? 'small' : 'medium';
        const searchDesktopHeight = `${searchHeight[searchSize]}px`;

        return (
          <>
            <SearchDesktopDropdownView
              height={searchDesktopHeight}
              className={`search-view__desktop ${searchViewClassName} ${
                isFixed ? 'fixed' : ''
              }`}
              ref={ref as RefObject<HTMLDivElement>}
              onClick={(event) => event.stopPropagation()}
            >
              <SearchInputContainer height={searchDesktopHeight}>
                <SearchInput
                  value={city}
                  size={searchSize}
                  onClick={handleInputClickCustom}
                  {...getInputProps({
                    className: 'location-search-input',
                  })}
                />
                {city && (
                  <RemoveTextButton onClick={handleRemove}>
                    <DefaultIcon name="close" />
                  </RemoveTextButton>
                )}
              </SearchInputContainer>
              <SearchView
                city={city}
                suggestions={suggestions}
                getSuggestionItemProps={getSuggestionItemProps}
                showDatePicker={showDatePicker}
                className={searchViewClassName}
                onSelect={handleSelect}
              />
            </SearchDesktopDropdownView>
          </>
        );
      }}
    </PlacesAutocomplete>
  );
});
