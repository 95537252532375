import React from 'react';
import Skeleton, { SkeletonProps } from '../UI/Skeleton/Skeleton';

interface Props extends SkeletonProps {
  isLoading: boolean;
  loadMultiple?: boolean;
  numLoaders?: number;
}

const IfLoading: React.FC<Props> = ({
  isLoading,
  children,
  loadMultiple = false,
  numLoaders = 1,
  ...skeletonLoaderProps
}) => {
  if (isLoading) {
    if (loadMultiple) {
      return (
        <>
          {new Array(numLoaders).fill(1).map((_, idx) => (
            <Skeleton key={idx} {...skeletonLoaderProps} />
          ))}
        </>
      );
    }

    return <Skeleton {...skeletonLoaderProps} />;
  }

  return <>{children}</>;
};

export default IfLoading;
