export const termsOfServiceUrl = 'https://www.rentunicorn.com/terms';
export const privacyPolicyUrl = 'https://www.rentunicorn.com/privacy';

export const lastResortFallbackLocation = {
  latitude: 34.0522,
  longitude: -118.2437,
};

export const defaultSearchCity = 'Los Angeles';

export const carouselImageSizes = {
  width: 305,
  height: 305,
  mobile: {
    maxWidth: 305,
    maxHeight: 305,
  },
  desktop: {
    maxWidth: 425,
    maxHeight: 425,
  },
};

export const maxWidth = 1920;

export const DEFAULT_QUERY_CACHE_STALE_TIME = 5 * 60 * 100; // 5 minutes of caching

export const DEFAULT_SEARCH_OPTIONS = {
  componentRestrictions: {
    country: ['US'],
  },
  types: ['(cities)'],
};

export const LEFT_ARROW_KEY_CODE = 37;
export const RIGHT_ARROW_KEY_CODE = 39;
export const DOWN_ARROW_KEY_CODE = 40;
export const UP_ARROW_KEY_CODE = 38;
