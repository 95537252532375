import React, { MouseEventHandler } from 'react';

import { ReactComponent as SearchIcon } from '../images/search.svg';
import {
  SearchButton,
  SearchInputContainer,
  SearchInputElement,
} from './components';

export type SearchInputSize = 'small' | 'medium';

export const SearchInput: React.FC<{
  onClick?: MouseEventHandler<HTMLInputElement>;
  value?: string;
  onChange?: (any) => void;
  size: SearchInputSize;
  className?: string;
}> = ({ onClick, value, onChange, size, className }) => {
  return (
    <SearchInputContainer
      inputSize={size}
      className={`search__input-wrapper ${className}`}
    >
      <SearchInputElement
        inputSize={size}
        value={value}
        onChange={onChange}
        onClick={onClick}
        placeholder="Where do you want to live?"
      />
      <SearchButton size={size}>
        <SearchIcon />
      </SearchButton>
    </SearchInputContainer>
  );
};

export default SearchInput;
