import { useTheme } from '@emotion/react';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useMediaQuery } from '.';
import { setSessionStorage, SS_SOUND_OFF_KEY } from '../lib/session-storage';

type UseVideoSwiper = {
  isVideoPlaying: boolean;
  isMuted: boolean;
  volume: number;
  requiresUserInteraction: boolean;
  handlers: {
    play: (videoId: string, source?: string) => void;
    pause: (videoId: string) => void;
    pauseAllPreviousVideos: (videoId: string) => void;
    adjustVolume: (videoId: string, volume: Volumelevel) => void;
    mute: () => void;
    unmute: () => void;
    handleVideoIsPlaying: (isVideoPlaying: boolean) => void;
    handleUserInteraction: (hasUserInteraction: boolean) => void;
  };
};

const VideoSwiperContext = React.createContext<UseVideoSwiper>(
  {} as UseVideoSwiper
);

export enum Volumelevel {
  MAX = 1.0,
  LOUD = 0.8,
  MEDIUM = 0.5,
  MUTED = 0.0,
}

const USER_INTERACTION_ERROR_MSG =
  'The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.';

const VideoSwiperProvider = ({ children }) => {
  const { mediaQuery } = useTheme();
  const minWidthMedium = useMediaQuery(mediaQuery.medium);
  const [isMuted, setIsMuted] = useState<boolean>(true);
  const [volume] = useState<number>(Volumelevel.LOUD);
  const [isVideoPlaying, setIsVideoPlaying] = useState<boolean>(false);

  useEffect(() => {
    setIsMuted(!minWidthMedium);
  }, [minWidthMedium]);

  const [
    requiresUserInteraction,
    setRequiresUserIteraction,
  ] = useState<boolean>(true);

  const pause = async (videoId: string) => {
    const video = document.getElementById(videoId) as HTMLVideoElement;

    if (video) {
      try {
        video.pause();
        setIsVideoPlaying(false);
      } catch (e) {
        console.error(e);
      }
    }
  };

  // Doesn't update state when pause
  const pauseAllPreviousVideos = (videoId: string) => {
    document.querySelectorAll('video').forEach((video) => {
      if (!video.paused && video.id !== videoId) {
        try {
          video.pause();
        } catch (e) {
          console.error(e);
        }
      }
    });
  };

  const play = async (videoId: string) => {
    const video = document.getElementById(videoId) as HTMLVideoElement;

    pauseAllPreviousVideos(videoId);

    if (video) {
      video.volume = volume;
      video.play().catch((e) => {
        console.error(e);
        if (e.message === USER_INTERACTION_ERROR_MSG) {
          mute();
          play(videoId);
        }
      });
    }
  };

  // Volume is read only in iOS. Can't adjust on transitions
  const adjustVolume = (videoId: string, volume: Volumelevel) => {
    const video = document.getElementById(videoId) as HTMLVideoElement;

    if (video && !isMuted) {
      try {
        video.volume = volume;
      } catch (e) {
        console.error(e);
      }
    }
  };

  const mute = () => {
    setIsMuted(true);
    setSessionStorage(SS_SOUND_OFF_KEY, true);
  };

  const unmute = () => {
    setIsMuted(false);
    setSessionStorage(SS_SOUND_OFF_KEY, false);
  };

  const value = {
    isVideoPlaying,
    isMuted,
    requiresUserInteraction,
    volume,
    handlers: {
      handleVideoIsPlaying: setIsVideoPlaying,
      handleUserInteraction: (hasUserInteraction) =>
        setRequiresUserIteraction(!hasUserInteraction),
      pause,
      pauseAllPreviousVideos,
      play,
      adjustVolume,
      mute,
      unmute,
    },
  };

  return (
    <VideoSwiperContext.Provider value={value}>
      {children}
    </VideoSwiperContext.Provider>
  );
};

const useVideoSwiper = () => React.useContext(VideoSwiperContext);

export { useVideoSwiper, VideoSwiperProvider };
