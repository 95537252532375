import { useQuery, UseQueryOptions } from 'react-query';
import { useService } from './useServices';

const useUserByIds = (
  queryKey: string,
  userIds: Array<string> = [],
  options: UseQueryOptions
) => {
  const userService = useService<'user'>('user');

  return useQuery([queryKey], () => userService.byUserIds(userIds), {
    ...options,
    refetchOnWindowFocus: false,
  });
};

export default useUserByIds;
