import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * This used to be handled out of the box with react router.
 * Since we don't have super long pages of search results (this is handle through pagination), most of the time we just want to take the user to the top of the page when the navigation/path changes
 * This implementation should handle the majority of our cases
 * https://reactrouter.com/web/guides/scroll-restoration
 */
const ScrollToTop: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export default ScrollToTop;
