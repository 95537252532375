/* @jsxImportSource @emotion/react */

import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useTheme } from '@emotion/react';
import { useMutation, useQuery } from 'react-query';

import Heading, {
  HeadingElementTypes,
} from '../../components/Typography/Heading';
import Paragraph, {
  ParagraphVariant,
} from '../../components/Typography/Paragraph';
import { WeightTypes } from '../../components/Typography/utils';
import Button from '../../components/UI/Button';
import { getModalStyles } from '../../components/Modal/utils';
import Icon from '../../components/Icon';
import IfLoading from '../../components/IfLoading';
import ListingTile from '../../components/ListingTile';

import { useApiClient, useAuth, useMediaQuery } from '../../hooks';
import { ModalContext } from '../../hooks/useModal';

import { Listing, ReferralType } from '../../types';

import {
  CloseIconWrapper,
  ModalContent,
  ButtonContainer,
} from '../../components/ListingTile/components';

type AddRoommateModalType = {
  listingId: string;
};

const AddRoommateModal: React.FC<AddRoommateModalType> = ({ listingId }) => {
  const { color, colorMap, mediaQuery } = useTheme();
  const minWidthMedium = useMediaQuery(mediaQuery.medium);

  const client = useApiClient();

  const { userId: user } = useAuth();

  const { data: listingDetails, isLoading: listingDetailsLoading } = useQuery<
    {},
    unknown,
    Listing
  >(
    [`listing-details-${!!user}-${listingId}`, { listingId }],
    () => client(`search/by-id?id=${listingId}`),
    { refetchOnWindowFocus: false }
  );

  const { handleCloseModal, setContentStyles } = useContext(ModalContext);

  const handleGenerateCode = async (): Promise<any> => {
    return await client('/referral/get-or-generate-code', {
      method: 'POST',
      body: {
        user_id: user,
        referral_type: ReferralType.ROOMMATE,
      },
    });
  };

  const {
    isLoading: generateCodeLoading,
    mutate: generateCode,
    isSuccess: generateCodeSuccess,
    data: referralData,
  } = useMutation(handleGenerateCode);

  const referralLink = useMemo(() => {
    return `${process.env.REACT_APP_UNICORN_WEB_URL}refer/${referralData?.referralCode}`;
  }, [referralData]);

  const handleTextInvite = useCallback(() => {
    if (window && generateCodeSuccess) {
      // Note: Android uses ? while iOS uses &
      const paramLink = navigator.userAgent.match(/Android/i) ? '?' : '&';
      const body = encodeURIComponent(
        `I just added you as a roommate on Unicorn 🦄 Join LA’s most exclusive apartment rental platform today! ${referralLink}`
      );

      window.open(`sms://${paramLink}body=${body}`, '_blank');
    }
  }, [referralLink, generateCodeSuccess]);

  useEffect(() => {
    generateCode();
  }, [generateCode]);

  const handleCopyInvite = () => {
    const invite = document.querySelector(
      '#referralInvite'
    ) as HTMLInputElement;

    if (invite) {
      invite.type = 'text';
      invite.select();
      document.execCommand('copy');
      invite.type = 'hidden';
    }
  };

  useEffect(() => {
    setContentStyles(
      getModalStyles(minWidthMedium, colorMap, {
        mediumScreen: {
          width: '846px',
          height: '670px',
          padding: '3.5rem 6.75rem',
          textAlign: 'center',
        },
        smallScreen: {
          height: '95%',
          padding: '4.25rem 1.5rem',
        },
      })
    );
  }, [minWidthMedium, colorMap, setContentStyles]);

  return (
    <>
      <CloseIconWrapper onClick={handleCloseModal}>
        <Icon color={colorMap.gray.gray6} width={36} height={36} name="close" />
      </CloseIconWrapper>
      <ModalContent>
        <div css={{ margin: '0 auto', maxWidth: '390px' }}>
          <Heading
            type={HeadingElementTypes.h3}
            color={color.primaryPink}
            weight={WeightTypes.bold}
            styles={{ marginTop: 0, marginBottom: minWidthMedium ? '8px' : 0 }}
          >
            Add a roommate
          </Heading>

          <Paragraph
            variant={ParagraphVariant.large}
            weight={WeightTypes.normal}
            styles={minWidthMedium ? {} : { marginTop: '4px', marginBottom: 0 }}
          >
            Send them a quick text with your authorization link to unlock access
            to the platform!
          </Paragraph>
        </div>

        <IfLoading isLoading={listingDetailsLoading} width={327} height={368}>
          <ListingTile
            className="listing-tile"
            listingId={listingId}
            data={listingDetails as Listing}
          />
        </IfLoading>

        <input type="hidden" id="referralInvite" value={referralLink} />

        <ButtonContainer>
          {!minWidthMedium && (
            <Button
              onClick={handleTextInvite}
              color="primaryGreen"
              disabled={generateCodeLoading}
            >
              Text Invite
            </Button>
          )}

          <Button
            variant={minWidthMedium ? 'primary' : 'tertiary'}
            onClick={handleCopyInvite}
            color="primaryGreen"
            style={{ marginTop: '8px' }}
            disabled={generateCodeLoading}
          >
            Copy Invite
          </Button>
        </ButtonContainer>
      </ModalContent>
    </>
  );
};

export default AddRoommateModal;
