import { useTheme } from '@emotion/react';
import { useContext, useEffect, useState } from 'react';
import { getModalStyles } from '../components/Modal/utils';
import UnlockAccessModal from '../components/UnlockAccessModal';
import { useAuth, useMediaQuery } from './index';
import { ModalContext } from './useModal';

export const routesToExclude = new Set(['/login', '/create', '/discover']);

const modalOverrides = {
  mediumScreen: { width: '400px' },
  smallScreen: { paddingTop: '1.0rem', height: '600px' },
};

export type UseUnlockAccessModal = {
  showModal: () => void;
  showModalForUserAndRoute: (pathname: string) => boolean;
};

const useUnlockAccessModal = (): UseUnlockAccessModal => {
  const { userId } = useAuth();
  const { mediaQuery, colorMap } = useTheme();
  const minWidthMedium = useMediaQuery(mediaQuery.medium);
  const [shouldShowModal, setShouldShowModal] = useState<boolean>(false);

  const {
    handleOpenModal,
    handleCloseModal,
    setContentStyles,
    setModalContent,
  } = useContext(ModalContext);

  const showModalForUserAndRoute = (pathname: string) => {
    return !userId && !routesToExclude.has(pathname);
  };

  const showModal = () => {
    setShouldShowModal(true);
    setContentStyles(getModalStyles(minWidthMedium, colorMap, modalOverrides));
    setModalContent(() => (
      <UnlockAccessModal handleCloseModal={handleCloseModal} />
    ));
    handleOpenModal();
  };

  useEffect(() => {
    if (shouldShowModal) {
      setContentStyles(
        getModalStyles(minWidthMedium, colorMap, modalOverrides)
      );
    }
  }, [minWidthMedium, colorMap, setContentStyles, shouldShowModal]);

  return {
    showModal,
    showModalForUserAndRoute,
  };
};

export default useUnlockAccessModal;
