import React from 'react';
import { useAuth } from './useAuth';
import { request, FetchOptions, ApiUnauthorizedError } from '../lib/api';
import {
  ListingMediaService,
  listingMediaServiceFactory,
  ListingService,
  listingServiceFactory,
  UserService,
  userServiceFactory,
  DiscoverService,
  discoverServiceFactory,
  QrCodeService,
  qrCodeServiceFactory,
  messageServiceFactory,
  MessageService,
} from '../lib/services';

export type apiClient = <T>(
  path: string,
  options?: FetchOptions | undefined
) => Promise<T>;

type Services = {
  apiClient: apiClient;
  listing: ListingService;
  listingMedia: ListingMediaService;
  user: UserService;
  discover: DiscoverService;
  qrCode: QrCodeService;
  message: MessageService;
};

const ServicesContext = React.createContext<Services>({} as Services);

const ServicesProvider = (props) => {
  const { token, userId, refreshToken } = useAuth();
  const apiClient: apiClient = React.useCallback(
    async function apiClient(path, options) {
      return request(path, options, { token, userId })
        .then((response) => response)
        .catch(async (error: ApiUnauthorizedError) => {
          if (error instanceof ApiUnauthorizedError) {
            return refreshToken()
              .then((newToken) =>
                request(path, options, { token: newToken, userId })
              )
              .catch((e) => {
                throw e;
              });
          } else {
            throw error;
          }
        });
    },
    [token, userId, refreshToken]
  );

  const services: Services = React.useMemo(
    () => ({
      apiClient,
      listing: listingServiceFactory(apiClient),
      listingMedia: listingMediaServiceFactory(apiClient),
      user: userServiceFactory(apiClient),
      discover: discoverServiceFactory(apiClient),
      qrCode: qrCodeServiceFactory(apiClient),
      message: messageServiceFactory(apiClient),
    }),
    [apiClient]
  );
  return <ServicesContext.Provider value={services} {...props} />;
};

const useServices = (serviceIdentifiers: Array<keyof Services>) => {
  const services = React.useContext(ServicesContext);
  return serviceIdentifiers.map((s) => services[s]);
};
const useService = <T extends keyof Services>(identifier: keyof Services) =>
  React.useContext(ServicesContext)[identifier] as Services[T];

const useApiClient = () => React.useContext(ServicesContext).apiClient;

export { ServicesProvider, useServices, useService, useApiClient };
