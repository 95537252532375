import React from 'react';

import firebase from '../lib/firebase';
import { request } from '../lib/api';
import {
  getLocalStorage,
  LS_REDIRECT_URL_KEY,
  LS_USER_ID_KEY,
  removeLocalStorage,
  setLocalStorage,
} from '../lib/local-storage';
import {
  setSessionStorage,
  SS_SHOW_ONBOARDING_IF_REQUIRED,
} from '../lib/session-storage';

type AuthContextValues = {
  token: string;
  userId: string;
  loaded: boolean;
  logout: () => void;
  handleSetRedirectUrl: (_: string) => void;
  redirectUrl: string;
  refreshToken: () => Promise<string>;
};
const FirebaseAuthContext = React.createContext<AuthContextValues>({
  token: '',
  userId: '',
  loaded: false,
  logout: () => {},
  handleSetRedirectUrl: () => {},
  redirectUrl: '',
  refreshToken: () => Promise.any(''),
});

type LoginPayload = {
  userId: string;
};

const login = (token): Promise<LoginPayload> =>
  request('/auth/login', { method: 'POST' }, { token }).catch((e) =>
    request('/auth/signup', { method: 'POST' }, { token })
  );

function AuthProvider(props) {
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [token, setToken] = React.useState<string>('');
  const [userId, setUserId] = React.useState<string>('');
  const [redirectUrl, setRedirectUrl] = React.useState<string>('');

  const handleSetRedirectUrl = (route: string) => {
    /*
      We will set it in the local storage but we can't guarantee this will work
      100% of the time. It will:
      1) Look for redirectUrl in the state on the AuthProvider
      2) If user refreshes the page, we will look for it in local storage

      But if user is in Incognito mode, it cannot redirect them
      to the Roommate Onboarding
    */
    setRedirectUrl(route);
    setLocalStorage(LS_REDIRECT_URL_KEY, route);
  };

  const logout = React.useCallback(() => firebase.auth().signOut(), []);

  const refreshToken = (): Promise<string> => {
    return new Promise((resolve, reject) => {
      const currentUser = firebase.auth().currentUser;

      // Logout user if they are not authorized
      if (!currentUser) {
        logout();
        return reject('User is not authorized.');
      }

      return resolve(currentUser.getIdToken());
    });
  };

  React.useEffect(
    () =>
      firebase.auth().onAuthStateChanged((user) => {
        if (!user) {
          // Remove from local storage
          removeLocalStorage(LS_USER_ID_KEY);
          console.log('User session has expired');
          setToken('');
          setUserId('');
          setLoaded(true);
          return;
        }

        setLoaded(false);

        user
          .getIdToken()
          .then((token) => {
            // Check local storage and don't query login if we have this set
            const userId = getLocalStorage<string>(LS_USER_ID_KEY);

            if (userId) {
              setUserId(userId);
              setToken(token);
              setLoaded(true);
            } else {
              login(token).then(({ userId }) => {
                setLocalStorage(LS_USER_ID_KEY, userId);
                setUserId(userId);
                setToken(token);
                // Show onboarding if it's a required action to the user
                setSessionStorage(SS_SHOW_ONBOARDING_IF_REQUIRED, true);
                setLoaded(true);
              });
            }
          })
          .catch(() => {
            // We should never get here -- this `catch` represents the case
            // where we have a valid firebase token but NEITHER `/auth/login`
            // or `/auth/signup` worked. This is probably a network error.
            // Rather than retry let's just logout of firebase and let the user
            // hope for better Internet weather on their next try.
            firebase.auth().signOut();
          });
      }),
    []
  );

  return (
    <FirebaseAuthContext.Provider
      value={{
        token,
        userId,
        logout,
        loaded,
        handleSetRedirectUrl,
        redirectUrl,
        refreshToken,
      }}
      {...props}
    />
  );
}

const useAuth = () => React.useContext(FirebaseAuthContext);

const NotLoggedIn = ({ children }) => {
  const { token, loaded } = useAuth();
  return !token && loaded ? children : null;
};

const LoggedIn = ({ children }) => {
  const { token } = useAuth();
  return token ? children : null;
};

export { AuthProvider, NotLoggedIn, LoggedIn, useAuth };
