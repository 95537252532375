/* @jsxImportSource @emotion/react */

import { useTheme } from '@emotion/react';
import React from 'react';
import ReactCarousel from 'react-slick';
import { useMediaQuery } from '../../hooks';
import { carouselImageSizes } from '../../lib/constants';

import { carouselSharedSettings } from '../../pages/Marketplace/helpers/constants';
import ListingDescription from '../../pages/Marketplace/ListingDescription';
import PhotosComingSoon from '../../pages/Marketplace/images/photo-coming-soon.png';

import { Listing } from '../../types';
import SentimentButton from '../SentimentButton';
import { SentimentButtonProps } from '../SentimentButton/types';
import {
  ListingTileChildImage,
  ListingTileContainer,
  StyledLink,
} from './components';

type ListingTileType = {
  listingId: string;
  data: Listing;
  className?: string;
  imageSizes?: { height: number; width: number };
  tilesInRow?: number;
  showArrows?: boolean;
} & Partial<SentimentButtonProps>;

const ListingTile: React.FC<ListingTileType> = ({
  listingId,
  data,
  className,
  imageSizes,
  tilesInRow = 4,
  showArrows = false,
  toggleSentiment = () => {},
  listing,
}) => {
  const { mediaQuery } = useTheme();

  const minWidthSmall = useMediaQuery(mediaQuery.small);
  const minWidthMedium = useMediaQuery(mediaQuery.medium);
  const { desktop: imageDesktop, mobile: imageMobile } = carouselImageSizes;
  const {
    height = minWidthMedium ? imageDesktop.maxHeight : imageMobile.maxHeight,
    width = minWidthMedium ? imageDesktop.maxWidth : imageMobile.maxWidth,
  } = imageSizes || {};

  const imgPad = minWidthSmall ? 140 : 28;

  return (
    <ListingTileContainer className={className}>
      <StyledLink
        target={minWidthMedium ? '_blank' : '_self'}
        to={{
          pathname: `/listings/${listingId}`,
        }}
      >
        <ReactCarousel
          {...carouselSharedSettings}
          dots={true}
          arrows={showArrows}
          className="child-carousel"
          swipe={false}
        >
          {!!data.imageUrls?.length ? (
            data.imageUrls?.slice(0, 4).map((src) => (
              <ListingTileChildImage
                width={width}
                height={height}
                key={src}
                src={`${src}`}
                css={{
                  height: Math.ceil(
                    (window.innerWidth - imgPad) / tilesInRow - 20
                  ),
                }}
                alt="listing around you"
              />
            ))
          ) : (
            <ListingTileChildImage
              width={width}
              height={height}
              key={`${listingId}-placeholder-image`}
              src={`${PhotosComingSoon}`}
              css={{
                height: Math.ceil(
                  (window.innerWidth - imgPad) / tilesInRow - 20
                ),
              }}
              alt="listing around you"
            />
          )}
        </ReactCarousel>
        <SentimentButton
          className="sentiment-btn"
          listing={data}
          toggleSentiment={toggleSentiment}
        />
        <ListingDescription {...data} />
      </StyledLink>
    </ListingTileContainer>
  );
};

export default ListingTile;
