/* @jsxImportSource @emotion/react */
import React from 'react';
import styled from '@emotion/styled';
import Button from '../../../components/UI/Button';
import Lottie from 'react-lottie';
import animationData from './rocket.json';
import {
  DESKTOP_MAX_PAGE_HEIGHT,
  DISCOVER_MIN_HEIGHT_MEDIA_QUERY,
} from '../constants';

type SlideMessageProps = {
  description: string;
  buttonText: string;
  handleClick: () => void;
};

const SlideMessage: React.FC<SlideMessageProps> = ({
  description,
  buttonText,
  handleClick,
}) => (
  <SlideMessageWrapper>
    <ContentWrapper>
      <Content>
        <Lottie
          options={{
            animationData,
            loop: true,
          }}
          width={146}
          height={132}
        />
        <span>{description}</span>
        <Button
          variant="primary"
          onClick={(evt) => {
            evt.preventDefault();
            handleClick();
          }}
          color="primaryPink"
        >
          {buttonText}
        </Button>
      </Content>
    </ContentWrapper>
  </SlideMessageWrapper>
);

export default SlideMessage;

const SlideMessageWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.2) 0%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.2) 100%
  );

  @media ${({ theme }) => theme.mediaQuery.medium} {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (min-height: ${DESKTOP_MAX_PAGE_HEIGHT}px) {
    margin: auto;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;
  background: ${({ theme }) => theme.colorMap.neutral.white};

  // Mobile landscape mode w/min height (when device rotates)
  @media ${({ theme }) => theme.mediaQuery.medium} {
    @media (max-height: ${DISCOVER_MIN_HEIGHT_MEDIA_QUERY}px) {
      height: 50%;
      width: 40%;
      padding: 0.5rem;
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 450px;
  padding: 0 1.5rem 1.5rem 1.5rem;
  margin: 0 auto;

  span {
    font-size: 1.125rem;
    padding: 0 1rem 2rem 1rem;
    text-align: center;
  }
`;
