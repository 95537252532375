import { useHistory } from 'react-router';
import Button from '../../../components/UI/Button';
import {
  removeSessionStorage,
  SS_SHOW_ONBOARDING_IF_REQUIRED,
} from '../../../lib/session-storage';

type SkipButtonProps = {
  elementId: string;
  disabled: boolean;
  redirectRoute: string;
};

export const SkipButton: React.FC<SkipButtonProps> = ({
  elementId,
  disabled,
  redirectRoute = '/listings',
}) => {
  const history = useHistory();

  const onClick = () => {
    removeSessionStorage(SS_SHOW_ONBOARDING_IF_REQUIRED);
    history.push(redirectRoute);
  };

  return (
    <Button
      id={elementId}
      onClick={onClick}
      variant="primary"
      color="black"
      disabled={disabled}
    >
      Skip
    </Button>
  );
};

export default SkipButton;
