/* @jsxImportSource @emotion/react */
import React from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import Display, {
  DisplayElementTypes,
} from '../../../components/Typography/Display';
import { WeightTypes } from '../../../components/Typography/utils';
import Button from '../../../components/UI/Button';
import Heading, {
  HeadingElementTypes,
} from '../../../components/Typography/Heading';
import ShareListing from '../../../components/ShareListing';
import { useMediaQuery, useConfig } from '../../../hooks';
import { getStaticAssetS3Url } from '../../../lib/static-assets';

const ShareYourPlaceImagePng = getStaticAssetS3Url(
  '/marketplace/share-your-place.png'
);
const ShareYourPlaceImageWebp = getStaticAssetS3Url(
  '/marketplace/share-your-place.webp'
);

export const ShareYourPlace: React.FC = () => {
  const { userListing } = useConfig();
  const { colorMap, mediaQuery } = useTheme();
  const minWidthMedium = useMediaQuery(mediaQuery.medium);

  return (
    <ShareYourPlaceWrapper>
      <Content>
        {minWidthMedium ? (
          <Display type={DisplayElementTypes.d6} weight={WeightTypes.bold}>
            Share your place
          </Display>
        ) : (
          <Heading
            css={{ margin: 0 }}
            type={HeadingElementTypes.h3}
            weight={WeightTypes.bold}
          >
            Share your place
          </Heading>
        )}
        <Text>
          When your place gets leased on Unicorn, you get rewarded. Help your
          landlord find their next renter.
        </Text>
        <ShareListing
          title={'Share your home with your network'}
          listingId={userListing?.listingId}
          button={
            <Button
              style={{
                background: colorMap.neutral.black,
                marginTop: minWidthMedium ? '2.0rem' : '1.0rem',
              }}
              onClick={() => {
                /** TODO: Do something */
              }}
              variant="tertiary"
              color="white"
              size="medium"
            >
              Quick share
            </Button>
          }
        />
      </Content>
      <ImageWrapper>
        <Picture>
          <source srcSet={ShareYourPlaceImageWebp} />
          <source srcSet={ShareYourPlaceImagePng} />
          <Image
            src={ShareYourPlaceImagePng}
            loading="lazy"
            alt="Share your place"
          />
        </Picture>
      </ImageWrapper>
    </ShareYourPlaceWrapper>
  );
};

export default ShareYourPlace;

const ShareYourPlaceWrapper = styled.div`
  width: 100%;
  height: 540px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #f1f1f1 78.61%, #f5f5f5 85.38%);
  flex-direction: column;
  border-radius: 16px;
  padding: 1.5rem 1.5rem 0rem 1.5rem;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    flex-direction: row;
    width: 100%;
  }
`;

const Content = styled.div`
  flex-basis: 55%;
  display: flex;
  flex-direction: column;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    margin-left: 2.5rem;
  }
`;

const Text = styled.div`
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.5rem;
  margin-top: 1rem;
  color: ${({ theme }) => theme.colorMap.gray.gray2};

  @media ${({ theme }) => theme.mediaQuery.medium} {
    font-size: 1.75rem;
    line-height: 2.25rem;
    max-width: 630px;
  }
`;

const ImageWrapper = styled.div`
  flex-basis: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55%;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    flex-wrap: wrap;
    height: 100%;
    padding-top: 1rem;
    margin-left: auto;
  }

  @media ${({ theme }) => theme.mediaQuery.large} {
    height: 100%;
    margin-left: auto;
  }
`;

const Picture = styled.picture`
  width: 100%;
  height: 100%;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    object-fit: contain;
  }
`;
