import styled from '@emotion/styled/macro';
import Button from '../../components/UI/Button';
import { carouselImageSizes } from '../../lib/constants';

export const mobileVideoHeight = 500;
export const mobileVideoWidth = '100%';

export const desktopVideoHeight = 500;
export const desktopVideoWidth = 572;
export const desktopImageGap = 8;
export const desktopImageHeight = desktopVideoHeight / 2 - desktopImageGap / 2;

export const maxWidthLDP = 1200;

export const Container = styled.div`
  .listing-photos-carousel {
    .slick-track {
      height: ${mobileVideoHeight}px;
      display: flex !important;
    }

    .slick-slide {
      height: inherit !important;

      > div {
        height: 100%;
      }
    }

    .slick-dots {
      bottom: 24px;

      li {
        width: 16px;
        margin: 0;
      }
    }

    img {
      height: 100%;
      object-fit: cover;
    }

    @media ${({ theme }) => theme.mediaQuery.medium} {
      display: none;
    }
  }

  nav {
    display: none;
  }

  footer {
    padding-bottom: 92px;
  }

  @media ${({ theme }) => theme.mediaQuery.medium} {
    nav {
      display: flex;
    }
  }
`;

export const ContentContainer = styled.div`
  padding: 1.5rem;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    max-width: ${maxWidthLDP}px;
    margin: 0 auto;
    padding-top: 20px;
    position: relative;
  }
`;

export const MediaGrid = styled.div`
  display: none;
  max-height: ${desktopVideoHeight}px;
  border-top-left-radius: 16px;

  &:nth-of-type(1) {
    border-top-left-radius: 16px;
  }

  @media ${({ theme }) => theme.mediaQuery.medium} {
    display: flex;
  }
`;

export const PhotoGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${desktopImageGap}px;
  flex: 1;
  margin-left: ${desktopImageGap}px;
  position: relative;

  img {
    width: 100%;
    height: ${desktopImageHeight}px;
    object-fit: cover;
    cursor: pointer;
    transition: hover 0.25s ease;

    &:nth-of-type(2) {
      border-top-right-radius: 16px;
    }

    &:nth-of-type(4) {
      border-bottom-right-radius: 16px;
    }

    &:hover {
      -webkit-filter: brightness(80%);
    }
  }
`;

export const MobileAskQuestion = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  position: fixed;
  bottom: 0;
  padding: 1.125rem 1.5rem 1.125rem;
  background-color: white;
  box-shadow: -0.217625px -6.99662px 28px rgba(0, 0, 0, 0.03),
    -0.141053px -4.53484px 16.3981px rgba(0, 0, 0, 0.0209549),
    -0.0838259px -2.69499px 8.91852px rgba(0, 0, 0, 0.0158889),
    -0.043525px -1.39932px 4.55px rgba(0, 0, 0, 0.0130312),
    -0.0177324px -0.570095px 2.28148px rgba(0, 0, 0, 0.0106111),
    -0.00403009px -0.129567px 1.10185px rgba(0, 0, 0, 0.00685764);
  z-index: ${({ theme }) => theme.zIndices.top};

  @media ${({ theme }) => theme.mediaQuery.medium} {
    display: none;
  }

  button {
    color: white;
    height: 40px;
    width: 50%;
  }
`;

export const Rent = styled.div`
  display: flex;
  align-items: center;
  line-height: 0;
`;

export const PersonalActions = styled.div`
  display: flex;
`;

export const Action = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100vw;
  z-index: ${({ theme }) => theme.zIndices.middle};
  padding: 1rem 1rem 0;
  position: absolute;
  top: 0;
  left: 0;
  background-color: transparent;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    position: relative;
    width: auto;
    padding: 0;
    z-index: ${({ theme }) => theme.zIndices.bottom};

    > svg:first-of-type {
      display: none;
    }
  }
`;
// This element is hidden for now until we actually know if a user has seen this listing
export const ListingLabel = styled.p`
  font-weight: 700;
  font-size: 0.75rem;
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.secondaryGreen};
  margin-top: 0;
  margin-bottom: 0;
  display: none;
`;

export const ListingDetailsContainer = styled.div`
  padding: 1rem 0;
  position: relative;
  display: flex;
  flex-direction: row;
  height: 100%;
`;
export const ListingDetailsTitle = styled.h2`
  font-size: 1.75rem;
  font-weight: 600;
  margin: 0;
  text-transform: capitalize;
`;

export const ListingDetailsLeft = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    flex: 1;
  }
`;

export const ListingDetailsRight = styled.div`
  flex-basis: 0%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    flex-basis: 40%;
    overflow: visible;
  }
`;

export const StyledSection = styled.section`
  padding: 3rem 0;
  border-bottom: ${({ theme }) => `1px solid ${theme.colorMap.gray.gray4}`};
`;

export const HighlightsSection = styled(StyledSection)`
  border-top: ${({ theme }) => `1px solid ${theme.colorMap.gray.gray4}`};
`;

export const OverviewReadMoreLink = styled.a`
  font-weight: 700;
  font-size: 1.125rem;
  text-decoration-line: underline;
  color: ${({ theme }) => `${theme.colorMap.neutral.black}`};
  cursor: pointer;

  &:visited {
    color; ${({ theme }) => `${theme.colorMap.neutral.black}`};
  }
`;

export const Highlight = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 0 0 1.5rem 0;

  &:last-of-type {
    padding: 0;
  }

  svg {
    width: 36px;
    margin-right: 1rem;
    color: black;
  }
`;

export const HighlightDescription = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HighlightLabel = styled.span`
  font-weight: 600;
  font-size: 1.125rem;
`;

export const HighlightLabelDescription = styled.span`
  font-weight: 400;
  font-size: 1rem;

  color: ${({ theme }) => `${theme.colorMap.gray.gray2}`};
`;

export const DescriptionParagraph = styled.p`
  white-space: pre-line;
  font-weight: 400;
  font-size: 1.125rem;
`;

export const DescriptionModal = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 3.75rem;
  overflow: scroll;
  max-height: 100%;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    padding: 1rem 2rem;
    max-height: 700px;
  }
`;

export const DescriptionModalHeading = styled.h3`
  font-size: 1.625rem;
  text-align: left;
  margin-bottom: 0;
  text-transform: capitalize;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    font-size: 1.75rem;
    text-align: left;
    margin: 0;
  }
`;

export const DescriptionModalParagraph = styled.p`
  white-space: pre-line;
  font-size: 1.125rem;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    font-size: 1.125rem;
  }
`;

export const RatingsSection = styled(StyledSection)`
  @media ${({ theme }) => theme.mediaQuery.medium} {
    ul {
      max-width: 500px;
      margin: 1.125rem 0 0 0;
    }

    margin-right: 10px;
  }
`;

export const RatingsAskQuestion = styled.div`
  @media ${({ theme }) => theme.mediaQuery.medium} {
    display: flex;
    padding: 3.125rem 0 2.5rem;

    ${RatingsSection}, .ask-question-container {
      flex: 1;
      margin-left: auto;
    }
  }
`;

export const AmenitiesSection = styled(StyledSection)<{ maxWidth?: string }>`
  max-width: ${({ maxWidth }) => maxWidth || '618px'};
  margin: 0;
`;

export const LocationSection = styled(StyledSection)<{ maxWidth?: string }>`
  max-width: ${({ maxWidth }) => maxWidth || '618px'};
  margin: 0;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    max-width: none;
  }
`;

export const MapContainer = styled.div`
  height: 420px;
  width: 100vw;
  margin-left: -24px;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    height: 375px;
    width: 100%;
    margin-left: 0;
    border-radius: 16px;
    overflow: hidden;
  }
`;

export const ListingTitle = styled.h2`
  font-size: 1.375rem;
  font-weight: 600;
  margin: 0;
  text-transform: capitalize;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    font-size: 1.75rem;
  }
`;

export const DetailSection = styled.section`
  padding: 1rem 0 1rem;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    padding: 2rem 0 1rem;
    border-bottom: none;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
`;

export const DetailSectionHeading = styled.div`
  @media ${({ theme }) => theme.mediaQuery.medium} {
    width: 100%;
  }
`;

export const DetailSectionHeadingInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const DetailSectionHeadingInfo = styled.div`
  min-height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:nth-of-type(1n + 2) {
    margin-left: 24px;
  }
`;

export const SectionHeading = styled.h3`
  font-size: 1.375rem;
  font-weight: 600;
  margin: 0;
  text-transform: capitalize;
`;

export const Description = styled.p`
  font-size: 0.875rem;
  font-weight: 400;
  margin-top: 0;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    font-size: 1rem;
  }
`;

export const DetailList = styled.ul<{ numColumns: number }>`
  padding-left: 0;
  display: grid;
  grid-template-columns: ${({ numColumns }): string =>
    Array.from(Array(numColumns).keys()).reduce((acc, _) => `${acc} 1fr`, '')};
  grid-gap: 1rem;
  margin: 0 auto;
  width: 100%;
`;

export const MainImage = styled.div`
  max-width: 50%;
  min-width: 50%;
  cursor: pointer;

  img {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
  }
`;

export const StyledShowAllImageButton = styled(Button)`
  display: block;
  position: absolute;
  bottom: 1rem;
  right: 1rem;

  &:active {
    position: absolute;
    top: auto;
    bottom: 1rem;
    right: 1rem;
  }
`;

export const Section = styled.section`
  background: ${({ theme }) => theme.colorMap.neutral.black};
  border-radius: 16px;
  position: relative;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3.125rem 0 1.25rem;
  }
`;

export const PreviewSection = styled(Section)`
  margin-top: 20px;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    margin-top: 80px;
  }
`;

export const PreviewSectionHeading = styled.h1`
  color: ${({ theme }) => theme.color.tertiaryGreen};
  font-size: 2.125rem;
  font-weight: 700;
  margin: 0;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    margin-bottom: 40px;
  }
`;

export const SignUpSectionHeading = styled.h3`
  font-size: 1.375rem;
  font-weight: 700;
  margin-top: 24px;
  margin-bottom: 16px;
`;

export const SignUpContent = styled.p`
  font-size: 1rem;
  font-weight: 400;
`;

export const SignUpSectionIcons = styled.div`
  @media ${({ theme }) => theme.mediaQuery.medium} {
    display: flex;
    max-width: 400px;
    margin: 0 auto;
    align-items: flex-start;
    justify-content: center;
    grid-gap: 20px;
  }
`;

export const IconContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 156px;
  margin: 0 auto;

  svg {
    color: ${({ theme }) => theme.color.primaryGreen};
  }

  @media ${({ theme }) => theme.mediaQuery.medium} {
    margin: 0;
    min-width: 197px;
  }
`;

export const RewardList = styled.ul`
  list-style: none;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: flex-end;
  padding: 0;
  margin-top: 1.5rem;

  li {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0;
    width: 153px;
  }

  .icon {
    background-color: ${({ theme }) => theme.colorMap.gray.gray1};

    path {
      stroke: ${({ theme }) => theme.color.tertiaryGreen};
    }
  }

  @media ${({ theme }) => theme.mediaQuery.medium} {
    margin-top: 0;
    display: flex;
    grid-gap: 68px;
  }
`;

export const StyledButton = styled(Button)`
  position: absolute;
  bottom: 28px;
  right: 20px;
  width: 125px;
  background: ${({ theme }) => theme.color.tertiaryGreen};
  color: ${({ theme }) => theme.colorMap.neutral.black};
  display: none;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    position: relative;
    min-width: 171px;
    width: 100%;
    bottom: 0;
    right: 0;
    margin: rem 0rem 1rem 0rem;
    display: inline;
  }
`;

export const CallToActionSection = styled.section`
  padding: 1rem 1.5rem;
  box-shadow: -0.217625px -6.99662px 28px rgba(0, 0, 0, 0.03),
    -0.141053px -4.53484px 16.3981px rgba(0, 0, 0, 0.0209549),
    -0.0838259px -2.69499px 8.91852px rgba(0, 0, 0, 0.0158889),
    -0.043525px -1.39932px 4.55px rgba(0, 0, 0, 0.0130312),
    -0.0177324px -0.570095px 2.28148px rgba(0, 0, 0, 0.0106111),
    -0.00403009px -0.129567px 1.10185px rgba(0, 0, 0, 0.00685764);

  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: white;

  button {
    max-width: none;
  }

  @media ${({ theme }) => theme.mediaQuery.medium} {
    box-shadow: none;
    padding: 0 1.5rem 6.25rem;
    position: initial;

    button {
      margin: 0 auto;
      width: 327px;
    }
  }
`;

export const Dots = styled.ul`
  li button:before {
    color: ${({ theme }) => theme.colorMap.neutral.white};
    opacity: 0.8;
  }
  li.slick-active button:before {
    color: ${({ theme }) => theme.color.primaryPink};
  }
`;

export const SimilarThoughtsSection = styled(StyledSection)`
  max-width: ${maxWidthLDP}px;
  margin: 0 auto;
  border-bottom: 0;

  @media ${({ theme }) => theme.mediaQuery.small} {
    .parent-carousel {
      > .slick-list > .slick-track > .slick-active + .slick-slide {
        margin-left: 0;
      }
    }
  }

  img {
    max-width: ${carouselImageSizes.width}px;
    border-radius: 20px;
    max-height: ${carouselImageSizes.height}px;
    object-fit: cover;

    margin: 0 auto;
  }
`;

export const ImageGalleryContainer = styled.div``;
