export const SS_KEY_PREFIX = 'unicorn';
export const SS_SOUND_OFF_KEY = `${SS_KEY_PREFIX}_soundOff`;
export const SS_SHOW_ONBOARDING_IF_REQUIRED = `${SS_KEY_PREFIX}_showOnboarding`;
export const SS_ONBOARDING_PAGE_PATH = `${SS_KEY_PREFIX}_onboardingScreen`;

interface SessionStorage<T> {
  data: T;
}

const parse = <T>(cached: string): SessionStorage<T> | null => {
  try {
    const ls: SessionStorage<T> = JSON.parse(cached);
    return ls;
  } catch (err) {
    console.error('Error occured parsing json ', err);
    return null;
  }
};

export const setSessionStorage = <T>(key: string, data: T) => {
  sessionStorage.setItem(key, JSON.stringify({ data }));
};

export const removeSessionStorage = (key: string) => {
  sessionStorage.removeItem(key);
};

export const getSessionStorage = <T>(key: string): T | null => {
  const cached = sessionStorage.getItem(key);
  const ls = cached ? parse(cached) : null;
  return ls ? (ls.data as T) : null;
};
