import React, { useState } from 'react';

export const LISTINGS_PER_PAGE = 20;

type PaginationContextValues = {
  currentPage: number;
  setCurrentPage: (page: number) => void;

  setTotal: (total: number) => void;
  total: number;

  currentPageResults: number;
  setCurrentPageResults: (page: number) => void;

  offset: number;
  setOffset: React.Dispatch<React.SetStateAction<number>>;

  limit: number;
  setLimit: React.Dispatch<React.SetStateAction<number>>;

  reset: () => void;
};

export const PaginationContext = React.createContext<PaginationContextValues>({
  currentPage: 1,
  setCurrentPage: () => {
    /* do nothing */
  },

  setTotal: () => {
    /* do nothing */
  },
  total: 0,

  setCurrentPageResults: () => {
    /* do nothing */
  },
  currentPageResults: LISTINGS_PER_PAGE,

  setOffset: (_offset) => {
    /** do nothing */
  },
  offset: 0,

  setLimit: (_limit) => {
    /** do nothing */
  },
  limit: 0,
  reset: () => {
    /** do nothing */
  },
});

const PaginationProvider = ({ children }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [currentPageResults, setCurrentPageResults] = useState(0);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(0);

  const reset = () => {
    setLimit(0);
    setOffset(0);
    setTotal(0);
    setCurrentPage(1);
  };

  return (
    <PaginationContext.Provider
      value={{
        total,
        setTotal,

        currentPage,
        setCurrentPage,

        currentPageResults,
        setCurrentPageResults,

        offset,
        setOffset,

        limit,
        setLimit,

        reset,
      }}
    >
      {children}
    </PaginationContext.Provider>
  );
};

export default PaginationProvider;
