import { useState } from 'react';

type UseVideoLoadingState = {
  isLoading: boolean;
  hasBuffered: boolean;
  error: boolean;
  handlers: {
    load;
    setError;
    clearError;
    setBuffered;
    clearBuffered;
  };
};

const useVideoLoadingState = (): UseVideoLoadingState => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [hasBuffered, setHasBuffered] = useState<boolean>(false);

  return {
    isLoading,
    hasBuffered,
    error,
    handlers: {
      load: () => setIsLoading(false),
      setError: () => setError(true),
      clearError: () => setError(false),
      setBuffered: () => setHasBuffered(true),
      clearBuffered: () => setHasBuffered(false),
    },
  };
};

export default useVideoLoadingState;
