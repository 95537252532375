/* @jsxImportSource @emotion/react */

import React from 'react';
import styled from '@emotion/styled';
import Icon from '../Icon';
import { useTheme } from '@emotion/react';

type RatingTileTypes = {
  icon: React.FC;
  title: string;
  description: string;
  rating: number;
};

const RatingTile: React.FC<RatingTileTypes> = ({
  icon: IconType,
  title,
  description,
  rating,
}) => {
  const { colorMap } = useTheme();

  return (
    <Tile key={title}>
      <Icon icon={IconType} />

      <p css={{ fontSize: '0.875rem', fontWeight: 500, margin: '0.125rem 0' }}>
        {title}
      </p>
      {rating ? (
        <p
          css={{
            fontSize: '0.75rem',
            fontWeight: 500,
            color: colorMap.gray.gray2,
            lineHeight: '18px',
            margin: 0,
          }}
        >
          {description}
        </p>
      ) : (
        <p
          css={{
            fontSize: '0.75rem',
            fontWeight: 500,
            color: colorMap.red.red1,
            lineHeight: '18px',
            margin: 0,
          }}
        >
          No reviews yet
        </p>
      )}
      <RatingNumber>{rating ? rating.toFixed(1) : ''}</RatingNumber>
      <RatingBarContainer>
        <RatingBar>
          <ColoredBar rating={rating} />
        </RatingBar>
      </RatingBarContainer>
    </Tile>
  );
};

export default RatingTile;

const Tile = styled.div`
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colorMap.gray.gray6};
  padding: 0.5rem 0.5rem 1.25rem 0.5rem;
`;

const RatingNumber = styled.h2`
  font-size: 1.625rem;
  font-weight: 700;
  color: ${({ theme }) => theme.color.secondaryGreen};
  line-height: 34px;
  margin: 0.5rem 0;
  min-height: 34px;
`;

const RatingBarContainer = styled.div`
  box-shadow: inset 0px 0px 1px rgba(0, 0, 0, 0.04),
    inset 0px 1px 2px rgba(0, 0, 0, 0.11);
  border-radius: 12px;
  height: 12px;
  padding: 0.25rem;
  background-color: white;
`;

const RatingBar = styled.div`
  background: ${({ theme }) => theme.colorMap.gray.gray5};
  border-radius: 12px;
  height: 4px;
`;

const ColoredBar = styled.div<{ rating: number }>`
  background: ${({ theme }) => theme.color.secondaryGreen};
  border-radius: 12px;
  width: ${({ rating }) => `${(rating / 5) * 100}%`};
  height: 4px;
`;
