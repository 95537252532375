import styled from '@emotion/styled';
import Button from '../../components/UI/Button';
import { UnicornSmallLogo, UnicornLargeLogo } from '../../components/UI/Brand';
import { inputWidth } from './AuthPage';

import mobileImage from './unicorn-login-home-mobile.png';

export const HomeWrapper = styled.div`
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  background-image: url(${mobileImage});
  background-size: cover;
  background-position: 65%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    background-image: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const HomeContent = styled.div`
  width: 100%;
  padding: 7.75rem 1.5rem 2rem;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    padding: 5.625rem 1.5rem 2rem;
    width: 43%;
    justify-content: center;
    align-items: center;
  }
`;

export const HomeImageContainer = styled.div`
  width: 57%;
  height: 100%;
  display: flex;
  background-color: #f4f4f4;
`;

export const HomeImage = styled.img`
  object-fit: contain;
  margin: 0 auto;
`;

export const HomeSmallLogo = styled(UnicornSmallLogo)`
  margin: 0 auto 1rem;
  color: white;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    margin: 0 auto 2rem;
  }
`;

export const HomeLargeLogo = styled(UnicornLargeLogo)`
  svg {
    max-width: 100%;
  }
`;

export const HomeCta = styled.div`
  text-align: center;
  font-size: 1rem;
  line-height: 1.375;
  font-weight: 500;
  letter-spacing: 0.18px;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    font-size: 1.375rem;
    max-width: 413px;
    margin: 0 auto;
  }
`;

export const HomeActions = styled.div`
  @media ${({ theme }) => theme.mediaQuery.medium} {
    margin: 6.25rem auto 0;
    width: 100%;
    max-width: 327px;
  }
`;

// This reproduces a bunch of styling from buttons
// Because these screens aren't really "in" the app,
// and becuase of time pressure, this is a punt on the issue
// of button-styled <a> elements
export const HomeButton = styled.a`
  display: block;
  width: 100%;
  height: 40px;
  border-radius: 16px;

  text-align: center;
  font-size: 1rem;
  line-height: 40px;
  font-weight: 600;

  background-color: ${({ theme }) => theme.color.primaryGreen};
  color: white;

  // override button styles
  &:visited {
    color: white;
  }
  &:hover {
    text-decoration: none;
  }

  &.primaryPink {
    background-color: ${({ theme }) => theme.color.primaryPink};
  }

  & + & {
    margin-top: 16px;
  }

  @media ${({ theme }) => theme.mediaQuery.medium} {
    background-color: ${({ theme }) => theme.color.primaryGreen};

    &.primaryPink {
      background-color: ${({ theme }) => theme.color.primaryPink};
    }
  }
`;

export const Privacy = styled.div`
  font-size: 0.75rem;
  line-height: 1.333;
  text-align: center;
  font-weight: 500;
}`;

export const Terms = styled.div`
  font-size: 0.75rem;
  line-height: 1.6;
  color: #2f2f2f;
}`;

export const AuthError = styled.p`
  width: ${inputWidth};
  margin: 0.5em auto 0;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.color.error};
  text-align: left;

  &:before {
    content: '!';
    display: inline-block;

    width: 17px;
    height: 17px;
    padding-left: 1px;
    border-radius: 50%;
    color: white;
    background-color: ${({ theme }) => theme.color.error};
    text-align: center;
    font-size: 0.875rem;
    font-weight: bold;
    line-height: 17px;
    margin-right: 8px;
  }
`;

export const AuthDescription = styled.div`
  width: ${inputWidth};
  margin: 5.625rem auto 0;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    width: 100%;
    margin: 0;
  }
`;

export const AuthHeadline = styled.h1`
  width: 66%;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    width: auto;
  }
`;

export const AuthText = styled.p`
  color: ${({ theme }) => theme.colorMap.gray.gray1};
`;

export const BackButton = styled(Button)`
  padding: 0;
  text-align: left;
  @media ${({ theme }) => theme.mediaQuery.medium} {
    text-align: center;
    margin: 0 auto;
  }
`;

export const ResentIndicator = styled.div`
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 171px;

  height: 40px;
  border-radius: 16px;

  text-align: center;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 40px;
  color: white;
  background-color: black;
`;
