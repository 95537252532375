import React, { useContext } from 'react';

import DatePicker from 'react-datepicker';
import { useHistory } from 'react-router';
import 'react-datepicker/dist/react-datepicker.css';
import { DefaultIcon } from '../../../components/Icon';
import {
  IconWrapper,
  RecentHeading,
  RecentList,
  RecentListItem,
  SearchViewContainer,
  SearchViewQuestion,
  SuggestionItem,
  SuggestionList,
} from './components';
import { useTheme } from '@emotion/react';
import { PaginationContext } from '../../../hooks/usePagination';
import { useSearch } from '../../../hooks';

const defaultSuggestedSearches = [
  {
    label: 'Los Angeles, CA',
    value: 'Los Angeles, CA, USA',
  },
  {
    label: 'Santa Monica, CA',
    value: 'Santa Monica, CA, USA',
  },
  {
    label: 'Venice, CA',
    value: 'Venice, CA, USA',
  },
];

const SearchView: React.FC<{
  suggestions: Array<any>;
  getSuggestionItemProps: (string, object) => any;
  showDatePicker: boolean;
  className?: string;
  iconType?: string;
  city?: string;
  onSelect: (address: string) => void;
}> = ({
  suggestions,
  getSuggestionItemProps,
  showDatePicker,
  className,
  city,
  onSelect,
}) => {
  const history = useHistory();
  const { colorMap } = useTheme();
  const { setCurrentPage } = useContext(PaginationContext);

  const {
    handlers: { handleGoBack },
  } = useSearch();

  const handleDateSelect = (date) => {
    const d = new Date(date);
    d.setHours(0, 0, 0, 0);

    // resets search results to page 1
    setCurrentPage(1);

    handleGoBack();

    history.push('/search', {
      city,
      available_at: d.toISOString(),
    });
  };

  if (showDatePicker) {
    return (
      <SearchViewContainer
        className={`search-view__container ${className}`}
        data-testid="date-picker-container"
      >
        <SearchViewQuestion className="search-view__question">
          When are you looking to move?
        </SearchViewQuestion>
        <DatePicker
          data-testid="date-picker"
          onChange={handleDateSelect}
          minDate={new Date()}
          inline
        />
      </SearchViewContainer>
    );
  }

  if (suggestions.length) {
    return (
      <SearchViewContainer className={`search-view__container ${className}`}>
        <SuggestionList>
          {suggestions.map((suggestion, idx) => {
            const className = suggestion.active
              ? 'suggestion-item--active'
              : 'suggestion-item';

            const style = suggestion.active
              ? { backgroundColor: '#fafafa', cursor: 'pointer' }
              : { backgroundColor: colorMap.neutral.white, cursor: 'pointer' };

            return (
              <SuggestionItem
                {...getSuggestionItemProps(suggestion, {
                  className,
                  style,
                })}
                key={idx}
              >
                <DefaultIcon name="pin" />
                <span data-testid="suggestion">{suggestion.description}</span>
              </SuggestionItem>
            );
          })}
        </SuggestionList>
      </SearchViewContainer>
    );
  }

  return (
    <SearchViewContainer className={`search-view__container ${className}`}>
      <RecentHeading>Suggested Searches</RecentHeading>

      <RecentList>
        {defaultSuggestedSearches.map(({ label, value }) => (
          <RecentListItem key={value} onClick={() => onSelect(value)}>
            <IconWrapper>
              <DefaultIcon name="pin" />
            </IconWrapper>
            {label}
          </RecentListItem>
        ))}
      </RecentList>
    </SearchViewContainer>
  );
};

export default SearchView;
