import { useState } from 'react';

type UseScrollLock = {
  scrollStart;
  scrollEnd;
  scrollMove;
};

const useScrollLock = (): UseScrollLock => {
  const [lockHorizontal, setLockHorizontal] = useState<boolean>();
  const [startX, setStartX] = useState<number>(0);
  const [startY, setStartY] = useState<number>(0);

  const scrollStart = (evt: TouchEvent) => {
    const { clientX, clientY } = evt.changedTouches[0];
    setStartX(Math.trunc(clientX));
    setStartY(Math.trunc(clientY));
  };
  const scrollMove = (evt: TouchEvent) => {
    if (lockHorizontal === undefined) {
      const { clientX, clientY } = evt.changedTouches[0];
      const diffX = Math.abs(Math.trunc(clientX) - startX);
      const diffY = Math.abs(Math.trunc(clientY) - startY);
      // If we have more movement in x, lock, otherwise assume user is scrolling vertical
      if (diffY < diffX) {
        document.body.style.overflow = 'hidden';
        setLockHorizontal(true);
      } else {
        setLockHorizontal(false);
      }
    }
  };
  const scrollEnd = (evt: TouchEvent) => {
    // Clear touch
    setStartX(Math.trunc(0));
    setStartY(Math.trunc(0));
    setLockHorizontal(undefined);
    // Update document body
    document.body.style.overflow = '';
  };

  return { scrollStart, scrollMove, scrollEnd };
};

export default useScrollLock;
