import Lottie from 'react-lottie';
import loading from './loading.json';

const VideoLoader = () => (
  <Lottie
    options={{
      animationData: loading,
      loop: true,
    }}
    width={68}
    height={68}
  />
);

export default VideoLoader;
