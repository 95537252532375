// default icon sizes
// user can pass in height & width if these sizes don't work
export const iconSizes = {
  xSmall: 16,
  small: 20,
  medium: 24,
  large: 36,
  xLarge: 40,
};

export enum IconSizeKeys {
  X_SMALL = 'xSmall',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  X_LARGE = 'xLarge',
}
