import styled from '@emotion/styled';

const THUMB_WIDTH = 36;
const STOP_WIDTH = 8;

export const DescriptionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 0.875rem;
  line-height: 1.5;
  height: 16px;
  color: ${({ theme }) => theme.colorMap.gray.gray2};
  margin-bottom: 0.5em;
`;

export const SliderWrapper = styled.div`
  position: relative;
  height: ${THUMB_WIDTH}px;
  margin-bottom: 3px;

  // remember .clearfix?!
  &:before {
    display: table;
    content: ' ';
  }
`;

export const SliderSelection = styled.div`
  position: absolute;
  width: 100%;
  top: 11px;
  height: 14px;
  border-radius: 12px;
  border: ${({ theme }) => `4px solid ${theme.colorMap.gray.gray6}`};
`;

export const SliderBackground = styled.div`
  position: relative;
  height: 14px;
  margin: 0.625rem 0;
  border-radius: 12px;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const EmptyStop = styled.div`
  width: ${THUMB_WIDTH}px;
  height: ${STOP_WIDTH}px;
  cursor: pointer;
`;

export const SliderStop = styled.div`
  width: ${THUMB_WIDTH}px;
  height: ${STOP_WIDTH}px;
  cursor: pointer;
  display: flex;
  justify-content: center;

  &:before {
    content: ' ';
    display: block;
    height: ${STOP_WIDTH}px;
    width: ${STOP_WIDTH}px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colorMap.gray.gray5};
    box-shadow: inset 0px 1.75px 1.75px rgba(92, 67, 193, 0.14);
  }
`;

export const SliderThumb = styled.div`
  position: absolute;
  top: 0;

  appearance: none;
  padding: 0;
  background: white none;

  box-sizing: border-box;
  width: ${THUMB_WIDTH}px;
  height: ${THUMB_WIDTH}px;
  border-radius: ${THUMB_WIDTH / 2}px;
  border: 3px solid white;
  box-shadow: inset 0px 1.05882px 3.17647px rgba(92, 67, 193, 0.14);

  &:focus {
    outline: none;
    border: 3px solid ${({ theme }) => theme.color.primaryPink};
  }

  &:active {
    transform: scale(1.1);
  }
`;

export const LabelsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  font-weight: bold;
`;

export const SliderLabel = styled.span`
  width: ${THUMB_WIDTH}px;
  text-align: center;
  cursor: pointer;
`;
