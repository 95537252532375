/* @jsxImportSource @emotion/react */
import React from 'react';
import styled from '@emotion/styled';
import { ReactComponent as Icon } from './error-icon.svg';

const StyledError = styled.div`
  height: 17px;
  margin-top: 6px;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.color.error};
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

type ErrorProps = {
  message: string;
};

const Error = ({ message }: ErrorProps) => (
  <StyledError role="alert">
    <Icon css={{ marginRight: '8px' }} />
    {message}
  </StyledError>
);

export default Error;
