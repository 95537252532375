/* @jsxImportSource @emotion/react */
import Icon, { IconWrapper } from '../../components/Icon';
import { IconSizeKeys } from '../Icon/utils';

type ShareListingIconProps = {
  variant: IconSizeKeys;
  fillColor: string;
  type: 'round';
  height?: number;
  width?: number;
};

interface ShareListingButtonProps {
  iconWrapperProps: ShareListingIconProps;
}

const ShareListingButton = ({ iconWrapperProps }: ShareListingButtonProps) => {
  return (
    <IconWrapper {...iconWrapperProps} data-testid="share-listing-button">
      <Icon height={18} width={20} name="send" color={'none'} />
    </IconWrapper>
  );
};

export default ShareListingButton;
