import React, { useMemo } from 'react';
import Heading, {
  HeadingElementTypes,
} from '../../../components/Typography/Heading';
import { WeightTypes } from '../../../components/Typography/utils';
import { useSearchByProximity } from '../../../hooks/useSearchByProximity';
import { Listing } from '../../../types';
import { SimilarThoughtsSection } from '../components';
import SliderSection from '../../../components/SliderSection';
import { generateSimilarListingQuery } from '../utils';

const SIMILAR_THOUGHTS_LIMIT = 4;

export type SimilarThoughtsProps = {
  listingDetails: Listing;
};

const SimilarThoughts: React.FC<SimilarThoughtsProps> = ({
  listingDetails,
}) => {
  const similarListingsQueryPath = useMemo(() => {
    return generateSimilarListingQuery(
      listingDetails?.approximateLocation.latitude,
      listingDetails?.approximateLocation.longitude,
      SIMILAR_THOUGHTS_LIMIT,
      0
    );
  }, [listingDetails]);

  const {
    data: similarListingsUnfiltered,
    isLoading: similarListingLoading,
  } = useSearchByProximity(
    listingDetails?.approximateLocation.latitude,
    listingDetails?.approximateLocation.longitude,
    SIMILAR_THOUGHTS_LIMIT,
    0
  );

  const similarListings = similarListingsUnfiltered?.filter((listing) => {
    return listing.listingId !== listingDetails.listingId;
  });

  const similarListingsTotal = useMemo(
    () => similarListings?.length ?? SIMILAR_THOUGHTS_LIMIT,
    [similarListings]
  );

  return similarListings ? (
    <SimilarThoughtsSection data-testid="similar-thoughts-section">
      <Heading type={HeadingElementTypes.h6} weight={WeightTypes.bold}>
        Similar Listings
      </Heading>

      <SliderSection
        total={similarListingsTotal}
        data={similarListings}
        loading={similarListingLoading}
        queryPath={similarListingsQueryPath}
      />
    </SimilarThoughtsSection>
  ) : (
    <></>
  );
};

export default SimilarThoughts;
