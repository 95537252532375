import React, { useCallback, useContext, useRef, useState } from 'react';
import { useListingSearchParams } from '.';

export const SearchContext = React.createContext<any>({
  searchView: null,
  setSearchView: () => {},
});

export const PATHS_WITH_SEARCH_ON_NAVBAR = [
  '/search',
  '/favorites',
  '/profile',
  '/listings-near-you',
  '/listings/:listingId',
];

export const PATHS_WITH_SEARCH = [
  ...PATHS_WITH_SEARCH_ON_NAVBAR,
  '/listings', // Marketplace has a search but not on NavBar
];

export const SearchProvider = ({ children }) => {
  const [searchView, setSearchView] = useState(null);

  const [showSearchDesktopView, setShowSearchDesktopView] = useState(false);

  const searchRef = useRef<any>();

  return (
    <SearchContext.Provider
      value={{
        searchView,
        setSearchView,
        showSearchDesktopView,
        setShowSearchDesktopView,
        searchRef,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

function useSearch() {
  const { city: cityParams } = useListingSearchParams();
  const [city, setCity] = useState(cityParams || '');

  const {
    searchView,
    setSearchView,
    showSearchDesktopView,
    setShowSearchDesktopView,
    searchRef,
  } = useContext(SearchContext);

  const [showDatePicker, setShowDatePicker] = useState(false);

  const handleChange = useCallback((address) => {
    if (!address) setShowDatePicker(false);
    setCity(address);
  }, []);

  const handleSelect = async (address) => {
    // Note: This is to hide the keyboard on mobile devices
    // once a city is selected
    if (document?.activeElement) {
      (document.activeElement as HTMLElement).blur();
    }

    setShowDatePicker(true);
    setCity(address);

    // TODO: Revisit when api is defined
    // try {
    //   const results = await geocodeByAddress(address);
    //   const latLng = await getLatLng(results[0]);
    // } catch (error) {
    //   console.log('Error: ', error);
    // }
  };

  const handleInputClick = () => setSearchView(true);
  const handleGoBack = () => setSearchView(false);
  const handleRemove = () => {
    setCity('');
    setShowDatePicker(false);
  };

  const handlePageClick = (event: Event) => {
    if (
      searchRef.current &&
      searchRef.current.contains(event.target) &&
      !showSearchDesktopView
    ) {
      setShowSearchDesktopView(true);
    } else if (
      searchRef.current &&
      !searchRef.current.contains(event.target) &&
      showSearchDesktopView
    ) {
      setShowSearchDesktopView(false);
    }
  };

  const shouldShowSearch = useCallback((pathname, params, paths) => {
    const routePathname = params.reduce((acc, pm) => {
      const { param, dynamicKey } = pm;
      acc = pathname.replace(`/${param}`, dynamicKey);

      return acc;
    }, '');

    return paths.includes(routePathname);
  }, []);

  return {
    city,
    searchView,
    setSearchView,
    showSearchDesktopView,
    setShowSearchDesktopView,
    showDatePicker,
    searchRef,
    shouldShowSearch,
    handlers: {
      handleChange,
      handleSelect,
      handleInputClick,
      handleGoBack,
      handleRemove,
      handlePageClick,
    },
  };
}

export default useSearch;
