import React from 'react';
import { DefaultIcon } from '../../../components/Icon';
import { MuteButtonContainer, StyledMuteButton } from './components';

export type VideoMuteButtonProps = {
  unmute: (evt) => void;
};

const VideoMuteButton: React.FC<VideoMuteButtonProps> = ({ unmute }) => {
  return (
    <MuteButtonContainer>
      <StyledMuteButton
        onClick={unmute}
        variant={'secondary'}
        size={'medium'}
        color="black"
      >
        <DefaultIcon name={'soundOff'} />
        <span>Unmute</span>
      </StyledMuteButton>
    </MuteButtonContainer>
  );
};

export default VideoMuteButton;
