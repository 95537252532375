import React, { useContext, useEffect } from 'react';
import { ModalContext } from '../../../hooks/useModal';
import { useTheme } from '@emotion/react';
import { useMediaQuery } from '../../../hooks';
import {
  ModalContentWrapper,
  CloseIconWrapper,
} from '../../../components/Modal/components';
import Icon from '../../../components/Icon';
import { getModalStyles } from '../../../components/Modal/utils';
import {
  DescriptionModal,
  DescriptionModalHeading,
  DescriptionModalParagraph,
} from '../components';

const modalImageSizes = {
  mediumSize: {
    width: '780px',
    height: '750px',
  },
  defaultSize: {
    width: '100%',
    height: '100%',
  },
};

const mobileBorderRadius = {
  borderRadius: '0px',
};

type FullDescriptionModalProps = {
  description: string;
};

const FullDescriptionModal = ({ description }: FullDescriptionModalProps) => {
  const { handleCloseModal, setContentStyles } = useContext(ModalContext);
  const { colorMap, mediaQuery } = useTheme();
  const minWidthMedium = useMediaQuery(mediaQuery.medium);

  useEffect(() => {
    setContentStyles({
      ...getModalStyles(minWidthMedium, colorMap),
      ...(minWidthMedium
        ? modalImageSizes.mediumSize
        : modalImageSizes.defaultSize),
      ...(minWidthMedium ? {} : mobileBorderRadius),
      ...{ overflow: 'visible' },
    });
  }, [minWidthMedium, colorMap, setContentStyles]);

  return (
    <ModalContentWrapper>
      <CloseIconWrapper
        css={{ position: 'absolute' }}
        aria-label={'close-icon-wrapper'}
        onClick={handleCloseModal}
      >
        <Icon
          color={colorMap.gray.gray6}
          width={36}
          height={36}
          name="close"
        ></Icon>
      </CloseIconWrapper>
      <DescriptionModal>
        <DescriptionModalHeading>Overview</DescriptionModalHeading>
        <DescriptionModalParagraph>{description}</DescriptionModalParagraph>
      </DescriptionModal>
    </ModalContentWrapper>
  );
};

export default FullDescriptionModal;
