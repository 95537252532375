import { useState, useEffect } from 'react';

const useScroll = () => {
  const [scrollY, setScrollY] = useState<number>(window.scrollY);

  const handleScroll = () => setScrollY(window.scrollY);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollY]);

  return { scrollY };
};

export default useScroll;
