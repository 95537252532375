import { useQuery } from 'react-query';
import { useApiClient } from '../hooks';
import { generateSimilarListingQuery } from '../pages/ListingDetailPage/utils';

import { Listing } from '../types';

export function useSearchByProximity(
  lat: number | undefined,
  lng: number | undefined,
  limit: number,
  offset: number
) {
  const apiRequest = useApiClient();

  const queryPath = generateSimilarListingQuery(lat, lng, limit, offset);

  const query = useQuery<{}, {}, Array<Listing>>(
    [queryPath],
    () => {
      return apiRequest(queryPath);
    },
    {
      enabled: !!lat && !!lng,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  return { ...query };
}
