/* @jsxImportSource @emotion/react */
import { useTheme } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { getStaticAssetS3Url } from '../../lib/static-assets';
import { color } from '../../lib/theme';
import Icon from '../Icon';
import { CloseIconWrapper } from '../Modal/components';
import Heading, { HeadingElementTypes } from '../Typography/Heading';
import { WeightTypes } from '../Typography/utils';
import Button from '../UI/Button';
import Skeleton from '../UI/Skeleton/Skeleton';
import {
  MainImage,
  MAIN_IMAGE_WIDTH,
  UnlockAccessWrapper,
  ValueProp,
  ValueProps,
} from './components';

const imageSrc = getStaticAssetS3Url('/unlock-access/unicorn_member.webp');

const valueProps = [
  'Find your next place months ahead',
  'Real reviews from actual tennants',
  'Save time and money',
];

export type UnlockAccessModalProps = {
  handleCloseModal: () => void;
};

const UnlockAccessModal: React.FC<UnlockAccessModalProps> = ({
  handleCloseModal,
}) => {
  const { colorMap } = useTheme();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const loadImageOffscreen = () => {
    const image = new Image();
    image.onload = () => setIsLoading(false);
    image.src = imageSrc;
  };

  useEffect(() => {
    loadImageOffscreen();
  });

  return (
    <UnlockAccessWrapper>
      <CloseIconWrapper
        aria-label={'close-icon-wrapper'}
        onClick={handleCloseModal}
      >
        <Icon
          color={colorMap.gray.gray6}
          width={36}
          height={36}
          name="close"
        ></Icon>
      </CloseIconWrapper>
      {isLoading ? (
        <Skeleton height={236} width={MAIN_IMAGE_WIDTH - 15} />
      ) : (
        <MainImage src={imageSrc} />
      )}
      <Heading
        css={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}
        type={HeadingElementTypes.h3}
        color={colorMap.neutral.black}
        weight={WeightTypes.bold}
      >
        Unlock Access
      </Heading>

      <ValueProps>
        <Heading
          css={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}
          type={HeadingElementTypes.h6}
          color={color.primaryPink}
          weight={WeightTypes.semiBold}
        >
          What’s in it for me?
        </Heading>
        {valueProps.map((valueProp) => (
          <ValueProp>
            <Icon
              color={color.primaryPink}
              width={36}
              height={36}
              name="check"
            ></Icon>
            <span>{valueProp}</span>
          </ValueProp>
        ))}
      </ValueProps>
      <Button
        id="unlock-access-create-button"
        variant="primary"
        color="primaryPink"
        size="large"
        style={{ marginTop: 'auto', marginBottom: '0rem' }}
        onClick={() => {
          history.push(`/create`);
          handleCloseModal();
        }}
      >
        Create an account
      </Button>
      <Button
        id="unlock-access-sign-in-buttom"
        variant="tertiary"
        color={colorMap.neutral.black}
        size="large"
        style={{ margin: '0.5rem' }}
        onClick={() => {
          history.push(`/login`);
          handleCloseModal();
        }}
      >
        Sign in
      </Button>
    </UnlockAccessWrapper>
  );
};

export default UnlockAccessModal;
