import styled from '@emotion/styled';
import {
  fontWeightMap,
  WeightTypes,
} from '../../../components/Typography/utils';
import { carouselImageSizes } from '../../../lib/constants';

export const ListingDetailContainer = styled.div`
  align-self: flex-start;
  text-align: left;
  max-width: ${carouselImageSizes.mobile.maxWidth}px;

  @media ${({ theme }) => theme.mediaQuery.small} {
    margin: 0 auto;
    padding-left: 10px;
  }

  @media ${({ theme }) => theme.mediaQuery.medium} {
    margin: 0 auto;
    padding-left: 10px;
    max-width: ${carouselImageSizes.desktop.maxWidth}px;
  }

  @media ${({ theme }) => theme.mediaQuery.max} {
    margin-left: auto;
  }
`;

export const ListingRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 96%;
  margin: 0 auto;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: ${fontWeightMap[WeightTypes.normal]};
  color: ${({ theme }) => theme.colorMap.gray.gray2};
`;

export const ListingFirstRow = styled(ListingRow)`
  font-weight: ${fontWeightMap[WeightTypes.semiBold]};
  font-size: 1rem;
  line-height: 1.5rem;
  padding-top: 0.25rem;
  color: ${({ theme }) => theme.colorMap.neutral.black};
`;
