import React from 'react';
import { Link } from 'react-router-dom';

import styled from '@emotion/styled';
import Paragraph, { ParagraphVariant } from '../Typography/Paragraph';

const Tabs: React.FC<{ className?: string }> = ({ children, className }) => {
  return <TabsList className={className}>{children}</TabsList>;
};

// Tab can either redirect to a route or calls an onClick
export const Tab: React.FC<{
  redirectUrl?: string;
  onClick?: () => void;
  isActive: boolean;
}> = ({ redirectUrl, onClick, children, isActive }) => {
  if (redirectUrl) {
    return (
      <StyledLink to={redirectUrl} isActive={isActive}>
        <Paragraph variant={ParagraphVariant.medium}>{children}</Paragraph>
      </StyledLink>
    );
  }

  return (
    <Button onClick={onClick} isActive={isActive}>
      <Paragraph variant={ParagraphVariant.medium}>{children}</Paragraph>
    </Button>
  );
};

export default Tabs;

const TabsList = styled.ul<{ className?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
`;

const StyledLink = styled(Link)<{ isActive: boolean }>``;

const Button = styled.button<{ isActive: boolean }>`
  outline: none;
  border: none;
  background-color: white;
  padding-left: 0;
  padding-right: 0;

  cursor: ${({ isActive }) => (isActive ? 'default' : 'pointer')};
  border-bottom: ${({ isActive, theme }) =>
    isActive ? `1px solid ${theme.color.black}` : 'none'};

  p {
    color: ${({ isActive, theme }) =>
      isActive ? theme.color.black : theme.colorMap.gray.gray2};
  }
`;
