import styled from '@emotion/styled/macro';
import { DefaultIcon } from '../Icon';

type MapMarkerProps = {
  lat?: number;
  lng?: number;
};

const MapMarker: React.FC<MapMarkerProps> = ({ lat, lng }) => {
  return (
    <div
      style={{
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <IconRadius>
        <IconWrapper>
          <DefaultIcon color={'white'} name="house" />
        </IconWrapper>
      </IconRadius>
    </div>
  );
};

export default MapMarker;

const IconRadius = styled.div`
  width: 144px;
  height: 144px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 112, 163, 0.24);
  border-radius: 50% !important;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 57.6px;
  height: 57.6px;
  background: linear-gradient(109.73deg, #ff70a3 -8.89%, #ff2d78 91.92%);

  svg {
    width: 36px;
    height: 36px;
  }
`;
