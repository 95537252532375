import type { ComponentPropsWithoutRef } from 'react';
import { ReactComponent as HeartOutline } from './heart-outline.svg';
import { ReactComponent as HeartSolid } from './heart-solid.svg';
import { SentimentButtonProps } from './types';
import { Sentiment } from '../../types';
import styled from '@emotion/styled';

const SentimentButton = ({
  listing,
  toggleSentiment,
  iconStyle,
  className,
}: ComponentPropsWithoutRef<'button'> & SentimentButtonProps) => (
  <ThemedSentimentButton
    data-testid={`${listing?.listingId}-sentiment-button`}
    aria-label="SentimentButton"
    onClick={(e) => {
      e.preventDefault(); // stop propagation to the surrounding <Link>
      if (listing) toggleSentiment(listing);
    }}
    title={
      listing?.sentiment !== Sentiment.positive
        ? 'Favorite Listing'
        : 'Unfavorite Listing'
    }
    className={className}
  >
    {listing?.sentiment !== Sentiment.positive ? (
      <HeartOutline css={{ ...iconStyle }} />
    ) : (
      <HeartSolid css={{ ...iconStyle }} />
    )}
  </ThemedSentimentButton>
);

export default SentimentButton;

const ThemedSentimentButton = styled.button`
  appearance: none;
  background: inherit;
  border: none;
  padding: 0.5rem;
  width: 36px;
  height: 36px;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    width: 21px;
    height: auto;
    color: ${({ theme }) => theme.color.primaryPink};
  }
`;
