/* @jsxImportSource @emotion/react */

import React, { ChangeEvent, useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import styled from '@emotion/styled';

import { Listing } from '../../../types';
import { TextInput } from '../../../components/UI/Form';
import Button from '../../../components/UI/Button';
import SentimentButton from '../../../components/SentimentButton';
import { useApiClient, useConfig, useMediaQuery } from '../../../hooks';
import { useTheme } from '@emotion/react';

import { ReactComponent as ChampagneSvg } from '../images/Champagne.svg';
import ShareListing, {
  ShareListingButton,
} from '../../../components/ShareListing';
import IfLoading from '../../../components/IfLoading';
import { getReadableDate, monthlyRent } from '../../../lib/utils';
import { AppConfigCacheKey } from '../../../hooks/useConfig';
import { IconSizeKeys } from '../../../components/Icon/utils';
import { navBarHeight } from '../../../components/NavBar/components';

const askQuestionStickyBufferTop = 20;

interface AskQuestionType extends Partial<Listing> {
  setAskQuestion: (show: boolean) => void;
  toggleSentiment: (l: Listing) => void;
  listingDetails?: Listing;
  listingDetailsLoading: boolean;
}

const AskQuestion: React.FC<AskQuestionType> = ({
  setAskQuestion,
  toggleSentiment,
  listingDetails,
  listingDetailsLoading,
}) => {
  const apiClient = useApiClient();

  const { user } = useConfig();

  const [email, setEmail] = useState(user?.email);
  const [selectedQuestion, setSelectedQuestion] = useState<string>('');
  const { mediaQuery, color, colorMap } = useTheme();
  const minWidthMedium = useMediaQuery(mediaQuery.medium);
  const [open, setIsOpen] = useState<boolean>(false);

  const { data } = useQuery<{}, unknown, { questions: Array<string> }>(
    'ask-question',
    () => apiClient('/messaging/get-questions'),
    { refetchOnWindowFocus: false }
  );

  const queryClient = useQueryClient();
  // invalidate config once user submits their email
  const invalidateCacheConfig = () =>
    queryClient.invalidateQueries(AppConfigCacheKey);

  const sendQuestion = async () => {
    await apiClient(`/messaging/submit-question`, {
      method: 'POST',
      body: {
        email,
        message: selectedQuestion,
        listing_id: listingDetails?.listingId,
      },
    });

    invalidateCacheConfig();
  };

  const { isSuccess, mutate, reset } = useMutation(sendQuestion);

  const handleQuestionClick = (value: string) => () => {
    setSelectedQuestion(value);
  };

  const handleSend = (event) => {
    event.preventDefault();
    mutate();
  };

  const onSuccessAcknowledgment = (event) => {
    event.preventDefault();
    reset();
  };

  useEffect(() => setIsOpen(true), []);

  return (
    <React.Fragment>
      <AskQuestionContainer
        className={`ask-question-container ${open ? 'open' : ''}`}
        data-testid="ask-question-container"
      >
        <Header>
          <div>
            <IfLoading
              isLoading={listingDetailsLoading}
              height={45}
              width={100}
            >
              <Rent>
                <h2
                  css={{
                    fontSize: '1.625rem',
                    fontWeight: 700,
                    color: color.primaryPink,
                  }}
                >
                  {monthlyRent(listingDetails?.monthlyRent || 0)}
                </h2>
                &nbsp;
                <span css={{ fontSize: '0.875rem', fontWeight: 500 }}>
                  / mo
                </span>
              </Rent>
            </IfLoading>

            <IfLoading
              isLoading={listingDetailsLoading}
              height={20}
              width={115}
            >
              <div css={{ fontSize: '1rem', fontWeight: 400 }}>
                Avail.{' '}
                {getReadableDate(
                  listingDetails?.availableAt || new Date().toISOString()
                )}
              </div>
            </IfLoading>
          </div>

          {/* TODO: Change to Buttons */}
          <Actions>
            <Action>
              <ShareListing
                listingId={listingDetails?.listingId}
                button={
                  <ShareListingButton
                    iconWrapperProps={{
                      variant: minWidthMedium
                        ? IconSizeKeys.SMALL
                        : IconSizeKeys.X_SMALL,
                      height: 45,
                      width: 45,
                      fillColor: colorMap.neutral.white,
                      type: 'round',
                    }}
                  />
                }
              />
            </Action>

            <Action>
              <SentimentButton
                listing={listingDetails}
                toggleSentiment={toggleSentiment}
                css={{
                  background: colorMap.neutral.white,
                }}
              />
            </Action>
          </Actions>
        </Header>

        <FormContainer onSubmit={handleSend}>
          {!user?.email && (
            <>
              <label
                htmlFor="email"
                css={{ fontSize: '1.125rem', fontWeight: 600 }}
              >
                Your Email
              </label>
              <TextInput
                type="email"
                value={email}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setEmail(event?.currentTarget.value)
                }
                id="email"
                data-testid="email-input"
                css={{
                  marginTop: '12px',
                }}
              />
              <p css={{ fontSize: '0.75rem', fontWeight: 400 }}>
                Recipients won't see your email.
              </p>
            </>
          )}

          {isSuccess ? (
            <>
              <IconWrapper>
                <ChampagneSvg />
              </IconWrapper>
              <div css={{ fontSize: '1.125rem', fontWeight: 500 }}>
                Sit back and relax, we will let you know when the tenant has
                replied back!
              </div>
            </>
          ) : (
            data?.questions?.map((question) => {
              return (
                <QuestionItem
                  key={question}
                  onClick={handleQuestionClick(question)}
                  isSelected={selectedQuestion === question}
                  data-testid="question-item"
                >
                  {question}
                </QuestionItem>
              );
            })
          )}
          {isSuccess ? (
            <StyledButton
              variant="primary"
              isSuccess={isSuccess}
              onClick={onSuccessAcknowledgment}
            >
              {' '}
              Thanks
            </StyledButton>
          ) : (
            <StyledButton
              disabled={!selectedQuestion || !email}
              type="submit"
              variant="primary"
              isSuccess={isSuccess}
            >
              Send Question
            </StyledButton>
          )}
        </FormContainer>
      </AskQuestionContainer>
      <Overlay onClick={() => setAskQuestion(false)} />
    </React.Fragment>
  );
};

export default AskQuestion;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: ${({ theme }) => theme.zIndices.bottom};

  @media ${({ theme }) => theme.mediaQuery.medium} {
    display: none;
  }
`;

const AskQuestionContainer = styled.div`
  border-radius: 16px 16px 0px 0px;
  background: white;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: ${({ theme }) => theme.zIndices.topmost};
  display: block;
  max-height: 0px;
  transition: max-height 0.6s cubic-bezier(0.22, 1, 0.36, 1);

  &.open {
    max-height: 524px;
  }

  @media ${({ theme }) => theme.mediaQuery.medium} {
    position: sticky !important;
    max-width: 375px;
    border-radius: 16px;
    margin-left: 10px;
    box-shadow: 0px 9px 40px rgba(0, 0, 0, 0.03),
      0px 4.55625px 17.4375px rgba(0, 0, 0, 0.02025),
      0px 1.8px 6.5px rgba(0, 0, 0, 0.015),
      0px 0.39375px 2.3125px rgba(0, 0, 0, 0.00975);
    z-index: ${({ theme }) => theme.zIndices.middle};
    top: ${navBarHeight + askQuestionStickyBufferTop}px;
    &.open {
      max-height: none;
    }
  }
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: ${({ theme }) => theme.colorMap.gray.gray6};
  border-radius: 16px 16px 0px 0px;
  padding: 1rem 1.5rem;
`;

const FormContainer = styled.form`
  padding: 1rem 1.5rem;
  border-radius: 0px 0px 16px 16px;
`;

const Action = styled.div`
  width: 45px;
  height: 45px;
  border-radius: 100px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 16px;
  }
`;

const Rent = styled.div`
  display: flex;
  align-items: center;
  line-height: 0;
`;

const Actions = styled.div`
  display: flex;

  & > svg {
    border-radius: 100%;
    background-color: white;
  }
`;

const QuestionItem = styled.div<{ isSelected: boolean }>`
  background: ${({ isSelected, theme }) =>
    isSelected ? theme.color.primaryGreen : theme.colorMap.neutral.white};
  border: ${({ theme }) => `1px solid ${theme.color.primaryGreen}`};
  box-sizing: border-box;
  border-radius: 16px;
  padding: 0.75rem;
  margin-bottom: 12px;
  cursor: pointer;
  height: 48px;

  font-size: 1rem;
  font-weight: 600;
  color: ${({ isSelected, theme }) =>
    isSelected ? theme.colorMap.neutral.white : theme.color.primaryGreen};
  text-align: center;
`;

const StyledButton = styled(Button)<{ isSuccess: boolean }>`
  margin: 1.25rem auto 0;
  min-width: 100%;
  cursor: pointer;
  background: ${({ theme }) => theme.colorMap.neutral.black};
  height: 48px;
`;

const IconWrapper = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 100%;
  margin-top: 48px;
  margin-bottom: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
