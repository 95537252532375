import styled from '@emotion/styled';

export const SearchActionsContainer = styled.div`
  position: relative;
  padding: 1.5rem;
  width: 100%;
`;

export const SearchInput = styled.input`
  border: none;
  caret-color: ${({ theme }) => theme.color.primaryPink};
  margin-left: 8px;
  font-size: 1.25rem;
  width: calc(100% - 72px);

  &:focus {
    outline: none;

    &::placeholder {
      color: transparent;
    }
  }
`;

export const GoBackButton = styled.button`
  appearance: none;
  display: inline-block;
  background: white none;
  border: none;
  line-height: 1;
  font-size: 1em;
  padding: 1em 0.5em;
  border-radius: 1.5em;
  font-size: 0.875rem;
  font-weight: 500;
  text-decoration: none;
`;

export const RemoveTextButton = styled.button`
  background: ${({ theme }) => theme.colorMap.gray.gray5};
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 20px;
  top: 0;
  bottom: 0;
  margin: auto;

  svg {
    min-width: 26px !important;
  }

  @media ${({ theme }) => theme.mediaQuery.medium} {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    position: absolute;
    right: 0;
    left: 0;
    margin: auto;
    margin-right: 70px;
    top: 0;
    bottom: 0;
    z-index: ${({ theme }) => theme.zIndices.top};
  }
`;
