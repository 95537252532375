import React, { useEffect } from 'react';
import { VideoSwiperProvider, DiscoverProvider } from '../../hooks';
import DiscoverPage from './DiscoverPage';

const Discover: React.FC = () => {
  useEffect(() => {
    // Prevent scroll
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = '';
    };
  });

  return (
    <DiscoverProvider>
      <VideoSwiperProvider>
        <DiscoverPage />
      </VideoSwiperProvider>
    </DiscoverProvider>
  );
};

export default Discover;
