/**
 * TODO: In the future these should be backend driven.
 * Options will be rendered dynamically versus declared as an enum here
 */
export enum RentalAmenityKey {
  WASHER_DRYER = 'WASHER_DRYER',
  AIR_CONDITIONING = 'AIR_CONDITIONING',
  DOG_FRIENDLY = 'DOG_FRIENDLY',
  CAT_FRIENDLY = 'CAT_FRIENDLY',
  DISHWASHER = 'DISHWASHER',
  HEATING = 'HEATING',
  BACKYARD = 'BACKYARD',
  BALCONY_PATIO = 'BALCONY_PATIO',
  POOL = 'POOL',
  GYM = 'GYM',
  FURNISHED = 'FURNISHED',
}
