/* @jsxImportSource @emotion/react */

import React, { useState, useEffect, useContext, useRef } from 'react';
import styled from '@emotion/styled';
import Icon from '../../../components/Icon';
import { useTheme } from '@emotion/react';
import { ModalContext } from '../../../hooks/useModal';
import { useMediaQuery } from '../../../hooks';
import { CloseIconWrapper } from '../../../components/Modal/components';
import { getModalStyles } from '../../../components/Modal/utils';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import 'swiper/swiper.scss';
import {
  LEFT_ARROW_KEY_CODE,
  RIGHT_ARROW_KEY_CODE,
} from '../../../lib/constants';

type PhotoGalleryModalProps = {
  defaultImageUrl: string;
  imageUrls: Array<string>;
};

const CLICK_SLIDE_TRANSITION_MS = 500;
const MAX_PREVIEW_IMAGES = 6;

const PhotoGalleryModal: React.FC<PhotoGalleryModalProps> = ({
  defaultImageUrl,
  imageUrls,
}) => {
  const { handleCloseModal, setContentStyles } = useContext(ModalContext);
  const { colorMap, mediaQuery } = useTheme();
  const minWidthMedium = useMediaQuery(mediaQuery.medium);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [previewImages, setPreviewImages] = useState<string[]>([]);
  const [sortedImages] = useState<string[]>([
    defaultImageUrl,
    ...imageUrls.filter((url) => url !== defaultImageUrl),
  ]);
  const swiperRef = useRef<any>(null);

  const [selectedImageUrl, setSelectedImageUrl] = useState<string>(
    defaultImageUrl || imageUrls[0]
  );

  useEffect(() => {
    if (defaultImageUrl) setSelectedImageUrl(defaultImageUrl);
  }, [defaultImageUrl]);

  useEffect(() => {
    setContentStyles(getModalStyles(minWidthMedium, colorMap));
  }, [minWidthMedium, colorMap, setContentStyles]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.keyCode === LEFT_ARROW_KEY_CODE) {
        previous();
      } else if (e.keyCode === RIGHT_ARROW_KEY_CODE) {
        next();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  });

  const previous = () => {
    if (swiperRef?.current) {
      const previousIndex = selectedIndex - 1;
      const adjustedIndex =
        previousIndex < 0 ? sortedImages.length - 1 : previousIndex;

      setSelectedIndex(adjustedIndex);
      swiperRef.current.slideTo(adjustedIndex, CLICK_SLIDE_TRANSITION_MS);
    }
  };

  const next = () => {
    if (swiperRef?.current) {
      const nextIndex = selectedIndex + 1;
      const adjustedIndex = nextIndex < sortedImages.length ? nextIndex : 0;

      setSelectedIndex(adjustedIndex);
      swiperRef.current.slideTo(adjustedIndex, CLICK_SLIDE_TRANSITION_MS);
    }
  };

  useEffect(() => {
    const maxPreviewImagesHalved = MAX_PREVIEW_IMAGES / 2;
    const startingIndex = Math.max(
      0,
      selectedIndex < sortedImages.length - maxPreviewImagesHalved
        ? selectedIndex - maxPreviewImagesHalved
        : sortedImages.length - MAX_PREVIEW_IMAGES
    );
    const endingIndex = Math.min(
      sortedImages.length,
      startingIndex < 1
        ? Math.min(6, selectedIndex + (6 - startingIndex))
        : selectedIndex + 3
    );
    const previews = sortedImages.slice(startingIndex, endingIndex);
    setSelectedImageUrl(sortedImages[selectedIndex || 0]);
    setPreviewImages(previews);
  }, [selectedIndex, imageUrls, sortedImages]);

  const selectImageFromPreview = (url) => {
    if (swiperRef?.current) {
      const selectedImageIndex = sortedImages.indexOf(url);
      setSelectedIndex(selectedImageIndex);
      swiperRef.current.slideTo(selectedImageIndex, CLICK_SLIDE_TRANSITION_MS);
    }
  };

  return (
    <>
      <CloseIconWrapper
        aria-label={'close-icon-wrapper'}
        onClick={handleCloseModal}
      >
        <Icon color={colorMap.gray.gray6} width={36} height={36} name="close" />
      </CloseIconWrapper>
      <PhotoModalWrapper>
        <Swiper
          onInit={(swiper: any) => {
            swiperRef.current = swiper;
            swiper.init();
          }}
          slidesPerView={1}
          spaceBetween={0}
          allowTouchMove={false}
          simulateTouch={false}
          updateOnWindowResize={true}
          centeredSlides={true}
          centeredSlidesBounds={true}
          loop={false}
          normalizeSlideIndex={true}
          runCallbacksOnInit={false}
        >
          {sortedImages.map((url) => (
            <SwiperSlide key={`swiper-slide-${url}`}>
              <PhotoModalActiveImage key={url} src={url} alt="listing photo" />
            </SwiperSlide>
          ))}
        </Swiper>
        {previewImages.length > 1 ? (
          <React.Fragment>
            <IconWrapper>
              <IconArrowContainer onClick={previous}>
                <Icon
                  color={colorMap.neutral.white}
                  width={48}
                  height={48}
                  viewBox={`25 0 50 50`}
                  name="leftArrow"
                />
              </IconArrowContainer>
              <IconArrowContainer onClick={next}>
                <Icon
                  color={colorMap.neutral.white}
                  width={48}
                  height={48}
                  viewBox={`25 0 50 50`}
                  name="rightArrow"
                />
              </IconArrowContainer>
            </IconWrapper>
            <PhotoModalPreview>
              {previewImages.map((url, idx) => {
                return (
                  <PhotoModalPreviewImageContainer>
                    {url === selectedImageUrl ? (
                      <PhotoModalPreviewImageActive
                        key={`preview-image-active-${url}`}
                        src={url}
                        alt="listing preview photo active"
                      />
                    ) : (
                      <PhotoModalPreviewImage
                        onClick={() => selectImageFromPreview(url)}
                        key={`preview-image-${url}`}
                        src={url}
                        alt="listing preview photo"
                      />
                    )}
                  </PhotoModalPreviewImageContainer>
                );
              })}
            </PhotoModalPreview>
          </React.Fragment>
        ) : (
          ''
        )}
      </PhotoModalWrapper>
    </>
  );
};

export default PhotoGalleryModal;

const PhotoModalWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;

  .swiper {
    width: 100%;

    .swiper-wrapper {
      min-height: 500px;

      .swiper-slide {
        width: 100% !important;
      }
    }
  }
`;

const PhotoModalActiveImage = styled.img`
  width: 100%;
  height: 500px;
  object-fit: cover;
  border-radius: 1rem 1rem 0px 0px;
`;

const PhotoModalPreview = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 0.5rem 0 0 0;
`;

const PhotoModalPreviewImageContainer = styled.div`
  flex: 1;
  height: 120px;
  margin-left: 0.5rem;

  &:first-of-type {
    margin-left: 0;
  }

  &:first-of-type {
    img {
      border-bottom-left-radius: 1rem;
    }
  }

  &:last-of-type {
    img {
      border-bottom-right-radius: 1rem;
    }
  }
`;

const PhotoModalPreviewImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(0.7);
`;

const PhotoModalPreviewImageActive = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const IconWrapper = styled.div`
  position: absolute;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 65%;
  z-index: ${({ theme }) => theme.zIndices.topmost};
  width: 100%;

  button:nth-of-type(1) {
    margin-right: 1.2rem;
  }
`;

const IconArrowContainer = styled.button`
  background: none;
  border: none;
  padding: 0;

  svg {
    transition: hover 0.25s ease 0s;
    opacity: 1;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  &:disabled {
    svg {
      opacity: 0.6;
      pointer-events: none;
    }
  }
`;
