import styled from '@emotion/styled';

const searchPxBySize = {
  small: '36px',
  medium: '48px',
};

const searchSvgPxBySize = {
  small: '18px',
  medium: '24px',
};

const searchInputFontBySize = {
  small: '0.875rem',
  medium: '1.125rem',
};

const searchInputContainerBySize = (
  size: 'small' | 'medium'
): { width: string; height: string } => {
  return {
    small: {
      width: '327px',
      height: '46px',
    },
    medium: {
      width: '656px',
      height: '64px',
    },
  }[size];
};

export const SearchInputContainer = styled.div<{
  inputSize: 'small' | 'medium';
}>`
  position: relative;
  width: ${(props) => searchInputContainerBySize(props.inputSize).width};
  height: ${(props) => searchInputContainerBySize(props.inputSize).height};
  margin: 0 auto;
  z-index: ${({ theme }) => theme.zIndices.middle};

  &.fixed {
    position: fixed;
    top: 17px;
    bottom: 0px;
    right: 0px;
    left: 0px;
  }
`;

export const SearchInputElement = styled.input<{
  inputSize: 'small' | 'medium';
}>`
  width: 100%;
  height: 100%;
  padding: 0.75rem 1.25rem;
  background: ${({ theme }) => theme.colorMap.gray.gray6};
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.11);
  border-radius: 30px;
  border: none;
  line-height: 1.5rem;
  font-weight: 400;
  font-size: ${(props) => searchInputFontBySize[props.inputSize]};

  &:focus {
    outline: none;

    &::placeholder {
      color: transparent;
    }
  }
`;

export const SearchButton = styled.button<{ size: 'small' | 'medium' }>`
  position: absolute;
  top: 5px;
  bottom: 5px;
  right: 5px;
  border: none;
  border-radius: 100%;
  height: ${(props) => searchPxBySize[props.size]};
  width: ${(props) => searchPxBySize[props.size]};
  background: ${({ theme }) => theme.color.primaryPink};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    height: ${(props) => searchSvgPxBySize[props.size]};
    width: ${(props) => searchSvgPxBySize[props.size]};
  }

  @media ${({ theme }) => theme.mediaQuery.medium} {
    border-radius: 50%;
    top: 8px;
    bottom: 8px;
    right: 8px;
    height: ${(props) => searchPxBySize[props.size]};
    width: ${(props) => searchPxBySize[props.size]};
    margin: auto;

    svg {
      height: ${(props) => searchSvgPxBySize[props.size]};
      width: ${(props) => searchSvgPxBySize[props.size]};
    }
  }
`;
