import { ReactComponent as LinearGradientProfile } from './Icons/LinearGradientProfile.svg';
import { ReactComponent as Profile } from './Icons/Profile.svg';
import { ReactComponent as Heart } from './Icons/Heart.svg';
import { ReactComponent as Globe } from './Icons/Globe.svg';
import { ReactComponent as GlobeGradient } from './Icons/GlobeGradient.svg';
import { ReactComponent as Search } from './Icons/Search.svg';
import { ReactComponent as Gear } from './Icons/Gear.svg';
import { ReactComponent as Exit } from './Icons/Exit.svg';
import { ReactComponent as Caret } from './Icons/Caret.svg';
import { ReactComponent as Check } from './Icons/Check.svg';
import { ReactComponent as Video } from './Icons/Video.svg';
import { ReactComponent as Photo } from './Icons/Photo.svg';
import { ReactComponent as Error } from './Icons/Error.svg';
import { ReactComponent as Edit } from './Icons/Edit.svg';
import { ReactComponent as Close } from './Icons/Close.svg';
import { ReactComponent as LeftArrow } from './Icons/LeftArrow.svg';
import { ReactComponent as RightArrow } from './Icons/RightArrow.svg';
import { ReactComponent as Send } from './Icons/Send.svg';
import { ReactComponent as Messaging } from './Icons/Messaging.svg';
import { ReactComponent as Email } from './Icons/Email.svg';
import { ReactComponent as AddUser } from './Icons/AddUser.svg';
import { ReactComponent as Pin } from './Icons/Pin.svg';
import { ReactComponent as House } from './Icons/House.svg';
import { ReactComponent as DownChevron } from './Icons/DownChevron.svg';
import { ReactComponent as UpChevron } from './Icons/UpChevron.svg';
import { ReactComponent as Play } from './Icons/Play.svg';
import { ReactComponent as ExcalmationCircle } from './Icons/ExcalmationCircle.svg';
import { ReactComponent as Slider } from './Icons/Slider.svg';
import { ReactComponent as SoundOn } from './Icons/SoundOn.svg';
import { ReactComponent as SoundOff } from './Icons/SoundOff.svg';
import { ReactComponent as UnicornLogo } from './Icons/UnicornLogo.svg';
import { ReactComponent as Review } from './Icons/Review.svg';
import { ReactComponent as Furnished } from './Icons/Furnished.svg';
import { ReactComponent as WelcomeSearchIcon } from './Icons/WelcomeSearchIcon.svg';
import { ReactComponent as Calendar } from './Icons/Calendar.svg';
import { ReactComponent as HouseRentalType } from './Icons/HouseRentalType.svg';
import { ReactComponent as CondoRentalType } from './Icons/CondoRentalType.svg';
import { ReactComponent as CoopRentalType } from './Icons/CoopRentalType.svg';
import { ReactComponent as TownhouseRentalType } from './Icons/TownhouseRentalType.svg';
import { ReactComponent as ApartmentRentalType } from './Icons/ApartmentRentalType.svg';
import { ReactComponent as EntirePlace } from './Icons/EntirePlace.svg';
import { ReactComponent as PrivateRoom } from './Icons/PrivateRoom.svg';
import { ReactComponent as SharedRoom } from './Icons/SharedRoom.svg';
import { ReactComponent as LeftChevron } from './Icons/LeftChevron.svg';
import { ReactComponent as Tree } from './Icons/Tree.svg';
import { ReactComponent as Leaf } from './Icons/Leaf.svg';
import { ReactComponent as Filter } from './Icons/Filter.svg';

const icons = {
  profile: Profile,
  heart: Heart,
  globe: Globe,
  globeGradient: GlobeGradient,
  linearGradientProfile: LinearGradientProfile,
  search: Search,
  gear: Gear,
  exit: Exit,
  caret: Caret,
  check: Check,
  video: Video,
  photo: Photo,
  error: Error,
  edit: Edit,
  close: Close,
  leftArrow: LeftArrow,
  rightArrow: RightArrow,
  send: Send,
  messaging: Messaging,
  email: Email,
  addUser: AddUser,
  pin: Pin,
  house: House,
  upChevron: UpChevron,
  downChevron: DownChevron,
  play: Play,
  excalmationCircle: ExcalmationCircle,
  slider: Slider,
  soundOn: SoundOn,
  soundOff: SoundOff,
  unicornLogo: UnicornLogo,
  review: Review,
  furnished: Furnished,
  welcomeSearchIcon: WelcomeSearchIcon,
  calendar: Calendar,
  houseRentalType: HouseRentalType,
  apartmentRentalType: ApartmentRentalType,
  condoRentalType: CondoRentalType,
  coopRentalType: CoopRentalType,
  townhouseRentalType: TownhouseRentalType,
  entirePlace: EntirePlace,
  sharedRoom: SharedRoom,
  privateRoom: PrivateRoom,
  leftChevron: LeftChevron,
  leaf: Leaf,
  tree: Tree,
  filter: Filter,
};

export const getIcon = (name) => icons[name];
