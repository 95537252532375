import React from 'react';

import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import styled from '@emotion/styled';
import GoogleMapReact from 'google-map-react';

import MapMarker from '../../../components/MapMarker';
import Heading, {
  HeadingElementTypes,
} from '../../../components/Typography/Heading';
import Label, { LabelTypes } from '../../../components/Typography/Label';
import Button from '../../../components/UI/Button';
import Paragraph, {
  ParagraphVariant,
} from '../../../components/Typography/Paragraph';
import { colorMap } from '../../../lib/theme';
import { WeightTypes } from '../../../components/Typography/utils';
import { useConfig } from '../../../hooks/useConfig';
import { Listing } from '../../../types';
import { useApiClient, useMediaQuery } from '../../../hooks';
import IfLoading from '../../../components/IfLoading';
import { abbreviateState } from '../../../lib/utils';
import { useTheme } from '@emotion/react';

const Dashboard: React.FC = () => {
  const history = useHistory();
  const client = useApiClient();

  const { user, userListing } = useConfig();
  const { mediaQuery } = useTheme();
  const minWidthMedium = useMediaQuery(mediaQuery.medium);

  const { data: listingDetails, isLoading: listingDetailsLoading } = useQuery<
    {},
    unknown,
    Listing
  >(
    [
      `listing-details-${!!user}-${userListing?.listingId}`,
      { listingId: userListing?.listingId },
    ],
    () => client(`search/by-id?id=${userListing?.listingId}`),
    {
      refetchOnWindowFocus: false,
      enabled: !!userListing,
    }
  );

  const handleSearch = () => {
    history.push('/listings');
  };

  const handleAddHome = () => {
    history.push('/listings/add');
  };

  const handleEditListing = (event) => {
    event.preventDefault();

    if (listingDetails?.listingId) {
      history.push('/list-your-home/edit');
    }
  };

  const hasNoUserListing = !userListing;

  return (
    <DashboardContainer>
      <ClickToAction>
        <Heading
          type={HeadingElementTypes.h5}
          color={colorMap.neutral.white}
          styles={{ margin: 0, marginBottom: '0.25rem' }}
        >
          Welcome home!
        </Heading>

        <Label
          type={LabelTypes.l4}
          color={colorMap.neutral.white}
          weight={WeightTypes.semiBold}
          styles={{ marginBottom: '1.5rem' }}
        >
          Consider this your homebase, a place to manage anything and everything
          about your rental
        </Label>

        <Button onClick={handleSearch}>
          <Label
            type={LabelTypes.l5}
            color={colorMap.neutral.black}
            weight={WeightTypes.semiBold}
          >
            Start searching
          </Label>
        </Button>
      </ClickToAction>

      <Heading
        type={HeadingElementTypes.h6}
        styles={{ margin: '0 1rem 1.5rem' }}
      >
        My home
      </Heading>

      <DashboardContent>
        {hasNoUserListing && (
          <AddHomeCta>
            <Label type={LabelTypes.l2}>No home on file...yet!</Label>
            <Paragraph
              variant={ParagraphVariant.xLarge}
              color={colorMap.gray.gray2}
              styles={{ width: '280px', textAlign: 'center' }}
            >
              It takes less than 2 minutes to add a review of your home
            </Paragraph>

            <Button
              variant="primary"
              color="primaryPink"
              onClick={handleAddHome}
            >
              <Label type={LabelTypes.l2}>Add your home</Label>
            </Button>
          </AddHomeCta>
        )}

        {!hasNoUserListing && (
          <IfLoading isLoading={listingDetailsLoading}>
            <StyledLink to={`/listings/${listingDetails?.listingId}`}>
              <ListingCover listingImage={listingDetails?.imageUrls[0]}>
                <GradientOverlay />
                <ListingDetails>
                  <ListingContent>
                    <Label
                      type={LabelTypes.l3}
                      color={colorMap.neutral.white}
                      weight={WeightTypes.semiBold}
                    >
                      {listingDetails?.address.address1}
                    </Label>
                    <Label
                      type={LabelTypes.l5}
                      color={colorMap.neutral.white}
                      weight={WeightTypes.semiBold}
                    >
                      {listingDetails?.address.city},{' '}
                      {abbreviateState(listingDetails?.address.state)}{' '}
                      {listingDetails?.address.postalCode}
                    </Label>
                  </ListingContent>

                  <Button
                    onClick={handleEditListing}
                    color="white"
                    variant="primary"
                    style={{
                      width: '80px',
                      height: '30px',
                      minHeight: 'unset',
                    }}
                  >
                    <Label
                      type={LabelTypes.l5}
                      color={colorMap.neutral.black}
                      weight={WeightTypes.bold}
                    >
                      Edit
                    </Label>
                  </Button>
                </ListingDetails>
              </ListingCover>
            </StyledLink>
          </IfLoading>
        )}

        {minWidthMedium && (
          <IfLoading isLoading={listingDetailsLoading}>
            <MapContainer>
              <GoogleMapReact
                resetBoundsOnResize
                center={{
                  lat: listingDetails?.approximateLocation?.latitude,
                  lng: listingDetails?.approximateLocation?.longitude,
                }}
                zoom={15}
                options={{
                  draggable: false,
                  disableDefaultUI: true,
                  keyboardShortcuts: false,
                }}
              >
                <MapMarker
                  lat={listingDetails?.approximateLocation?.latitude}
                  lng={listingDetails?.approximateLocation?.longitude}
                />
              </GoogleMapReact>
            </MapContainer>
          </IfLoading>
        )}
      </DashboardContent>
    </DashboardContainer>
  );
};

export default Dashboard;

const DashboardContainer = styled.div`
  padding-bottom: 4rem;
`;

const ClickToAction = styled.div`
  padding: 1rem;
  background: linear-gradient(90deg, #ff70a3 0%, #fc4c9b 49.88%, #ff3d82 100%);
  border-radius: 1rem;
  margin: 1.5rem 1rem;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    padding: 3rem;
  }
`;

const AddHomeCta = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 2.375rem 1.5rem 1.5rem;
`;

const DashboardContent = styled.div`
  margin: 1rem;
  background-color: ${colorMap.gray.gray6};
  border-radius: 1rem;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    display: flex;
  }
`;

const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

const GradientOverlay = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  background: linear-gradient(
    0deg,
    rgba(32, 32, 32, 0.45) -40.7%,
    rgba(32, 32, 32, 0) 83.06%
  );

  border-radius: 1rem;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

const ListingCover = styled.div<{ listingImage: string | undefined }>`
  position: relative;

  background: ${({ listingImage }) => `url(${listingImage}) no-repeat center`};
  background-size: cover;
  height: 75vw;
  width: 100%;
  border-radius: 1rem;

  display: flex;
  align-items: flex-end;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    height: 282px;
    width: 375px;

    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

const ListingDetails = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 1rem;

  z-index: 1;
`;

const ListingContent = styled.div``;

const MapContainer = styled.div`
  height: 282px;
  width: calc(100% - 375px);

  > div > div {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
`;
