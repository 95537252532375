import React from 'react';
import { getIcon } from './iconSelector';

type DefaultIconType = {
  name: string;
  color?: string;
  width?: number;
  height?: number;
};

const DefaultIcon: React.FC<DefaultIconType> = ({
  name,
  color,
  width = 36,
  height = 36,
}) => {
  const IconComponent = getIcon(name);
  return <IconComponent width={width} height={height} color={color} />;
};

export default DefaultIcon;
