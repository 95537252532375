import { SwiperOptions } from 'swiper/types';
import { MediaQuerySize } from '../../../lib/theme';

export const DEFAULT_VIDEO_WIDTH = 264;
export const DEFAULT_VIDEO_HEIGHT = 400;
export const MIN_VIDEO_HEIGHT = 348;
export const MIN_VIDEO_WIDTH = 230;

export const POPULAR_LISTINGS_PAGINATION_LIMIT = 10;
export const DEFAULT_MAX_VIDEOS_SHOWN = 5;

export const SWIPER_BREAKPOINTS: {
  [width: number]: SwiperOptions;
} = {
  // min
  300: {
    slidesPerView: 1.5,
    spaceBetween: 0,
  },
  // xsmall
  375: {
    slidesPerView: 1.2,
    spaceBetween: 0,
  },
  // small
  576: {
    slidesPerView: 2,
    spaceBetween: 0,
  },
  // medium
  768: {
    slidesPerView: 3,
    spaceBetween: 10,
  },
  // large
  1024: {
    slidesPerView: 4,
    spaceBetween: 10,
  },
  // xlarge
  1200: {
    slidesPerView: 4,
    spaceBetween: 10,
  },
  // max
  1440: {
    slidesPerView: 5,
    spaceBetween: 10,
  },
};

export const SWIPER_BREAKPOINT_BY_SIZE: {
  [key in MediaQuerySize]: number;
} = {
  min: 300,
  xsmall: 375,
  small: 576,
  medium: 768,
  large: 1024,
  xlarge: 1200,
  max: 1440,
};
