import { setSessionStorage } from './session-storage';
import { encodeBody } from './utils';

export class ApiUnauthorizedError extends Error {}
export type HTTPMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

export type FetchOptions = {
  method?: HTTPMethod;
  body?: Record<string, any>;
  headers?: Record<string, string>;
  paginationCacheKey?: string;
};

type ApiRequestOptions = {
  token: string;
  userId?: string;
};

function snakeCaseToCamelCase(data) {
  // base case: pass through primitive values
  switch (typeof data) {
    case 'string':
    case 'boolean':
    case 'number':
      return data;
    case 'object':
      if (data === null) return data;
  }

  // for arrays, map over values in order to convert objects
  if (Array.isArray(data)) return data.map(snakeCaseToCamelCase);

  return Object.entries(data)
    .map(([k, v]: [string, any]): [string, any] => [
      k.replace(/_(.)/g, (_, $1) => $1.toUpperCase()),
      snakeCaseToCamelCase(v),
    ])
    .reduce((acc, [k, v]) => Object.assign(acc, { [k]: v }), {});
}

// Safari does not support lookbehind assertion in regex,
// @see https://caniuse.com/js-regexp-lookbehind
// so this function takes some extra steps to deal with slashes in URLs.
// Admittedly this may be over-engineered, but it's worth it so that
// devs can `request('path/to/resource')` and `request('/path/to/resource')`.
const buildUrl = (path: string): string =>
  (process.env.REACT_APP_UNICORN_API_HOST || '').replace(/\/$/g, '') +
  `/${path}`.replace(/\/{2,}/, '/');

function request(
  path: string,
  {
    method = 'GET',
    body,
    headers,
    paginationCacheKey,
    ...options
  }: FetchOptions = {},
  { token, userId }: ApiRequestOptions
) {
  const isGetRequest = method.toLowerCase() === 'get';

  const query: string = isGetRequest && body ? '?' + encodeBody(body) : '';

  return fetch(buildUrl(path + query), {
    method,
    body: isGetRequest ? null : JSON.stringify(body),
    ...options,
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
      ...(userId ? { 'X-Unicorn-Id': userId } : null),
    },
  })
    .then((response) => {
      if (!response.ok) {
        if (response.status === 401) {
          return Promise.reject(new ApiUnauthorizedError());
        }
        return Promise.reject(new Error(response.statusText));
      }

      return response;
    })
    .then((response) => {
      // store the header results in localStorage for access in the components
      const paginationOffset =
        response.headers.get('x-unicorn-pagination-offset') || '';
      const paginationTotal =
        response.headers.get('x-unicorn-pagination-total') || '';

      if (paginationOffset || paginationTotal) {
        const baseKey = paginationCacheKey
          ? `${path}?${paginationCacheKey}`
          : `${path}${query}`;

        setSessionStorage(`${baseKey}-pagination-offset`, paginationOffset);
        setSessionStorage(`${baseKey}-pagination-total`, paginationTotal);
      }

      return response.json();
    })
    .then(snakeCaseToCamelCase);
}

export { request, snakeCaseToCamelCase };
