import { css, Global } from '@emotion/react';
import { UnicornTheme } from './lib/theme';

type GlobalStylesProps = {
  theme: UnicornTheme;
};

const getStyles = (theme) => css`
  html {
    box-sizing: border-box;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
    font-family: 'ZichtbaarRegular', -apple-system, BlinkMacSystemFont,
      'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
      'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #ffffff;
  }

  #root {
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    // Need this for position sticky on ask a question
    overflow-x: hidden;
    overflow-y: scroll;
  }

  @media ${theme.mediaQuery.medium} {
    #root {
      overflow-x: visible;
      overflow-y: visible;
    }
  }

  a,
  a:visited {
    color: ${theme.color.primaryPink};
    font-weight: bold;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  .visually-hidden {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  figure {
    margin: 0;
  }

  .grecaptcha-badge {
    visibility: hidden;
  }
`;

const GlobalStyles = ({ theme }: GlobalStylesProps) => (
  <Global styles={getStyles(theme)} />
);

export default GlobalStyles;
