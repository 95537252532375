import React from 'react';

export const getModalStyles = (
  minWidthMedium: boolean,
  colorMap: Record<string, Record<string, string>>,
  styleOverrides?: {
    mediumScreen?: React.CSSProperties;
    smallScreen?: React.CSSProperties;
  }
): React.CSSProperties => {
  if (minWidthMedium) {
    return {
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      margin: 'auto',
      width: '800px',
      height: '631px',
      backgroundColor: `${colorMap.neutral.white}`,
      WebkitOverflowScrolling: 'touch',
      outline: 'none',
      padding: '1.25rem',
      borderRadius: '20px 20px 20px 20px',
      zIndex: 2,
      overflow: 'visible',
      ...styleOverrides?.mediumScreen,
    };
  }

  return {
    position: 'fixed',
    bottom: '0',
    left: '0',
    margin: 'auto',
    width: '100%',
    height: '510px',
    inset: 'auto 0px 0px',
    backgroundColor: `${colorMap.neutral.white}`,
    outline: 'none',
    padding: '0px',
    borderRadius: '32px 32px 0px 0px',
    zIndex: 2,
    overflow: 'visible',
    ...styleOverrides?.smallScreen,
  };
};
