/* @jsxImportSource @emotion/react */

import React from 'react';
import styled from '@emotion/styled';
import { VideoListingDetails } from '../../../types';
import {
  abbreviateState,
  availDisplay,
  bathsDisplay,
  bedsDisplay,
  monthlyRent,
} from '../../../lib/utils';
import Button from '../../../components/UI/Button';
import { useConditionalWindow } from '../../../hooks/useConditionalWindow';
import { DISCOVER_PADDING_REM } from '../constants';

type ListingDetailsButtonLink = {
  target: string;
  linkTo: string;
};

type VideoDetailsProps = {
  videoDetails: VideoListingDetails;
  buttonLink: ListingDetailsButtonLink;
};

const VideoDetails: React.FC<VideoDetailsProps> = ({
  videoDetails,
  buttonLink: { target, linkTo },
}) => {
  const window = useConditionalWindow();
  const onClick = () => window.open(linkTo, target);

  return (
    <VideoDetailsWrapper>
      <Container>
        <Price>{monthlyRent(videoDetails.monthlyRent)}</Price>
        <Details>
          <DetailsText>{bedsDisplay(videoDetails.numBedrooms)}</DetailsText>
          <Divider>|</Divider>
          <DetailsText>{bathsDisplay(videoDetails.numBathrooms)}</DetailsText>
          <Divider>|</Divider>
          <DetailsText>{availDisplay(videoDetails.availableAt)}</DetailsText>
        </Details>
        <Details>
          <DetailsText>{videoDetails.rentalType}</DetailsText>&nbsp;in&nbsp;
          <DetailsText>{videoDetails.city}</DetailsText>,&nbsp;
          <DetailsText>{abbreviateState(videoDetails.state)}</DetailsText>
        </Details>
        <ListingDetailsButton
          onClick={onClick}
          variant={'primary'}
          size={'medium'}
          color="primaryPink"
        >
          Listing Details
        </ListingDetailsButton>
      </Container>
    </VideoDetailsWrapper>
  );
};

export default VideoDetails;

const VideoDetailsWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: ${DISCOVER_PADDING_REM}rem;
  color: ${({ theme }) => theme.colorMap.neutral.white};
  position: relative;
`;

const Container = styled.div`
  height: 100%;
  width: 100%;
`;

const Price = styled.span`
  font-weight: 700;
  font-size: 1.15rem;
  margin: 0.5rem 0;
  top: 0;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    font-size: 1.25rem;
  }
`;

const Details = styled.div`
  font-weight: 700;
  margin: 0.5rem 0;
  font-size: 1rem;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    font-size: 1rem;
  }
`;

const DetailsText = styled.span`
  text-transform: capitalize;
`;

const Divider = styled.span`
  margin-left: 0.3rem;
  margin-right: 0.3rem;
`;

const ListingDetailsButton = styled(Button)`
  align-self: flex-end;
  margin: 1rem 0 0 0;
  border: none;
  z-index: ${({ theme }) => theme.zIndices.top};
`;
