/* @jsxImportSource @emotion/react */
import React, { ReactElement, useContext } from 'react';
import { ShareListingButtonWrapper } from './components';
import { ModalContext } from '../../hooks/useModal';
import { ShareListingModal } from '.';

export type ShareListingProps = {
  listingId?: string;
  title?: string;
  button: ReactElement;
};

export interface ShareListingButton extends ReactElement {}

const ShareListing = ({ listingId, title, button }: ShareListingProps) => {
  const { handleOpenModal, setModalContent } = useContext(ModalContext);

  const handleClick = () => {
    handleOpenModal();
    setModalContent(() => (
      <ShareListingModal title={title} listingId={listingId} />
    ));
  };

  return (
    <>
      <ShareListingButtonWrapper
        aria-label="ShareListingButton"
        onClick={handleClick}
      >
        {button}
      </ShareListingButtonWrapper>
    </>
  );
};

export default ShareListing;
