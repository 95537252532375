import React from 'react';
import styled from '@emotion/styled';

import Button from '../UI/Button';

import { ReactComponent as UnicornLogo } from './images/unicorn-logo.svg';
import { ReactComponent as Instagram } from './images/instagram.svg';
import { ReactComponent as Tiktok } from './images/tiktok.svg';
import { ReactComponent as EqualHousing } from './images/house.svg';

import { termsOfServiceUrl, privacyPolicyUrl } from '../../lib/constants';
import PageContentWrapper from '../PageContentWrapper';

const inviteFriend = () => {
  const sms =
    'sms:&body=Check%20out%20Unicorn%20🦄%20LA’s%20most%20exclusive%20apartment%20rental%20platform.%20https://www.rentunicorn.com/';
  window?.open(sms, '_blank')?.focus();
};

const Footer: React.FC = () => {
  return (
    <StyledFooter>
      <PageContentWrapper>
        <LinkSection>
          <Description>
            <UnicornLogo />
            <StyledParagraph>
              Unicorn is a video-first apartment listing platform, powered by
              creators.
            </StyledParagraph>

            <Button
              onClick={inviteFriend}
              type="button"
              variant="secondary"
              color="primaryGreen"
            >
              Invite a friend
            </Button>
          </Description>
          {/* TODO: Update Footer Links and Social Media Links */}
          <Menu>
            <StyledSection>
              <StyledMenuHeading>About</StyledMenuHeading>
              <StyledMenu>
                <StyledMenuItem>
                  <a
                    href="https://rentunicorn.typeform.com/to/xYiZ2bos"
                    target="_blank"
                    rel="noreferrer"
                    className="footer-link"
                  >
                    Careers
                  </a>
                </StyledMenuItem>
                <StyledMenuItem>
                  <a
                    href="https://rentunicorn.typeform.com/to/W17RIkva"
                    target="_blank"
                    rel="noreferrer"
                    className="footer-link"
                  >
                    Become a Scout
                  </a>
                </StyledMenuItem>
              </StyledMenu>
            </StyledSection>

            <StyledSection>
              <StyledMenuHeading>Support</StyledMenuHeading>
              <StyledMenu>
                <StyledMenuItem>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="mailto:support@rentunicorn.com?subject=Unicorn%20Support"
                    className="footer-link"
                  >
                    Contact
                  </a>
                </StyledMenuItem>
                <StyledMenuItem>
                  <a
                    href="https://www.jotform.com/form/213430332418142"
                    target="_blank"
                    rel="noreferrer"
                    className="footer-link"
                  >
                    Add a Listing
                  </a>
                </StyledMenuItem>
              </StyledMenu>
            </StyledSection>

            <StyledSection>
              <StyledMenuHeading>Follow Us</StyledMenuHeading>
              <SocialMedia>
                <a
                  href="https://www.instagram.com/rentunicorn/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Instagram />
                </a>

                <a
                  href="https://www.tiktok.com/@rentunicorn"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Tiktok />
                </a>
              </SocialMedia>
            </StyledSection>
          </Menu>
        </LinkSection>

        <HorizontalRule />

        <DisclaimerSection>
          <StyledSection className="equal-housing-terms-conditions">
            <div>
              <EqualHousingDisclaimer>
                <EqualHousing />
                <BottomText className="equal-housing">
                  Equal Housing Opportunity
                </BottomText>
              </EqualHousingDisclaimer>

              <BottomText className="copyright">
                Copyright © {new Date().getFullYear()}. Unicorn. All rights
                reserved.
              </BottomText>
            </div>
            <BottomText className="terms-conditions">
              <a className="terms-conditions-link" href={termsOfServiceUrl}>
                Terms &amp; Conditions
              </a>
              &nbsp;•&nbsp;
              <a className="privacy-link" href={privacyPolicyUrl}>
                Privacy Policy
              </a>
            </BottomText>
          </StyledSection>
        </DisclaimerSection>
      </PageContentWrapper>
    </StyledFooter>
  );
};

const StyledFooter = styled.footer`
  background-color: ${({ theme }) => theme.color.primaryGreen};
  padding: 3.375rem 1.5rem 2.5rem;
  width: 100%;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    padding: 3.125rem 6.25rem 3.125rem;
  }
`;

const StyledParagraph = styled.p`
  width: 315px;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 400;
  color: white;
  margin-top: 14px;
  margin-bottom: 24px;
`;

const StyledSection = styled.section`
  margin-top: 40px;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    flex: 1;

    &.equal-housing-terms-conditions {
      width: 100%;
      display: inline-flex;
      align-items: flex-end;
      justify-content: space-between;
    }
  }

  @media ${({ theme }) => theme.mediaQuery.large} {
    margin-top: 0;
    flex: 1;

    &.equal-housing-terms-conditions {
      width: 100%;
      display: inline-flex;
      align-items: flex-end;
      justify-content: space-between;
    }
  }
`;

const LinkSection = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  @media ${({ theme }) => theme.mediaQuery.large} {
    flex-direction: row;
    align-items: flex-start;
  }
`;

const DisclaimerSection = styled.div`
  width: 100%;
  margin: 0 auto;
`;

const Description = styled.div`
  @media ${({ theme }) => theme.mediaQuery.medium} {
    flex: 1 1 35%;
  }
`;

const Menu = styled.div`
  @media ${({ theme }) => theme.mediaQuery.medium} {
    display: flex;
    flex: 1 1 65%;
  }
`;

const StyledMenuHeading = styled.h4`
  color: ${({ theme }) => theme.color.tertiaryGreen};
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    margin-top: 0;
  }
`;

const StyledMenu = styled.ul`
  padding-left: 0;
  list-style: none;
  margin-top: 0;
  min-width: 125px;
`;

const StyledMenuItem = styled.li`
  color: white;
  font-size: 1rem;
  font-weight: 400;
  margin-top: 20px;

  .footer-link {
    color: white;
    font-weight: inherit;
  }
`;

const SocialMedia = styled.div`
  margin-top: 20px;
`;

const HorizontalRule = styled.hr`
  color: ${({ theme }) => theme.colorMap.neutral.white};
  border: 1px solid;
  opacity: 0.25;
  margin-top: 40px;
  margin-bottom: 26px;
  margin-left: auto;
  margin-right: auto;
`;

const EqualHousingDisclaimer = styled.div`
  display: inline-flex;
  align-items: center;
  margin-top: -16px;
`;

const BottomText = styled.p`
  color: ${({ theme }) => theme.colorMap.gray.gray6};
  font-size: 0.75rem;
  font-weight: 400;
  opacity: 0.5;

  .terms-conditions-link,
  .privacy-link {
    color: inherit;
    font-weight: inherit;
  }

  &.equal-housing {
    width: 100px;
    margin-left: 20px;
  }

  &.copyright {
    margin-bottom: 0;
  }

  &.terms-conditions {
    margin-top: 4px;
    margin-bottom: 0;
  }
`;

export default Footer;
