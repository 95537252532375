import React, { useContext } from 'react';
import { Listing } from '../../../types';
import {
  SectionHeading,
  DescriptionParagraph,
  OverviewReadMoreLink,
  StyledSection,
} from '../components';
import IfLoading from '../../../components/IfLoading';
import { ModalContext } from '../../../hooks/useModal';
import FullDescriptionModal from './FullDescriptionModal';

const MAX_CHAR_LIMIT = 350;

const truncatedTextAtEndOfSentenceOrWord = (str: string) => {
  const truncated = str.substring(0, MAX_CHAR_LIMIT);
  const nearestSentenceIdx = truncated.lastIndexOf('.');
  const nearestWordIdx = truncated.lastIndexOf(' ');
  const cutOffIndex =
    nearestSentenceIdx < nearestWordIdx ? nearestWordIdx : nearestSentenceIdx;

  return truncated.substring(0, cutOffIndex) + '...';
};

const Overview: React.FC<{ isLoading: boolean; listingDetails?: Listing }> = ({
  isLoading,
  listingDetails,
}) => {
  const { handleOpenModal, setModalContent } = useContext(ModalContext);
  const truncateText = (listingDetails?.description?.length || 0) > 300;
  const description =
    truncateText && listingDetails?.description !== undefined
      ? truncatedTextAtEndOfSentenceOrWord(listingDetails.description)
      : listingDetails?.description;

  const openModal = () => {
    setModalContent(() => (
      <FullDescriptionModal description={listingDetails?.description || ''} />
    ));
    handleOpenModal();
  };

  return (
    <React.Fragment>
      {description && (
        <StyledSection data-testid="listing-description">
          <SectionHeading>Overview</SectionHeading>
          <IfLoading
            isLoading={isLoading}
            loadMultiple={false}
            numLoaders={1}
            height={165}
            width="100%"
          >
            <DescriptionParagraph>{description}</DescriptionParagraph>
            {truncateText ? (
              <OverviewReadMoreLink onClick={() => openModal()}>
                Read more
              </OverviewReadMoreLink>
            ) : (
              ''
            )}
          </IfLoading>
        </StyledSection>
      )}
    </React.Fragment>
  );
};

export default Overview;
