import { useMutation, useQueryClient } from 'react-query';

import { Listing, Sentiment } from '../types';

import { useApiClient } from './index';
import { useAuth } from './useAuth';

type SentimentUpsertResponse = {
  sentiment: Sentiment;
  listingId: string;
};

const getToggledSentiment = (l: Listing) =>
  l.sentiment === Sentiment.positive ? Sentiment.unknown : Sentiment.positive;

export function useToggleSentimentMutation(queryKey: string, queryParams?: {}) {
  const { userId } = useAuth();
  const apiRequest = useApiClient();
  const queryClient = useQueryClient();

  return useMutation(
    (listing: Listing): Promise<SentimentUpsertResponse> =>
      apiRequest('/listing-sentiment/upsert', {
        method: 'PUT',
        body: {
          user_id: userId,
          created_by: userId,
          listing_id: listing.listingId,
          sentiment: getToggledSentiment(listing),
        },
      }),
    {
      // optimistically update on toggle
      async onMutate(listing: Listing) {
        await queryClient.cancelQueries(queryKey);

        const optimisticListing = {
          ...listing,
          ...{ sentiment: getToggledSentiment(listing) },
        };

        const queryKeys: Array<string | Record<string, string>> = [queryKey];

        // Note: not all requests will have queryParams
        if (queryParams) {
          queryKeys.push(queryParams);
        }

        queryClient.setQueryData(queryKeys, (previous) =>
          !Array.isArray(previous)
            ? optimisticListing
            : previous.map((l) =>
                l.listingId !== listing.listingId ? l : optimisticListing
              )
        );

        return { optimisticListing };
      },
      // set to actual value on success
      onSuccess(result: SentimentUpsertResponse, _vars, context) {
        queryClient.setQueryData([queryKey, queryParams], (previous) => {
          return !Array.isArray(previous)
            ? previous
            : previous.map((l) =>
                l.listingId !== result.listingId
                  ? l
                  : { ...(l as Listing), ...result }
              );
        });
      },
      // reset to previous value when the mutation errors
      onError(_err, _vars, previousValue) {
        queryClient.setQueryData([queryKey, queryParams], previousValue);
      },

      // refetch when the mutation succeeds (this should be effectively a no-op)
      onSettled() {
        queryClient.invalidateQueries([queryKey, queryParams]);
      },
    }
  );
}
