import React, { useEffect } from 'react';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import { useDiscover, useMediaQuery, useVideoSwiper } from '../../../hooks';
import {
  VideoContent,
  VideoLoading,
  DesktopVideoSection,
  DesktopWrapper,
  Pagination,
  PaginationIconWrapper,
  MapSection,
  MobileWrapper,
  DiscoverPageDesktop,
  DiscoverPageContentWrapper,
  DiscoverPageMobile,
} from '../components';
import {
  MAX_PAGINATION_PAGES,
  cacheKeyByFeed,
  CLICK_SLIDE_TRANSITION_MS,
  DISCOVER_MIN_HEIGHT_MEDIA_QUERY,
} from '../constants';
import { discoverFeeds, extractMapLocation } from '../utils';
import { DiscoverFeed } from '../../../lib/services/discover';
import VideoCarousel from '../VideoSwiper';
import DiscoverButtons from '../DiscoverButtons';
import VideoLoader from '../VideoLoader';
import { DefaultIcon } from '../../../components/Icon';
import MapView from '../MapView';
import { getNextPageParam, getPreviousPageParam, handleNext } from './helpers';
import { useTheme } from '@emotion/react';
import { Helmet } from 'react-helmet';
import { DOWN_ARROW_KEY_CODE, UP_ARROW_KEY_CODE } from '../../../lib/constants';

const DiscoverPage: React.FC = () => {
  const queryClient = useQueryClient();
  const { mediaQuery } = useTheme();
  const minWidthMedium = useMediaQuery(mediaQuery.medium);
  const minHeight = useMediaQuery(
    `(min-height: ${DISCOVER_MIN_HEIGHT_MEDIA_QUERY}px)`
  );

  const {
    feed,
    index,
    offset,
    limit,
    location,
    hasReachedEnd,
    hasLocationResults,
    isLoading,
    swiperRef,
    handlers,
  } = useDiscover();

  const {
    requiresUserInteraction,
    handlers: { play, handleUserInteraction, handleVideoIsPlaying, mute },
  } = useVideoSwiper();

  const fetchVideos = ({ pageParam = 0 }) => {
    return handlers.videos(feed, pageParam, limit);
  };

  const {
    data: paginatedResults,
    isLoading: isQueryLoading,
    fetchNextPage,
    fetchPreviousPage,
  } = useInfiniteQuery([cacheKeyByFeed[feed], { location }], fetchVideos, {
    refetchOnWindowFocus: false,
    keepPreviousData: false,
    enabled: hasLocationResults,
    getPreviousPageParam: () => getPreviousPageParam(offset, limit),
    getNextPageParam: () => getNextPageParam(offset, limit, hasReachedEnd),
  });

  const videos = paginatedResults ? paginatedResults.pages.flat() : undefined;
  const videoPageParams = paginatedResults
    ? (paginatedResults.pageParams as number[])
    : [];

  const handleIndexChange = React.useCallback(
    (idx: number, currentVideoId: string) => {
      handlers.updateIndex(idx);
      swiperRef.current.slideTo(idx, 0, false);

      if (!requiresUserInteraction) {
        play(currentVideoId);
      }
    },
    [handlers, play, swiperRef, requiresUserInteraction]
  );

  useEffect(() => {
    if (
      paginatedResults &&
      paginatedResults.pages.length > MAX_PAGINATION_PAGES
    ) {
      const {
        pages,
        pageParams,
        currentVideoId,
        index: adjustedIndex,
      } = handleNext(index, paginatedResults);

      queryClient.setQueryData([cacheKeyByFeed[feed], { location }], () => {
        return {
          pages,
          pageParams,
        };
      });
      handleIndexChange(adjustedIndex, currentVideoId);
    }
  }, [
    paginatedResults,
    handleIndexChange,
    feed,
    index,
    limit,
    location,
    queryClient,
  ]);

  const { latitude: lat, longitude: lng } = extractMapLocation(
    videos ? videos[index] : undefined
  );

  const onReset = async (promise: Promise<void>) => {
    // Show loading on feed restart
    handlers.setLoading(true);
    // Doesn't play when switching feeds on iOS so trigger user interaction
    if (!minWidthMedium) {
      mute();
      handleVideoIsPlaying(false);
      handleUserInteraction(false);
    }
    promise.then(() => {
      queryClient.resetQueries([cacheKeyByFeed[feed], { location }]);
      swiperRef.current.slideTo(0, 0, false);
      handlers.setLoading(false);
    });
  };

  const reset = () => {
    onReset(Promise.resolve(handlers.restartFeed()));
  };

  const changeFeed = (feed: DiscoverFeed) => {
    onReset(Promise.resolve(handlers.changeFeed(feed)));
  };
  // Only show on desktop. Arrow button prev
  const clickPrevSlide = () => {
    const hasPrevSlide = videos && index > 0;

    if (swiperRef?.current && hasPrevSlide) {
      swiperRef.current.slidePrev(CLICK_SLIDE_TRANSITION_MS);
    }
  };
  // Only show on desktop. Arrow button next
  const clickNextSlide = () => {
    // We add an additional slide to the end of the swiper once we have reached the end of the feed.
    // Need to check if the hasReachedEnd boolean is true
    const hasNextSlide =
      (videos && index < videos.length - 1) ||
      (index < videos!!.length && hasReachedEnd);

    if (swiperRef?.current && hasNextSlide) {
      swiperRef.current.slideNext(CLICK_SLIDE_TRANSITION_MS);
    }
  };

  const getCurrentPageParam = (idx: number) => {
    const { pageParams }: { pageParams: any[] } = paginatedResults || {
      pageParams: [],
    };

    const currentPageParam =
      pageParams.length === 0 || pageParams.includes(undefined)
        ? offset
        : pageParams[idx];

    return currentPageParam;
  };

  const prev = () => {
    const pageParamIndex = 0;
    const pageParam = getCurrentPageParam(pageParamIndex);

    fetchPreviousPage({ pageParam: getPreviousPageParam(pageParam, limit) });
  };

  const next = () => {
    const { pageParams }: { pageParams: any[] } = paginatedResults || {
      pageParams: [],
    };
    const pageParamIndex = pageParams.length - 1;
    const pageParam = getCurrentPageParam(pageParamIndex);

    fetchNextPage({
      pageParam: getNextPageParam(pageParam, limit, hasReachedEnd),
    });
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.keyCode === UP_ARROW_KEY_CODE) {
        clickPrevSlide();
      } else if (e.keyCode === DOWN_ARROW_KEY_CODE) {
        clickNextSlide();
      }
    };
    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  });

  return minWidthMedium && minHeight ? (
    <DiscoverPageDesktop className="fullbleed">
      <DiscoverPageContentWrapper padding="0px 0px">
        <DesktopWrapper>
          <DesktopVideoSection>
            <VideoContent>
              {isLoading ||
                (isQueryLoading && (
                  <VideoLoading>
                    <VideoLoader />
                  </VideoLoading>
                ))}
              <DiscoverButtons
                activeFeed={feed}
                handleFeedClick={changeFeed}
                feeds={discoverFeeds}
              />
              <VideoCarousel
                videos={videos}
                videoPageParams={videoPageParams}
                allowSwipe={false}
                reset={reset}
                prev={prev}
                next={next}
              />
            </VideoContent>
          </DesktopVideoSection>
          <Pagination>
            <PaginationIconWrapper onClick={clickPrevSlide}>
              <DefaultIcon name="upChevron" />
            </PaginationIconWrapper>
            <PaginationIconWrapper onClick={clickNextSlide}>
              <DefaultIcon name="downChevron" />
            </PaginationIconWrapper>
          </Pagination>
          <MapSection>
            <MapView lat={lat} lng={lng} />
          </MapSection>
        </DesktopWrapper>
      </DiscoverPageContentWrapper>
    </DiscoverPageDesktop>
  ) : (
    <DiscoverPageMobile className="fullbleed">
      <Helmet>
        <meta name="theme-color" content="black" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black" />
      </Helmet>
      <DiscoverPageContentWrapper padding="0px 0px">
        <MobileWrapper>
          <VideoContent>
            {isLoading ||
              (isQueryLoading && (
                <VideoLoading>
                  <VideoLoader />
                </VideoLoading>
              ))}
            <DiscoverButtons
              activeFeed={feed}
              handleFeedClick={changeFeed}
              feeds={discoverFeeds}
            />
            <VideoCarousel
              videos={videos}
              videoPageParams={videoPageParams}
              allowSwipe={true}
              reset={reset}
              prev={prev}
              next={next}
            />
          </VideoContent>
        </MobileWrapper>
      </DiscoverPageContentWrapper>
    </DiscoverPageMobile>
  );
};

export default DiscoverPage;
