import styled from '@emotion/styled';
import { css } from '@emotion/react';
import {
  RadioInput as BaseRadioInput,
  CheckboxInput as BaseCheckboxInput,
} from '../../../components/UI/Form';

export { TextInput } from '../../../components/UI/Form';

export const HeaderContainer = styled.div`
  margin: 0 -1.5rem;
  padding: 6rem 1.5rem;
  background: linear-gradient(180deg, #ff70a3 0%, #fc4c9b 49.88%, #ff3d82 100%);
  color: ${({ theme }) => theme.colorMap.neutral.white};

  @media ${({ theme }) => theme.mediaQuery.medium} {
    color: ${({ theme }) => theme.colorMap.neutral.black};
    margin: 0;
    padding: 10rem 0 1rem;
    background: transparent none;
  }
`;

export const FieldsContainer = styled.div`
  @media ${({ theme }) => theme.mediaQuery.medium} {
    max-width: 413px;
  }
`;

export const ImageContainer = styled.div`
  display: none;
  @media ${({ theme }) => theme.mediaQuery.medium} {
    display: block;
    align-self: stretch;
    height: 632px;
  }
`;

export const FieldSingle = styled.div`
  display: grid;
  min-width: 10px;
  width: 100%;
`;

export const FieldDivider = styled.div`
  min-height: 1px;
  min-width: 1x;
  padding: 1rem 0;
`;

export const FieldGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(10px, 1fr));
  grid-template-rows: auto;
  column-gap: 1em;
`;

export const Label = styled.label<{ color?: string }>`
  color: ${({ color }) => color || 'black'};
  display: block;
  font-size: 1.125rem;
  line-height: 1.4;
  font-weight: 600;
  margin-top: 24px;
  margin-bottom: 12px;
`;

export const SecondaryLabel = styled.div`
  color: ${({ theme }) => theme.colorMap.gray.gray2};
  font-size: 0.875rem;
  line-height: 1.5;
  margin-top: -12px;
  margin-bottom: 12px;
`;

export const RadioInput = styled(BaseRadioInput)`
  margin: 0.375em 0;
`;

export const CheckboxInput = styled(BaseCheckboxInput)`
  margin: 0.375em 0;
`;

export const datePickerStyles = (
  theme,
  isDateSelected,
  selectedAriaLabel
) => css`
  &.react-datepicker {
    border: none;
    font-family: inherit;
    font-size: 1em;
    border-radius: 0;
    display: block;
    padding: 1rem;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__month-container {
    float: none;
  }

  .react-datepicker__header {
    background: transparent none;
    border: none;
    border-radius: none;
  }

  .react-datepicker__month {
    margin: 0;
  }

  .react-datepicker__day-names,
  .react-datepicker__week {
    display: flex;
    justify-content: space-between;
  }

  .react-datepicker__day-names {
    margin-top: 1em;
  }

  .react-datepicker__week {
    margin: 0.25rem 0;
  }

  .react-datepicker__day-name,
  .react-datepicker__day {
    margin: 0;
    width: 2em;
    line-height: 2em;
    font-weight: 500;
  }

  .react-datepicker__day-name {
    color: #4f4f4f;
  }

  .react-datepicker__day:hover,
  .react-datepicker__day:focus,
  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    border-radius: 1em;
  }

  .react-datepicker__day--keyboard-selected {
    background-color: #ffffff;
    color: #000;
  }

  .react-datepicker__day--outside-month {
    color: #828282;
  }

  [aria-label='Choose ${selectedAriaLabel}'] {
    color: ${isDateSelected ? '#ffffff' : '#000'};
    background-color: ${isDateSelected ? theme.color.primaryPink : '#ffffff'};
    border-radius: 1em;

    &.react-datepicker__day--outside-month {
      color: ${isDateSelected ? '#ffffff' : '#000'};
      background-color: ${isDateSelected ? theme.color.primaryPink : '#ffffff'};
    }
  }
`;

export const StyledDatePickerButton = styled.button`
  background: ${({ theme }) => `${theme.colorMap.gray.gray5}`};
  border-color: ${({ theme }) => `${theme.colorMap.gray.gray5}`};
  border: none;
  border-radius: 16px;
  width: 190px;
  height: 56px;
  color: ${({ theme }) => `${theme.colorMap.gray.gray1}`};
  text-align: center;
  font-size: 1rem;
  line-height: 1.4375rem;
  font-family: inherit;

  &::-webkit-inner-spin-button,
  &::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
`;

export const FieldGroup = styled.div`
  padding-bottom: 48px;
  border-bottom: ${({ theme }) => `1px 0 ${theme.colorMap.gray.gray4}`};
`;

export const LeaseStartEndDateButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const LeaseEndButton = styled.div`
  margin-left: 0;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    margin-left: 0.75rem;
  }
`;

export const StyledErrorContainer = styled.div`
  position: relative;
  min-height: 20px;
`;
