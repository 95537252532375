import styled from '@emotion/styled';
import React, { MouseEventHandler } from 'react';
import { DefaultIcon } from '../Icon';

export type VideoPlayButtonProps = {
  showButton: boolean;
  handleOnClick?: MouseEventHandler<HTMLElement>;
};

const VideoPlayButton: React.FC<VideoPlayButtonProps> = ({
  showButton,
  handleOnClick,
}) => {
  return (
    <PlayButton
      onClick={handleOnClick}
      style={{ display: showButton ? 'flex' : 'none' }}
    >
      {showButton && <DefaultIcon width={50} height={50} name="slider" />}
    </PlayButton>
  );
};

export default VideoPlayButton;

const PlayButton = styled.div`
  margin: auto;
  border: none;
  background-color: transparent;
  position: absolute;
  z-index: ${({ theme }) => theme.zIndices.top};
  cursor: pointer;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  height: 50px;
  width: 50px;
  visibility: visible !important;
  opacity: 0.85;
  transition: hover 0.25s ease-in-out;
  outline-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 1;
  }
`;
