import type { apiClient } from '../../hooks';
import { VideoListingDetails } from '../../types';

export type DiscoverFeed = 'local' | 'popular';

export const DEFAULT_PROXIMITY_METERS = 804672; // 500 mile large radius for now until there's more videos

export type DiscoverService = {
  byProximity: (
    lat: number,
    lon: number,
    offset: number,
    limit: number,
    proximity: number
  ) => Promise<VideoListingDetails[]>;
  byPopular: (offset: number, limit: number) => Promise<VideoListingDetails[]>;
};

export const discoverServiceFactory = (
  apiClient: apiClient
): DiscoverService => ({
  byProximity: (
    lat,
    lon,
    offset = 0,
    limit = 10,
    proximity
  ): Promise<VideoListingDetails[]> =>
    apiClient<VideoListingDetails[]>(`/discover/public/by-proximity`, {
      body: { lat, lon, offset, limit, proximity },
    }),
  byPopular: (offset = 0, limit = 10): Promise<VideoListingDetails[]> =>
    apiClient<VideoListingDetails[]>(`/discover/public/by-popular`, {
      body: { offset, limit },
    }),
});
