import React from 'react';
import styled from '@emotion/styled';
import FormError from './Error';

export type TextInputProps = Omit<React.HTMLProps<HTMLInputElement>, 'as'> & {
  error?: { message: string } | string;
  styleType?: 'default' | 'round';
};

const StyledDefaultInput = styled.input`
  appearance: none;
  border: none;
  border-bottom: 1px solid lightgray;
  width: 100%;
  padding: 0.25rem 0;
  font-size: 1em;

  transition: border-bottom-color 0.25s linear;

  &:focus {
    outline: 0;
    border-bottom-color: black;
  }

  &::placeholder {
    color: lightgray;
  }

  &.error,
  &.error:focus {
    color: ${({ theme }) => theme.color.error};
  }
`;

const StyledRoundInput = styled.input`
  background: #f4f4f4;
  border-radius: 16px;
  appearance: none;
  border: none;
  width: 100%;
  padding: 1rem 1.5rem;
  font-size: 1em;
`;

const inputTypes = {
  default: StyledDefaultInput,
  round: StyledRoundInput,
};

const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  ({ styleType = 'default', error, className, ...props }, ref) => {
    const message =
      error && (typeof error === 'string' ? error : error.message);

    const classNames = [message && 'error', className]
      .filter(Boolean)
      .join(' ');

    const StyledInput = inputTypes[styleType];

    return (
      <>
        <StyledInput className={classNames} ref={ref} {...props} />
        {message && <FormError message={message} />}
      </>
    );
  }
);

export default TextInput;
