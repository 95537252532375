import styled from '@emotion/styled';

import LeftArrow from '../images/leftArrow.svg';
import RightArrow from '../images/rightArrow.svg';

export const SearchViewContainer = styled.div`
  padding: 0 1.5rem;
  height: calc(var(--vh, 1vh) * 100);
  height: 100vh;
  box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.05),
    0px 5.39259px 7.64444px rgba(0, 0, 0, 0.0303704),
    0px 1.14074px 1.95556px rgba(0, 0, 0, 0.0196296);

  > div {
    display: flex;
    justify-content: center;
    min-height: 440px;
  }

  .react-datepicker {
    border: none;
    width: 426px;
    top: 32px;
    height: 100%;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    color: black;
    width: 32px;
    height: 32px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 0.875rem;
    outline: none;
    margin: 0;
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--selected {
    background-color: ${({ theme }) => theme.color.primaryPink};
    border-radius: 40px;
    color: white;
  }

  .react-datepicker__day:hover {
    border-radius: 40px;
  }

  .react-datepicker__header {
    background-color: transparent;
    border: none;
  }

  .react-datepicker__current-month {
    text-align: center;
    color: ${({ theme }) => theme.color.primaryPink};
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 24px;
  }

  .react-datepicker__navigation--previous {
    background: url(${LeftArrow}) no-repeat center;
    width: 32px;
    height: 32px;
    border: none;
    box-shadow: 0px 10px 9px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    left: 0;
    top: 160px;
  }

  .react-datepicker__navigation--next {
    background: url(${RightArrow}) no-repeat center;
    width: 32px;
    height: 32px;
    border: none;
    box-shadow: 0px 10px 9px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    right: 0;
    top: 160px;
  }

  .react-datepicker__day--outside-month {
    visibility: hidden;
  }

  .react-datepicker__day-name,
  .react-datepicker__day--disabled {
    color: ${({ theme }) => theme.colorMap.gray.gray2};
  }

  .react-datepicker__month-container {
    padding-left: 48px;
    float: left;
  }

  @media ${({ theme }) => theme.mediaQuery.medium} {
    height: unset;
    background: white;
    position: absolute;
    width: 670px;
    top: 0;
    z-index: ${({ theme }) => theme.zIndices.bottom};
    border-radius: 40px;
    overflow: hidden;
    padding-top: 75px;
    padding-bottom: 0;
    opacity: 0;

    &.hide {
      height: 0;
      opacity: 0;
    }

    &.show {
      display: block;
      opacity: 1;
      overflow: visible;
      height: fit-content;

      transition-property: height, opacity;
      transition-duration: 0.25s, 0.25s;
    }

    .react-datepicker__current-month {
      padding-left: 64px;
      text-align: left;
      font-size: 1.375rem;
      margin-bottom: 0;
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      width: 46px;
      height: 46px;
    }

    .react-datepicker__month-container {
      padding-left: 0;
      float: none;
    }
  }
`;

export const SearchViewQuestion = styled.h4`
  text-align: center;
  font-size: 1.625rem;
  margin-bottom: 8px;
`;

export const SuggestionList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

export const SuggestionItem = styled.li`
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  padding: 1.5rem;

  svg {
    margin-right: 16px;
  }
`;

export const RecentHeading = styled.h5`
  font-weight: 400;
  font-size: 0.875rem;
  padding: 0 1.5rem;
`;

export const RecentList = styled.ul`
  list-style-type: none;
  padding: 0 1.5rem;

  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  background-color: none;
  border-radius: 100%;
  margin-right: 16px;
`;

export const RecentListItem = styled.li`
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  padding: 1.5rem 0;
  cursor: pointer;
  width: 100%;
  border-radius: 16px;
  height: 64px;

  &:hover {
    background-color: ${({ theme }) => theme.colorMap.gray.gray5};
  }

  @media ${({ theme }) => theme.mediaQuery.medium} {
    height: auto;
    padding: 1rem 0;
  }
`;
