import styled from '@emotion/styled';
import { maxWidth } from '../../lib/constants';
import { ConditionalLink } from '../ConditionalLink';

export const navBarHeight = 80;

export const NavBarWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colorMap.neutral.white};
  box-shadow: 0px -3px 20px rgba(0, 0, 0, 0.11);
  width: 100%;
  padding: 0.25rem 1.5rem 0.75rem;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  z-index: ${({ theme }) => theme.zIndices.top};

  @media ${({ theme }) => theme.mediaQuery.medium} {
    position: fixed;
    top: 0;
    bottom: auto;
    width: 100%;
    height: ${navBarHeight}px;
    border-radius: 0px 0px 30px 30px;
    padding: 0.25rem 1.5rem 1.25rem;
    background: ${({ theme }) => theme.colorMap.gray.gray6};
    box-shadow: 0px -3px 20px rgba(0, 0, 0, 0.11);
    z-index: ${({ theme }) => theme.zIndices.top};
  }
`;

export const StyledNavBar = styled.nav`
  width: 100%;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: ${navBarHeight}px;
    max-width: ${maxWidth}px;
    margin: auto;
    padding: 0 7%;
    box-shadow: none;
  }
`;

export const SemiCircle = styled.div`
  height: 84px;
  width: 96px;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.colorMap.neutral.white};
  position: absolute;
  top: -20px;
  right: 0;
  z-index: ${({ theme }) => theme.zIndices.bottommost};
  box-shadow: 28px -36px 40px 0px rgb(0 0 0 / 11%);

  button {
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    top: 8px;
    border: none;
    background: transparent;
  }

  @media ${({ theme }) => theme.mediaQuery.medium} {
    display: none;
  }
`;

export const StyledLink = styled(ConditionalLink)`
  text-decoration: none;
  color: ${({ theme }) => theme.colorMap.gray.gray1};
  display: flex;
  flex-direction: column;
  align-items: center;
  outline: none;

  &:visited {
    color: inherit;
  }

  &:hover {
    text-decoration: none;
  }
`;

export const LogoWrapper = styled(ConditionalLink)`
  display: none;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    display: block;
    width: 150px;
    margin-right: 50px;

    svg {
      max-width: 100%;
    }
  }
`;

export const MenuList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MenuItem = styled.li<{ isActive: boolean }>`
  svg {
    fill: none;

    color: ${({ isActive, theme }) =>
      isActive ? theme.color.primaryPink : theme.colorMap.gray.gray4};

    path,
    ellipse {
      stroke: ${({ isActive, theme }) =>
        isActive ? theme.color.primaryPink : theme.colorMap.gray.gray2};
    }
  }

  @media ${({ theme }) => theme.mediaQuery.medium} {
    margin-left: 50px;
    width: 40px;
    height: 40px;
    border-radius: 48px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const MenuItemText = styled.p`
  line-height: 1.125rem;
  margin-bottom: 0;
  margin-top: 0;
  font-size: 0.625rem;
  font-weight: 500;
  color: ${({ theme }) => theme.color.primaryPink};
  position: absolute;
`;

export const AccountSubMenu = styled.ul`
  z-index: ${({ theme }) => theme.zIndices.middle};
  width: 217px;
  height: fit-content;
  border-radius: 20px;
  position: absolute;
  list-style: none;
  padding: 0;
  bottom: ${navBarHeight}px;
  right: 8px;
  background: white;
  margin: 0;
  margin-bottom: 1.25rem;
  box-shadow: 0px 9px 40px rgba(0, 0, 0, 0.03),
    0px 4.55625px 17.4375px rgba(0, 0, 0, 0.02025),
    0px 1.8px 6.5px rgba(0, 0, 0, 0.015),
    0px 0.39375px 2.3125px rgba(0, 0, 0, 0.00975);

  @media ${({ theme }) => theme.mediaQuery.medium} {
    top: 88px;
    right: 80px;
  }
`;

export const SubMenuItem = styled.li<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  background-color: ${({ isActive }) =>
    isActive ? 'rgba(255, 112, 163, 0.1)' : 'inherit'};
  padding: 1rem 1.25rem;
  cursor: ${({ isActive }) => (isActive ? 'default' : 'pointer')};

  &:first-of-type {
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
  }

  &:last-of-type {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }
`;

export const Overlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: ${({ theme }) => theme.zIndices.bottom};
`;

export const SubMenuText = styled.p`
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
  margin-left: 28px;
`;

export const SearchContainer = styled.div`
  margin-top: 32px;

  .search-view__desktop {
    position: relative;
  }

  @media ${({ theme }) => theme.mediaQuery.medium} {
    margin-top: unset;

    li {
      width: 100%;
    }
  }
`;
