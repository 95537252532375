import React, { useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import Label, { LabelTypes } from '../../../components/Typography/Label';
import Paragraph, {
  ParagraphVariant,
} from '../../../components/Typography/Paragraph';
import { WeightTypes } from '../../../components/Typography/utils';
import {
  useConfig,
  useMediaQuery,
  useMessagesByListingId,
  useSetMessageRead,
  useUserByIds,
} from '../../../hooks';
import MessageDetail from './MessageDetail';
import { useTheme } from '@emotion/react';
import ListingTile from '../../../components/ListingTile';
import IfLoading from '../../../components/IfLoading';
import { Listing } from '../../../types';
import { UserInfo } from '../../../hooks/useConfig';
import useSearchById from '../../../hooks/useSearchById';

export type MessageProps = {
  id: string;
  submittedAt: string;
  respondedAt: string | null;
  userId: string;
  listingId: string;
  message: string;
  readAt: string;
  mobileNumber: string;
  firstName: string | null;
  lastName: string | null;
};

const Messaging: React.FC = () => {
  const { userListing } = useConfig();

  const [messageToShow, setMessageToShow] = useState<MessageProps | null>(null);
  const { mediaQuery } = useTheme();
  const minWidthMedium = useMediaQuery(mediaQuery.medium);

  const [senderUserIds, setSenderUserIds] = useState<Set<string>>(new Set());

  const [hydratedUserInbox, setHydratedUserInbox] = useState<
    Array<MessageProps & UserInfo>
  >([]);

  const { data: userInbox } = useMessagesByListingId(
    `messages-by-listing-id-${userListing?.listingId}`,
    userListing?.listingId,
    {
      enabled: !!userListing?.listingId,
      onSuccess: (data) => {
        const senderUserIds: Set<string> = new Set();
        (data as Array<MessageProps>).forEach(({ userId }) => {
          senderUserIds.add(userId);
        });

        setSenderUserIds(senderUserIds);
      },
    }
  );

  const {
    data: listingDetails,
    isLoading: listingDetailsLoading,
  } = useSearchById({ userListing });

  useUserByIds(
    `user-details-${listingDetails?.listingId}`,
    Array.from(senderUserIds),
    {
      enabled: !!senderUserIds.size,
      onSuccess: (data) => {
        const usersDetailMap = (data as Array<UserInfo>).reduce(
          (map, currUser) => {
            map[currUser.id] = currUser;
            return map;
          },
          {}
        );

        const hydratedUserInbox = (userInbox || []).map((message) => {
          return {
            ...usersDetailMap[message.userId],
            ...message,
          };
        });

        setHydratedUserInbox(hydratedUserInbox);
      },
    }
  );

  const handleShowMessageDetails = (message: MessageProps) => {
    setMessageToShow(message);

    if (!message.readAt) {
      setMessageRead(message);
    }
  };

  const { mutate: setMessageRead } = useSetMessageRead(
    `messages-by-listing-id-${userListing?.listingId}`
  );

  if (!minWidthMedium && messageToShow) {
    return (
      <MessageDetail
        {...((messageToShow as unknown) as MessageProps)}
        onBack={() => setMessageToShow(null)}
      />
    );
  }

  return (
    <Container>
      <LeftSection>
        {hydratedUserInbox?.map((message) => {
          return (
            <MessageTile
              onClick={() => handleShowMessageDetails(message)}
              key={message.id}
              data-testid={`message-${message.id}`}
            >
              <MessageContent
                showActiveMessage={
                  (messageToShow || { id: null }).id === message.id
                }
              >
                {!message.readAt &&
                (messageToShow || { id: null }).id !== message.id ? (
                  <UnreadIndicator />
                ) : (
                  <Placeholder />
                )}
                <PreviewContent>
                  <Label type={LabelTypes.l5} weight={WeightTypes.bold}>
                    {message.firstName} {message.lastName}
                  </Label>

                  <Paragraph
                    variant={ParagraphVariant.small}
                    weight={WeightTypes.light}
                    styles={{
                      margin: 0,
                    }}
                  >
                    {message.message}
                  </Paragraph>
                </PreviewContent>
              </MessageContent>
            </MessageTile>
          );
        })}
      </LeftSection>

      {minWidthMedium && (
        <CenterSection isInitialState={!messageToShow}>
          {messageToShow ? (
            <MessageDetail
              {...((messageToShow as unknown) as MessageProps)}
              onBack={() => setMessageToShow(null)}
            />
          ) : (
            <Paragraph variant={ParagraphVariant.medium}>
              No message selected.
            </Paragraph>
          )}
        </CenterSection>
      )}

      {minWidthMedium && (
        <RightSection>
          <IfLoading
            isLoading={listingDetailsLoading && !!listingDetails}
            width={327}
            height={368}
          >
            {listingDetails?.listingId && (
              <ListingTile
                listingId={listingDetails?.listingId}
                data={listingDetails as Listing}
              />
            )}
          </IfLoading>
        </RightSection>
      )}
    </Container>
  );
};

export default Messaging;

const Container = styled.div`
  display: flex;
`;

const LeftSection = styled.div`
  @media ${({ theme }) => theme.mediaQuery.medium} {
    width: 327px;
  }

  width: 100%;
`;

const CenterSection = styled.div<{ isInitialState: boolean }>`
  flex-grow: 1;

  ${({ isInitialState }) =>
    isInitialState &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
    `}
`;

const RightSection = styled.div`
  max-width: 350px;
`;

const PreviewContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
`;

const UnreadIndicator = styled.div`
  width: 12px;
  height: 12px;
  background-color: ${({ theme }) => theme.colorMap.green.green3};
  border-radius: 100%;
`;

const Placeholder = styled.div`
  width: 12px;
  height: 12px;
`;

const MessageTile = styled.div`
  padding: 12px;
  border-bottom: 1px solid #f4f4f4;

  cursor: pointer;
`;

const MessageContent = styled.div<{ showActiveMessage?: boolean }>`
  ${({ showActiveMessage, theme }) =>
    showActiveMessage &&
    css`
      border-left: 3px solid ${theme.colorMap.green.green3};
    `}

  display: flex;
  column-gap: 0.75rem;
  align-items: center;
`;
