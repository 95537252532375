import { AppEnv, APP_ENV } from './app-env';
import Hotjar from '@hotjar/browser';

const loadHotJar = () => {
  if (APP_ENV === AppEnv.PROD) {
    const siteId = 3088643;
    const hotjarVersion = 6;
    Hotjar.init(siteId, hotjarVersion);
  }
};
export default loadHotJar;
