import { useCallback, useEffect, useState } from 'react';
import Heading, {
  HeadingElementTypes,
} from '../../../components/Typography/Heading';
import { WeightTypes } from '../../../components/Typography/utils';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { useMediaQuery, useService } from '../../../hooks';
import { Location, VideoListingDetails } from '../../../types';
import Button from '../../../components/UI/Button';
import Skeleton from '../../../components/UI/Skeleton/Skeleton';
import { rangeTo } from '../../../lib/utils';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
// Styles must use direct files imports
import 'swiper/swiper.scss';
import { useHistory } from 'react-router';
import { MediaQuerySize } from '../../../lib/theme';
import {
  DEFAULT_MAX_VIDEOS_SHOWN,
  DEFAULT_VIDEO_HEIGHT,
  DEFAULT_VIDEO_WIDTH,
  MIN_VIDEO_HEIGHT,
  MIN_VIDEO_WIDTH,
  POPULAR_LISTINGS_PAGINATION_LIMIT,
  SWIPER_BREAKPOINTS,
  SWIPER_BREAKPOINT_BY_SIZE,
} from './constants';
import VideoPreview from './VideoPreview';
import { useQuery } from 'react-query';
import { DEFAULT_QUERY_CACHE_STALE_TIME } from '../../../lib/constants';

type PopularListingsProps = {
  location: Location;
};

const MobileVideos: React.FC = ({ children }) => {
  return (
    <Swiper
      key={'popular-listings-swiper'}
      slidesPerView={1}
      spaceBetween={0}
      allowTouchMove={true}
      simulateTouch={true}
      updateOnWindowResize={true}
      centeredSlides={false}
      loop={true}
      breakpoints={SWIPER_BREAKPOINTS}
      normalizeSlideIndex={true}
    >
      {children}
    </Swiper>
  );
};

const DesktopVideos: React.FC = ({ children }) => {
  return <VideosContainer>{children}</VideosContainer>;
};

const PopularListings: React.FC<PopularListingsProps> = ({ location }) => {
  const [maxVideosToShow, setMaxVideosToShow] = useState<number>();
  const discoverService = useService<'discover'>('discover');
  const history = useHistory();
  // Media Queries
  const { mediaQuery } = useTheme();
  const minWidthMin = useMediaQuery(mediaQuery.min);
  const minWidthXsmall = useMediaQuery(mediaQuery.xsmall);
  const minWidthSmall = useMediaQuery(mediaQuery.small);
  const minWidthMedium = useMediaQuery(mediaQuery.medium);
  const minWidthLarge = useMediaQuery(mediaQuery.large);
  const minWidthXlarge = useMediaQuery(mediaQuery.xlarge);
  const minWidthMax = useMediaQuery(mediaQuery.max);

  const maxVideosPerBreakpoint = useCallback(() => {
    let size: MediaQuerySize;

    if (minWidthMax) {
      size = 'max';
    } else if (minWidthXlarge) {
      size = 'xlarge';
    } else if (minWidthLarge) {
      size = 'large';
    } else if (minWidthMedium) {
      size = 'medium';
    } else if (minWidthSmall) {
      size = 'small';
    } else if (minWidthXsmall) {
      size = 'xsmall';
    } else if (minWidthMin) {
      size = 'min';
    } else {
      // Happens when videos first load - break and show nothing
      return 0;
    }
    return (
      (SWIPER_BREAKPOINTS[SWIPER_BREAKPOINT_BY_SIZE[size]]
        ?.slidesPerView as number) || DEFAULT_MAX_VIDEOS_SHOWN
    );
  }, [
    minWidthMin,
    minWidthXsmall,
    minWidthSmall,
    minWidthMedium,
    minWidthLarge,
    minWidthXlarge,
    minWidthMax,
  ]);

  const { data: videos, isLoading } = useQuery<{}, {}, VideoListingDetails[]>(
    [
      `marketplace-popular-listings`,
      {
        latitude: location.latitude,
        longitude: location.longitude,
      },
    ],
    () => discoverService.byPopular(0, POPULAR_LISTINGS_PAGINATION_LIMIT),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: DEFAULT_QUERY_CACHE_STALE_TIME,
    }
  );

  const renderVideos = (showCarousel: boolean) => {
    return (
      <>
        {isLoading && (
          <SkeletonContainer>
            {rangeTo(
              1,
              // Be careful when refactoring this code:
              // We need to round because we use doubles to partially show a video in the mobile swiper
              Math.round(maxVideosToShow || DEFAULT_MAX_VIDEOS_SHOWN)
            ).map((_, idx) => (
              <Skeleton
                css={{ borderRadius: '16px' }}
                key={`skeleton-${idx}`}
              />
            ))}
          </SkeletonContainer>
        )}
        {!isLoading &&
          videos &&
          maxVideosToShow &&
          (showCarousel
            ? videos.map((video) => (
                <SwiperSlide key={`${video.listingId}-${video.videoUrl}`}>
                  <VideoPreview
                    video={video}
                    redirectOnClick={toListingDetailsPage}
                  ></VideoPreview>
                </SwiperSlide>
              ))
            : videos
                .slice(0, maxVideosToShow)
                .map((video) => (
                  <VideoPreview
                    key={`${video.listingId}-${video.videoUrl}`}
                    video={video}
                    redirectOnClick={toListingDetailsPage}
                  ></VideoPreview>
                )))}
      </>
    );
  };

  const toListingDetailsPage = (listingId: string) => {
    const linkTo = `/listings/${listingId}`;

    if (minWidthMedium) {
      window.open(linkTo, '_blank');
    } else {
      history.push(linkTo);
    }
  };

  const toDiscoverPage = () => {
    const linkTo = `/discover`;

    if (minWidthMedium) {
      window.open(linkTo, '_blank');
    } else {
      history.push(linkTo);
    }
  };

  useEffect(() => {
    const maxVideos = maxVideosPerBreakpoint();
    setMaxVideosToShow(maxVideos);
  }, [
    maxVideosPerBreakpoint,
    minWidthXsmall,
    minWidthSmall,
    minWidthMedium,
    minWidthLarge,
    minWidthXlarge,
    minWidthMax,
  ]);

  return (
    <PopularListingsContainer>
      <PopularListingsHeader>
        <Heading
          weight={WeightTypes.semiBold}
          type={
            minWidthMedium ? HeadingElementTypes.h3 : HeadingElementTypes.h5
          }
        >
          Popular listings
        </Heading>
        <ViewAllButton
          onClick={toDiscoverPage}
          variant="secondary"
          color="primaryPink"
          size="small"
        >
          View all
        </ViewAllButton>
      </PopularListingsHeader>
      {minWidthMedium ? (
        <DesktopVideos>{renderVideos(false)}</DesktopVideos>
      ) : (
        <MobileVideos>{renderVideos(true)}</MobileVideos>
      )}
    </PopularListingsContainer>
  );
};

export default PopularListings;

const PopularListingsContainer = styled.div`
  position: relative;
  width: 100%;
  padding-left: 30px;

  @media ${({ theme }) => theme.mediaQuery.small} {
    padding-left: 24px;
    padding-right: 24px;
  }

  @media ${({ theme }) => theme.mediaQuery.medium} {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const PopularListingsHeader = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
`;

const VideosContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-evenly;

  div.marketplace-video-container {
    margin-right: 20px;
  }
`;

const ViewAllButton = styled(Button)`
  position: relative;
  margin-left: 28px;
  max-width: 100px;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    margin-left: auto;
  }
`;

const SkeletonContainer = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;

  div {
    width: 90% !important;
    height: ${DEFAULT_VIDEO_HEIGHT}px !important;

    span {
      width: 100% !important;
      height: ${DEFAULT_VIDEO_HEIGHT}px !important;
    }
  }

  @media ${({ theme }) => theme.mediaQuery.small} {
    justify-content: space-evenly;
  }

  @media ${({ theme }) => theme.mediaQuery.medium} {
    justify-content: space-evenly;

    div {
      width: 100% !important;
      min-height: ${MIN_VIDEO_HEIGHT}px;
      min-width: ${MIN_VIDEO_WIDTH}px;
      max-width: calc(100% - 30px);
      aspect-ratio: ${DEFAULT_VIDEO_WIDTH} / ${DEFAULT_VIDEO_HEIGHT};

      span {
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
`;
