import type { apiClient } from '../../hooks/useServices';
import { QrCode } from '../../types/QrCode';

export type QrCodeService = {
  byCode: (code: string) => Promise<QrCode>;
};

export const qrCodeServiceFactory = (apiClient: apiClient): QrCodeService => ({
  byCode: (code: string): Promise<QrCode> =>
    apiClient<QrCode>(`/qr-code/by-code`, {
      method: 'GET',
      body: {
        code,
      },
    }),
});
