import { useTheme } from '@emotion/react';
import React from 'react';
import ListingLabel from '../../../components/ListingLabel';
import { useMediaQuery } from '../../../hooks';
import { abbrMonthlyRent, getReadableDate } from '../../../lib/utils';
import { ListingType } from '../../../types/ListingType';

import {
  ListingDetailContainer,
  ListingFirstRow,
  ListingRow,
} from './components';

export type ListingDetailType = {
  imageUrls: Array<string>;
  monthlyRent: number;
  availableAt: string;
  listingId: string;
  rentalType: RentalType;
  listingType: ListingType;
  numBathrooms: number;
  numBedrooms: number;
  leaseDurationDays?: number;
  address: {
    address1: string;
    address2: string;
    city: string;
    state: string;
    postalCode: string;
  };
};

export enum RentalType {
  coop = 'coop',
  multi_family = 'multi_family',
  single_family = 'single_family',
  apartment = 'apartment',
  duplex_triplex = 'duplex_triplex',
  townhome = 'townhome',
  other = 'other',
  condo = 'condo',
  townhouse = 'townhouse',
  house = 'house',
}

const getReadableRentalType = (rentalType: RentalType): string => {
  const toReadableWords = rentalType.split('_').join(' ');
  const capFirstLetter = toReadableWords.charAt(0).toUpperCase();

  return `${capFirstLetter}${toReadableWords.substr(1)}`;
};

const ListingBedBaths: React.FC<{
  numBedrooms: number;
  numBathrooms: number;
}> = ({ numBedrooms, numBathrooms }) => {
  return (
    <span style={{ marginRight: '0.25rem' }}>
      {numBedrooms === 0 ? 'Studio' : `${numBedrooms} beds`}
      <span style={{ marginLeft: '0.50rem' }}>{numBathrooms} baths</span>
    </span>
  );
};

const ListingDetail: React.FC<ListingDetailType> = ({
  rentalType,
  listingType,
  numBathrooms,
  numBedrooms,
  address,
  monthlyRent,
  availableAt,
  leaseDurationDays,
}) => {
  const { mediaQuery } = useTheme();
  const minWidthMedium = useMediaQuery(mediaQuery.medium);
  return (
    <ListingDetailContainer>
      <ListingLabel
        styleOverrides={{ left: minWidthMedium ? '24px' : '14px' }}
        leaseDurationDays={leaseDurationDays}
        listingType={listingType}
      />
      <ListingFirstRow>
        <span>
          {getReadableRentalType(rentalType)} • {address?.city}
        </span>
        <span>{abbrMonthlyRent(monthlyRent)} </span>
      </ListingFirstRow>
      <ListingRow>
        <ListingBedBaths
          numBedrooms={numBedrooms}
          numBathrooms={numBathrooms}
        ></ListingBedBaths>
        <span>Avail. {getReadableDate(availableAt)}</span>
      </ListingRow>
    </ListingDetailContainer>
  );
};

export default ListingDetail;
