import { useQuery } from 'react-query';
import { RESULTS_PER_PAGE } from '../components/Pagination';
import { bedroomOptionMax, Duration } from '../components/SearchFilters/utils';
import { snakeCaseToCamelCase } from '../lib/api';
import { ListingResponse } from '../lib/services/listing';
import { encodeBody, toShortCityName } from '../lib/utils';
import { useService } from './useServices';

const queryKey = 'search-by-filters';

const getNumBedroomsSet = (selectedBeds) => {
  const numBedroomsSet: Array<string> = [];
  const fourPlusIdx = 4;
  const toStringRepresentation = (idx) =>
    idx === fourPlusIdx ? bedroomOptionMax : idx;

  for (const [idx] of selectedBeds.entries()) {
    const isSelected = selectedBeds[idx];

    if (isSelected) {
      const bedroomOption = toStringRepresentation(idx).toString();
      numBedroomsSet.push(bedroomOption);
    }
  }
  return numBedroomsSet;
};

const sixMonthsInDays = 182;
const fiveYearsInDays = 5 * 365;

const daysByDuration = {
  [Duration.SHORT_TERM]: {
    min_lease_duration_days: 0,
    max_lease_duration_days: sixMonthsInDays,
  },
  [Duration.LONG_TERM]: {
    min_lease_duration_days: sixMonthsInDays + 1,
    max_lease_duration_days: fiveYearsInDays,
  },
};

const durationOptionsToDays = (selectedDuration) => {
  if (selectedDuration.size === 2) {
    const { min_lease_duration_days } = daysByDuration[Duration.SHORT_TERM];
    const { max_lease_duration_days } = daysByDuration[Duration.LONG_TERM];
    return {
      min_lease_duration_days,
      max_lease_duration_days,
    };
  } else if (
    selectedDuration.size === 1 &&
    selectedDuration.has(Duration.SHORT_TERM)
  ) {
    return daysByDuration[Duration.SHORT_TERM];
  } else if (
    selectedDuration.size === 1 &&
    selectedDuration.has(Duration.LONG_TERM)
  ) {
    return daysByDuration[Duration.LONG_TERM];
  } else {
    return null;
  }
};

export const selectedDurationOptions = (
  minDays?: number,
  maxDays?: number
): Duration[] => {
  if (
    minDays !== undefined &&
    maxDays !== undefined &&
    maxDays > sixMonthsInDays
  ) {
    return [Duration.SHORT_TERM, Duration.LONG_TERM];
  } else if (maxDays && maxDays > sixMonthsInDays) {
    return [Duration.LONG_TERM];
  } else if (maxDays && maxDays <= sixMonthsInDays) {
    return [Duration.SHORT_TERM];
  } else {
    return [];
  }
};

export const selectedBedroomIdx = (selection: string): string => {
  return selection === bedroomOptionMax ? '4' : selection;
};

export const selectedBedrooms = (numBedroomsSet?: any): boolean[] => {
  const arr = new Array(5).fill(false);

  if (typeof numBedroomsSet === 'object') {
    numBedroomsSet.forEach((num) => {
      const idx = num === bedroomOptionMax ? 4 : num.trim();
      arr[idx] = true;
    });
  } else if (numBedroomsSet !== undefined) {
    const idx = numBedroomsSet === bedroomOptionMax ? 4 : numBedroomsSet.trim();
    arr[idx] = true;
  }
  return arr;
};

const paramsToConvertToNumbers = new Set([
  'min_lease_duration_days',
  'max_lease_duration_days',
  'min_monthly_rent',
  'max_monthly_rent',
  'offset',
  'limit',
]);

export const parseAndFormatQueryStringParams = <T>(params): T => {
  const convertedParams = Object.fromEntries(
    Object.entries(params).map(([k, v]) => {
      if (paramsToConvertToNumbers.has(k)) {
        if (params[k] === 'object') {
          return [k, params[k].map((v) => parseInt(v as string))];
        } else {
          return [k, parseInt(v as string)];
        }
      } else {
        return [k, v];
      }
    })
  );
  return snakeCaseToCamelCase(convertedParams) as T;
};

export const extractFilterArgsFromQueryParams = (
  queryParams
): UseSearchByFiltersArgs => {
  const params = parseAndFormatQueryStringParams<FilterQueryParams>(
    queryParams
  );
  const selectedBeds = selectedBedrooms(params.numBedroomsSet);
  const selectedDuration = new Set(
    selectedDurationOptions(
      params.minLeaseDurationDays,
      params.maxLeaseDurationDays
    )
  );
  const selectedPlaceType = params.listingTypes
    ? new Set([params.listingTypes].flat(1))
    : new Set([]);
  const minPrice = params.minMonthlyRent ? `${params.minMonthlyRent}` : '';
  const maxPrice = params.maxMonthlyRent ? `${params.maxMonthlyRent}` : '';
  const city = params.city || '';
  const availableAt = params.availableAt || '';
  // Override in pagination
  const offset = params.offset || 0;
  const limit = params.limit || RESULTS_PER_PAGE;

  return {
    selectedBeds,
    selectedDuration,
    selectedPlaceType,
    minPrice,
    maxPrice,
    city,
    availableAt,
    offset,
    limit,
  };
};

const convertPriceToInt = (price?: string): number | null => {
  if (!price) return null;
  return parseInt(price.replace('$', ''));
};

export type FilterQueryParams = {
  minLeaseDurationDays?: number;
  maxLeaseDurationDays?: number;
  numBedroomsSet?: string[];
  listingTypes?: string[];
  minMonthlyRent?: number;
  maxMonthlyRent?: number;
  offset?: number;
  limit?: number;
  city?: string;
  availableAt?: string;
};

export type UseSearchByFiltersArgs = {
  selectedBeds: boolean[];
  selectedDuration: Set<string>;
  selectedPlaceType: Set<string>;
  minPrice: string;
  maxPrice: string;
  city: string;
  availableAt: string;
  limit: number;
  offset: number;
};

export function useSearchByFilters(
  shouldSearch,
  args: UseSearchByFiltersArgs,
  options
) {
  const listingService = useService<'listing'>('listing');

  const {
    selectedBeds,
    selectedDuration,
    selectedPlaceType,
    minPrice,
    maxPrice,
    city,
    availableAt,
    limit,
    offset,
  } = args;

  const numBedroomsSet = selectedBeds ? getNumBedroomsSet(selectedBeds) : null;
  const duration = selectedDuration
    ? durationOptionsToDays(selectedDuration)
    : null;
  const listingTypes = selectedPlaceType ? Array.from(selectedPlaceType) : null;
  const minMonthlyRent = minPrice
    ? typeof minPrice === 'string'
      ? convertPriceToInt(minPrice)
      : minPrice
    : null;
  const maxMonthlyRent = maxPrice
    ? typeof maxPrice === 'string'
      ? convertPriceToInt(maxPrice)
      : maxPrice
    : null;
  const cityParams = toShortCityName(city);

  const maxLimit = limit > RESULTS_PER_PAGE ? RESULTS_PER_PAGE : limit;

  const queryParams = {
    ...duration,
    num_bedrooms_set: numBedroomsSet,
    listing_types: listingTypes,
    min_monthly_rent: minMonthlyRent || null,
    max_monthly_rent: maxMonthlyRent || null,
    city: cityParams,
    available_at: availableAt,
    offset,
    limit: maxLimit,
  };

  const queryParamsEncoded = encodeBody(queryParams);

  const query = useQuery(
    [queryKey, queryParamsEncoded],
    () =>
      listingService
        .searchListingsByFilters(queryParams, queryParamsEncoded)
        .catch(() => Promise.resolve([] as ListingResponse[])),
    {
      ...options,
      refetchOnWindowFocus: false,
      enabled: shouldSearch,
      staleTime: 5 * 60 * 100, // 5 minutes of caching
    }
  );

  return {
    query,
    queryKey,
    queryParamsEncoded,
  };
}

export default useSearchByFilters;
