import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';

import Tabs, { Tab } from '../../components/Tabs';
import AppPageComponent from '../AppPage';
import Dashboard from './Dashboard';
import Messaging from './Messaging';
import { useHistory, useLocation } from 'react-router';

export enum HomeView {
  Dashboard = 'dashboard',
  Messages = 'messages',
}

const Home: React.FC = () => {
  const [homeView, setHomeView] = useState(HomeView.Dashboard);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const view = query.get('view') || '';

    if (!view) {
      history.push({
        pathname: '/home',
        search: `?view=${HomeView.Dashboard}`,
      });
    }

    setHomeView(
      HomeView[`${view.charAt(0).toUpperCase()}${view.substring(1)}`]
    );
  }, [location, history]);

  const handleDashboardClick = () => {
    history.push({
      pathname: '/home',
      search: `?view=${HomeView.Dashboard}`,
    });

    setHomeView(HomeView.Dashboard);
  };

  const handleMessagesClick = () => {
    history.push({
      pathname: '/home',
      search: `?view=${HomeView.Messages}`,
    });

    setHomeView(HomeView.Messages);
  };

  return (
    <AppPageComponent>
      <StyledTabsList>
        <Tab
          onClick={handleDashboardClick}
          isActive={homeView === HomeView.Dashboard}
        >
          Dashboard
        </Tab>
        <Tab
          onClick={handleMessagesClick}
          isActive={homeView === HomeView.Messages}
        >
          Messages
        </Tab>
      </StyledTabsList>

      {homeView === HomeView.Dashboard && <Dashboard />}
      {homeView === HomeView.Messages && <Messaging />}
    </AppPageComponent>
  );
};

export default Home;

const StyledTabsList = styled(Tabs)`
  padding-left: 0;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    padding-top: 2rem;
  }
`;
