import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTheme } from '@emotion/react';
import ReactCarousel from 'react-slick';
import { useHistory } from 'react-router';

import useMediaQuery from '../../hooks/useMediaQuery';
import { useSearch, useUserLocation } from '../../hooks';
import { PaginationContext } from '../../hooks/usePagination';
import { useSearchByProximity } from '../../hooks/useSearchByProximity';

import { lastResortFallbackLocation, maxWidth } from '../../lib/constants';

import Footer from '../../components/Footer';
import UnicornScouts from './UnicornScouts';
import Icon from '../../components/Icon';
import Button from '../../components/UI/Button';
import AppPage from '../AppPage';
import PageContentWrapper from '../../components/PageContentWrapper';

import {
  HeaderWrapper,
  Header,
  HeaderTitleContainer,
  HeaderTitle,
  PlacesAroundYouSection,
  HeaderContainer,
  PlacesAroundYouHeaderFirstRow,
  PlacesAroundYouHeaderSecondRow,
  Section,
  HeaderContent,
  CommuterFriendlyRentalsSection,
  CommuterFriendlyRentalList,
  StyledViewAllBtn,
  PlacesAroundYouCarousel,
  Left,
  Right,
  PopularListingsSection,
  PopularListingsSectionHeader,
  PlacesAroundYouHeader,
} from './components';

import {
  commuterFriendlyMockData,
  carouselSharedSettings,
} from './helpers/constants';

import { SearchMobileView, SearchDesktopView } from './Search';
import SearchNeighborhood, {
  SearchNeighborhoodDefaults,
} from './SearchNeighborhood';
import SearchHighlight from './SearchHighlight';
import ShareYourPlace from './ShareYourPlace';
import PopularListings from './PopularListings';
import SliderSection from '../../components/SliderSection';
import { getSessionStorage } from '../../lib/session-storage';

const DEFAULT_PROXIMITY_METERS = 804672; // 500 mile large radius default for now until there's more content

const PLACES_AROUND_YOU_LIMIT = 4;

const Marketplace: React.FC<{}> = () => {
  const { mediaQuery, colorMap } = useTheme();
  // These screen checks are defined for the carousel
  const minWidthSmall = useMediaQuery(mediaQuery.small);
  const minWidthMedium = useMediaQuery(mediaQuery.medium);

  const history = useHistory();
  const { userLocation } = useUserLocation();
  const locationToSearch = userLocation || lastResortFallbackLocation;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const {
    currentPage,
    total,
    setCurrentPage,
    setTotal,
    setOffset,
    setCurrentPageResults,
  } = useContext(PaginationContext);

  const {
    handlers: { handlePageClick },
    searchRef,
    showSearchDesktopView,
  } = useSearch();

  const placesAroundYouSliderRef = useRef(null);

  const placesAroundYouQueryPath = useMemo(() => {
    return `/search/by-proximity?lat=${locationToSearch.latitude}&lon=${
      locationToSearch.longitude
    }&available_at=${new Date(
      new Date().setHours(0, 0, 0, 0)
    ).toISOString()}&proximity=${DEFAULT_PROXIMITY_METERS}&date_window_size_days=${90}&limit=${PLACES_AROUND_YOU_LIMIT}&offset=${
      (currentPage - 1) * PLACES_AROUND_YOU_LIMIT
    }`;
  }, [currentPage, locationToSearch.latitude, locationToSearch.longitude]);

  const {
    data: placesAroundYou,
    error: placesAroundYouError,
    isLoading: placesAroundYouLoading,
  } = useSearchByProximity(
    locationToSearch.latitude,
    locationToSearch.longitude,
    PLACES_AROUND_YOU_LIMIT,
    (currentPage - 1) * PLACES_AROUND_YOU_LIMIT
  );

  useEffect(() => {
    setTotal(
      Number(
        getSessionStorage(`${placesAroundYouQueryPath}-pagination-total`)
      ) || 0
    );

    setOffset(
      Number(
        getSessionStorage(`${placesAroundYouQueryPath}-pagination-offset`)
      ) || 0
    );

    setCurrentPageResults(placesAroundYou?.length || 0);
  }, [
    placesAroundYou,
    setCurrentPageResults,
    setOffset,
    setTotal,
    placesAroundYouQueryPath,
  ]);

  const handleViewAll = () => {
    history.push('/listings-near-you');
  };

  const handleResize = () => setWindowWidth(window.innerWidth);

  const debounce = (func, delay = 500) => {
    let timer;

    return () => {
      if (timer) clearTimeout(timer);
      timer = setTimeout(func, delay);
    };
  };

  useEffect(() => {
    const debouncedResize = debounce(handleResize);

    if (window) {
      window.addEventListener('resize', debouncedResize);
    }

    return () => window.removeEventListener('resize', debouncedResize);
  });

  const placesAroundYouTotal = useMemo(
    () => placesAroundYou?.length ?? PLACES_AROUND_YOU_LIMIT,
    [placesAroundYou]
  );

  const renderSearch = () => {
    if (minWidthMedium) {
      return (
        <SearchDesktopView
          ref={searchRef}
          showSearchView={showSearchDesktopView}
          onClick={handlePageClick}
        />
      );
    } else {
      return <SearchMobileView />;
    }
  };

  const renderPlacesAroundYouSlides = () => {
    const placesAroundYouData = placesAroundYou;

    return (
      <SliderSection
        ref={placesAroundYouSliderRef}
        total={placesAroundYouTotal}
        data={placesAroundYouData}
        loading={placesAroundYouLoading}
        queryPath={placesAroundYouQueryPath}
      />
    );
  };

  // TODO: Currently hidden with display: none;
  const renderCommuterFriendlySlides = () => {
    const Component = commuterFriendlyMockData.map(
      ({ image, listingDetail }, idx) => (
        <li key={`commuter-friendly-li-${idx}`}>
          <img src={image.src} alt={image.alt} />
          {/* <ListingDescription /> */}
        </li>
      )
    );

    if (minWidthMedium) return Component;

    return (
      <ReactCarousel
        {...carouselSharedSettings}
        centerMode={true}
        centerPadding={`${92 + (windowWidth - 208 - 167) * 0.5}`}
      >
        {Component}
      </ReactCarousel>
    );
  };

  const handlePrevious = () => {
    setCurrentPage(currentPage - 1);
    const slider = placesAroundYouSliderRef?.current || { slickPrev: () => {} };
    slider.slickPrev();
  };

  const handleNext = () => {
    setCurrentPage(currentPage + 1);
    const slider = placesAroundYouSliderRef?.current || { slickNext: () => {} };
    slider.slickNext();
  };

  return (
    <AppPage className="fullbleed">
      <HeaderWrapper>
        <Header>
          {renderSearch()}
          <HeaderTitleContainer>
            <HeaderTitle>Find your home on Unicorn</HeaderTitle>

            <Button
              variant="primary"
              color="primaryPink"
              size="large"
              className="places-open-button"
              onClick={handleViewAll}
            >
              Available in{' '}
              {new Date().toLocaleString('default', { month: 'long' })}
            </Button>
          </HeaderTitleContainer>
        </Header>
      </HeaderWrapper>
      {/* Popular Listings */}
      <PageContentWrapper>
        <PopularListingsSection>
          <PopularListingsSectionHeader>
            <PopularListings location={locationToSearch} />
          </PopularListingsSectionHeader>
        </PopularListingsSection>
      </PageContentWrapper>
      {/* Search Highlights + Search Neighborhoods + Scouts */}
      <PageContentWrapper>
        <Section>
          <SearchHighlight />
        </Section>

        <Section>
          <SearchNeighborhood
            city={SearchNeighborhoodDefaults.city}
            neighborhoods={SearchNeighborhoodDefaults.neighborhoods}
          />
        </Section>

        <Section>
          <UnicornScouts />
        </Section>
      </PageContentWrapper>
      {/**
       * TODO: Move under PageContentWrapper to control max-width once this component has been updated
       * Places around you
       */}
      <PageContentWrapper>
        <PlacesAroundYouSection>
          <PlacesAroundYouHeader>
            <PlacesAroundYouHeaderFirstRow>
              <Left>
                <h3>Places around you</h3>
                <StyledViewAllBtn
                  variant="secondary"
                  color="primaryPink"
                  size="small"
                  onClick={handleViewAll}
                >
                  View all
                </StyledViewAllBtn>
              </Left>

              {minWidthSmall && (
                <Right>
                  {currentPage !== 1 && (
                    <Button variant="tertiary" onClick={handlePrevious}>
                      <Icon
                        name="leftArrow"
                        viewBox="40 12 20 20"
                        size="medium"
                        color={colorMap.neutral.white}
                      />
                    </Button>
                  )}

                  {currentPage !==
                    Math.ceil(total / PLACES_AROUND_YOU_LIMIT) && (
                    <Button variant="tertiary" onClick={handleNext}>
                      <Icon
                        name="rightArrow"
                        viewBox="40 12 20 20"
                        size="medium"
                        color={colorMap.neutral.white}
                      />
                    </Button>
                  )}
                </Right>
              )}
            </PlacesAroundYouHeaderFirstRow>

            <PlacesAroundYouHeaderSecondRow>
              Unexplainable prices with great value
            </PlacesAroundYouHeaderSecondRow>
          </PlacesAroundYouHeader>

          <PlacesAroundYouCarousel width={Math.min(windowWidth, maxWidth)}>
            {renderPlacesAroundYouSlides()}
          </PlacesAroundYouCarousel>

          {placesAroundYouError && (
            <p>There was an error fetching places around you.</p>
          )}
        </PlacesAroundYouSection>
      </PageContentWrapper>
      {/* Share your place */}
      <PageContentWrapper>
        <Section>
          <ShareYourPlace />
        </Section>

        <CommuterFriendlyRentalsSection>
          <HeaderContainer>
            <HeaderContent>
              <h3>Commuter Friendly Rentals</h3>
              <p>
                spend more time with your friends &amp; less time on the road!
              </p>
              <StyledViewAllBtn
                variant="secondary"
                color="secondaryPink"
                size="small"
              >
                View all
              </StyledViewAllBtn>
            </HeaderContent>
          </HeaderContainer>

          <CommuterFriendlyRentalList>
            {renderCommuterFriendlySlides()}
          </CommuterFriendlyRentalList>
        </CommuterFriendlyRentalsSection>
      </PageContentWrapper>

      <Footer />
    </AppPage>
  );
};

export default Marketplace;
