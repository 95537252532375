import React from 'react';
import { DefaultIcon } from '../../../components/Icon';
import { ErrorScreen, ErrorTitle } from './components';

const VideoError = () => (
  <ErrorScreen>
    <ErrorTitle>
      <DefaultIcon name="excalmationCircle" color={'white'} />
      <span>Video Player Error</span>
    </ErrorTitle>
    <span>This video is unavailable. Please try again later.</span>
  </ErrorScreen>
);

export default VideoError;
