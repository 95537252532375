import styled from '@emotion/styled/macro';
import AppPage from '../AppPage';
import { DESKTOP_MAX_PAGE_HEIGHT } from './constants';
import PageContentWrapper from '../../components/PageContentWrapper';
import { maxWidth } from '../../lib/constants';
import { getStaticAssetS3Url } from '../../lib/static-assets';
import { DISCOVER_MIN_HEIGHT_MEDIA_QUERY } from './constants';

const background = getStaticAssetS3Url('/discover/egg.jpeg');

export const DiscoverPageMobile = styled.div`
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.9)),
    url(${background});
  background-repeat: no-repeat;
  background-size: cover;
`;

export const DiscoverPageDesktop = styled(AppPage)`
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.9)),
    url(${background});
  background-repeat: no-repeat;
  background-size: cover;
`;

export const DiscoverPageContentWrapper = styled(PageContentWrapper)`
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);

  @media ${({ theme }) => theme.mediaQuery.medium} {
    height: 100%;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const VideoContent = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    padding-left: 80px;

    @media (max-height: ${DISCOVER_MIN_HEIGHT_MEDIA_QUERY}px) {
      padding-left: 0px;
    }
  }
`;

export const VideoLoading = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  align-items: center;
  justify-content: center;

  @media (min-height: ${DESKTOP_MAX_PAGE_HEIGHT}) {
    margin-top: 0px;
  }
`;

export const MobileWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

export const DesktopWrapper = styled.div`
  max-width: ${maxWidth}px;
  max-height: ${DESKTOP_MAX_PAGE_HEIGHT}px;
  backdrop-filter: blur(10px);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
`;

export const DesktopVideoSection = styled.div`
  width: 40%;
  height: 100%;
  min-width: 600px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Pagination = styled.div`
  height: 100%;
  width: 80px;
  min-width: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;

  div {
    &:first-of-type {
      margin-bottom: 1.25rem;
    }
  }
`;

export const PaginationIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${({ theme }) => theme.zIndices.top};
  border-radius: 50%;
  width: 36px;
  height: 36px;
  background: white;
  opacity: 0.8;
  cursor: pointer;
  transition: hover 0.25s ease 0s;

  &:hover {
    opacity: 1;
  }
`;

export const MapSection = styled.div`
  flex: 1;
  flex-shrink: 2;
  height: 100%;
  width: 100%;

  @media (min-height: ${DESKTOP_MAX_PAGE_HEIGHT}) {
    div div div {
      border-radius: 32px;
    }
  }
`;
