import styled from '@emotion/styled';

export const SearchMobileInputContainer = styled.div<{
  showBackground: boolean;
}>`
  width: 100%;
  height: 80px;
  top: 0;
  position: fixed;
  z-index: ${({ theme }) => theme.zIndices.top};
  background: ${(props) => ({ theme }) =>
    props.showBackground ? theme.colorMap.neutral.white : 'none'};
`;

export const SearchMobileSidePanel = styled.div`
  z-index: ${({ theme }) => theme.zIndices.top};
  overflow: hidden;

  &.hide {
    transition-property: right, width;
    transition-duration: 1s, 1s;
  }

  &.show {
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    right: 0;
    overflow: visible;
    transition: right 0.5s;
  }

  background: white;
  position: fixed;
  width: 0;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  top: 0;
  right: -100vw;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    display: none;
  }
`;

export const SearchDesktopDropdownView = styled.div<{ height: string }>`
  height: 0;
  background: white;
  border-radius: 30px;
  position: relative;

  .search__input-wrapper {
    display: none;
  }

  @media ${({ theme }) => theme.mediaQuery.medium} {
    display: flex;
    justify-content: center;
    height: ${(props) => props.height};

    .search__input-wrapper {
      display: block;
      height: 100%;
    }
  }
`;

export const SearchInputContainer = styled.div<{ height: string }>`
  position: relative;
  width: 100%;
  height: ${(props) => props.height};
`;
