/* @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { VideoListingDetails } from '../../../types';
import {
  Controls,
  Details,
  Poster,
  StyledVideo,
  VideoContainer,
  VideoDetailsAndControls,
  VideoPlayer,
} from './components';
import {
  useAuth,
  useMediaDimensions,
  useMediaQuery,
  useVideoSwiper,
} from '../../../hooks';
import VideoDetails from './VideoDetails';
import { useTheme } from '@emotion/react';
import { AuthenticatedVideoButtons, PublicVideoButtons } from './VideoButtons';
import useVideoLoadingState from '../../../hooks/useVideoLoadingState';
import { MediaType } from '../../../types/Media';
import {
  DISCOVER_MIN_HEIGHT_MEDIA_QUERY,
  SLIDE_TRANSITION_DELAY_MS,
} from '../constants';
import VideoError from './VideoError';
import VideoMuteButton from './VideoMuteButton';
import { useSwiper } from 'swiper/react';
import VideoPlayButton from '../../../components/VideoPlayButton';

type VideoProps = {
  isActiveVideo: boolean;
  idx: number;
  videoDetails: VideoListingDetails;
};

const Video: React.FC<VideoProps> = ({ isActiveVideo, idx, videoDetails }) => {
  const { mediaQuery } = useTheme();
  const { userId } = useAuth();
  const minWidthMedium = useMediaQuery(mediaQuery.medium);
  const minHeight = useMediaQuery(
    `(min-height: ${DISCOVER_MIN_HEIGHT_MEDIA_QUERY}px)`
  );

  const swiper = useSwiper();

  const isPrevVideo = swiper.realIndex - 1 === idx;
  const isNextVideo = swiper.realIndex + 1 === idx;

  const {
    isVideoPlaying,
    isMuted,
    requiresUserInteraction,
    handlers: {
      pause,
      play,
      handleUserInteraction,
      handleVideoIsPlaying,
      mute,
      unmute,
    },
  } = useVideoSwiper();

  const {
    isLoading,
    error,
    hasBuffered,
    handlers: { load, setBuffered, clearBuffered },
  } = useVideoLoadingState();

  const { orientation } = useMediaDimensions(
    MediaType.IMAGE,
    videoDetails.posterUrl || ''
  );

  const handleClick = (evt) => {
    evt.preventDefault();
    const targetTagName = evt.target.tagName.toLowerCase();

    if (
      targetTagName === 'div' ||
      targetTagName === 'img' ||
      targetTagName === 'circle' ||
      targetTagName === 'video'
    ) {
      if (requiresUserInteraction) handleUserInteraction(true);

      isVideoPlaying ? pause(videoDetails.id) : play(videoDetails.id);
    }
  };

  const handlePlay = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    handleUserInteraction(true);
    play(videoDetails.id);
  };

  const handleUnmute = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    unmute();
  };

  const isPosterVisible =
    !error && (!hasBuffered || isLoading || !isActiveVideo);

  useEffect(() => {
    const video = document.getElementById(videoDetails.id) as HTMLMediaElement;

    if (!video) return;

    if (isActiveVideo || isPrevVideo || isNextVideo) {
      if (video.src !== videoDetails.videoUrl) {
        video.src = videoDetails.videoUrl;
      }
    } else if (video.src !== '') {
      video.src = '';
      video.load();
      clearBuffered();
    }
  }, [
    isActiveVideo,
    isPrevVideo,
    isNextVideo,
    videoDetails.id,
    videoDetails.videoUrl,
    clearBuffered,
  ]);

  return (
    <StyledVideo onClick={handleClick} key={`${videoDetails.id}`}>
      {/* Error */}
      {error && <VideoError />}
      {orientation && (
        <VideoContainer>
          {/* Poster */}
          {videoDetails.posterUrl && (
            <Poster
              className={`${orientation} ${
                isPosterVisible ? 'visible' : 'hidden'
              }`}
              src={videoDetails.posterUrl}
            />
          )}
          {/* Video */}
          {!error && (
            <VideoPlayer
              src={videoDetails.videoUrl}
              id={videoDetails.id}
              poster={videoDetails.posterUrl || ''}
              className={`${orientation} discover-video-player  ${
                isPosterVisible ? 'hidden' : 'visible'
              }`}
              // poster={videoDetails.posterUrl || ''}
              // onError={setError} // Don't trigger for now until we can debug why it fails
              // Need to update state only when we get callback.
              // iOS never resolves promise sometimes when trying to manually trigger video play
              onPlaying={() => {
                // Otherwise occasionally see a "flash" when video starts playing
                // Add slight delay
                handleVideoIsPlaying(true);

                setTimeout(() => {
                  setBuffered();
                }, SLIDE_TRANSITION_DELAY_MS);
              }}
              autoPlay={isActiveVideo && !requiresUserInteraction}
              onEnded={() => play(videoDetails.id)}
              playsInline={true}
              onLoadStart={load}
              muted={isMuted}
              preload="auto"
            />
          )}
          {/* Mobile Only - Mute Button (Top) */}
          {(!minWidthMedium || !minHeight) && isMuted && (
            <VideoMuteButton unmute={handleUnmute} />
          )}
          {/* Details & Controls (Bottom) */}
          <VideoDetailsAndControls>
            <Details>
              <VideoDetails
                videoDetails={videoDetails}
                buttonLink={{
                  target: minWidthMedium ? '_blank' : '_self',
                  linkTo: userId ? `/listings/${videoDetails.listingId}` : `/`,
                }}
              />
            </Details>
            <Controls>
              {userId ? (
                <AuthenticatedVideoButtons
                  isMuted={isMuted}
                  handleMuted={() => (isMuted ? unmute() : mute())}
                  listingId={videoDetails.listingId}
                />
              ) : (
                <PublicVideoButtons
                  isMuted={isMuted}
                  handleMuted={() => (isMuted ? unmute() : mute())}
                  listingId={videoDetails.listingId}
                />
              )}
            </Controls>
          </VideoDetailsAndControls>
        </VideoContainer>
      )}
      {/* Play Button */}
      {orientation && !error && !isVideoPlaying && (
        <VideoPlayButton showButton={true} handleOnClick={handlePlay} />
      )}
    </StyledVideo>
  );
};

export default Video;
