import styled from '@emotion/styled';

export const inputWidth = '327px';

const AuthPage = styled.div`
  overflow: hidden;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  padding: 1rem 0.625rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media ${({ theme }) => theme.mediaQuery.large} {
    text-align: center;
    padding: 15vh 20vw;
    padding: calc(var(--vh, 1vh) * 15) 20vw;
  }

  .react-tel-input {
    font-family: inherit;
    font-size: 1em;
    max-width: ${inputWidth};
    margin: 0 auto;

    .form-control {
      appearance: none;
      width: 100%;
      border-color: transparent;
      border-radius: 10px;
      padding: 1.125rem 0.875rem 1.125rem 3.75rem;
      box-shadow: 0px 14px 18px rgba(0, 0, 0, 0.15);
      transition: box-shadow ease 0.25s, border-color ease 0.25s;
      color: #495057;
      font-family: inherit;
      font-size: 1.5em;
      font-weight: 500;
      letter-spacing: 0.18px;

      &.invalid-number {
        color: ${({ theme }) => theme.color.error};
      }

      &:disabled {
        background-color: #f8f8f8;
        color: lightgrey;
      }

      ::placeholder {
        color: ${({ theme }) => theme.colorMap.gray.gray4};
      }
    }

    .form-control--code {
      padding: 1.125rem 3.75rem 1.125rem 4rem;
      letter-spacing: 5px;
      text-align: center;
    }

    .form-control:focus {
      outline: 0;
      box-shadow: 0px 14px 18px rgba(0, 0, 0, 0.15);
      // 0 0 0.2rem 0.1rem rgba(0, 123, 255, 0.25) inset;

      &.invalid-number {
        box-shadow: 0px 14px 18px rgba(0, 0, 0, 0.15);
        // 0 0 0.2rem 0.1rem #f44336 inset;
      }
    }

    .country-list {
      width: ${inputWidth};
    }
  }
`;

export default AuthPage;
