/* @jsxImportSource @emotion/react */

import React, { useContext, useEffect, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router';
import ReactCarousel from 'react-slick';
import { useQuery } from 'react-query';
import { useTheme } from '@emotion/react';
import GoogleMapReact from 'google-map-react';

import Footer from '../../components/Footer';
import { Button } from '../../components/UI/Button';
import SentimentButton from '../../components/SentimentButton';
import { useMediaQuery } from '../../hooks';
import { useApiClient, useAuth, useToggleSentimentMutation } from '../../hooks';
import { Listing } from '../../types';

import AppPage from '../AppPage';

import WomanOnComputer from './images/woman-on-computer.png';

import { ReactComponent as SoundSvg } from './images/Sound.svg';
import { ReactComponent as KeySvg } from './images/Key.svg';
import { ReactComponent as SunSvg } from './images/Sun.svg';
import { ReactComponent as DishwasherSvg } from './images/Dishwasher.svg';
import { ReactComponent as BarbellSvg } from './images/Barbell.svg';
import { ReactComponent as CloseSvg } from './images/Close.svg';
import { ReactComponent as HeartSvg } from './images/Heart.svg';
import { ReactComponent as PriceSvg } from './images/Price.svg';
import { ReactComponent as LoadingSvg } from './images/Loading.svg';
import { ReactComponent as CheckSvg } from './images/Check.svg';
import { ReactComponent as PrivacyProtection } from './images/PrivacyProtection.svg';
import { ReactComponent as BathtubSvg } from './images/Bathtub.svg';
import { ReactComponent as BedSvg } from './images/Bed.svg';
import { ReactComponent as WasherDryerSvg } from './images/WasherDryer.svg';
import { ReactComponent as DogFriendlySvg } from './images/DogFriendly.svg';
import { ReactComponent as CatFriendlySvg } from './images/CatFriendly.svg';
import { ReactComponent as AirConditioningSvg } from './images/AirConditioning.svg';
import { ReactComponent as PoolSvg } from './images/Pool.svg';
import { ReactComponent as BackyardSvg } from './images/Backyard.svg';
import { ReactComponent as BalconyPatioSvg } from './images/BalconyPatio.svg';
import { ReactComponent as ParkingSvg } from './images/Parking.svg';
import { ReactComponent as FurnishedSvg } from '../../components/Icon/Icons/Furnished.svg';
import { ReactComponent as CalendarSvg } from '../../components/Icon/Icons/Calendar.svg';

import RatingTile from './RatingTile';
import AmenityTile from './AmenityTile';
import AskQuestion from './AskQuestion';
import PhotoGalleryModal from './PhotoGalleryModal';

import Icon from './Icon';
import { stripAddressNumber } from './utils';

import {
  Action,
  AmenitiesSection,
  Container,
  ContentContainer,
  Description,
  DetailList,
  DetailSection,
  MediaGrid,
  MobileAskQuestion,
  PersonalActions,
  PhotoGrid,
  RatingsSection,
  Rent,
  SectionHeading,
  PreviewSectionHeading,
  RewardList,
  StyledButton,
  StyledShowAllImageButton,
  SignUpSectionHeading,
  IconContent,
  SignUpSectionIcons,
  SignUpContent,
  CallToActionSection,
  MainImage,
  PreviewSection,
  LocationSection,
  MapContainer,
  desktopVideoHeight,
  desktopVideoWidth,
  mobileVideoHeight,
  mobileVideoWidth,
  Dots,
  desktopImageHeight,
  DetailSectionHeading,
  DetailSectionHeadingInfo,
  ListingTitle,
  ListingDetailsContainer,
  ListingDetailsLeft,
  ListingDetailsRight,
  DetailSectionHeadingInfoContainer,
} from './components';
import ShareListing, {
  ShareListingButton,
} from '../../components/ShareListing';
import { color } from '../../lib/theme';
import IfLoading from '../../components/IfLoading';
import { ModalContext } from '../../hooks/useModal';
import {
  associate,
  getReadableDate,
  monthlyRent,
  loadImageOffscreen,
  loadVideoOffscreen,
} from '../../lib/utils';
import useVideo from './Video/hooks';
import { DesktopVideo, MobileVideo } from './Video';
import { MediaFormat } from '../../types/Media';
import useScrollLock from '../../hooks/useScrollLock';
import { DEFAULT_QUERY_CACHE_STALE_TIME } from '../../lib/constants';
import { ConditionalLink } from '../../components/ConditionalLink';
import MapMarker from '../../components/MapMarker';
import { useConditionalHistory } from '../../hooks/useConditionalHistory';
import SimilarThoughts from './SimilarThoughts';
import { IconSizeKeys } from '../../components/Icon/utils';
import Highlights from './Highlights';
import Overview from './Description';

const ratingDetailMap = {
  landlordRating: {
    icon: KeySvg,
    title: 'Landlord',
    description: 'Fixes issues within days of calling.',
  },
  naturalLight: {
    icon: SunSvg,
    title: 'Natural Light',
    description: 'Amazing light all day long.',
  },
  noiseLevel: {
    icon: SoundSvg,
    title: 'Noise Level',
    description: 'Compared to rush hour traffic.',
  },
};

const amenitiesDetailMap = {
  hasAcunit: {
    icon: AirConditioningSvg,
    label: 'AC Unit',
  },
  hasDishwasher: {
    icon: DishwasherSvg,
    label: 'Dishwasher',
  },
  hasWasherDryer: {
    icon: WasherDryerSvg,
    label: 'Washer/Dryer',
  },
  allowsDogs: {
    icon: DogFriendlySvg,
    label: 'Dogs Allowed',
  },
  allowsCats: {
    icon: CatFriendlySvg,
    label: 'Cats Allowed',
  },
  hasGym: {
    icon: BarbellSvg,
    label: 'Gym',
  },
  hasBalcony: {
    icon: BalconyPatioSvg,
    label: 'Balcony',
  },
  hasPool: {
    icon: PoolSvg,
    label: 'Pool',
  },
  hasBackyard: {
    icon: BackyardSvg,
    label: 'Backyard',
  },
  numParkingSpots: {
    icon: ParkingSvg,
    label: 'Parking',
  },
  furnished: {
    icon: FurnishedSvg,
    label: 'Furnished',
  },
};

const ListingDetailPage: React.FC = () => {
  const { mediaQuery, colorMap } = useTheme();
  const minWidthMedium = useMediaQuery(mediaQuery.medium);

  const history = useHistory();
  const { push } = useConditionalHistory();
  const location = useLocation();

  const [showAskQuestion, setShowAskQuestion] = useState<boolean>(false);

  const { userId: user } = useAuth();

  const client = useApiClient();

  const { listingId } = useParams<{ listingId?: string }>();

  const { scrollStart, scrollMove, scrollEnd } = useScrollLock();

  const { data: listingDetails, isLoading: listingDetailsLoading } = useQuery<
    {},
    unknown,
    Listing
  >(
    [`listing-details-${!!user}-${listingId}`, { listingId }],
    () =>
      client(`${!!user ? '/search' : '/search/public'}/by-id?id=${listingId}`),
    { refetchOnWindowFocus: false, staleTime: DEFAULT_QUERY_CACHE_STALE_TIME }
  );

  // Video Hook
  const {
    isPlaying,
    videoError,
    handlers: { handleError, handlePlay },
  } = useVideo();

  // Listing Videos
  const listingVideos = listingDetails?.videoUrls.map((url: string) => {
    return {
      mediaFormat: MediaFormat.MP4,
      url: url,
    };
  });

  // Mobile ((We use gallery on desktop view & carousel for mobile))
  const hasVideoContent =
    !videoError && listingVideos && listingVideos.length > 0;

  // Show skeleton loaders until video + images are fully
  const [imageLoadedByUrl, setImageLoadedByUrl] = useState<{}>({});
  const [videoLoaded, setVideoLoaded] = useState<boolean>(false);

  // Load video + images offscreen
  useEffect(() => {
    if (listingDetails) {
      Promise.all(
        listingDetails.imageUrls.map(async (url) => {
          const { loaded } = await loadImageOffscreen(url);
          return { url, loaded };
        })
      ).then((arr: { url: string; loaded: boolean }[]) => {
        const map = associate(arr, 'url', 'loaded');
        setImageLoadedByUrl(map);
      });
      if (listingDetails.videoUrls[0]) {
        loadVideoOffscreen(listingDetails.videoUrls[0]).then(({ loaded }) => {
          setVideoLoaded(loaded);
        });
      }
    }
  }, [listingDetails]);

  const { handleOpenModal, setModalContent } = useContext(ModalContext);

  const openImageGallery = (imageUrl?: string) => {
    handleOpenModal();
    setModalContent(() => (
      <PhotoGalleryModal
        defaultImageUrl={imageUrl || listingDetails!!.imageUrls[0]}
        imageUrls={listingDetails!!.imageUrls}
      />
    ));
  };

  useEffect(() => {
    if (minWidthMedium) {
      setShowAskQuestion(true);
    } else {
      setShowAskQuestion(false);
    }
  }, [minWidthMedium]);

  const handleMobileClose = () => {
    // User is logged in and has history
    if (!!user && location.key) {
      history.goBack();
      return;
    }

    // For a logged in user who copied & pasted link,
    // we will redirect them back to Marketplace
    // For a public user, they will be shown the Unlock Access modal
    push('/listings');
  };

  const numBedrooms = listingDetails?.numBedrooms || 0;

  const desktopImageGalleryStart = !hasVideoContent ? 1 : 0;
  const desktopImageGalleryEnd = !hasVideoContent ? 5 : 4;
  const desktopShowAllImageGalleryButton = () => {
    if (
      listingDetails &&
      listingDetails.imageUrls.length >
        desktopImageGalleryEnd - desktopImageGalleryStart
    ) {
      return true;
    } else {
      return false;
    }
  };

  const sentimentMutation = useToggleSentimentMutation(
    `listing-details-${!!user}-${listingId}`,
    {}
  );

  const appendDots = (dots, maxNumDots) => {
    // Early return if we don't have enough images
    if (dots.length <= maxNumDots) return <Dots>{dots}</Dots>;

    const currentIndex = dots.indexOf(
      dots.find((dot) => dot.props.className === 'slick-active')
    );
    const buffer = (maxNumDots - 1) / 2;
    const start = Math.max(0, currentIndex - buffer);
    const end = start + maxNumDots;
    const maxIndex = Math.min(dots.length, dots.length - maxNumDots);

    if (currentIndex < maxIndex + buffer) {
      const currentDots = dots.slice(start, end);
      return <Dots>{currentDots}</Dots>;
    } else {
      return <Dots>{dots.slice(maxIndex, dots.length)}</Dots>;
    }
  };

  const carouselSettings = {
    infinite: true,
    speed: 375,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    appendDots: (dots) => appendDots(dots, 5),
  };

  const amenities = Object.keys(amenitiesDetailMap)
    .filter(
      (amenity) =>
        listingDetails &&
        (listingDetails[amenity] ||
          listingDetails.amenities.find(
            ({ amenityKey }) =>
              amenityKey.toLowerCase() === amenity.toLowerCase()
          ))
    )
    .map((amenity) => listingDetails && amenitiesDetailMap[amenity])
    .map(AmenityTile);

  return (
    <Container>
      <AppPage>
        {!!user && (
          <MobileAskQuestion>
            <div>
              <IfLoading
                isLoading={listingDetailsLoading}
                height={37}
                width={80}
              >
                <Rent>
                  <h2 css={{ fontSize: '1.375rem', fontWeight: 700 }}>
                    {monthlyRent(listingDetails?.monthlyRent || 0)}
                  </h2>
                  &nbsp;
                  <span css={{ fontSize: '0.875rem', fontWeight: 500 }}>
                    / mo
                  </span>
                </Rent>
              </IfLoading>

              <IfLoading
                isLoading={listingDetailsLoading}
                height={17}
                width={100}
              >
                <div
                  css={{
                    fontSize: '0.875rem',
                    fontWeight: 500,
                    color: colorMap.gray.gray2,
                  }}
                >
                  Avail.{' '}
                  {getReadableDate(
                    listingDetails?.availableAt || new Date().toISOString()
                  )}
                </div>
              </IfLoading>
            </div>

            <Button
              css={{ maxWidth: 'none' }}
              variant="primary"
              color="primaryPink"
              onClick={setShowAskQuestion as () => void}
            >
              Ask Question
            </Button>
          </MobileAskQuestion>
        )}

        {/* Only show on small width view */}
        {!minWidthMedium ? (
          <ReactCarousel
            {...carouselSettings}
            className="listing-photos-carousel"
          >
            {listingDetailsLoading && (
              <IfLoading
                key={`skeleton-loader-mobile-video`}
                isLoading={listingDetailsLoading || !videoLoaded}
                height={mobileVideoHeight}
                width={mobileVideoWidth}
              />
            )}
            {!listingDetailsLoading && hasVideoContent && (
              <IfLoading
                key={`skeleton-loader-mobile-video`}
                isLoading={!videoLoaded}
                height={mobileVideoHeight}
                width={mobileVideoWidth}
              >
                <MobileVideo
                  videos={listingVideos || []}
                  isPlaying={isPlaying}
                  handlePlay={handlePlay}
                  handleError={handleError}
                />
              </IfLoading>
            )}
            {!listingDetailsLoading &&
              listingDetails?.imageUrls.map((imageUrl) => {
                return (
                  <IfLoading
                    key={`skeleton-loader-mobile-${imageUrl}`}
                    isLoading={!imageLoadedByUrl[imageUrl]}
                    height={mobileVideoHeight}
                    width={mobileVideoWidth}
                  >
                    <img
                      onTouchStart={scrollStart}
                      onTouchMove={scrollMove}
                      onTouchCancel={scrollEnd}
                      onTouchEnd={scrollEnd}
                      src={imageUrl}
                      key={imageUrl}
                      alt="listing"
                    />
                  </IfLoading>
                );
              })}
          </ReactCarousel>
        ) : (
          ''
        )}

        <ContentContainer>
          <DetailSection>
            <DetailSectionHeading>
              <IfLoading
                isLoading={listingDetailsLoading}
                height={32}
                width={300}
              >
                <ListingTitle data-testid="listing-label">
                  {listingDetails?.label ||
                    stripAddressNumber(listingDetails?.address?.address1 || '')}
                </ListingTitle>
              </IfLoading>

              <DetailSectionHeadingInfoContainer>
                {/* Only show avail at on desktop */}
                {minWidthMedium && (
                  <DetailSectionHeadingInfo>
                    <Icon icon={CalendarSvg} css={{ marginRight: '8px' }} />
                    <IfLoading
                      css={{ margin: '0.375rem 0px' }}
                      isLoading={listingDetailsLoading}
                      height={24}
                      width={minWidthMedium ? 80 : 50}
                    >
                      <p
                        css={{
                          fontSize: '1rem',
                          fontWeight: 500,
                          margin: '0 auto',
                        }}
                      >
                        Avail.{' '}
                        {getReadableDate(
                          listingDetails?.availableAt ||
                            new Date().toISOString()
                        )}
                      </p>
                    </IfLoading>
                  </DetailSectionHeadingInfo>
                )}
                {/* Beds */}
                <DetailSectionHeadingInfo>
                  <Icon icon={BedSvg} css={{ marginRight: '8px' }} />
                  <IfLoading
                    isLoading={listingDetailsLoading}
                    height={24}
                    width={minWidthMedium ? 80 : 50}
                  >
                    <p data-testid="listing-bed">
                      {numBedrooms ? `${numBedrooms} Bed` : 'Studio'}
                      {numBedrooms > 1 && 's'}
                    </p>
                  </IfLoading>
                </DetailSectionHeadingInfo>
                {/* Baths */}
                <DetailSectionHeadingInfo>
                  <Icon icon={BathtubSvg} css={{ marginRight: '8px' }} />
                  <IfLoading
                    isLoading={listingDetailsLoading}
                    height={24}
                    width={minWidthMedium ? 80 : 50}
                  >
                    <p data-testid="listing-bath">
                      {listingDetails?.numBathrooms} Bath
                      {listingDetails?.numBathrooms !== 1 && 's'}
                    </p>
                  </IfLoading>
                </DetailSectionHeadingInfo>
              </DetailSectionHeadingInfoContainer>
            </DetailSectionHeading>

            <Action>
              <CloseSvg
                onClick={handleMobileClose}
                data-testid="mobile-close-icon"
              />
              <PersonalActions>
                <ShareListing
                  listingId={listingDetails?.listingId}
                  button={
                    <ShareListingButton
                      iconWrapperProps={{
                        variant: IconSizeKeys.LARGE,
                        height: 45,
                        width: 45,
                        fillColor: minWidthMedium
                          ? 'none'
                          : colorMap.neutral.white,
                        type: 'round',
                      }}
                    />
                  }
                />
                <Icon
                  css={{ marginLeft: '16px', backgroundColor: 'white' }}
                  variant={minWidthMedium ? 'dark-large' : 'light-small'}
                  icon={() =>
                    user ? (
                      <SentimentButton
                        listing={listingDetails}
                        toggleSentiment={sentimentMutation.mutate}
                      />
                    ) : (
                      <ConditionalLink to="/create">
                        <HeartSvg css={{ width: '20px', height: '18px' }} />
                      </ConditionalLink>
                    )
                  }
                />
              </PersonalActions>
            </Action>
          </DetailSection>
          {/* Only show on desktop view*/}
          <MediaGrid>
            {
              /* Show skeleton when loading for video or main image */
              <IfLoading
                isLoading={listingDetailsLoading}
                loadMultiple={false}
                numLoaders={1}
                height={desktopVideoHeight}
                width={desktopVideoWidth}
              >
                {hasVideoContent && minWidthMedium ? (
                  <IfLoading
                    isLoading={!videoLoaded}
                    loadMultiple={false}
                    numLoaders={1}
                    height={desktopVideoHeight}
                    width={desktopVideoWidth}
                  >
                    <DesktopVideo
                      videos={listingVideos || []}
                      isPlaying={isPlaying}
                      handlePlay={handlePlay}
                      handleError={handleError}
                    />
                  </IfLoading>
                ) : (
                  <IfLoading
                    isLoading={
                      !imageLoadedByUrl[listingDetails?.imageUrls[0] || '']
                    }
                    loadMultiple={false}
                    numLoaders={1}
                    height={desktopVideoHeight}
                    width={desktopVideoWidth}
                  >
                    <MainImage>
                      <img
                        onClick={() =>
                          openImageGallery(listingDetails?.imageUrls[0])
                        }
                        key={listingDetails?.imageUrls[0]}
                        src={listingDetails?.imageUrls[0]}
                        alt="listing"
                        css={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                        }}
                      />
                    </MainImage>
                  </IfLoading>
                )}
              </IfLoading>
            }

            <PhotoGrid>
              {
                <IfLoading
                  isLoading={listingDetailsLoading}
                  loadMultiple={true}
                  numLoaders={4}
                  key={`skeleton-loader-images`}
                  height={desktopImageHeight - 0.5}
                  width="100%"
                >
                  {listingDetails?.imageUrls
                    .slice(desktopImageGalleryStart, desktopImageGalleryEnd)
                    .map((imageUrl) => {
                      return (
                        <IfLoading
                          isLoading={!imageLoadedByUrl[imageUrl]}
                          loadMultiple={false}
                          numLoaders={1}
                          key={`skeleton-loader-${imageUrl}`}
                          height={desktopImageHeight}
                          width="100%"
                        >
                          <img
                            onClick={() => openImageGallery(imageUrl)}
                            key={imageUrl}
                            src={imageUrl}
                            alt="listing"
                          />
                        </IfLoading>
                      );
                    })}
                </IfLoading>
              }
              {desktopShowAllImageGalleryButton() ? (
                <StyledShowAllImageButton
                  onClick={() => openImageGallery()}
                  size="medium"
                  color="secondaryOutline"
                  variant="secondary"
                >
                  Show All Photos
                </StyledShowAllImageButton>
              ) : (
                ''
              )}
            </PhotoGrid>
          </MediaGrid>
          {/* Public Preview Section */}
          {!user && (
            <PreviewSection
              css={{ padding: '1.75rem 1.25rem' }}
              data-testid="preview-section"
            >
              <PreviewSectionHeading>
                The only platform that rewards tenants
              </PreviewSectionHeading>
              <RewardList>
                <li>
                  <Icon
                    icon={PriceSvg}
                    variant="light-large"
                    className="icon"
                  />
                  <p style={{ color: colorMap.gray.gray6 }}>
                    Earn money when you move out
                  </p>
                </li>

                <li>
                  <Icon
                    style={{ color: color.primaryGreen }}
                    icon={LoadingSvg}
                    variant="light-large"
                    className="icon"
                  />
                  <p style={{ color: colorMap.gray.gray6 }}>
                    Save money when you move in
                  </p>
                </li>

                <li>
                  <Icon
                    style={{ color: color.primaryGreen }}
                    icon={KeySvg}
                    variant="light-large"
                    className="icon"
                  />
                  <p style={{ color: colorMap.gray.gray6 }}>
                    Early access to pre-listed rentals
                  </p>
                </li>
              </RewardList>
              <ConditionalLink to="/create">
                <StyledButton variant="primary" color="primaryGreen">
                  Join the community
                </StyledButton>
              </ConditionalLink>
            </PreviewSection>
          )}
          {/* Listing Details */}
          <ListingDetailsContainer>
            <ListingDetailsLeft>
              {/* Highlights */}
              {!!user && (
                <Highlights
                  isLoading={listingDetailsLoading}
                  listingDetails={listingDetails}
                />
              )}
              {/* Overview/Description */}
              {!!user && (
                <Overview
                  isLoading={listingDetailsLoading}
                  listingDetails={listingDetails}
                />
              )}
              {/* Ratings */}
              {!!user && (
                <RatingsSection data-testid="ratings-container">
                  <SectionHeading>Ratings</SectionHeading>
                  <Description>
                    Verified tenants review their living experience in this
                    place
                  </Description>

                  <DetailList numColumns={2}>
                    <IfLoading
                      isLoading={listingDetailsLoading}
                      loadMultiple={true}
                      numLoaders={4}
                      height={165}
                      width="100%"
                    >
                      {Object.keys(listingDetails?.ratings || [])
                        .map((ratingType) => ({
                          ...ratingDetailMap[ratingType],
                          rating: listingDetails?.ratings[ratingType],
                        }))
                        .map(RatingTile)}
                    </IfLoading>
                  </DetailList>
                </RatingsSection>
              )}
              {/* Amenities */}
              {amenities.length > 0 && (
                <AmenitiesSection
                  maxWidth={'100%'}
                  data-testid="amenities-section"
                >
                  <SectionHeading>Amenities</SectionHeading>
                  <Description>What this place has to offer</Description>

                  <DetailList numColumns={!user && minWidthMedium ? 3 : 2}>
                    <IfLoading
                      isLoading={listingDetailsLoading}
                      loadMultiple={true}
                      numLoaders={4}
                      height={52}
                      width="100%"
                    >
                      {amenities}
                    </IfLoading>
                  </DetailList>
                </AmenitiesSection>
              )}
            </ListingDetailsLeft>

            <ListingDetailsRight>
              {/* Ask a question desktop */}
              {!!user && showAskQuestion && (
                <AskQuestion
                  listingDetails={listingDetails}
                  toggleSentiment={sentimentMutation.mutate}
                  setAskQuestion={setShowAskQuestion}
                  listingDetailsLoading={listingDetailsLoading}
                />
              )}
            </ListingDetailsRight>
          </ListingDetailsContainer>
          {/* Public CTA */}
          {!user && (
            <section css={{ textAlign: 'center' }}>
              <img src={WomanOnComputer} alt="women on computer" />
              <SignUpSectionHeading>
                Sign up to unlock access in 2 mins or less!
              </SignUpSectionHeading>

              <SignUpSectionIcons>
                <IconContent>
                  <Icon icon={PrivacyProtection} variant="light-large" />
                  <p css={{ fontSize: '1rem', fontWeight: 400 }}>
                    We value your privacy and protect your data
                  </p>
                </IconContent>

                <IconContent css={{ margin: '0.5rem auto 2.75rem' }}>
                  <Icon
                    css={{ stroke: color.primaryGreen }}
                    icon={CheckSvg}
                    variant="light-large"
                  />
                  <SignUpContent>
                    Transparent reviews from renters
                  </SignUpContent>
                </IconContent>
              </SignUpSectionIcons>
            </section>
          )}
          {/* Map View */}
          {user && (
            <LocationSection
              data-testid="location-section"
              maxWidth={!user ? '100%' : '618px'}
            >
              <SectionHeading>Location</SectionHeading>
              <Description>Get a sense of your future neighborhood</Description>

              <MapContainer>
                {!listingDetailsLoading && (
                  <GoogleMapReact
                    resetBoundsOnResize
                    center={{
                      lat: listingDetails?.approximateLocation?.latitude,
                      lng: listingDetails?.approximateLocation?.longitude,
                    }}
                    zoom={15}
                    options={{
                      draggable: false,
                      disableDefaultUI: true,
                      keyboardShortcuts: false,
                    }}
                  >
                    <MapMarker
                      lat={listingDetails?.approximateLocation?.latitude}
                      lng={listingDetails?.approximateLocation?.longitude}
                    />
                  </GoogleMapReact>
                )}
              </MapContainer>
            </LocationSection>
          )}
          {/* Similar Thoughts */}
          {user && listingDetails && (
            <SimilarThoughts listingDetails={listingDetails} />
          )}
        </ContentContainer>

        {!user && (
          <CallToActionSection data-testid="call-to-action-section">
            <ConditionalLink to="/create">
              <Button variant="primary" color="primaryPink">
                {minWidthMedium
                  ? 'Sign up to view full listing'
                  : 'Unlock access, create an account'}
              </Button>
            </ConditionalLink>
          </CallToActionSection>
        )}

        <Footer />
      </AppPage>
    </Container>
  );
};

export default ListingDetailPage;
