import React from 'react';
import { useHistory, useParams } from 'react-router';
import { DecisionPage } from '../../components/DecisionPage';

const EditListing: React.FC = () => {
  const history = useHistory();
  const { listingId } = useParams<{ listingId: string }>();

  return (
    <DecisionPage
      title="What would you like to edit?"
      buttonOptions={[
        {
          text: 'Unit details',
          icon: {
            iconName: 'edit',
          },
          handleOnClick: () =>
            history.push(`/list-your-home/${listingId}/edit`),
          elementId: 'edit-unit-details',
        },
        {
          text: 'Video and images',
          icon: {
            iconName: 'video',
          },
          handleOnClick: () =>
            history.push(`/list-your-home/${listingId}/media/edit`),
          elementId: 'edit-video-images',
        },
      ]}
      bottomButton={{
        text: 'Go back',
        elementId: 'go-back-btn',
        handleOnClick: () => history.goBack(),
      }}
    />
  );
};

export default EditListing;
