import type { ComponentPropsWithoutRef } from 'react';
import styled from '@emotion/styled';
import Lottie from 'react-lottie';
import loading from './loading.json';

export type LoaderProps = ComponentPropsWithoutRef<'div'>;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FullscreenLoaderContainer = styled(LoaderContainer)`
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  width: 100%;
`;

export function FullscreenLoader({ className }: LoaderProps) {
  return (
    <FullscreenLoaderContainer className={className}>
      <Lottie
        options={{
          animationData: loading,
          loop: true,
        }}
        width={600}
        height={600}
      />
    </FullscreenLoaderContainer>
  );
}
