/* @jsxImportSource @emotion/react */
import useMediaQuery from '../../hooks/useMediaQuery';

import {
  HomeWrapper,
  HomeSmallLogo,
  HomeLargeLogo,
  HomeCta,
  HomeActions,
  HomeButton,
  HomeContent,
  HomeImageContainer,
  HomeImage,
} from './components';
import { Link } from 'react-router-dom';
import desktopImage from './unicorn-login-home-desktop.png';
import { useTheme } from '@emotion/react';
import Paragraph, {
  ParagraphVariant,
} from '../../components/Typography/Paragraph';

const Home = () => {
  const { color, mediaQuery } = useTheme();
  const isMediumWidth = useMediaQuery(mediaQuery.medium);

  return (
    <HomeWrapper>
      <HomeContent>
        <HomeCta>
          {isMediumWidth ? <HomeLargeLogo /> : <HomeSmallLogo />}
          {!isMediumWidth && (
            <Paragraph
              css={{ width: '260px', margin: '0 auto' }}
              color={color.primaryGreen}
              variant={ParagraphVariant.xxLarge}
            >
              Access to apartments months before anyone else
            </Paragraph>
          )}
        </HomeCta>
        <HomeActions>
          <Link to="/create" className="primaryGreen" component={HomeButton}>
            Get Started
          </Link>
          <Link to="/login" className="primaryPink" component={HomeButton}>
            Sign In
          </Link>
        </HomeActions>
      </HomeContent>
      {isMediumWidth ? (
        <HomeImageContainer>
          <HomeImage src={desktopImage} />
        </HomeImageContainer>
      ) : (
        ''
      )}
    </HomeWrapper>
  );
};

export default Home;
