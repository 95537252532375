import { useHistory, useParams } from 'react-router';
import { useService } from '../../hooks';

const QrCodeRedirect: React.FC = () => {
  const { code } = useParams<{ code: string }>();
  const history = useHistory();
  const service = useService<'qrCode'>('qrCode');

  const redirect = async () => {
    try {
      const { redirectUrl } = await service.byCode(code.toLocaleLowerCase());
      window.open(redirectUrl, '_self');
    } catch {
      history.push('/');
    }
  };

  redirect();

  return <></>;
};

export default QrCodeRedirect;
